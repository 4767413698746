import {Component, DestroyRef, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {generateApiKey} from 'generate-api-key';
import {object} from "@angular/fire/database";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { ClientApisLogsComponent } from '../client-apis-logs/client-apis-logs.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-client-apis-list',
  templateUrl: './client-apis-list.component.html',
  styleUrls: ['./client-apis-list.component.scss',
    '../../../common/styles/listing.scss'],
  styles: [`
    :host {
      display: flex;
      justify-content: center;
    }
  `]
})
export class ClientApisListComponent implements OnInit, OnDestroy {
  autoArchiveShortSessions: boolean;
  private clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  unArchivedVsArchived = 'unarchived';
  displayedAPIKeysList: any[] = [];
  originalApiKeysFromClientDoc: any;
  isCopied= false
  constructor(private firestoreService: FirestoreService,
              private clientInContextService: ClientInContextService,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private destroyRef: DestroyRef,
              private dialog: MatDialog,) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        const apiKeysFromClient = [];
        if (selectedClientDocData.apiKeys) {
          for (const apiKey of Object.keys(selectedClientDocData.apiKeys)) {
            apiKeysFromClient.push({
              key: apiKey,
              ...selectedClientDocData.apiKeys[apiKey]
            });
          }
        }
        this.originalApiKeysFromClientDoc = apiKeysFromClient?.slice() ?? [];
        this.displayedAPIKeysList = apiKeysFromClient;
    });
  }

  ngOnInit(): void {
  }

  onCopySucess(){
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false
    }, 500);
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  reloadClientInContext() {
    const clientSubscription = this.firestoreService.getClientById(this.selectedClientDocData.id).pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((clientFromDB) => {
      const loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
          if (userDocData) {
            const clientElementInUserDoc = userDocData?.clients.filter(client => client.clientId === clientFromDB.id);
            if (clientElementInUserDoc && clientElementInUserDoc.length > 0) {
              clientFromDB.role = clientElementInUserDoc[0]?.role;
            }
            this.clientInContextService.clientInContextSubject.next(clientFromDB);
            loggedInUserFromAuthServiceSubscription?.unsubscribe();
            clientSubscription?.unsubscribe();
          }
        });
    });
  }



  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
  }

  areArchivedAPIKeysShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedAPIKeysShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }

  createNewAPIKey() {
    const newAPIKeyObject: any = {
      key: generateApiKey({
        method: 'string',
        length: 32,
        pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
      }),
      getTransactional: true,
      getMaster: true,
      putMaster: false,
      isArchived: false,
      service: null,
    }
    this.displayedAPIKeysList.push(newAPIKeyObject);
  }

  async archiveAPIKey($event, apiKey, isArchival) {
    if (this.originalApiKeysFromClientDoc.filter(apiKeyRecord => apiKeyRecord.key === apiKey.key).length === 0) {
      //API Key is not saved yet in Firestore
      this.displayedAPIKeysList.filter(apiKeyRecord => apiKeyRecord.key === apiKey.key)[0].isArchived = isArchival;
      return;
    }
    this.displayedAPIKeysList.filter(apiKeyRecord => apiKeyRecord.key === apiKey.key)[0].isArchived = isArchival;
    const clientUpdateObject = this.mapAPIKeysInMemoryToFSFormat();
    try {
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, clientUpdateObject);
      this.reloadClientInContext();
      if (isArchival) {
        this.openSnackBar('API Key archived successfully', 'success');
      } else {
        this.openSnackBar('API key unarchived successfully', 'success');
      }
    } catch (error) {
      console.log('Error in archiving/unarchiving API key:' + JSON.stringify(error));
      if (isArchival) {
        this.openSnackBar('Error in archiving API key:' + error.message, 'failure');
      } else {
        this.openSnackBar('Error in unarchiving API key:' + error.message, 'failure');
      }
    }
  }

  async saveAPIKeys() {
    try {
      const clientUpdateObject = this.mapAPIKeysInMemoryToFSFormat();
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, clientUpdateObject);
      this.reloadClientInContext();
      this.openSnackBar('API keys saved successfully', 'success');
    } catch (error) {
      console.log('Error in saving API keys:' + JSON.stringify(error));
      this.openSnackBar('Error in saving API keys:' + error.message, 'failure');
    }

  }

  mapAPIKeysInMemoryToFSFormat() {
    const clientUpdateObject: any = {};
    const apiKeysObj: any = {};
    for (const apiKeyEntry of this.displayedAPIKeysList) {
      apiKeysObj[apiKeyEntry.key] = {
        getTransactional: apiKeyEntry.getTransactional,
        getMaster: apiKeyEntry.getMaster,
        putMaster: apiKeyEntry.putMaster,
        service: apiKeyEntry.service ?? null,
        isArchived: apiKeyEntry.isArchived,
      }
    }
    clientUpdateObject.apiKeys = apiKeysObj;
    clientUpdateObject.apiKeyIds = this.displayedAPIKeysList.filter(apiKey => !apiKey.isArchived).map(apiKey => apiKey.key);
    return clientUpdateObject;
  }

  cancelClicked() {
    this.displayedAPIKeysList = this.originalApiKeysFromClientDoc.filter(apiKey => apiKey.isArchived === this.areArchivedAPIKeysShown());
  }

  getAPIKeysToDisplay() {
    if (this.areUnArchivedAPIKeysShown()) {
      return this.displayedAPIKeysList.filter(apiKey => !apiKey.isArchived);
    } else {
      return this.displayedAPIKeysList.filter(apiKey => apiKey.isArchived);
    }
  }


  openApiLogs(){
    this.dialog.open(ClientApisLogsComponent, {});
  }
}

