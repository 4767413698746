<div style="display: flex; align-items: center; justify-content: center;">
  <ngx-charts-pie-chart
    [view]="view"
    [scheme]="colorScheme"
    [results]="pieChartData"
    tooltipDisabled="true"
    [gradient]="gradient"
    [legend]="showLegend"
    maxLabelLength="36"
    [legendPosition]="legendPosition"
    legendTitle="Tasks"
    trimLabels="false"
    [labels]="showLabels"
    [doughnut]="isDoughnut"
    [labelFormatting]="labelFormatting"
    style="fill: #FFFFFF;"
  *ngIf="pieChartData?.length > 0">
  </ngx-charts-pie-chart>
  <div *ngIf="pieChartDataToDisplay?.length === 0">
    There is no data available for today
  </div>
</div>
