import {Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, OnChanges, SimpleChanges, DestroyRef} from '@angular/core';
import {FirestoreService} from "../../../services/firestore.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditLayoutDialogComponent} from "./edit-layout-dialog/edit-layout-dialog.component";
import {Subscription} from "rxjs";
import {ClientInContextService} from "../../../services/client-in-context.service";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-client-location-layout',
	templateUrl: './client-location-layout.component.html',
	styleUrls: ['./client-location-layout.component.scss']
})
export class ClientLocationLayoutComponent implements OnInit, OnDestroy, OnChanges {
	@Input() locationId: string;
	@Input() locationName: string;
	@Input() clientId: string;
	@Input() triggerReload: Date;
	public layoutList: any[];
	clientInContextServiceSubscription: Subscription;
	selectedClientDocData: any;
  private allLayoutsSubscription: Subscription;

	constructor(private firestoreService: FirestoreService,
							private dialog: MatDialog,
							private clientInContextService: ClientInContextService,
              private destroyRef: DestroyRef) {
		this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
      });
	}

  ngOnChanges(changes: SimpleChanges): void {
		if (changes.triggerReload?.currentValue) {
      //console.log(`Propagating triggerReload change`);
			this.triggerReload = changes.triggerReload.currentValue;
		}
	}



	ngOnInit(): void {
		this.allLayoutsSubscription = this.firestoreService
			.getAllLayoutsForLocIdForClientId(this.locationId, this.clientId)
      .pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((layoutList) => {
				this.layoutList = layoutList.map((layout) => {
					return {
						...layout,
						isLayoutNameBeingEdited: false
					};
				});
			});
	}

  async saveNewLayoutName($event, layout) {
		$event?.stopPropagation();
		if (!layout.name) {
			return;
		}
		await this.firestoreService.updateLytByIdForLocationIdClientId(this.clientId, this.locationId, layout.id, {name: layout.name ? layout.name : null});
	}

	preventPropagation($event) {
		$event.stopPropagation();
	}

	openEditLayoutDialog($event, layout){
		$event.stopPropagation();
		const dialogConfig = new MatDialogConfig();
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			clientId: this.clientId,
			locationId: this.locationId,
			locationName: this.locationName,
			layout
		};
		this.dialog.open(EditLayoutDialogComponent, dialogConfig);
	}

	ngOnDestroy(): void {
		this.clientInContextServiceSubscription?.unsubscribe();
    this.allLayoutsSubscription?.unsubscribe();
	}
}
