import {AfterViewInit, Component, DestroyRef, Inject, Injectable, OnDestroy, ViewChild} from '@angular/core';
import {ActivitySession, Column} from '../../../common/interfaces/new-table-interfaces';
import {MatTableDataSource} from '@angular/material/table';
import {RegistrationMainAttributes} from '../../../common/interfaces/clock-interfaces';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth.service';
import {FirestoreService} from '../../../services/firestore.service';
import {Router} from '@angular/router';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {dateIsToday, TIME_FORMAT, TIME_ZONE} from '../../../common/utils/time-utils';
import momentDurationFormatSetup from 'moment-duration-format';
import {EditPresenceDialogComponent} from './edit-presence-dialog/edit-presence-dialog.component';
import {
  MatDatepickerModule,
  MatDateRangeSelectionStrategy,
  DateRange,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import {DateAdapter} from '@angular/material/core';
import {FormControl, FormGroup} from '@angular/forms';
import {CreatePresenceDialogComponent} from './create-presence-dialog/create-presence-dialog.component';
import {ReportExportModalComponent} from '../../../common/components/report-export-modal/report-export-modal.component';
import * as XLSX from 'xlsx';
import {TableColumnProperties, Workbook} from 'exceljs';
import {saveAs} from 'file-saver';
import {TranslateService} from '@ngx-translate/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
//import 'moment/locale/nl';
momentDurationFormatSetup(moment);
const dayOfWeekOffset = (date: Date) =>
  date.getDay() === 0 ? 6 : date.getDay() - 1;
const addDays = (date: Date, days: number) =>
  new Date(date.setDate(date.getDate() + days));
const addMonths = (date: Date, months: number) =>
  new Date(date.setMonth(date.getMonth() + months));

@Injectable()
export class SelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  off = false;

  constructor(private dateAdapter: DateAdapter<D>) {
  }

  selectionFinished(date: D | null, currentRange: DateRange<D>): DateRange<D> {
    if (!this.off) {
      return this._createWeekRange(date);
    }

    let {start, end} = currentRange;

    if (start == null) {
      start = date;
    } else if (end == null && date && this.dateAdapter.compareDate(date, start) >= 0) {
      end = date;
    } else {
      start = date;
      end = null;
    }

    return new DateRange<D>(start, end);
  }

  createPreview(activeDate: D | null, currentRange: DateRange<D>): DateRange<D> {
    if (!this.off) {
      return this._createWeekRange(activeDate);
    }

    let start: D | null = null;
    let end: D | null = null;

    if (currentRange.start && !currentRange.end && activeDate) {
      start = currentRange.start;
      end = activeDate;
    }

    return new DateRange<D>(start, end);
  }

  private _createWeekRange(date: D | null): DateRange<D> {
    if (date) {
      // const a = mondayToFriday(date)
      const dayOfWeek = this.dateAdapter.getDayOfWeek(date);
      const dayOfWeekOffsetWeekRange = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      const start = this.dateAdapter.addCalendarDays(date, -0 - dayOfWeekOffsetWeekRange);
      const end = this.dateAdapter.addCalendarDays(date, 6 - dayOfWeekOffsetWeekRange);
      return new DateRange<D>(start, end);
    }

    return new DateRange<D>(null, null);
  }
}

@Component({
  selector: 'app-registrations-presences-section',
  templateUrl: './registrations-presences-section.component.html',
  styleUrls: ['./registrations-presences-section.component.scss',
    '../../../common/styles/listing.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: SelectionStrategy,
    },
  ],
})
export class RegistrationsPresencesSectionComponent implements AfterViewInit, OnDestroy {
  today = new Date();
  day = this.today.getDate();
  month = this.today.getMonth();
  year = this.today.getFullYear();
  weekNumber = 0;
  weekSelected: any;
  dateRange: FormGroup;
  quickControl = new FormControl('WEEK');

  @ViewChild('picker') picker: MatDateRangePicker<Date>;

  columns: Column[] = [
    {
      name: 'workerName',
      displayName: 'DASHBOARD.TABLEHEAD.WORKERS.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: null,
      footerValue: 'Total',
    },
    {
      name: 'workerGroupName',
      displayName: 'DASHBOARD.TABLEHEAD.GROUP.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'daysWorked',
      displayName: 'DASHBOARD.TABLEHEAD.DAYSWORKED.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'hoursWorked',
      displayName: 'DASHBOARD.TABLEHEAD.HOURSWORKED.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: 'sumDurations',
      footerValue: '',
    },
    {
      name: 'breakHoursPaid',
      displayName: 'DASHBOARD.TABLEHEAD.BREAKHOURSPAID.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: 'sumDurations',
      footerValue: '',
    },
    {
      name: 'breakHoursUnpaid',
      displayName: 'DASHBOARD.TABLEHEAD.BREAKHOURSUNPAID.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: 'sumDurations',
      footerValue: '',
    },
    {
      name: 'hoursPaid',
      displayName: 'DASHBOARD.TABLEHEAD.HOURSPAID.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: 'sumDurations',
      footerValue: '',
    },
    /*{
      name: 'sickHours',
      displayName: 'Sick hours',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: null,//change to 'sumDurations' - when sick hours is implemented
      footerValue: '',
    },*/
  ];

  toggleTableChart: 'table' | 'chart' = 'table';
  selectedView = 'listView';
  selectedMode = 'THIS_WEEK';

  // TABLE
  displayedColumns: string[] = this.columns.filter((column) => column.showHeader).map((column) => column.name);

  displayedTopFilters: any[] = this.columns.filter(
    (column) => column.showTopFilter
  );

  dataSource: MatTableDataSource<any>;
  lastDirection: '' | 'asc' | 'desc' = '';
  filterDictionary = new Map<string, string | number | boolean>();

  columnsHeadersToDisplayNested: string[] = [
    'date',
    'locationName',
    'startTime',
    'endTime',
    'hoursWorked',
    'breakHoursPaid',
    'breakHoursUnpaid',
    'hoursPaid',
    //'sickHours',
    'edit',
    'checkColumn'
  ];

  workerNestedAttributesReadableMap: Map<string, string> = new Map([
    ['date', 'DASHBOARD.TABLEHEAD.DATE.TITLE.TEXT'],
    ['locationName', 'DASHBOARD.TABLEHEAD.LOCATION.TITLE.TEXT'],
    ['startTime', 'DASHBOARD.TABLEHEAD.STARTTIME.TITLE.TEXT'],
    ['endTime', 'DASHBOARD.TABLEHEAD.ENDTIME.TITLE.TEXT'],
    ['hoursWorked', 'DASHBOARD.TABLEHEAD.HOURSWORKED.TITLE.TEXT'],
    ['breakHoursPaid', 'DASHBOARD.TABLEHEAD.BREAKHOURSPAID.TITLE.TEXT'],
    ['breakHoursUnpaid', 'DASHBOARD.TABLEHEAD.BREAKHOURSUNPAID.TITLE.TEXT'],
    ['hoursPaid', 'DASHBOARD.TABLEHEAD.HOURSPAID.TITLE.TEXT'],
    //['sickHours', 'Sick hours'],
    ['edit', ' '],
    ['checkColumn', ' ']
  ]);

  columnsToDisplayNested: string[] = [
    'date',
    'locationName',
    'startTime',
    'endTime',
    'hoursWorked',
    'breakHoursPaid',
    'breakHoursUnpaid',
    'hoursPaid',
    //'sickHours',
    'edit',
    'checkColumn'
  ];

  columnHeaderToColumnMapDailyOverviewWeekly: Map<string, string> = new Map([
    ['workerName', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.WORKER.LABEL.TEXT')],
    ['locationName', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.LOCATION.LABEL.TEXT')],
    ['sickHours', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.SICK.LABEL.TEXT')],
    ['monday', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.MONDAY.LABEL.TEXT')],
    ['tuesday', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.TUESDAY.LABEL.TEXT')],
    ['wednesday', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.WEDNESDAY.LABEL.TEXT')],
    ['thursday', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.THURSDAY.LABEL.TEXT')],
    ['friday', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.FRIDAY.LABEL.TEXT')],
    ['saturday', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.SATURDAY.LABEL.TEXT')],
    ['sunday', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.SUNDAY.LABEL.TEXT')],
    ['hoursPaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.HOURSPAID.LABEL.TEXT')]
  ]);

  columnHeaderToColumnMapDailyDetail: Map<string, string> = new Map([
    ['workerName', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.WORKER.LABEL.TEXT')],
    ['locationName', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.LOCATION.LABEL.TEXT')],
    ['date', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.DATE.LABEL.TEXT')],
    ['day', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.DAY.LABEL.TEXT')],
    ['startTime', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.STARTTIME.LABEL.TEXT')],
    ['endTime', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.ENDTIME.LABEL.TEXT')],
    ['hoursPaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.HOURSPAID.LABEL.TEXT')],
    ['breakHoursPaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.BREAKSPAID.LABEL.TEXT')],
    ['breakHoursUnpaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.BREAKSUNPAID.LABEL.TEXT')],
    ['sickHours', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.SICK.LABEL.TEXT')],
    ['hoursWorked', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.TOTALHOURSWORKED.LABEL.TEXT')]
  ]);

  columnHeaderToColumnMapDailyUpdateOverview: Map<string, string> = new Map([
    ['workerName', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.WORKER.LABEL.TEXT')],
    ['locationName', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.LOCATION.LABEL.TEXT')],
    ['date', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.DATE.LABEL.TEXT')],
    ['day', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.DAY.LABEL.TEXT')],
    ['startTime', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.STARTTIME.LABEL.TEXT')],
    ['endTime', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.ENDTIME.LABEL.TEXT')],
    ['hoursPaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.HOURSPAID.LABEL.TEXT')],
    ['breakHoursPaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.BREAKSPAID.LABEL.TEXT')],
    ['breakHoursUnpaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.BREAKSUNPAID.LABEL.TEXT')],
    ['sickHours', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.SICK.LABEL.TEXT')],
    ['totalPaid', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.TOTALPAID.LABEL.TEXT')],
    ['updatedBy', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.UPDATEDBY.LABEL.TEXT')],
    ['updatedAt', this.translateService.instant('DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.UPDATEDAT.LABEL.TEXT')]
  ]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dateInContextSubscription: Subscription;
  selectedDate: Date = new Date();
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  unarchivingPresence = false;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  presencesSubscription: Subscription;
  presencesRawData: any[];
  filterString: string;
  expandedElement: any = null;
  usedStartDate: object;
  isArchivedShown = false;
  tableData: any[];
  workerTotalsMap: Map<any, any>;
  clientLocInContextServiceSubscription: Subscription;
  selectedLocationId: string;
  workerIdForLoggedInUser: string;
  selectedLocationName: string;
  allRowsExpanded = false;

  constructor(private authService: AuthService,
              private firestoreService: FirestoreService,
              private router: Router,
              private clientInContextService: ClientInContextService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private dateAdapter: DateAdapter<Date>,
              private translateService: TranslateService,
              private destroyRef: DestroyRef,
              @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
              private rg: SelectionStrategy<any>) {

    this.weekNumber = moment().isoWeek();
    const fromDate = moment().startOf('isoWeek').toDate();
    const toDate = moment().endOf('isoWeek').toDate();
    this.dateRange = new FormGroup({
      start: new FormControl(fromDate),
      end: new FormControl(toDate),
    });
    this.dateAdapter.setLocale('nl');
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
        (userDocData) => {
          this.loggedInUserDocData = userDocData;
          /*if (userDocData.languageCode === 'nl') {
            this.setDutchMomentLocale();
          }*/
          this.workerIdForLoggedInUser = userDocData.associatedWorkerId ?? null;
          this.clientLocInContextServiceSubscription = this.clientInContextService.clientLocSubject.subscribe(selectedLocation => {
            this.selectedLocationId = !selectedLocation || (selectedLocation?.id === '-1') ? null : selectedLocation?.id;
            this.selectedLocationName = !selectedLocation || (selectedLocation?.id === '-1') ? null : selectedLocation?.name;
            this.fetchPresencesForClient(this.isArchivedShown, new Date());
          });
        }
      );
    });
  }

  off = false;

  get customRange() {
    return this.rg.off;
  }

  set customRange(value) {
    this.rg.off = value;
  }

  weekToggle(value: string) {
    console.log(value);

    switch (value) {
      case 'THIS_WEEK':
        this.customRange = false;
        this.selectedMode = 'THIS_WEEK';
        this.dateRange.controls.start.setValue(this.thisWeek().start);
        this.dateRange.controls.end.setValue(this.thisWeek().end);
        this.picker.close();
        break;
      case 'LAST_WEEK':
        this.customRange = false;
        this.selectedMode = 'LAST_WEEK';
        this.dateRange.controls.start.setValue(this.lastWeek().start);
        this.dateRange.controls.end.setValue(this.lastWeek().end);
        this.picker.close();
        break;
      case 'THIS_MONTH':
        this.customRange = false;
        this.selectedMode = 'THIS_MONTH';
        this.dateRange.controls.start.setValue(this.thisMonth().start);
        this.dateRange.controls.end.setValue(this.thisMonth().end);
        this.picker.close();
        break;
      case 'LAST_MONTH':
        this.customRange = false;
        this.selectedMode = 'LAST_MONTH';
        this.dateRange.controls.start.setValue(this.lastMonth().start);
        this.dateRange.controls.end.setValue(this.lastMonth().end);
        this.picker.close();
        break;
      case 'WEEK_RANGE':
        this.selectedMode = 'WEEK_RANGE';
        this.customRange = false;
        this.dateRange.controls.start.setValue(this.thisWeek().start);
        this.dateRange.controls.end.setValue(this.thisWeek().end);
        this.picker.close();
        break;
      case 'CUSTOM_RANGE':
        this.selectedMode = 'CUSTOM_RANGE';
        this.customRange = true;
        break;
    }
  }

  thisWeek() {
    const dayOfWeek = new Date().getDay();
    const dayOfWeekOffsetThisWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    const start = addDays(new Date(), -0 - dayOfWeekOffsetThisWeek);
    const end = addDays(new Date(), 6 - dayOfWeekOffsetThisWeek);
    return {start, end};
  }

  lastWeek() {
    const dayOfWeek = new Date().getDay();
    const dayOfWeekOffsetLastWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    const start = addDays(new Date(), -0 - dayOfWeekOffsetLastWeek - 7);
    const end = addDays(new Date(), 6 - dayOfWeekOffsetLastWeek - 7);
    return {start, end};
  }

  thisMonth() {
    const d = new Date();
    const start = new Date(d.getFullYear(), d.getMonth(), 1);
    const end = addDays(
      addMonths(new Date(d.getFullYear(), d.getMonth(), 1), 1),
      -1
    );
    return {start, end};
  }

  lastMonth() {
    const d = new Date();
    const start = addMonths(new Date(d.getFullYear(), d.getMonth(), 1), -1);
    const end = addDays(new Date(d.getFullYear(), d.getMonth(), 1), -1);
    return {start, end};
  }

  ngAfterViewInit() {
    this.dateRange.valueChanges.subscribe(value => {
      if (this.usedStartDate === value.start && !['WEEK_RANGE', 'CUSTOM_RANGE'].includes(this.selectedMode)) {
        //console.log('!week/custom range - start value same');
        return;
      }

      if ((!value.start || !value.end) && ['WEEK_RANGE', 'CUSTOM_RANGE'].includes(this.selectedMode)) {
        //console.log('week/custom range - start or end value missing');
        return;
      }
      //console.log('went through');
      this.weekNumber = moment(value.start).isoWeek();
      this.usedStartDate = value.start;
      this.fetchPresencesForClient(this.isArchivedShown, moment(value.start).toDate());
      //console.log(value);
      //console.log(moment(value.start).toDate());
    });
  }

  getDisplayedCols() {
    return [...this.displayedColumns, 'checkColumn','expand'];
  }

  fetchPresencesForClient(isArchived: boolean, selectedDate: Date) {
    this.presencesSubscription?.unsubscribe();
    let customRangeStartDate = null;
    let customRangeEndDate = null;
    if (this.dateRange.controls.start.value) {
      customRangeStartDate = moment(this.dateRange.controls.start.value).toDate();
      customRangeEndDate = moment(this.dateRange.controls.end.value).toDate();
    }
    this.presencesSubscription =
      this.firestoreService.getPresencesForClientId(this.selectedClientDocData.id, customRangeStartDate ?? selectedDate, isArchived, this.selectedMode, this.selectedLocationId,
        customRangeEndDate ?? null).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((presences: any[]) => {
        this.tableData = [];
        //presences = presences.filter(presence => presence.deviceType === 'CLOCKWEB' || presence.deviceType === 'CLOCK');
        this.presencesRawData = presences;
        this.tableData = this.mapFirestoreDataToTableData(presences);

        this.tableData.sort((n1, n2) => {
          return +n1.workerName > +n2.workerName ? -1 : +n1.workerName < +n2.workerName ? 1 : 0;
        });

        console.log(`Loading presences data - length:${this.tableData.length}`)
        if (!this.dataSource) {
          this.dataSource = new MatTableDataSource(this.tableData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.dataSource.data = this.tableData;
        }
        this.setUpFiltering();
      });
  }

  mapFirestoreDataToTableData(presences: any[]) {
    const workerTableData = [];
    const workerPrecensesMap = new Map();
    this.workerTotalsMap = new Map();
    for (const presence of presences) {
      if (presence.workerId) {
        let workerSpecificList = workerPrecensesMap.get(presence.workerId);
        if (!workerSpecificList) {
          workerSpecificList = []
        }
        workerSpecificList.push(presence);
        workerPrecensesMap.set(presence.workerId, workerSpecificList);

        let workerSpecificTotalsObj: any = this.workerTotalsMap.get(presence.workerId);
        if (!workerSpecificTotalsObj) {
          workerSpecificTotalsObj = {
            totalHoursWorked: 0,
            totalHoursPaid: 0,
            totalBreakHoursPaid: 0,
            totalBreakHoursUnpaid: 0,
            locSpecificHours: new Map()
          };
        }

        if (presence.endTimestamp) {
          if (presence.endTimestampRounded && (presence.deviceType !== 'CLOCKWEB')) {
            workerSpecificTotalsObj.totalHoursWorked += presence.durationHoursWorkedRounded;
            workerSpecificTotalsObj.totalHoursPaid +=
              (presence.durationPaidHoursRounded || presence.durationPaidHoursRounded === 0) && (presence.deviceType !== 'CLOCKWEB') ?
                presence.durationPaidHoursRounded : (presence.durationPaidHours ?? 0);
            workerSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ?? 0;
            workerSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ?? 0;

            let workerLocSpecificTotalsObj = workerSpecificTotalsObj.locSpecificHours.get(presence.locationName);
            if (!workerLocSpecificTotalsObj) {
              workerLocSpecificTotalsObj = {}
              workerLocSpecificTotalsObj.totalHoursWorked =  presence.durationHoursWorkedRounded;
              workerLocSpecificTotalsObj.totalHoursPaid = (presence.durationPaidHoursRounded || presence.durationPaidHoursRounded === 0) && (presence.deviceType !== 'CLOCKWEB') ?
                presence.durationPaidHoursRounded : (presence.durationPaidHours ?? 0);
              workerLocSpecificTotalsObj.totalBreakHoursPaid = presence.durationBreaksPaid ?? 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid = presence.durationBreaksUnpaid ?? 0;
            } else {
              workerLocSpecificTotalsObj.totalHoursWorked +=  presence.durationHoursWorkedRounded;
              workerLocSpecificTotalsObj.totalHoursPaid += (presence.durationPaidHoursRounded || presence.durationPaidHoursRounded === 0) && (presence.deviceType !== 'CLOCKWEB') ?
                presence.durationPaidHoursRounded : (presence.durationPaidHours ?? 0);
              workerLocSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ?? 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ?? 0;
            }
            workerSpecificTotalsObj.locSpecificHours.set(presence.locationName, workerLocSpecificTotalsObj);
            this.workerTotalsMap.set(presence.workerId, workerSpecificTotalsObj);
          } else {
            if (presence.durationHoursWorked) {
              if (presence.durationBreaksUnpaid) {
                presence.durationPaidHours = presence.durationHoursWorked - presence.durationBreaksUnpaid;
              } else {
                presence.durationPaidHours = presence.durationHoursWorked;
              }
            }
            workerSpecificTotalsObj.totalHoursWorked += presence.durationHoursWorked ? presence.durationHoursWorked : 0;
            workerSpecificTotalsObj.totalHoursPaid += presence.durationPaidHours ? presence.durationPaidHours : 0;
            workerSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ? presence.durationBreaksPaid : 0;
            workerSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ? presence.durationBreaksUnpaid : 0;

            let workerLocSpecificTotalsObj = workerSpecificTotalsObj.locSpecificHours.get(presence.locationName);
            if (!workerLocSpecificTotalsObj) {
              workerLocSpecificTotalsObj = {};
              workerLocSpecificTotalsObj.totalHoursWorked = presence.durationHoursWorked ? presence.durationHoursWorked : 0;
              workerLocSpecificTotalsObj.totalHoursPaid = presence.durationPaidHours ? presence.durationPaidHours : 0;
              workerLocSpecificTotalsObj.totalBreakHoursPaid = presence.durationBreaksPaid ? presence.durationBreaksPaid : 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid = presence.durationBreaksUnpaid ? presence.durationBreaksUnpaid : 0;
            } else {
              workerLocSpecificTotalsObj.totalHoursWorked += presence.durationHoursWorked ? presence.durationHoursWorked : 0;
              workerLocSpecificTotalsObj.totalHoursPaid += presence.durationPaidHours ? presence.durationPaidHours : 0;
              workerLocSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ? presence.durationBreaksPaid : 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ? presence.durationBreaksUnpaid : 0;
            }
            workerSpecificTotalsObj.locSpecificHours.set(presence.locationName, workerLocSpecificTotalsObj);

            this.workerTotalsMap.set(presence.workerId, workerSpecificTotalsObj);
          }
        } else {
          if (presence.startTimestampRounded && (presence.deviceType !== 'CLOCKWEB')) {
            workerSpecificTotalsObj.totalHoursPaid +=
              presence.durationPaidHoursRounded || presence.durationPaidHoursRounded === 0 && (presence.deviceType !== 'CLOCKWEB') ?
                presence.durationPaidHoursRounded : (presence.durationPaidHours ?? 0);
            workerSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ?? 0;
            workerSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ?? 0;

            let workerLocSpecificTotalsObj = workerSpecificTotalsObj.locSpecificHours.get(presence.locationName);
            if (!workerLocSpecificTotalsObj) {
              workerLocSpecificTotalsObj = {};
              workerLocSpecificTotalsObj.totalHoursPaid = presence.durationPaidHoursRounded || presence.durationPaidHoursRounded === 0 && (presence.deviceType !== 'CLOCKWEB') ?
                presence.durationPaidHoursRounded : (presence.durationPaidHours ?? 0);
              workerLocSpecificTotalsObj.totalBreakHoursPaid = presence.durationBreaksPaid ?? 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid = presence.durationBreaksUnpaid ?? 0;
            } else {
              workerLocSpecificTotalsObj.totalHoursPaid += presence.durationPaidHoursRounded || presence.durationPaidHoursRounded === 0 && (presence.deviceType !== 'CLOCKWEB') ?
                presence.durationPaidHoursRounded : (presence.durationPaidHours ?? 0);
              workerLocSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ?? 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ?? 0;
            }
            workerSpecificTotalsObj.locSpecificHours.set(presence.locationName, workerLocSpecificTotalsObj);

            this.workerTotalsMap.set(presence.workerId, workerSpecificTotalsObj);
          } else {
            workerSpecificTotalsObj.totalHoursPaid += presence.durationPaidHours ? presence.durationPaidHours : 0;
            workerSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ? presence.durationBreaksPaid : 0;
            workerSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ? presence.durationBreaksUnpaid : 0;

            let workerLocSpecificTotalsObj = workerSpecificTotalsObj.locSpecificHours.get(presence.locationName);
            if (!workerLocSpecificTotalsObj) {
              workerLocSpecificTotalsObj = {};
              workerLocSpecificTotalsObj.totalHoursPaid = presence.durationPaidHours ? presence.durationPaidHours : 0;
              workerLocSpecificTotalsObj.totalBreakHoursPaid = presence.durationBreaksPaid ? presence.durationBreaksPaid : 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid = presence.durationBreaksUnpaid ? presence.durationBreaksUnpaid : 0;
            } else {
              workerLocSpecificTotalsObj.totalHoursPaid += presence.durationPaidHours ? presence.durationPaidHours : 0;
              workerLocSpecificTotalsObj.totalBreakHoursPaid += presence.durationBreaksPaid ? presence.durationBreaksPaid : 0;
              workerLocSpecificTotalsObj.totalBreakHoursUnpaid += presence.durationBreaksUnpaid ? presence.durationBreaksUnpaid : 0;
            }
            workerSpecificTotalsObj.locSpecificHours.set(presence.locationName, workerLocSpecificTotalsObj);

            this.workerTotalsMap.set(presence.workerId, workerSpecificTotalsObj);
          }
        }
      }
    }
    for (const workerId of workerPrecensesMap.keys()) {
      let workerSpecificPresencesList = workerPrecensesMap.get(workerId);
      const workerRecord: any = {
        workerId,
        workerName: workerSpecificPresencesList[0].workerName,
        workerGroupName: workerSpecificPresencesList[0].workerGroupName ?? ''
      }
      workerSpecificPresencesList = workerSpecificPresencesList.map(presence => {
        return {
          dateToCompare: moment(presence.startTimestamp.toDate()).tz(TIME_ZONE).format('YYYY-MM-DD'),
          ...presence
        }
      });
      workerRecord.daysWorked = [...new Set(workerSpecificPresencesList.map(p => p.dateToCompare))].length;
      const workerTotalsObj = this.workerTotalsMap.get(workerId);
      workerRecord.sickHours = '-';

      if (['THIS_WEEK', 'LAST_WEEK', 'WEEK_RANGE'].includes(this.selectedMode)) {
        workerRecord.nestedRows = this.setUpWeekListForWorker(workerSpecificPresencesList, this.workerIdForLoggedInUser === workerId);
      } else if (['THIS_MONTH', 'LAST_MONTH'].includes(this.selectedMode)) {
        workerRecord.nestedRows = this.setUpMonthListForWorker(workerSpecificPresencesList, this.workerIdForLoggedInUser === workerId);
      } else if (this.selectedMode === 'CUSTOM_RANGE') {
        workerRecord.nestedRows = this.setUpCustomDateListForWorker(workerSpecificPresencesList, this.workerIdForLoggedInUser === workerId);
      }

      workerRecord.isEndTimeMissing = workerRecord.nestedRows.filter(wr => wr.isEndTimestampMissing)?.length > 0;
      workerRecord.hoursWorked = workerTotalsObj.totalHoursWorked ? moment.duration(workerTotalsObj.totalHoursWorked, 'seconds').format(TIME_FORMAT) : '00:00';
      workerRecord.hoursPaid = workerTotalsObj.totalHoursPaid ? moment.duration(workerTotalsObj.totalHoursPaid, 'seconds').format(TIME_FORMAT) : '00:00';
      workerRecord.breakHoursPaid = workerTotalsObj.totalBreakHoursPaid ? moment.duration(workerTotalsObj.totalBreakHoursPaid, 'seconds').format(TIME_FORMAT) : '00:00';
      workerRecord.breakHoursUnpaid = workerTotalsObj.totalBreakHoursUnpaid ? moment.duration(workerTotalsObj.totalBreakHoursUnpaid, 'seconds').format(TIME_FORMAT) : '00:00';
      workerTableData.push(workerRecord);
    }
    return workerTableData;
  }

  buildNestedDataArrayForWorker(workerSpecificPresenceList) {
    const dayWiseListForWorker = [];
    for (const presence of workerSpecificPresenceList) {
      dayWiseListForWorker.push({})
    }
    return dayWiseListForWorker;
  }

  setUpFiltering() {
    this.dataSource.filterPredicate = (record, filter) => {
      const map = new Map(JSON.parse(filter));
      const returnValues = [];

      for (const [key, value] of map) {
        // @ts-ignore
        const recordValue: string | number | boolean | undefined = record[key as keyof ActivitySession];

        if (typeof recordValue === 'string') {
          const regex = new RegExp(`${value}`, 'i');
          returnValues.push(regex.test(recordValue));
        }
        if (typeof recordValue === 'number') {
          returnValues.push(recordValue === Number(value));
        }
        if (typeof recordValue === 'boolean') {
          returnValues.push(recordValue === value);
        }
      }
      return returnValues.every(Boolean);
    };

    /*//Below code maintains filter application in table when new data arrives -- no longer needed as table data loading approach changed
    for (const entry of Array.from(this.filterDictionary.entries())) {
      //console.log(JSON.stringify(entry));
      this.headerFilter({target: {value: entry[1]}}, entry[0]);
    }
   */
    this.postProcessingFilterSort();
  }

  /**
   * Sorts the list of columns based on the specified column name and direction.
   *
   * @param  columnName - The name of the column to sort by.
   * @param  direction - The sorting direction, either 'asc' (ascending) or 'desc' (descending).
   * @returns void
   */
  sortList(columnName: string, direction: 'asc' | 'desc'): void {
    this.columns.forEach((column, index) => {
      this.columns[index].filtered = column.name === columnName;
    });
    if (!this.dataSource.sort) {
      return;
    }
    if (direction === 'asc' && (this.dataSource.sort.direction !== 'asc' || this.dataSource.sort.active !== columnName)) {
      this.dataSource.sort.sort({
        id: columnName,
        start: 'asc',
        disableClear: true,
      });
    } else if (direction === 'desc' && (this.dataSource.sort.direction !== 'desc' || this.dataSource.sort.active !== columnName)) {
      this.dataSource.sort.sort({
        id: columnName,
        start: 'desc',
        disableClear: true,
      });
    }
    this.postProcessingFilterSort();
  }

  /**
   * Applies header filter to the data based on the specified column name and filter value.
   *
   * @param event - The event object containing filter-related information.
   * @param columnName - The name of the column to be filtered.
   * @returns void
   */
  headerFilter(event: any, columnName: string): void {
    if (event.target.value) {
      this.filterDictionary.set(columnName, event.target.value);
    }
    if (!event.target.value) {
      this.filterDictionary.delete(columnName);
    }
    this.applyPredicateFilter();
    this.postProcessingFilterSort();
  }

  applyPredicateFilter(): void {
    const jsonString = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
    this.dataSource.filter = jsonString;
    const filters: string[] = [];
    this.filterDictionary.forEach((value, key) => {
      if (key !== 'archived') {
        const columnForFilter = this.columns.filter(column => column.name === key)[0];
        filters.push(`${this.translateService.instant(columnForFilter.displayName)}: ${value}`);
      }
    });
    this.filterString = filters.length > 0 ? 'Filtered on ' + filters.join(' and ') : '';
  }

  /**
   * Applies a predicate filter to the data source using the filter dictionary.
   * Converts the filter dictionary to a JSON string and sets it as the data source filter.
   *
   */
  postProcessingFilterSort(): void {
    this.columns.forEach((column, index) => {
      if (this.dataSource.sort?.active === column.name || column.filterValue !== '') {
        column.filtered = true;
      } else {
        column.filtered = false;
      }
    });

    this.columns.forEach((column, columnIndex) => {
      this.columns[columnIndex].filterOptions = [];
      const optionsSet: Set<any> = new Set();
      this.dataSource.filteredData.forEach((row, rowIndex) => {
        for (const [key, value] of Object.entries(row)) {
          if (key === column.name) {
            optionsSet.add(value);
          }
        }
      });
      column.filterOptions = [...optionsSet].sort();
    });

    this.columns.forEach((column, columnIndex) => {
      const footerType = this.columns[columnIndex].footerType;
      const filteredDatasource: any[] = [];
      this.dataSource.filteredData.forEach((row, rowIndex) => {
        for (const [key, value] of Object.entries(row)) {
          if (key === column.name) {
            filteredDatasource.push(value);
          }
        }
      });
      if (footerType === 'total') {
        column.footerValue = 'Total';
      }
      if (footerType === 'sum') {
        column.footerValue = filteredDatasource.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue;
          },
          0
        );
      }
      if (footerType === 'count') {
        column.footerValue = filteredDatasource.length;
      }
      if (footerType === 'sumDurations') {
        column.footerValue = filteredDatasource.reduce(
          (accumulator, currentValue) => {
            const accumulatedValue = moment.duration(accumulator).as('seconds') + (currentValue ? moment.duration(currentValue).as('seconds') : 0);
            return moment.duration(accumulatedValue, 'seconds').format(TIME_FORMAT);
          }, 0);
      }
    });
  }

  /**
   * Clears filters and resets sorting for the data source.
   */
  removeFilters(): void {
    this.filterString = '';
    this.lastDirection = '';
    this.filterDictionary.clear();
    this.columns.forEach((column, index) => {
      this.columns[index].filterValue = '';
    });
    if (this.dataSource.sort) {
      this.dataSource.sort.active = '';
      this.dataSource.sort.direction = '';
      this.dataSource.filter = '';
    }
    this.postProcessingFilterSort();
  }

  openEditDialog($event, presenceRecord) {
    $event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      presenceRecord,
    };
    this.dialog.open(EditPresenceDialogComponent, dialogConfig);
  }

  async unarchivePresence($event, presenceRecord) {
    $event.stopPropagation();
    try {
      this.unarchivingPresence = true;
      await this.firestoreService.unarchiveAllTaskRegnsNPresence(presenceRecord.presenceDoc, this.selectedClientDocData.id);
      this.unarchivingPresence = false;
    } catch (error: any) {
      this.unarchivingPresence = false;
      this.openSnackBar('Error in presence unarchival: ' + error.message, 'error');
    }
  }

  openReportExportDialog(reportName) {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Select Presence Data Export Format',
      downloadOptions: [{name: 'Excel', icon: 'news'}, {name: 'CSV', icon: 'csv'}, {name: 'JSON', icon: 'data_object'}]
    };
    this.dialog.open(ReportExportModalComponent, dialogConfig).afterClosed().subscribe(data => {
      this.exportCSV(reportName, data.downloadAs.name, data.isTitled);
    });
  }

  expandRow(element: any) {
    this.allRowsExpanded = false;
    this.expandedElement = this.expandedElement?.workerId === element.workerId ? null : element;
  }

  applyExpandedClass(element: any) {
    return this.expandedElement?.workerId === element.workerId;
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.dateInContextSubscription?.unsubscribe();
    this.presencesSubscription?.unsubscribe();
    this.clientLocInContextServiceSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
  }

  setUpWeekListForWorker(presenceListForWorker, isLoggedInUser) {
    let count = 0;
    const workerSpecificList = [];
    const startOfWeekMoment = moment(this.dateRange.value.start).startOf('isoWeek');//It's actually Sunday in moment, while our week starts from Monday
    const toTimestamp = moment().tz(TIME_ZONE).endOf('week').add(1, 'day').toDate();
    while (count < 7) {
      const dateStr = startOfWeekMoment.clone().add(count, 'day').format('YYYY-MM-DD');
      const presenceObjsForDateStr = presenceListForWorker.filter(presence => presence.dateToCompare === dateStr) ?? null;
      let hoursWorkedStr = '-';
      let hoursPaidStr = '-';
      let breakHoursPaidStr = '-';
      let breakHoursUnpaidStr = '-';
      if (presenceObjsForDateStr && Array.isArray(presenceObjsForDateStr) && (presenceObjsForDateStr.length > 0)) {
        for (const presenceObj of presenceObjsForDateStr) {
          if (presenceObj.startTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
            hoursWorkedStr =
              !presenceObj?.durationHoursWorkedRounded && (presenceObj.deviceType !== 'CLOCKWEB') ?
                '00:00' : moment.duration(presenceObj.durationHoursWorkedRounded, 'seconds').format(TIME_FORMAT);
            hoursPaidStr =
              !presenceObj?.durationPaidHoursRounded && (presenceObj.deviceType !== 'CLOCKWEB') ?
                '00:00' : moment.duration(presenceObj.durationPaidHoursRounded, 'seconds').format(TIME_FORMAT);
            breakHoursPaidStr =
              !presenceObj?.durationBreaksPaid ? '00:00' : moment.duration(presenceObj.durationBreaksPaid, 'seconds').format(TIME_FORMAT);
            breakHoursUnpaidStr =
              !presenceObj?.durationBreaksUnpaid ? '00:00' : moment.duration(presenceObj.durationBreaksUnpaid, 'seconds').format(TIME_FORMAT);
          } else {
            hoursWorkedStr = !presenceObj?.durationHoursWorked ? '00:00' : moment.duration(presenceObj.durationHoursWorked, 'seconds').format(TIME_FORMAT);
            hoursPaidStr = !presenceObj?.durationPaidHours ? '00:00' : moment.duration(presenceObj.durationPaidHours, 'seconds').format(TIME_FORMAT);
            breakHoursPaidStr = !presenceObj?.durationBreaksPaid ? '00:00' : moment.duration(presenceObj.durationBreaksPaid, 'seconds').format(TIME_FORMAT);
            breakHoursUnpaidStr =
              !presenceObj?.durationBreaksUnpaid ? '00:00' : moment.duration(presenceObj.durationBreaksUnpaid, 'seconds').format(TIME_FORMAT);
          }

          let isEndTimestampMissing = false;
          if (!dateIsToday(startOfWeekMoment.clone().add(count, 'day')) && presenceObj?.startTimestamp && !presenceObj?.endTimestamp) {
            isEndTimestampMissing = true;
          }
          let workerStartTs;
          let workerEndTs;
          if (presenceObj) {
            if (presenceObj.startTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
              workerStartTs = presenceObj.startTimestampRounded;
            } else {
              workerStartTs = presenceObj.startTimestamp;
            }

            if (presenceObj.endTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
              workerEndTs = presenceObj.endTimestampRounded;
            } else {
              workerEndTs = presenceObj.endTimestamp;
            }
          }

          workerSpecificList.push({
            presenceDoc: presenceObj ?? null,
            locationName: presenceObj.locationName ?? null,
            date: startOfWeekMoment.clone().add(count, 'day').format('dddd D MMM'),
            startTime: presenceObj ? workerStartTs ? moment(workerStartTs.toMillis()).tz(TIME_ZONE).format('HH:mm') : '-' : '-',
            endTime: presenceObj ? workerEndTs ? moment(workerEndTs.toMillis()).tz(TIME_ZONE).format('HH:mm') : '-' : '-',
            hoursWorked: hoursWorkedStr,
            hoursPaid: hoursPaidStr,
            breakHoursPaid: breakHoursPaidStr,
            breakHoursUnpaid: breakHoursUnpaidStr,
            deviceType: presenceObj?.deviceType ?? null,
            sickHours: '-',
            isEndTimestampMissing,
            isLoggedInUser,
            updatedAt: presenceObj?.updatedTimestamp ?? null,
            updatedBy: presenceObj?.updatedByUserName ?? null
          });
        }
      }
      count++;
    }
    return workerSpecificList;
  }

  setUpMonthListForWorker(presenceListForWorker, isLoggedInUser) {
    let count = 0;
    const workerSpecificList = [];
    const startOfMonthMoment = moment(this.dateRange.value.start).startOf('month'); // Start of the month
    const endOfMonthMoment = moment(this.dateRange.value.start).endOf('month'); // End of the month
    const daysInMonth = endOfMonthMoment.diff(startOfMonthMoment, 'days') + 1; // Calculate days in the month

    while (count < daysInMonth) {
      const dateStr = startOfMonthMoment.clone().add(count, 'days').format('YYYY-MM-DD');
      const presenceObjsForDateStr = presenceListForWorker.filter(presence => presence.dateToCompare === dateStr) ?? null;
      let hoursWorkedStr = '-';
      let hoursPaidStr = '-';
      let breakHoursPaidStr = '-';
      let breakHoursUnpaidStr = '-';

      if (presenceObjsForDateStr && Array.isArray(presenceObjsForDateStr) && (presenceObjsForDateStr.length > 0)) {
        for (const presenceObj of presenceObjsForDateStr) {
          if (presenceObj.startTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
            hoursWorkedStr =
              !presenceObj?.durationHoursWorkedRounded && (presenceObj.deviceType !== 'CLOCKWEB') ?
                '00:00' : moment.duration(presenceObj.durationHoursWorkedRounded, 'seconds').format(TIME_FORMAT);
            hoursPaidStr =
              !presenceObj?.durationPaidHoursRounded && (presenceObj.deviceType !== 'CLOCKWEB') ?
                '00:00' : moment.duration(presenceObj.durationPaidHoursRounded, 'seconds').format(TIME_FORMAT);
            breakHoursPaidStr =
              !presenceObj?.durationBreaksPaid ? '00:00' : moment.duration(presenceObj.durationBreaksPaid, 'seconds').format(TIME_FORMAT);
            breakHoursUnpaidStr =
              !presenceObj?.durationBreaksUnpaid ? '00:00' : moment.duration(presenceObj.durationBreaksUnpaid, 'seconds').format(TIME_FORMAT);
          } else {
            hoursWorkedStr = !presenceObj?.durationHoursWorked ? '00:00' : moment.duration(presenceObj.durationHoursWorked, 'seconds').format(TIME_FORMAT);
            hoursPaidStr = !presenceObj?.durationPaidHours ? '00:00' : moment.duration(presenceObj.durationPaidHours, 'seconds').format(TIME_FORMAT);
            breakHoursPaidStr = !presenceObj?.durationBreaksPaid ? '00:00' : moment.duration(presenceObj.durationBreaksPaid, 'seconds').format(TIME_FORMAT);
            breakHoursUnpaidStr =
              !presenceObj?.durationBreaksUnpaid ? '00:00' : moment.duration(presenceObj.durationBreaksUnpaid, 'seconds').format(TIME_FORMAT);
          }


          let isEndTimestampMissing = false;
          if (!dateIsToday(startOfMonthMoment.clone().add(count, 'days')) && presenceObj?.startTimestamp && !presenceObj?.endTimestamp) {
            isEndTimestampMissing = true;
          }

          let workerStartTs;
          let workerEndTs;
          if (presenceObj) {
            if (presenceObj.startTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
              workerStartTs = presenceObj.startTimestampRounded;
            } else {
              workerStartTs = presenceObj.startTimestamp;
            }

            if (presenceObj.endTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
              workerEndTs = presenceObj.endTimestampRounded;
            } else {
              workerEndTs = presenceObj.endTimestamp;
            }
          }

          workerSpecificList.push({
            presenceDoc: presenceObj ?? null,
            locationName: presenceObj.locationName ?? null,
            date: startOfMonthMoment.clone().add(count, 'days').format('dddd D MMM'),
            startTime: presenceObj ? workerStartTs ? moment(workerStartTs.toMillis()).tz(TIME_ZONE).format('HH:mm') : '-' : '-',
            endTime: presenceObj ? workerEndTs ? moment(workerEndTs.toMillis()).tz(TIME_ZONE).format('HH:mm') : '-' : '-',
            hoursWorked: hoursWorkedStr,
            hoursPaid: hoursPaidStr,
            breakHoursPaid: breakHoursPaidStr,
            breakHoursUnpaid: breakHoursUnpaidStr,
            deviceType: presenceObj?.deviceType ?? null,
            sickHours: '-',
            isEndTimestampMissing,
            isLoggedInUser,
            updatedAt: presenceObj?.updatedTimestamp ?? null,
            updatedBy: presenceObj?.updatedByUserName ?? null
          });
        }
      }
      count++;
    }
    return workerSpecificList;
  }

  setUpCustomDateListForWorker(presenceListForWorker, isLoggedInUser) {
    let count = 0;
    const workerSpecificList = [];
    const startOfCustomRangeMoment = moment(this.dateRange.value.start); // Start of the custom range
    const endOfCustomRangeMoment = moment(this.dateRange.value.end); // End of the custom range
    const daysInCustomRange = endOfCustomRangeMoment.diff(startOfCustomRangeMoment, 'days') + 1; // Calculate days in the custom range

    while (count < daysInCustomRange) {
      const dateStr = startOfCustomRangeMoment.clone().add(count, 'days').format('YYYY-MM-DD');
      const presenceObjsForDateStr = presenceListForWorker.filter(presence => presence.dateToCompare === dateStr) ?? null;
      let hoursWorkedStr = '-';
      let hoursPaidStr = '-';
      let breakHoursPaidStr = '-';
      let breakHoursUnpaidStr = '-';

      if (presenceObjsForDateStr && Array.isArray(presenceObjsForDateStr) && (presenceObjsForDateStr.length > 0)) {
        for (const presenceObj of presenceObjsForDateStr) {
          if (presenceObj.startTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
            hoursWorkedStr =
              !presenceObj?.durationHoursWorkedRounded && (presenceObj.deviceType !== 'CLOCKWEB') ?
                '00:00' : moment.duration(presenceObj.durationHoursWorkedRounded, 'seconds').format(TIME_FORMAT);
            hoursPaidStr =
              !presenceObj?.durationPaidHoursRounded && (presenceObj.deviceType !== 'CLOCKWEB') ?
                '00:00' : moment.duration(presenceObj.durationPaidHoursRounded, 'seconds').format(TIME_FORMAT);
            breakHoursPaidStr =
              !presenceObj?.durationBreaksPaid ? '00:00' : moment.duration(presenceObj.durationBreaksPaid, 'seconds').format(TIME_FORMAT);
            breakHoursUnpaidStr =
              !presenceObj?.durationBreaksUnpaid ? '00:00' : moment.duration(presenceObj.durationBreaksUnpaid, 'seconds').format(TIME_FORMAT);
          } else {
            hoursWorkedStr = !presenceObj?.durationHoursWorked ? '00:00' : moment.duration(presenceObj.durationHoursWorked, 'seconds').format(TIME_FORMAT);
            hoursPaidStr = !presenceObj?.durationPaidHours ? '00:00' : moment.duration(presenceObj.durationPaidHours, 'seconds').format(TIME_FORMAT);
            breakHoursPaidStr = !presenceObj?.durationBreaksPaid ? '00:00' : moment.duration(presenceObj.durationBreaksPaid, 'seconds').format(TIME_FORMAT);
            breakHoursUnpaidStr =
              !presenceObj?.durationBreaksUnpaid ? '00:00' : moment.duration(presenceObj.durationBreaksUnpaid, 'seconds').format(TIME_FORMAT);
          }


          let isEndTimestampMissing = false;
          if (!dateIsToday(startOfCustomRangeMoment.clone().add(count, 'days')) && presenceObj?.startTimestamp && !presenceObj?.endTimestamp) {
            isEndTimestampMissing = true;
          }

          let workerStartTs;
          let workerEndTs;
          if (presenceObj) {
            if (presenceObj.startTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
              workerStartTs = presenceObj.startTimestampRounded;
            } else {
              workerStartTs = presenceObj.startTimestamp;
            }

            if (presenceObj.endTimestampRounded && (presenceObj.deviceType !== 'CLOCKWEB')) {
              workerEndTs = presenceObj.endTimestampRounded;
            } else {
              workerEndTs = presenceObj.endTimestamp;
            }
          }

          workerSpecificList.push({
            presenceDoc: presenceObj ?? null,
            locationName: presenceObj.locationName ?? null,
            date: startOfCustomRangeMoment.clone().add(count, 'days').format('dddd D MMM'),
            startTime: presenceObj ? workerStartTs ? moment(workerStartTs.toMillis()).tz(TIME_ZONE).format('HH:mm') : '-' : '-',
            endTime: presenceObj ? workerEndTs ? moment(workerEndTs.toMillis()).tz(TIME_ZONE).format('HH:mm') : '-' : '-',
            hoursWorked: hoursWorkedStr,
            hoursPaid: hoursPaidStr,
            breakHoursPaid: breakHoursPaidStr,
            breakHoursUnpaid: breakHoursUnpaidStr,
            deviceType: presenceObj?.deviceType ?? null,
            sickHours: '-',
            isEndTimestampMissing,
            isLoggedInUser,
            updatedAt: presenceObj?.updatedTimestamp ?? null,
            updatedBy: presenceObj?.updatedByUserName ?? null
          });
        }
      }
      count++;
    }
    return workerSpecificList;
  }

  toggleArchived(isArchivedSelected: boolean) {
    this.isArchivedShown = isArchivedSelected;
    if (!isArchivedSelected) {
      this.fetchPresencesForClient(false, moment(this.dateRange.controls.start.value).toDate());
    } else {
      this.fetchPresencesForClient(true, moment(this.dateRange.controls.start.value).toDate());
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreatePresenceDialogComponent, dialogConfig);
  }

  exportCSV(option: string, format: string, isTitled: boolean) {
    const startDateMoment = moment(this.dateRange.controls.start.value);
    const endDateMoment = moment(this.dateRange.controls.end.value);
    let fileName: string;
    let columns: string[];
    let headerNames: string[];
    let title: string;
    let dataToExport: any;
    const groupName = this.filterDictionary.get('workerGroupName') ? this.filterDictionary.get('workerGroupName') : 'all';
    let fileNameLocPrefix = '';
    let titleLocPrefix = '';
    if (this.selectedLocationName) {
      fileNameLocPrefix = this.selectedLocationName + '_';
      titleLocPrefix = this.selectedLocationName + ' - ';
    }
    switch (option) {
      case 'DAILY_OVERVIEW':
        if (!isTitled) {
          fileName = `${fileNameLocPrefix}daily_overview_${startDateMoment.format('YYYY')}-${this.weekNumber}_${groupName}_${moment().format('YYYY-MM-DD_HH-mm')}`;
          if (['THIS_WEEK', 'LAST_WEEK', 'WEEK_RANGE'].includes(this.selectedMode)) {
            columns = ['workerName', 'locationName', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', /*'hoursWorked',*/ 'hoursPaid'/*'sickHours'*/];
            headerNames = columns.map(column => this.columnHeaderToColumnMapDailyOverviewWeekly.get(column));
          } else if (['THIS_MONTH', 'LAST_MONTH'].includes(this.selectedMode)) {
            columns = this.generateDateRangeStrings(this.dateRange.value.start, this.dateRange.value.end);
            headerNames = [this.columnHeaderToColumnMapDailyOverviewWeekly.get('workerName')]
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('locationName')])
            .concat(columns)
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('hoursPaid')]);
          } else { //custom range
            columns = this.generateDateRangeStrings(this.dateRange.value.start, this.dateRange.value.end);
            headerNames = [this.columnHeaderToColumnMapDailyOverviewWeekly.get('workerName')]
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('locationName')])
            .concat(columns)
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('hoursPaid')]);
          }

          dataToExport = this.mapTableDataToDailyOverviewCSVExport(columns);

          switch (format) {
            case 'CSV':
              this.downloadAsCSV(dataToExport, fileName, [], headerNames, title, false);
              break;
            case 'Excel':
              this.downloadAsExcel(dataToExport, fileName, [], headerNames, title, false);
              break;
            case 'JSON':
              this.downloadAsJSON(dataToExport, fileName, [], headerNames, title, false);
              break;
          }
        } else {
          fileName = `${fileNameLocPrefix}daily_overview_${startDateMoment.format('YYYY')}-${this.weekNumber}_${groupName}_${moment().format('YYYY-MM-DD_HH-mm')}`;
          if (['THIS_WEEK', 'LAST_WEEK', 'WEEK_RANGE'].includes(this.selectedMode)) {
            columns = ['workerName', 'locationName', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', /*'hoursWorked',*/ 'hoursPaid'/*'sickHours'*/];
            headerNames = columns.map(column => this.columnHeaderToColumnMapDailyOverviewWeekly.get(column));
          } else if (['THIS_MONTH', 'LAST_MONTH'].includes(this.selectedMode)) {
            columns = this.generateDateRangeStrings(this.dateRange.value.start, this.dateRange.value.end);
            headerNames = [this.columnHeaderToColumnMapDailyOverviewWeekly.get('workerName')]
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('locationName')])
            .concat(columns)
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('hoursPaid')]);
          } else { //custom range
            columns = this.generateDateRangeStrings(this.dateRange.value.start, this.dateRange.value.end);
            headerNames = [this.columnHeaderToColumnMapDailyOverviewWeekly.get('workerName')]
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('locationName')])
            .concat(columns)
            .concat([this.columnHeaderToColumnMapDailyOverviewWeekly.get('hoursPaid')]);
          }
          title = `${titleLocPrefix}${this.weekNumber} (${startDateMoment.format('YYYYMMDD')} - ${endDateMoment.format('YYYYMMDD')}) ${groupName} - ${moment().format('YYYY-MM-DD_HH:mm')}`;

          dataToExport = this.mapTableDataToDailyOverviewCSVExport(columns);

          switch (format) {
            case 'CSV':
              this.downloadAsCSV(dataToExport, fileName, [], headerNames, title, true);
              break;
            case 'Excel':
              this.downloadAsExcel(dataToExport, fileName, [], headerNames, title, true);
              break;
            case 'JSON':
              this.downloadAsJSON(dataToExport, fileName, [], headerNames, title, true);
              break;
          }
        }
        break;
      case 'DAILY_DETAIL':
        if (!isTitled) {
          fileName = `${fileNameLocPrefix}daily_detail_${startDateMoment.format('YYYY')}-${this.weekNumber}_${groupName}_${moment().format('YYYY-MM-DD_HH-mm')}`;
          columns = ['date', 'day', 'workerName', 'locationName', 'startTime', 'endTime', 'hoursWorked', 'breakHoursPaid', 'breakHoursUnpaid', 'hoursPaid' /*'sickHours'*/];
          dataToExport = this.mapTableDataToDailyDetailCSVExport();
          headerNames = columns.map(column => this.columnHeaderToColumnMapDailyDetail.get(column));
          switch (format) {
            case 'CSV':
              this.downloadAsCSV(dataToExport, fileName, [], headerNames, title, false);
              break;
            case 'Excel':
              this.downloadAsExcel(dataToExport, fileName, [], headerNames, title, false);
              break;
            case 'JSON':
              this.downloadAsJSON(dataToExport, fileName, [], headerNames, title, false);
              break;
          }
        } else {
          fileName = `${fileNameLocPrefix}daily_detail_${startDateMoment.format('YYYY')}-${this.weekNumber}_${groupName}_${moment().format('YYYY-MM-DD_HH-mm')}`;
          columns = ['date', 'day', 'workerName', 'locationName', 'startTime', 'endTime', 'hoursWorked', 'breakHoursPaid', 'breakHoursUnpaid', 'hoursPaid' /*, 'sickHours'*/];
          title = `${titleLocPrefix}${this.weekNumber} (${startDateMoment.format('YYYYMMDD')} - ${endDateMoment.format('YYYYMMDD')}) ${groupName} - ${moment().format('YYYY-MM-DD_HH:mm')}`;
          dataToExport = this.mapTableDataToDailyDetailCSVExport();
          headerNames = columns.map(column => this.columnHeaderToColumnMapDailyDetail.get(column));
          switch (format) {
            case 'CSV':
              this.downloadAsCSV(dataToExport, fileName, [], headerNames, title, true);
              break;
            case 'Excel':
              this.downloadAsExcel(dataToExport, fileName, [], headerNames, title, true);
              break;
            case 'JSON':
              this.downloadAsJSON(dataToExport, fileName, [], headerNames, title, true);
              break;
          }
        }
        break;
      case 'DAILY_UPDATE_OVERVIEW':
        if (!isTitled) {
          fileName = `${fileNameLocPrefix}daily_update_overview_${startDateMoment.format('YYYY')}-${this.weekNumber}_${groupName}_${moment().format('YYYY-MM-DD_HH-mm')}`;
          columns = ['date', 'day', 'workerName', 'locationName', 'startTime', 'endTime', 'breakHoursPaid', 'breakHoursUnpaid',
            'totalPaid', 'updatedBy', 'updatedAt' /*'sickHours'*/];
          dataToExport = this.mapTableDataToDailyUpdateOverviewCSVExport();
          headerNames = columns.map(column => this.columnHeaderToColumnMapDailyUpdateOverview.get(column));
          switch (format) {
            case 'CSV':
              this.downloadAsCSV(dataToExport, fileName, [], headerNames, title, false);
              break;
            case 'Excel':
              this.downloadAsExcel(dataToExport, fileName, [], headerNames, title, false);
              break;
            case 'JSON':
              this.downloadAsJSON(dataToExport, fileName, [], headerNames, title, false);
              break;
          }
        } else {
          fileName = `${fileNameLocPrefix}daily_update_overview_${startDateMoment.format('YYYY')}-${this.weekNumber}_${groupName}_${moment().format('YYYY-MM-DD_HH-mm')}`;
          columns = ['date', 'day', 'workerName', 'locationName', 'startTime', 'endTime', 'breakHoursPaid', 'breakHoursUnpaid',
            'totalPaid', 'updatedBy', 'updatedAt' /*, 'sickHours'*/];
          title = `${titleLocPrefix}${this.weekNumber} (${startDateMoment.format('YYYYMMDD')} - ${endDateMoment.format('YYYYMMDD')}) ${groupName} - ${moment().format('YYYY-MM-DD_HH:mm')}`;
          dataToExport = this.mapTableDataToDailyUpdateOverviewCSVExport();
          headerNames = columns.map(column => this.columnHeaderToColumnMapDailyUpdateOverview.get(column));
          switch (format) {
            case 'CSV':
              this.downloadAsCSV(dataToExport, fileName, [], headerNames, title, true);
              break;
            case 'Excel':
              this.downloadAsExcel(dataToExport, fileName, [], headerNames, title, true);
              break;
            case 'JSON':
              this.downloadAsJSON(dataToExport, fileName, [], headerNames, title, true);
              break;
          }
        }
        break;
    }
  }

  downloadAsCSV(dataToExport: any, filename: string, dateColumns: string[], headers: string[], title: string, isTitled = false) {
    const workbook = new Workbook();

    workbook.company = 'OPUS Solutions B.V.';
    workbook.description =
      'This file was created using the OPUS Dashboard. The end user is at all times responsible for the content of this export.';
    workbook.creator = this.loggedInUserDocData.name ?? '';
    workbook.lastModifiedBy = this.loggedInUserDocData.name ?? '';
    const worksheet = workbook.addWorksheet('data', {
      views: [
        {
          state: 'frozen',
          ySplit: title?.length > 0 ? 3 : 1,
          activeCell: 'A1',
          showGridLines: false,
        },
      ],
    });

    const columns: TableColumnProperties[] = headers.map((headerName, index) => {
      if (index === 1) {
        return {
          name: headerName,
          //totalsRowLabel: 'Totals',
          filterButton: true,
        };
      }
      return {name: headerName, /*totalsRowFunction: 'sum',*/ filterButton: true};
    });

    if (isTitled) {
      worksheet.addRow([title]);
    }
    const rows = this.convertToArrayOfArrays(dataToExport, headers);

    worksheet.addTable({
      name: 'Hours',
      ref: title?.length > 0 ? 'A3' : 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true,
      },
      columns,
      rows,
    });

    worksheet.columns.forEach((column) => (column.width = 15));

    workbook.csv.writeBuffer().then(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'application/csv;charset=UTF-8',
      });
      saveAs(data, filename + '.csv');
    });
  }

  downloadAsJSON(dataToExport: any, filename: string, dateColumns: string[], headers: string[], title: string, isTitled = false) {
    const jsonToExport: any = {}
    if (isTitled) {
      jsonToExport.title = title;
    }
    jsonToExport.data = dataToExport;

    const blob = new Blob([JSON.stringify(jsonToExport)], {type: 'application/json;charset=UTF-8'});
    saveAs(blob, filename + '.json');
  }

  downloadAsExcel(dataToExport: any, filename: string, dateColumns: string[], headers: string[], title: string, isTitled = false) {
    const workbook = new Workbook();

    workbook.company = 'OPUS Solutions B.V.';
    workbook.description =
      'This file was created using the OPUS Dashboard. The end user is at all times responsible for the content of this export.';
    workbook.creator = this.loggedInUserDocData.name ?? '';
    workbook.lastModifiedBy = this.loggedInUserDocData.name ?? '';
    const worksheet = workbook.addWorksheet('data', {
      views: [
        {
          state: 'frozen',
          ySplit: title?.length > 0 ? 3 : 1,
          activeCell: 'A1',
          showGridLines: false,
        },
      ],
    });

    const columns: TableColumnProperties[] = headers.map((headerName, index) => {
      if (index === 1) {
        return {
          name: headerName,
          //totalsRowLabel: 'Totals',
          filterButton: true,
        };
      }
      return {name: headerName, /*totalsRowFunction: 'sum',*/ filterButton: true};
    });

    if (isTitled) {
      worksheet.addRow([title]);
      worksheet.getCell('A1').font = {size: 14, bold: true};
    }
    const rows = this.convertToArrayOfArrays(dataToExport, headers);

    worksheet.addTable({
      name: 'Hours',
      ref: title?.length > 0 ? 'A3' : 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium1',
        showRowStripes: true,
      },
      columns,
      rows,
    });

    worksheet.columns.forEach((column) => (column.width = 15));

    //JSON Export
    //https://gist.github.com/marcolarosa/f95e7873b747f1f051ee05daca97584d

    workbook.xlsx.writeBuffer().then(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(data, filename + '.xlsx');
    });
  }

  convertToArrayOfArrays(data: Element[], keys: string[]): string[][] {
    return data.map((obj) => keys.map((key) => (obj as any)[key] || ''));
  }

  mapTableDataToDailyOverviewCSVExport(columns: string[]) {
    const mappedList: string[] = [];
    const dataToExport = this.dataSource.filteredData;

    dataToExport.map(mainRow => {
      const csvRowMap = new Map();
      const commonCSVData: any = {};
      commonCSVData[this.columnHeaderToColumnMapDailyOverviewWeekly.get('workerName')] = mainRow.workerName;

      if (['LAST_WEEK', 'THIS_WEEK', 'WEEK_RANGE'].includes(this.selectedMode)) {
        //let dayCount = 0;
        mainRow.nestedRows.map(nestedRow => {

          const workerLocSpecificTotalsObj = this.workerTotalsMap.get(mainRow.workerId).locSpecificHours.get(nestedRow.locationName);
          commonCSVData[this.columnHeaderToColumnMapDailyOverviewWeekly.get('hoursPaid')]
            = workerLocSpecificTotalsObj.totalHoursPaid > 0 ? moment.duration(workerLocSpecificTotalsObj.totalHoursPaid, 'seconds').format('*HH:mm') : '-';
          commonCSVData[this.columnHeaderToColumnMapDailyOverviewWeekly.get('sickHours')] = '-';//not implemented yet

          let csvRow: any;
          if (csvRowMap.get(nestedRow.locationName)) {
            csvRow = csvRowMap.get(nestedRow.locationName);
          } else {
            csvRow = {...commonCSVData};
          }

          let totalHoursPaid = '-';
          if (nestedRow.presenceDoc) {
            if (nestedRow.presenceDoc.startTimestampRounded && (nestedRow.presenceDoc.deviceType !== 'CLOCKWEB')) {
              totalHoursPaid = nestedRow.presenceDoc.durationHoursWorked > 0 ?
                moment.duration(nestedRow.presenceDoc.durationPaidHoursRounded, 'seconds').format(TIME_FORMAT) : '-';
            } else {
              totalHoursPaid = nestedRow.presenceDoc.durationHoursWorked > 0 ?
                moment.duration(nestedRow.presenceDoc.durationPaidHours, 'seconds').format(TIME_FORMAT) : '-';
            }
          }
          csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('locationName')] = nestedRow.presenceDoc.locationName ?? '-';
          console.log(`${moment(nestedRow.date, 'dddd D MMM')},isoWeekday: ${moment(nestedRow.date).weekday()}`)
          switch (moment(nestedRow.date).weekday()) {
            case 0:
              csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('monday')] = totalHoursPaid;
              break;
            case 1:
              csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('tuesday')] = totalHoursPaid;
              break;
            case 2:
              csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('wednesday')] = totalHoursPaid;
              break;
            case 3:
              csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('thursday')] = totalHoursPaid;
              break;
            case 4:
              csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('friday')] = totalHoursPaid;
              break;
            case 5:
              csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('saturday')] = totalHoursPaid;
              break;
            case 6:
              csvRow[this.columnHeaderToColumnMapDailyOverviewWeekly.get('sunday')] = totalHoursPaid;
              break;
          }
          //dayCount++;
          csvRowMap.set(nestedRow.locationName, csvRow);
        });
      } else {
        mainRow.nestedRows.map(nestedRow => {
          commonCSVData[this.columnHeaderToColumnMapDailyOverviewWeekly.get('locationName')] = nestedRow.presenceDoc.locationName ?? '-';
          const workerLocSpecificTotalsObj = this.workerTotalsMap.get(mainRow.workerId).locSpecificHours.get(nestedRow.locationName);
          commonCSVData[this.columnHeaderToColumnMapDailyOverviewWeekly.get('hoursPaid')]
            = workerLocSpecificTotalsObj.totalHoursPaid > 0 ? moment.duration(workerLocSpecificTotalsObj.totalHoursPaid, 'seconds').format('*HH:mm') : '-';
          commonCSVData[this.columnHeaderToColumnMapDailyOverviewWeekly.get('sickHours')] = '-';//not implemented yet
          let csvRow: any;
          if (csvRowMap.get(nestedRow.locationName)) {
            csvRow = csvRowMap.get(nestedRow.locationName);
          } else {
            csvRow = {...commonCSVData};
          }

          let totalHoursPaid = '-';
          if (nestedRow.presenceDoc) {
            if (nestedRow.presenceDoc.startTimestampRounded && (nestedRow.presenceDoc.deviceType !== 'CLOCKWEB')) {
              totalHoursPaid = nestedRow.presenceDoc.durationHoursWorked > 0 ?
                moment.duration(nestedRow.presenceDoc.durationPaidHoursRounded, 'seconds').format(TIME_FORMAT) : '-';
            } else {
              totalHoursPaid = nestedRow.presenceDoc.durationHoursWorked > 0 ?
                moment.duration(nestedRow.presenceDoc.durationPaidHours, 'seconds').format(TIME_FORMAT) : '-';
            }
          }
          csvRow[moment(nestedRow.date, 'dddd D MMM').format('ddd DD-MMM')] = totalHoursPaid;
          csvRowMap.set(nestedRow.locationName, csvRow);
        });
      }
      let keyArray = Array.from(csvRowMap.keys());
      if (keyArray.filter(key => key == null).length > 0) {
        mappedList.push(csvRowMap.get(null));
      }
      keyArray = keyArray.filter(key => key !== null).sort((locA: any, locB: any) => {
        return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
      });
      for (const key of keyArray.filter(key => key !== null)) {
        mappedList.push(csvRowMap.get(key));
      }
    });
    return mappedList;
  }

  mapTableDataToDailyDetailCSVExport() {
    const mappedList: string[] = [];
    const dataToExport = this.dataSource.filteredData;

    dataToExport.map((mainRow: any) => {
      const workerName = mainRow.workerName;
      const sickHours = '-';//not implemented yet

      mainRow.nestedRows.map(nestedRow => {
        const csvRow: any = {};
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('workerName')] = workerName;
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('sickHours')] = sickHours;
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('locationName')] = nestedRow.locationName ?? '-';
        let totalHoursWorkedHHmm = '-';
        if (nestedRow.presenceDoc) {
          const totalHoursWorked = nestedRow.presenceDoc.durationHoursWorked ? nestedRow.presenceDoc.durationHoursWorked : 0;
          totalHoursWorkedHHmm = totalHoursWorked > 0 ? moment.duration(totalHoursWorked, 'seconds').format(TIME_FORMAT) : '-';
        }
        let hoursPaidShown;
        if (nestedRow.hoursPaid && (nestedRow.hoursPaid === '00:00')) {
          hoursPaidShown = '-';
        } else {
          hoursPaidShown = nestedRow.hoursPaid;
        }
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('date')] = moment(nestedRow.date, 'dddd D MMM').format('YYYY-MM-DD');
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('day')] = moment(nestedRow.date, 'dddd D MMM').format('dddd');
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('startTime')] = nestedRow.startTime;
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('endTime')] = nestedRow.endTime;
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('breakHoursUnpaid')] = nestedRow.breakHoursUnpaid ?? '00:00';
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('breakHoursPaid')] = nestedRow.breakHoursPaid ?? '00:00';
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('hoursPaid')] = hoursPaidShown;
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('hoursWorked')] = totalHoursWorkedHHmm ?? '-';
        csvRow[this.columnHeaderToColumnMapDailyDetail.get('sickHours')] = mainRow.sickHours ?? '-';
        mappedList.push(csvRow);
      });
    });
    return mappedList;
  }

  mapTableDataToDailyUpdateOverviewCSVExport() {
    const mappedList: string[] = [];
    const dataToExport = this.dataSource.filteredData;

    dataToExport.map((mainRow: any) => {
      const workerName = mainRow.workerName;
      const sickHours = '-';//not implemented yet

      mainRow.nestedRows.map(nestedRow => {
        const csvRow: any = {};
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('workerName')] = workerName;
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('sickHours')] = sickHours;
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('locationName')] = nestedRow.locationName ?? '-';
        let totalHoursWorkedHHmm = '-';
        if (nestedRow.presenceDoc) {
          const totalHoursWorked = nestedRow.presenceDoc.durationHoursWorked ? nestedRow.presenceDoc.durationHoursWorked : 0;
          totalHoursWorkedHHmm = totalHoursWorked > 0 ? moment.duration(totalHoursWorked, 'seconds').format(TIME_FORMAT) : '-';
        }
        let hoursPaidShown;
        if (nestedRow.hoursPaid && (nestedRow.hoursPaid === '00:00')) {
          hoursPaidShown = '-';
        } else {
          hoursPaidShown = nestedRow.hoursPaid;
        }
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('date')] = moment(nestedRow.date, 'dddd D MMM').format('YYYY-MM-DD');
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('day')] = moment(nestedRow.date, 'dddd D MMM').format('dddd');
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('startTime')] = nestedRow.startTime;
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('endTime')] = nestedRow.endTime;
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('breakHoursUnpaid')] = nestedRow.breakHoursUnpaid ?? '00:00';
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('breakHoursPaid')] = nestedRow.breakHoursPaid ?? '00:00';
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('totalPaid')] = hoursPaidShown;
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('updatedBy')] = nestedRow.updatedBy ?? '-';
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('updatedAt')]
          = nestedRow.updatedAt && nestedRow.updatedBy ? moment(nestedRow.updatedAt.toDate(), 'dddd D MMM').format('YYYY-MM-DD HH:mm') : '-';
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('hoursWorked')] = totalHoursWorkedHHmm ?? '-';
        csvRow[this.columnHeaderToColumnMapDailyUpdateOverview.get('sickHours')] = mainRow.sickHours ?? '-';
        mappedList.push(csvRow);
      });
    });
    return mappedList;
  }

  generateDateRangeStrings(startDate, endDate) {
    const dateStrings = [];
    const currentDate = moment(startDate);
    const endMoment = moment(endDate);

    while (currentDate.isSameOrBefore(endMoment)) {
      dateStrings.push(currentDate.format('ddd DD-MMM'));
      currentDate.add(1, 'day');
    }

    return dateStrings;
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  public toggleExpandAll() {
    this.allRowsExpanded = !this.allRowsExpanded;
    this.expandedElement = null;
  }

  setDutchMomentLocale() {
    moment.locale('nl', {
      months : 'januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december'.split('_'),
      monthsShort : 'jan._feb._mrt._apr._mei_jun._jul._aug._sep._okt._nov._dec.'.split('_'),
      monthsParseExact : true,
      weekdays : 'zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag'.split('_'),
      weekdaysShort : 'zo._ma._di._wo._do._vr._za.'.split('_'),
      weekdaysMin : 'Zo_Ma_Di_Wo_Do_Vr_Za'.split('_'),
      weekdaysParseExact : true,
      longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD-MM-YYYY', // Changed date format to Dutch standard
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
      },
      calendar : {
        sameDay : '[vandaag om] LT',
        nextDay : '[morgen om] LT',
        nextWeek : 'dddd [om] LT',
        lastDay : '[gisteren om] LT',
        lastWeek : '[afgelopen] dddd [om] LT',
        sameElse : 'L'
      },
      relativeTime : {
        future : 'over %s',
        past : '%s geleden',
        s : 'een paar seconden',
        m : 'een minuut',
        mm : '%d minuten',
        h : 'een uur',
        hh : '%d uur',
        d : 'een dag',
        dd : '%d dagen',
        M : 'een maand',
        MM : '%d maanden',
        y : 'een jaar',
        yy : '%d jaar'
      },
      dayOfMonthOrdinalParse : /\d{1,2}(ste|de)/,
      ordinal(num) {
        return num + (num === 1 || num === 8 || num >= 20 ? 'ste' : 'de');
      },
      meridiemParse : /AM|PM/, // Changed to AM/PM
      isPM(input) {
        return input.charAt(0) === 'P';
      },
      // In case the meridiem units are not separated around 12, then implement
      // this function (look at locale/id.js for an example).
      // meridiemHour : function (hour, meridiem) {
      //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
      // },
      meridiem(hours, minutes, isLower) {
        return hours < 12 ? 'AM' : 'PM'; // Changed to AM/PM
      },
      week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
      }
    });
  }
}
