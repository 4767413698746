import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';

@Component({
  selector: 'app-edit-asset-dialog',
  templateUrl: './edit-asset-dialog.component.html',
  styleUrl: './edit-asset-dialog.component.scss'
})
export class EditAssetDialogComponent implements OnInit, OnDestroy {
  editAssetForm: UntypedFormGroup;

  assetRecordBeingEdited: any;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  allLocationsList: any[];
  locationListSubscription: Subscription;
  beaconListSubscription: Subscription;
  allBeaconsList: any[];

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.assetRecordBeingEdited = data.assetRecord;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => {
        this.allLocationsList = locationsList?.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        });
        this.editAssetForm.patchValue({locationIds: locationsList.map(loc => loc.id)});
      });

      //Get all beacons, then filter out 2 lists - assigned to this asset, unassigned beacons, then join the 2 lists to showin UI dropdown
      this.beaconListSubscription = this.firestoreService.getAllUnarchivedBeaconsByTypeForClientId(this.selectedClientDocData?.id, 'ASSET').subscribe((beaconsList) => {
        const beaconsAssignedToThisAsset = beaconsList.filter(beacon =>  beacon.assignedId === this.assetRecordBeingEdited.id);
        const nonAssignedBeaconsList = beaconsList.filter(beacon => !beacon.hasOwnProperty('assignedId') || !beacon.assignedId);
        const listsCombined = beaconsAssignedToThisAsset.concat(nonAssignedBeaconsList);
        this.allBeaconsList = listsCombined?.sort((beaconA: any, beaconB: any) => {
          return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
        });
      });
    });
  }

  ngOnInit() {
    this.editAssetForm = this.fb.group({
      name: [this.assetRecordBeingEdited.name, []],
      type: [{value: this.assetRecordBeingEdited.type, disabled:true}, []],
      number: [this.assetRecordBeingEdited.number, []],
      locationIds: [this.assetRecordBeingEdited.locationIds, []],
      rssiThreshold: [this.assetRecordBeingEdited.rssiThreshold, [Validators.required]],
      beaconIds: [this.assetRecordBeingEdited.beaconIds, []],
      externalId: [this.assetRecordBeingEdited.externalId, []],
      filterForm: [this.assetRecordBeingEdited.externalId, []]
    });
  }



  async updateAsset() {
    if (this.editAssetForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    if (!this.editAssetForm.valid) {
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    const assetDataToUpdate = this.editAssetForm.value;
    assetDataToUpdate.name = assetDataToUpdate.name?.trim();

    let locationNames = [];
    if (assetDataToUpdate.locationIds && Array.isArray(assetDataToUpdate.locationIds) && (assetDataToUpdate.locationIds.length > 0)) {
      locationNames = this.allLocationsList.filter(loc => assetDataToUpdate.locationIds.includes(loc.id)).map(loc => loc.name);
    }
    assetDataToUpdate.locationNames = locationNames;
    let beaconNames = [];
    if (assetDataToUpdate.beaconIds && Array.isArray(assetDataToUpdate.beaconIds) && (assetDataToUpdate.beaconIds.length > 0)) {
      beaconNames = this.allBeaconsList
      .filter(beacon => assetDataToUpdate.beaconIds.includes(beacon.id)).map(beacon => beacon.name);
    }
    assetDataToUpdate.beaconNames = beaconNames;

    try {
      await this.firestoreService.updateAssetByIdForClientId(this.assetRecordBeingEdited.id, this.selectedClientDocData.id, assetDataToUpdate);
      this.openSnackBar('Changes have been saved', 'success');
      this.editAssetForm.markAsPristine();
      this.dialogRef.close();
    } catch (error) {
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.beaconListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}


