<mat-form-field class="w-100">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <ng-container *ngIf="control">
    <input matInput [matDatepicker]="picker" [formControl]="control" (dateChange)="onDateSelection($event)"/>
  </ng-container>
  <ng-control *ngIf="!control">
    <input matInput [matDatepicker]="picker" [(ngModel)]="selectedValue" (dateChange)="onDateSelection($event)" />
  </ng-control>
  

  <mat-datepicker-toggle *ngIf="!control?.value" matIconSuffix [for]="picker"></mat-datepicker-toggle>

  <button *ngIf="control?.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearDate()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
