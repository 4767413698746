<div class="container-f">
  <div class="page-top-space-pd">
    <mat-card class="page-main-card">
      <div>
        <span style="font-size: 2rem;" class="px-2 px-md-0">{{'DASHBOARD.SETTINGS.TITLE.TEXT' | translate}}</span>
        <div class="page-action">
          <nav mat-tab-nav-bar mat-align-tabs="left" class="tabs-selection" [tabPanel]="tabPanel">
            <a mat-tab-link class="{{l.hideMobile?'hide-sm':''}}"
               *ngFor="let l of navigation"
               [routerLink]="rootUrl + l.link"
               [active]="this.router.url === rootUrl + l.link">
              {{l.name | translate}}
            </a>
          </nav>
        </div>


        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
        <router-outlet></router-outlet>
      </div>
    </mat-card>
  </div>
</div>



