

<div class="listing-container">
  <div class="controls-container">
    <div class="date-container">
      <mat-form-field appearance="outline" class="listing-filter-with-archival">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label style="color: gray">{{'SETTINGS.FILTER.LABEL.TEXT' | translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterValue" placeholder="name" #input />
      </mat-form-field>
    </div>

    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()" *ngIf="selectedClientDocData?.hasAdminRole">
      <mat-icon matTooltip="Create User" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon>{{'SETTINGS.USER.CREATEUSER.BUTTON.TEXT' | translate}}</button>
    <div>
      <mat-button-toggle-group [hideSingleSelectionIndicator]="true" #group="matButtonToggleGroup" [(ngModel)]="unArchivedVsArchived"
        (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon matTooltip="Show users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon matTooltip="Show archived users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="table-card">
    <div *ngIf="areArchivedUsersShown()"> {{'SETTINGS.USER.ARCHIVEDUSER.TITLE.TEXT' | translate}}
    </div>
    <div class="mat-elevation-z8" style="clear:both;">
      <table class="listing-table" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ userAttributesReadableMap.get(column) | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper">
            <div class="grid-control-column">
              <button mat-icon-button color="accent" (click)="openEditDialog(element)" class="listing-table-tools"
              [disabled]="!selectedClientDocData?.hasAdminRole">
              <mat-icon>edit</mat-icon>
            </button>

            <button mat-icon-button color="accent" (click)="archiveUser(element)" class="listing-table-tools"
              *ngIf="!element.isArchived">
              <mat-icon matTooltip="Archive this user" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                matTooltipPosition="below">archive
              </mat-icon>
            </button>
            <button mat-icon-button color="accent" (click)="unarchiveUser(element)" class="listing-table-tools"
              *ngIf="element.isArchived">
              <mat-icon matTooltip="Unarchive this user" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                matTooltipPosition="below">unarchive
              </mat-icon>
            </button>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25" [showFirstLastButtons]="true"></mat-paginator>
    </div>
  </div>


</div>
