<div style="min-width: 400px;">
  <h2 mat-dialog-title >{{'SETTINGS.WORKERGROUPS.CREATE.TITLE.TEXT' | translate}}</h2>

  <mat-dialog-content [formGroup]="form" style="text-align: center;">
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required
                 formControlName="name">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <br/>
  <div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="createWorkerGroup()" style="margin-left: 20px;">{{'SETTINGS.WORKERGROUPS.CREATE.TITLE.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
