import {AfterViewInit, Component, DestroyRef, OnDestroy, ViewChild} from '@angular/core';
import {ActivitySession, Column, SessionMainAttributes} from '../../common/interfaces/new-table-interfaces';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {FirestoreService} from '../../services/firestore.service';
import {Router} from '@angular/router';
import {ClientInContextService} from '../../services/client-in-context.service';
import * as moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SNACKBAR_CLASSES} from '../../common/utils/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {EditSessionDialogComponent} from "./edit-session-dialog/edit-session-dialog.component";
import {CreateSessionDialogComponent} from "./create-session-dialog/create-session-dialog.component";
import {ConfirmationDialogComponent} from "../utility/confirmation-dialog/confirmation-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FilterProcessingService} from 'src/app/services/filter-processing.service';
import {getPermissions} from '../../common/utils/permission-utils';

@Component({
  selector: 'app-client-sessions-dashboard',
  templateUrl: './client-sessions-dashboard.component.html',
  styleUrls: ['./client-sessions-dashboard.component.scss',
    '../../common/styles/listing.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientSessionsDashboardComponent implements AfterViewInit, OnDestroy {
  columns: Column[] = [
    {
      name: 'workerName',
      displayName: 'DASHBOARD.TABLEHEAD.WORKERS.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: null,
      footerValue: '',
      hideMobile: false
    },
    {
      name: 'startTimestamp',
      displayName: 'DASHBOARD.TABLEHEAD.STARTTIMESTAMP.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: null,
      footerValue: '',
      hideMobile: true
    },
    {
      name: 'endTimestamp',
      displayName: 'DASHBOARD.TABLEHEAD.ENDTIMESTAMP.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: null,
      footerValue: '',
      hideMobile: true
    },

    {
      name: 'rowNumber',
      displayName: 'DASHBOARD.TABLEHEAD.ROW.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      hideMobile: false
    },
    {
      name: 'displayedTrolleyInfo',
      displayName: 'DASHBOARD.TABLEHEAD.TROLLEY.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      hideMobile: false
    },
    {
      name: 'varietyName',
      displayName: 'DASHBOARD.TABLEHEAD.VARIETY.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      hideMobile: false
    },
    {
      name: 'count',
      displayName: 'DASHBOARD.TABLEHEAD.COUNT.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
      hideMobile: true
    },

    {
      name: 'locationName',
      displayName: 'Location',
      showHeader: false,
      showHeaderFilter: false,
      showTopFilter: true,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: null,
      footerValue: '',
      hideMobile: false
    }
  ];

  toggleTableChart: 'table' | 'chart' = 'table';
  unArchivedVsArchived = 'unarchived';

  // TABLE
  displayedColumns: string[] = this.columns.filter((column) => column.showHeader).map((column) => column.name);
  displayedTopFilters: any[] = this.columns.filter(
    (column) => column.showTopFilter
  );

  dataSource: MatTableDataSource<SessionMainAttributes>;
  lastDirection: '' | 'asc' | 'desc' = '';
  filterDictionary = new Map<string, string | number | boolean>();

  expandedElement: any = null;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dateInContextSubscription: Subscription;
  selectedDate: Date;
  prdctvtyRowData: any[];
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  sessionsRawData: any[];
  filterString: string;
  archivedSessionsSubscription: Subscription;
  unarchivedSessionsSubscription: Subscription;
  displayedIcon = 'play_circle_outline';
  shownRecordsType = 'unarchived';
  tableData = [];
  dateToday: Date = new Date();
  clientLocInContextServiceSubscription: Subscription;
  selectedLocationId = null;

  perms: any;

  constructor(private authService: AuthService,
    private firestoreService: FirestoreService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private clientInContextService: ClientInContextService,
    private translateService: TranslateService,
    private destroyRef: DestroyRef, private filterProcessService: FilterProcessingService) {
    this.dateInContextSubscription = this.clientInContextService.dateInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(dateInContext => {
        if (!dateInContext) {
          const dateNow = new Date();
          this.selectedDate = dateNow;
          this.clientInContextService.dateInContextSubject.next(dateNow);
        } else {
          this.selectedDate = dateInContext;
        }
        this.prdctvtyRowData = [];

        this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(selectedClientDocData => {
            if (!selectedClientDocData) {
              return;
            }
            this.clientLocInContextServiceSubscription = this.clientInContextService.clientLocSubject.pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(selectedLocation => {
                this.selectedLocationId = !selectedLocation || (selectedLocation?.id === '-1') ? null : selectedLocation?.id;
                this.selectedClientDocData = selectedClientDocData;
                if (this.selectedClientDocData.rolePermissionsMap) {
                  this.perms = getPermissions('labor', 'sessions', selectedClientDocData.rolePermissionsMap);
                }
                if (this.shownRecordsType === 'unarchived') {
                  this.fetchUnarchivedSessions(this.displayedIcon !== 'play_circle_outline');
                } else {
                  this.fetchArchivedSessions(this.displayedIcon !== 'play_circle_outline')
                }
              });
          });
      });
  }

  futureFilter = (d: Date | null): boolean => {
    return d <= this.dateToday;
  };

  ngAfterViewInit() {

  }

  getDisplayedCols() {
    return [...this.displayedColumns, 'edit', 'manual_indicator'];
  }

  fetchUnarchivedSessions(continueStreaming) {
    this.archivedSessionsSubscription?.unsubscribe();
    this.unarchivedSessionsSubscription?.unsubscribe();
    this.unarchivedSessionsSubscription =
      this.firestoreService.getUnarchivedSessions(this.selectedClientDocData.id, this.selectedDate, this.selectedLocationId ?? null).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(sessions => {
          sessions = sessions.map(session => {
            return {
              ...session,
              displayedTrolleyInfo: session.trolleyName ? session.trolleyName + ' ' + session.trolleyNumber : (session.trolleyId ?? '')
            }
          });
          this.sessionsRawData = sessions;

          this.tableData = sessions.map(session => {
            const originalSession = {...session};
            const startTimestamp = session?.startTimestamp ? moment(session?.startTimestamp?.toMillis()).format('HH:mm') : '';
            const endTimestamp = session?.endTimestamp ? moment(session?.endTimestamp?.toMillis()).format('HH:mm') : '';
            const nettStartTimestamp = session?.nettStartTimestamp ? moment(session?.nettStartTimestamp?.toMillis()).format('HH:mm') : '';
            const nettEndTimestamp = session?.nettEndTimestamp ? moment(session?.nettEndTimestamp?.toMillis()).format('HH:mm') : '';
            const isOriginal = session.hasOwnProperty('isOriginal') ? session.isOriginal : true;
            const createdFromTraining = session.hasOwnProperty('createdFromTraining') ? session.createdFromTraining : false;
            const fixAllowed =
              (session.isManual || !isOriginal) ? false : !session.hasOwnProperty('fixRequestedAt') ||
                (['FIXED', 'FAILED'].includes(session.fixStatus)) ||
                (session.fixStatus === 'FIXING'
                  && (session.fixingAt && moment().diff(session.fixingAt?.toDate(), 'minutes') > 10)
                );
            const fixFailed = session.hasOwnProperty('fixRequestedAt') && ['FAILED'].includes(session.fixStatus); //'FIXED' can be added to this list to avoid re-fixing
            if (this.selectedClientDocData.correctionFactor && session.count && isOriginal && !session.isManual) {
              session.count = +(session.count * (1 + (this.selectedClientDocData.correctionFactor))).toFixed(0);
            } else {
              if (!session.count) {
                session.count = 0;
              }
            }
            return {
              originalSessionDocument: originalSession,
              editButtonClass: !isOriginal ? '' : 'listing-table-tools tdhover',
              fixButtonClass: session.hasOwnProperty('fixRequestedAt') ? '' : 'listing-table-tools tdhover',
              workerId: session.workerId,
              workerName: session.workerName,
              locationId: session.locationId,
              locationName: session.locationName,
              layoutId: session.layoutId,
              layoutName: session.layoutName,
              //timeRange: `${startTimestamp} - ${endTimestamp}`,
              startTimestamp,
              endTimestamp,
              count: session.count ?? 0,
              rowId: session.rowId,
              rowNumber: session.rowNumber,
              varietyId: session.varietyId ?? '',
              varietyName: session.varietyName ?? '',
              displayedTrolleyInfo: session.displayedTrolleyInfo ?? '',
              trolleyId: session.trolleyId ?? '',
              sessionId: session.id ?? '',
              deviceId: session.deviceId ?? '',
              nettPerformance: session.nettPerformance ? session.nettPerformance.toFixed(0) + '  ( ' + nettStartTimestamp + ' - ' + nettEndTimestamp + ' )' : '-',
              grossPerformance: session.grossPerformance ? session.grossPerformance.toFixed(0) + '  ( ' + startTimestamp + ' - ' + endTimestamp + ' )' : '-',
              deviceNumber: session.deviceNumber ?? '',
              startTs: session.startTimestamp,
              endTs: session.endTimestamp,
              isArchived: session.isArchived,
              isManual: session.hasOwnProperty('isManual') ? session.isManual : false,
              isOriginal,
              fixAllowed,
              fixFailed,
              createdFromTraining,
              failureReason: fixFailed ? session.failureMessage : ''
            }
          });

          this.tableData.sort((n1, n2) => {
            return +n1.startTimestamp > +n2.startTimestamp ? -1 : +n1.startTimestamp < +n2.startTimestamp ? 1 : 0;
          });
          if (!continueStreaming) {
            this.unarchivedSessionsSubscription?.unsubscribe();
          }
        });
  }

  fetchArchivedSessions(continueStreaming) {
    this.archivedSessionsSubscription?.unsubscribe();
    this.unarchivedSessionsSubscription?.unsubscribe();
    this.archivedSessionsSubscription =
      this.firestoreService.getArchivedSessions(this.selectedClientDocData.id, this.selectedDate, this.selectedLocationId ?? null).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(sessions => {
          sessions = sessions.map(session => {
            return {
              ...session,
              displayedTrolleyInfo: session.trolleyName ? session.trolleyName + ' ' + session.trolleyNumber : session.trolleyId
            }
          });
          this.sessionsRawData = sessions;

          this.tableData = sessions.map(session => {
            return this.getTableRowForSessionDoc(session);
          });

          this.tableData.sort((n1, n2) => {
            return +n1.startTimestamp > +n2.startTimestamp ? -1 : +n1.startTimestamp < +n2.startTimestamp ? 1 : 0;
          });
          console.log('new data - archived sessions-length:' + this.tableData.length);
          if (!continueStreaming) {
            this.archivedSessionsSubscription?.unsubscribe();
          }
        });

  }


  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.dateInContextSubscription?.unsubscribe();
    this.archivedSessionsSubscription?.unsubscribe();
    this.unarchivedSessionsSubscription?.unsubscribe();
    this.dateInContextSubscription?.unsubscribe();
  }

  downloadAsCSV(type: string) {
    const filename = `${type} ${moment(this.selectedDate).format('YYYY-MM-DD')}`;
    if (!this.dataSource.filteredData || (this.dataSource.filteredData.length === 0)) {
      this.openSnackBar('No data to download', 'error');
      return;
    }
    const csvData = this.ConvertToCSV(this.dataSource.filteredData, this.columns.filter(column => column.showHeader).map(column => column.name));
    const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getTableRowForSessionDoc(session) {
    const originalSession = {...session};
    const startTimestamp = session?.startTimestamp ? moment(session?.startTimestamp?.toMillis()).format('HH:mm') : '';
    const endTimestamp = session?.endTimestamp ? moment(session?.endTimestamp?.toMillis()).format('HH:mm') : '';
    const nettStartTimestamp = session?.nettStartTimestamp ? moment(session?.nettStartTimestamp?.toMillis()).format('HH:mm') : '';
    const nettEndTimestamp = session?.nettEndTimestamp ? moment(session?.nettEndTimestamp?.toMillis()).format('HH:mm') : '';
    const isOriginal = session.hasOwnProperty('isOriginal') ? session.isOriginal : true;
    const createdFromTraining = session.hasOwnProperty('createdFromTraining') ? session.createdFromTraining : false;
    const fixAllowed = session.isManual ? false : !session.hasOwnProperty('fixRequestedAt') ||
      (session.hasOwnProperty('fixRequestedAt') && !['REQUESTED', 'FIXING'].includes(session.fixStatus)); //'FIXED' can be added to this list to avoid re-fixing
    const fixFailed = session.hasOwnProperty('fixRequestedAt') && ['FAILED'].includes(session.fixStatus); //'FIXED' can be added to this list to avoid re-fixing
    if (this.selectedClientDocData.correctionFactor && session.count && session.isOriginal && !session.isManual) {
      session.count = +(session.count * (1 + (this.selectedClientDocData.correctionFactor))).toFixed(0);
    } else if (!session.count) {
      session.count = 0;
    }
    return {
      originalSessionDocument: originalSession,
      editButtonClass: !isOriginal ? '' : 'listing-table-tools tdhover',
      fixButtonClass: session.hasOwnProperty('fixRequestedAt') ? '' : 'listing-table-tools tdhover',
      workerId: session.workerId,
      workerName: session.workerName,
      locationId: session.locationId,
      locationName: session.locationName,
      layoutId: session.layoutId,
      layoutName: session.layoutName,
      //timeRange: `${startTimestamp} - ${endTimestamp}`,
      startTimestamp,
      endTimestamp,
      count: session.count ?? 0,
      rowId: session.rowId,
      rowNumber: session.rowNumber,
      varietyId: session.varietyId ?? '',
      varietyName: session.varietyName ?? '',
      displayedTrolleyInfo: session.displayedTrolleyInfo ?? '',
      trolleyId: session.trolleyId ?? '',
      sessionId: session.id ?? '',
      deviceId: session.deviceId ?? '',
      nettPerformance: session.nettPerformance ? session.nettPerformance.toFixed(0) + '  ( ' + nettStartTimestamp + ' - ' + nettEndTimestamp + ' )' : '-',
      grossPerformance: session.grossPerformance ? session.grossPerformance.toFixed(0) + '  ( ' + startTimestamp + ' - ' + endTimestamp + ' )' : '-',
      deviceNumber: session.deviceNumber ?? '',
      startTs: session.startTimestamp,
      endTs: session.endTimestamp,
      isArchived: session.isArchived,
      isManual: session.hasOwnProperty('isManual') ? session.isManual : false,
      isOriginal,
      fixAllowed,
      fixFailed,
      createdFromTraining,
      failureReason: fixFailed ? session.failureMessage : ''
    }
  }

  ConvertToCSV(objArray, headerList) {
    objArray = objArray.map(obj => obj.originalSessionDocument);
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (const header of headerList) {
      row += this.translateService.instant(this.columns.filter(column => column.name === header)[0].displayName) + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array.length; i++) {
      let line = '';
      let counter = 0;
      for (const header of headerList) {
        counter++;
        if (['startTimestamp', 'endTimestamp'].includes(header)) {
          line += ((array[i][header] ? moment(+array[i][header].toMillis()).format('HH:mm') : null) ?? '');
        } else {
          line += (array[i][header] ?? '');
        }
        if (counter < headerList.length) {
          line += ','
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  async copyAsTraining(sessionRecord) {
    try {
      const newTraininDocRef = await this.firestoreService.copySessionToNewTraining(this.sessionsRawData.filter(sess => sess.id === sessionRecord.sessionId)[0]);
      await this.router.navigate([`/annotations/${newTraininDocRef.id}`]);
      this.openSnackBar(`Training has been created successfully using session`, 'success');
    } catch (error) {
      console.log(JSON.stringify(error.message));
      this.openSnackBar(`Training could not be created from session due to an error:${error.message}`, 'failure');
    }
  }

  async editSession(sessionRecord) {
    const dialogConfig = new MatDialogConfig();
    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      sessionRecord
    };
    dialogConfig.panelClass = "fullView-mobileDialog"
    const dialogRef = this.dialog.open(EditSessionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (res) => {
      if (res?.data?.id) {
        if (this.shownRecordsType === 'archived') {
          this.fetchArchivedSessions(false);
        } else {
          this.fetchUnarchivedSessions(false);
        }
      }
    });
  }



  openSnackBar(message, type, duration?) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: duration ? duration : 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  toggleArchived(isArchivedSelected: boolean) {
    if (!isArchivedSelected) {
      this.shownRecordsType = 'unarchived';
      this.fetchUnarchivedSessions(this.displayedIcon !== 'play_circle_outline');
    } else {
      this.fetchArchivedSessions(this.displayedIcon !== 'play_circle_outline');
      this.shownRecordsType = 'archived';
    }
  }

  archiveSession(session) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive the session?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        session
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value?.decision) {
        try {
          await this.firestoreService.archiveSessionByIdForClientId(value?.session?.sessionId, this.selectedClientDocData.id);
          this.snackBar.open(`Session archived successfully`, '', {
            duration: 5000,
            panelClass: ['snackbar-success'],
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
          this.fetchUnarchivedSessions(this.displayedIcon !== 'play_circle_outline');
        } catch (error) {
          this.snackBar.open(`Error in archiving session.\nPlease try again and/or contact support if problem persists`, '', {
            panelClass: ['snackbar-error'],
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  async unarchiveSession(session) {
    try {
      await this.firestoreService.unArchiveSessionByIdForClientId(session?.sessionId, this.selectedClientDocData.id);
      this.snackBar.open(`Session unarchived successfully`, '', {
        duration: 5000,
        panelClass: ['snackbar-success'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
      this.fetchArchivedSessions(this.displayedIcon !== 'play_circle_outline');
    } catch (error) {
      this.snackBar.open(`Error in unarchiving session.\nPlease try again and/or contact support if problem persists`, '', {
        panelClass: ['snackbar-error'],
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

  async fixSession(session) {
    try {
      await this.firestoreService.updateSessionForClientId({
        fixRequestedAt: new Date(),
        fixStatus: 'REQUESTED',
        id: session.sessionId
      }, this.selectedClientDocData.id, false);
      this.snackBar.open(`Session fixing request raised successfully. Restart Streaming(if paused) to see result.`, '', {
        duration: 10000,
        panelClass: ['snackbar-success'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
      if (session.isArchived) {
        this.fetchArchivedSessions(this.displayedIcon !== 'play_circle_outline');
      } else {
        this.fetchUnarchivedSessions(this.displayedIcon !== 'play_circle_outline');
      }
    } catch (error) {
      this.snackBar.open(`Error in raising fixing request for session.\nPlease try again and/or contact support if problem persists`, '', {
        panelClass: ['snackbar-error'],
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

  openCreateSessionDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.panelClass = "fullView-mobileDialog"
    this.dialog.open(CreateSessionDialogComponent, dialogConfig);
  }

  getTooltipForStreamingButton() {
    if (this.displayedIcon === 'play_circle_outline') {
      return 'Start Streaming Data';
    } else {
      return 'Pause Streaming Data';
    }
  }

  toggleStreaming() {
    if (this.displayedIcon === 'play_circle_outline') {
      this.displayedIcon = 'pause_circle_outline';
      if (this.shownRecordsType === 'unarchived') {
        this.fetchUnarchivedSessions(true);
      } else {
        this.fetchArchivedSessions(true);
      }
    } else {
      if (this.shownRecordsType === 'unarchived') {
        this.unarchivedSessionsSubscription?.unsubscribe();
      } else {
        this.archivedSessionsSubscription?.unsubscribe();
      }
      this.displayedIcon = 'play_circle_outline';
    }
  }
}
