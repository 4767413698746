export const elementTypes = [
  {
    type: 'SIDE_HELP',
    description: 'Side help:',
    friendlyName: 'Side help',
    convertToHtml: true
  },
  {
    type: 'HELP',
    description: 'Help:',
    friendlyName: 'Help',
    convertToHtml: true
  },
  {
    type: 'TITLE',
    description: 'Title:',
    friendlyName: 'Title',
    convertToHtml: false
  },
  {
    type: 'SUBTITLE',
    description: 'Subtitle:',
    friendlyName: 'Subtitle',
    convertToHtml: false
  },
  {
    type: 'CONTENT',
    description: 'Content:',
    friendlyName: 'Content',
    convertToHtml: true
  },
  {
    type: 'TEXTCONTENT',
    description: 'Text content:',
    friendlyName: 'Text Content',
    convertToHtml: false
  },
  {
    type: 'LABEL',
    description: 'Label:',
    friendlyName: 'Label',
    convertToHtml: false
  },
  {
    type: 'TOOLTIP',
    description: 'Tooltip:',
    friendlyName: 'Tooltip',
    convertToHtml: true
  },
  {
    type: 'TEXTTOOLTIP',
    description: 'Text tooltip:',
    friendlyName: 'Text tooltip',
    convertToHtml: false
  },
  {
    type: 'BUTTON',
    description: 'Button:',
    friendlyName: 'Button',
    convertToHtml: false
  },
  {
    type: 'RELEASE_NOTES',
    description: 'Release Notes:',
    friendlyName: 'Release Notes',
    convertToHtml: true
  }
]
