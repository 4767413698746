<div class="listing-container" *ngIf="perms?.canRead">
  <div class="controls-container px-2 px-md-0">
    <!-- <div class="date-container">
      <mat-form-field appearance="outline" class="listing-filter-with-archival">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label style="color: gray">{{'SETTINGS.FILTER.LABEL.TEXT' | translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterValue" placeholder="client" #input />
      </mat-form-field>
    </div> -->
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
      *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create Label" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        vpn_key
      </mat-icon> <span class="hide-sm">{{'SETTINGS.DEVICES.ACTIVATIONKEY.BUTTON.TEXT' | translate}}</span></button>
    <span class="f-grow"></span>
    <button mat-button class="mobile-btn" style="height: 2rem;font-size: 0.95rem;" color="primary"
      style="height: 2rem;font-size: 0.95rem;"
      [disabled]="(deviceList?.length === 0) || beingArchived || lastSeenRequestInProgress || areArchivedDevicesShown() || !perms?.canUpdate"
      (click)="markLastSeenReqForMultiple()"> {{'SETTINGS.DEVICES.LASTSEENALL.BUTTON.TEXT' | translate}}</button>
    <span class="f-grow"></span>
    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
        [(ngModel)]="unArchivedVsArchived" (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon matTooltip="Show devices" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon matTooltip="Show archived devices" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="button-grid d-flex pb-3 hide-sm">
    <button mat-flat-button color="primary" class="hide-sm" style="height: 2rem;font-size: 0.95rem;"
      [disabled]="!areMultipleDevicesSelected() || !perms?.canUpdate" (click)="editMultipleDevices()">
      {{'SETTINGS.DEVICES.EDITSETTINGS.BUTTON.TEXT' | translate}}
      {{getSelectedDeviceCount()}}</button>
    <button mat-flat-button color="primary" style="height: 2rem;font-size: 0.95rem;margin-left: 2rem;"
      [disabled]="!areMultipleDevicesSelected() || beingArchived || lastSeenRequestInProgress"
      (click)="archiveMultipleDevices()" *ngIf="areUnArchivedDevicesShown() && perms?.canUpdate">
      {{'SETTINGS.DEVICES.ARCHIVE.BUTTON.TEXT' |
      translate}} {{getSelectedDeviceCount()}}</button>
    <button class="clr-primary" mat-button style="height: 2rem;font-size: 0.95rem;margin-left: 2rem"
      [disabled]="!perms?.canUpdate" (click)="editEnrollmentSettings()">
      {{'SETTINGS.DEVICES.ENROLLMENTSETTINGS.BUTTON.TEXT' | translate}}</button>
    <button mat-button style="height: 2rem;font-size: 0.95rem;margin-left: 2rem" color="primary"
      style="height: 2rem;font-size: 0.95rem;margin-left: 2rem;"
      [disabled]="(deviceList?.length === 0) || beingArchived || lastSeenRequestInProgress || areArchivedDevicesShown() || !perms?.canUpdate"
      (click)="markLastSeenReqForMultiple()"> {{'SETTINGS.DEVICES.LASTSEENALL.BUTTON.TEXT' | translate}}</button>
  </div>
  <div class="table-card table-mobile-res table-to-card-mb">
    <div *ngIf="areArchivedDevicesShown()"> {{'SETTINGS.DEVICES.ARCHIVED.TEXTCONTENT.TEXT' | translate}}</div>
    <div class="mat-elevation-z8 expandable-table" style="clear: both;">
      <app-common-table (visibilityChange)="visibilityClicked($event)"
        (retrunFilteredData)="countSelectedDataFromFiltered($event)" [isRowExpandable]="true" [isRowSelectable]="true"
        [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay"
        [gridList]="deviceList" [tableName]="'deviceTable'" (editDialogOpened)="openEditDialog($event)"
        (onArchiveRow)="archiveDevice($event)" (onUnArchiveRow)="unarchiveDevice($event)"
        [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'device'">

      </app-common-table>
    </div>
  </div>

</div>
