<div class="choose-date" *ngIf="selectedClientDocData">
  <button mat-icon-button class="matMini-icon-btn" (click)="decrementWeek()">
    <mat-icon>keyboard_double_arrow_left</mat-icon>
   
  </button>
  <button mat-icon-button class="matMini-icon-btn"  (click)="decrementDate()">
    <mat-icon>chevron_left</mat-icon>
  </button>
  
  
  <mat-form-field class="example-full-width date-form-field" appearance="outline">
    <mat-label> {{'DASHBOARD.COMMON.DATEPICKER.MATLABEL.LABEL.TEXT' | translate}}</mat-label>
    <input matInput [matDatepickerFilter]="futureFilter" (dateChange)="onDateChange()"
           [(ngModel)]="selectedDate" [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <button mat-icon-button (click)="incrementDate()" class="matMini-icon-btn" >
    <mat-icon>chevron_right</mat-icon>
  </button>
  <button mat-icon-button (click)="incrementWeek()" class="matMini-icon-btn" >
    <mat-icon>keyboard_double_arrow_right</mat-icon>
  </button>
  
</div>
