<div style="width:30rem">
  <h2 mat-dialog-title>
    <mat-icon class="material-symbols-outlined mr-2" style="margin-bottom: -3px;">
      upload
    </mat-icon>
    {{'SETTINGS.BEACON.UPLOADBEACONS.MODAL.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content>
    <div class="upload-content-des">
     {{'SETTINGS.BEACON.UPLOADBEACONS.DESCRIPTION.CONTENT.TEXT' | translate}}
    </div>
    <div class="browseFile-wrapper my-3">
      <input type="file" id="upload_file_btn" class="file-upload" (change)="onFileSelected($event)">
      <label for="upload_file_btn" class="btn btn-upload">
        {{'SETTINGS.BEACON.UPLOADBEACONS.BROWSEFILE.BUTTON.TEXT' | translate}}
      </label>
    </div>
    
    <label class="evalvation-label">Evaluation</label>
    <div class="upload-evalvation transparent-effect-card">
      <ng-container *ngIf="errorOccurred">
        <div  style="color:rgb(223 103 79);" *ngFor="let list of errorList">
          <span>{{list.message}}</span>&nbsp;<span *ngIf="list?.rowIndex">in row {{list.rowIndex}}</span>
          &nbsp;<span *ngIf="list?.columnIndex">& column  {{list.columnIndex}}</span>
        </div>
      </ng-container>
      
      <div *ngIf="!errorOccurred && uploadSucess" class="clr-primary">
        {{'SETTINGS.BEACON.UPLOADBEACONS.SUCESSMESSAGE.CONTENT.TEXT' | translate}}
      </div>
      <div *ngIf="uploadProgress" style="opacity: 0.7;" class="clr-primary">
        {{'SETTINGS.BEACON.UPLOADBEACONS.UPLOADINGMESSAGE.CONTENT.TEXT' | translate}}
      </div>
      
    </div>
    
  </mat-dialog-content>

  <div>
    <mat-dialog-actions class="w-100">
      <!--<button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.ARCHIVE.BUTTON.TEXT' | translate}}</button>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.RESETASSIGNING.BUTTON.TEXT' | translate}}</button>-->
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' |
        translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
