import {Component, Inject} from '@angular/core';
import {FirestoreService} from "../../../services/firestore.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import { BlobOptions } from 'buffer';

@Component({
  selector: 'app-upload-beacons-dialog',
  templateUrl: './upload-beacons-dialog.component.html',
  styleUrl: './upload-beacons-dialog.component.scss'
})
export class UploadBeaconsDialogComponent {
  private clientId: string;
  errorOccurred: boolean;
  errorList: any[] = [];
  uploadSucess:boolean = false

  constructor(
    private firestoreService: FirestoreService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UploadBeaconsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private auth: AngularFireAuth,
    private dialog: MatDialog,
  ) {
    this.clientId = data.clientId;
  }
  uploadProgress: boolean = false;
  onFileSelected($event) {
    this.uploadProgress = true
    const file: File = $event.target.files[0];
    if (!file) {
      return;
    }
    try {
      this.auth.idToken.subscribe(token => {
        if (token) {
          this.firestoreService.uploadBeaconsCSVFile($event.target.files, this.clientId, token).subscribe({
            next: (response) => {
              console.log('response:' + JSON.stringify(response));
              if (response.success) {
                this.openSnackBar(response.message, 'success');
                this.uploadSucess = true;
                this.uploadProgress = false;

              } else {
                this.errorOccurred = true;
                this.uploadSucess = false
                this.errorList = response.csvInvalidData ?? []; //response.csvInvalidData is an array with error list
                this.uploadProgress = false;
                this.openSnackBar(response.message, 'error');
                
              }
            },
            error: (error) => {
              if (error?.error) {
                this.openSnackBar(error?.error?.message, 'error');
              } else {
                this.openSnackBar('Request failed due to SYSTEM ERROR.', 'error');
              }
              this.uploadProgress = false;
            }
          });
        }
      });
    } catch (error) {
      console.log(error.message);
    }
  }
  close() {
    this.dialogRef.close();
  }
  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  protected readonly JSON = JSON;
}


