import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {VarietyMainAttributes} from "../../../common/interfaces/variety-interfaces";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {DatePipe} from "@angular/common";
import {Subscription} from "rxjs";
import {FirestoreService} from "../../../services/firestore.service";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute} from "@angular/router";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ClientInContextService} from "../../../services/client-in-context.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BreakpointService} from "../../../services/breakpoint.service";
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";
import {Timestamp} from "firebase/firestore";
//locationName, layoutName, rowNumber, variety, lastActivity, unarchive
@Component({
  selector: 'app-client-archived-rows',
  templateUrl: './client-archived-rows.component.html',
  styleUrls: ['./client-archived-rows.component.scss',
    '../../../common/styles/listing.scss']
})
export class ClientArchivedRowsComponent implements OnDestroy, AfterViewInit {
  archivedRowsAttributesReadableMap: Map<string, string> = new Map([
    ['locationName', 'DASHBOARD.SESSION.NESTEDTABLE.LOCATION.TITLE.TEXT'],
    ['layoutName', 'DASHBOARD.SESSION.NESTEDTABLE.LAYOUT.TITLE.TEXT'],
    ['rowNumber', 'SETTINGS.TABLEHEAD.ROWNUMBER.TITLE.TEXT'],
    ['varietyName', 'SETTINGS.TABLEHEAD.VARIETY.TITLE.TEXT'],
    ['latestActivityTimestamp', 'SETTINGS.TABLEHEAD.LASTACTIVITY.TITLE.TEXT']
  ]);

  screenSize = 'default';

  columnsToDisplay: string[] = ['locationName', 'layoutName', 'rowNumber', 'varietyName', 'latestActivityTimestamp'];

  columnsHeadersToDisplay: string[] = [
    'locationName',
    'layoutName',
    'rowNumber',
    'varietyName',
    'latestActivityTimestamp',
    'unarchived',
  ];
  dataSource: MatTableDataSource<VarietyMainAttributes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  archivedRowsList: any[];
  dateColumns: string[] = ['latestActivityTimestamp'];
  datePipe = new DatePipe('en-US');
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  archivedRowsSubscription: Subscription;
  breakpointSubscription: Subscription;
  filterValue: string
  labelsList: any[];

  constructor(public firestoreService: FirestoreService,
              public authService: AuthService,
              public route: ActivatedRoute,
              private dialog: MatDialog,
              private clientInContextService: ClientInContextService,
              private snackBar: MatSnackBar,
              private breakpointService: BreakpointService,
              private dialogRef: MatDialogRef<ClientArchivedRowsComponent>
  ) {
    this.breakpointSubscription = this.breakpointService.screenSize$.subscribe(screenSize => this.screenSize = screenSize);
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
      }
    );

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      this.fetchAllArchivedRows()
    });
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.archivedRowsSubscription?.unsubscribe();
    this.breakpointSubscription?.unsubscribe();
  }

  async unarchiveRow(row) {
    try {
      await this.firestoreService.unarchiveRowForLytLocClientId(row.locationId, row.layoutId, this.selectedClientDocData?.id, row.id);
      this.openSnackBar(`Row '${row?.rowNumber}' unarchived successfully`, 'success');
    } catch (error) {
      this.openSnackBar('Error in unarchiving row: ' + error.message, 'error');
      console.log(error.message);
    }
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  fetchAllArchivedRows() {
    this.archivedRowsList = [];
    this.archivedRowsSubscription = this.firestoreService
    .getAllArchivedRowsForClientId(this.selectedClientDocData.id)
    .subscribe((rowsList) => {
      this.archivedRowsList = rowsList.map((row) => {
        for (const [key, value] of Object.entries(row)) {
          if (this.dateColumns.includes(key)) {
            if (!value) {
              row[key] = '';
              continue;
            }
            const timeValue = value as Timestamp;
            row[key] = this.datePipe.transform(
              timeValue.toMillis(),
              'yyyy-MM-dd HH:mm'
            );
          }
        }
        return row;
      });

      this.archivedRowsList.sort((rowA: any, rowB: any) => {
        return rowA.rowNumber < rowB.rowNumber ? -1 : rowA.rowNumber > rowB.rowNumber ? 1 : 0;
      });

      this.dataSource = new MatTableDataSource(this.archivedRowsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}

