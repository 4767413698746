import {AfterViewInit, Component, DestroyRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {SNACKBAR_CLASSES} from '../../common/utils/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FirestoreService} from '../../services/firestore.service';
import {MatButtonModule} from '@angular/material/button';
import {MatTableDataSource} from '@angular/material/table';
import {ClientMainAttributes} from '../../common/interfaces/client-interfaces';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {getPermissions} from '../../common/utils/permission-utils';
import {ClientInContextService} from '../../services/client-in-context.service';

@Component({
  selector: 'app-client-user-profile',
  templateUrl: './client-user-profile.component.html',
  styleUrls: ['./client-user-profile.component.scss',
    '../../common/styles/listing.scss']
})
export class ClientUserProfileComponent implements OnInit, OnDestroy {
  userList: any[];
  private loggedInUserFromAuthServiceSubscription: Subscription;
  private loggedInUserDocData: any;
  name: string;
  email: string;
  notes: string;
  languageCode: string;
  webAppConfigSubscription: Subscription;
  @ViewChild(MatSort) sort: MatSort;
  clientNamesBlurred = false
  languagesList: any = []
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  perms: any;

  constructor(private authService: AuthService,
              private snackBar: MatSnackBar,
              private firestoreService: FirestoreService,
              private clientInContextService: ClientInContextService,
              private destroyRef: DestroyRef) {
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
        this.loggedInUserDocData = userDocData;
        if (this.loggedInUserDocData.hasOwnProperty('clientNamesBlurred')) {
          this.clientNamesBlurred = this.loggedInUserDocData.clientNamesBlurred;
        }
        this.name = userDocData.name;
        this.email = userDocData.email;
        this.notes = userDocData.notes;
        this.languageCode = userDocData.languageCode ? userDocData.languageCode : '';
        this.userList = this.loggedInUserDocData.clients
      });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        if (this.selectedClientDocData.rolePermissionsMap) {
          this.perms = getPermissions('settings', 'userProfile', selectedClientDocData.rolePermissionsMap);
        }
      });

    this.webAppConfigSubscription = this.firestoreService.getWebAppConfig().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((webAppDS) => {
        this.languagesList = Object.values(webAppDS.data().languages);
      });
  }

  async updateUser() {
    if (!this.name || !this.name?.trim()) {
      this.openSnackBar('Name is a mandatory field!', 'error');
      return;
    }
    if (!this.email || !this.email?.trim()) {
      this.openSnackBar('Email is a mandatory field!', 'error');
      return;
    }

    if ((this.name.trim() === this.loggedInUserDocData.name) &&
      (this.notes === this.loggedInUserDocData.notes) &&
      (this.languageCode === this.loggedInUserDocData.languageCode)) {
      this.openSnackBar('No changes to save!', 'error');
      return;
    }

    try {
      await this.firestoreService.updateUserById(this.loggedInUserDocData.id, {
        name: this.name?.trim(),
        notes: this.notes ?? null,
        languageCode: this.languageCode ?? null
      });
      this.openSnackBar('User info is changed successfully!', 'success');
    } catch (error) {
      this.openSnackBar('Error in saving user info:' + error.message, 'error');
    }
  }



  openSnackBar(message, type, duration?) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: duration ? duration : 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.webAppConfigSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
  }

}
