<div class="h-100 d-flex  main-container-wrapper" >
   <app-side-nav></app-side-nav>
   <div class="d-flex flex-column f-grow main-container">
    <div class="router-outlet h-100" [ngClass]="{'isLogout':!loggedInUserDocData}">
       <app-sidenav-help>
        <app-nav></app-nav>
        <div [class]="loggedInUserDocData?'container-fluid':'login-container h-100'">
          <router-outlet></router-outlet>
        </div>
       </app-sidenav-help>
    </div>
   </div>
</div>
