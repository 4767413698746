import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-beacon-selection',
  templateUrl: './beacon-selection.component.html',
  styleUrl: './beacon-selection.component.scss'
})
export class BeaconSelectionComponent implements OnInit, OnChanges {
  @Input() beaconIds: FormControl;
  @Input() allBeaconsList: any[];
  @Input() isFromGrid = false;
  @Output() selectionChange = new EventEmitter<void>();
  @Output() selectedValueChange = new EventEmitter<any>();
  @Input() selectedValue?: any;
  filterValue = '';
  selectedBeaconName = '';
  selectedBeaconMac = '';
  beaconAdditionalCount = 0;
  beaconNameMap: { [key: string]: string } = {};
  beaconMacMap: { [key: string]: string } = {};
  editedBeacons: any;
  isAdmin = true;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allBeaconsList?.currentValue) {
      this.allBeaconsList.forEach(beacon => {
        this.beaconNameMap[beacon.id] = beacon.name;
        this.beaconMacMap[beacon.id] = beacon.mac;
      });
      this.updateSelectedBeaconInfo(this.editedBeacons)
    }

    if (changes.beaconIds?.currentValue.value || changes.selectedValue?.currentValue) {
      this.editedBeacons = changes.selectedValue?.currentValue ? changes.selectedValue?.currentValue : changes.beaconIds?.currentValue.value;
      if (changes.selectedValue && changes.selectedValue?.currentValue) {
        this.beaconIds.setValue(changes.selectedValue.currentValue);
        this.updateSelectedBeaconInfo(this.editedBeacons);
      }
    }
  }

  ngOnInit(): void {
    this.beaconIds.valueChanges.subscribe(value => {
      this.updateSelectedBeaconInfo(value)
    });
  }

  updateSelectedBeaconInfo(beaconIds: string[]): void {
    if (beaconIds && beaconIds.length > 0) {
      this.selectedBeaconName = this.getBeaconName(beaconIds[0]);
      this.selectedBeaconMac = this.getBeaconMac(beaconIds[0])
      this.beaconAdditionalCount = beaconIds.length - 1;
    } else {
      this.selectedBeaconName = '';
      this.beaconAdditionalCount = 0;
    }
  }

  getBeaconName(id: string): string {
    if (id.length > 0) {
      return this.beaconNameMap[id];
    }
  }

  getBeaconMac(id: string): string {
    if (id.length > 0) {
      return this.beaconMacMap[id];
    }
  }

  onSelectionFn() {
    this.selectionChange.emit()
  }

  onModelChange(value: any) {
    this.selectedValueChange.emit(value);
  }
}
