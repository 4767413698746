<section class="new-table-design-container" tabindex="0" style="margin-top: 4px;" *ngIf="perms?.canRead">
  <div class="controls-container filter-container sm-grid-areas">
    <button mat-flat-button color="primary" class="add-area" (click)="openDialog()" [disabled]="!perms?.canCreate">
      <mat-icon matTooltip="Create Worker" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATE.BUTTON.TEXT' | translate}}</button>

    <div class="date-container justify-content-center daterange-multipleOptions date-area">
      <mat-form-field appearance="outline">
        <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.DATERANGE.LABEL.TEXT' | translate}}</mat-label>
        <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRange">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>{{'DASHBOARD.REGISTRATIONS.PRESENCES.WEEK.LABEL.TEXT' | translate}} {{weekNumber}}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker panelClass="daterange-multipleOptions">
          <mat-date-range-picker-actions>
            <div class="date-picker-actions-container">
              <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [formControl]="quickControl" name="weekCustomToggle"
                (change)="weekToggle($event.value)" vertical>
                <mat-button-toggle class="transparent-effect-card"
                  value="THIS_WEEK">{{'DASHBOARD.REGISTRATIONS.PRESENCES.THISWEEK.BUTTON.TEXT' |
                  translate}}</mat-button-toggle>
                <mat-button-toggle class="transparent-effect-card"
                  value="LAST_WEEK">{{'DASHBOARD.REGISTRATIONS.PRESENCES.LASTWEEK.BUTTON.TEXT' |
                  translate}}</mat-button-toggle>
                <mat-button-toggle class="transparent-effect-card" value="THIS_MONTH">This month</mat-button-toggle>
                <mat-button-toggle class="transparent-effect-card" value="LAST_MONTH">Last month</mat-button-toggle>
                <mat-button-toggle class="transparent-effect-card"
                  value="WEEK_RANGE">{{'DASHBOARD.REGISTRATIONS.PRESENCES.WEEKRANGE.BUTTON.TEXT' |
                  translate}}</mat-button-toggle>
                <mat-button-toggle class="transparent-effect-card" value="CUSTOM_RANGE">Custom range</mat-button-toggle>
              </mat-button-toggle-group>
              <div>
                <button mat-button
                  matDateRangePickerCancel>{{'DASHBOARD.REGISTRATIONS.PRESENCES.WEEK.CANCEL.BUTTON.TEXT' |
                  translate}}</button>
                <button mat-raised-button color="primary" matDateRangePickerApply>
                  {{'DASHBOARD.REGISTRATIONS.PRESENCES.WEEK.APPLY.BUTTON.TEXT' | translate}}
                </button>
              </div>
            </div>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>

    <button mat-icon-button aria-label="" matTooltip="Reload presences" class="hide-sm" (click)="reloadPresences()" style="float:right;margin-right:0.5rem;">
      <mat-icon>restart_alt</mat-icon>
    </button>
    <!-- <button mat-mini-fab [matMenuTriggerFor]="workerDisplayOptionsMenu">
      <mat-icon>playlist_add_check</mat-icon>
    </button> -->
    <button mat-icon-button aria-label="" class="download-area hide-sm" matTooltip="Export as CSV" style="float:right;margin-right: 0.5rem;"
      [matMenuTriggerFor]="downloadMenu">
      <mat-icon>download</mat-icon>
    </button>
    <mat-menu #downloadMenu="matMenu">
      <button mat-menu-item (click)="openReportExportDialog('DAILY_OVERVIEW')">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.DAILY.OVERVIEW.BUTTON.TEXT' | translate}}
      </button>
      <button mat-menu-item (click)="openReportExportDialog('DAILY_DETAIL')">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.DAILY.DETAIL.BUTTON.TEXT' | translate}}
      </button>
      <button mat-menu-item (click)="openReportExportDialog('DAILY_UPDATE_OVERVIEW')">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.DAILY.UPDATE.BUTTON.TEXT' | translate}}
      </button>
      <mat-divider></mat-divider>
      <div style="width: 100%;margin-top: 0.2rem;text-align: center;font-size: 0.8rem;font-weight: 100;">Excel Native</div>
      <button mat-menu-item (click)="exportCSV('DAILY_OVERVIEW', 'NativeExcel', false)">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.DAILY.OVERVIEW.BUTTON.TEXT' | translate}}<mat-chip>BETA</mat-chip>
      </button>
      <button mat-menu-item (click)="exportCSV('DAILY_DETAIL', 'NativeExcel', false)">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.DAILY.DETAIL.BUTTON.TEXT' | translate}}<mat-chip>BETA</mat-chip>
      </button>
      <button mat-menu-item (click)="exportCSV('DAILY_UPDATE_OVERVIEW', 'NativeExcel', false)">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.DAILY.UPDATE.BUTTON.TEXT' | translate}}<mat-chip>BETA</mat-chip>
      </button>
    </mat-menu>
    <mat-button-toggle-group [hideSingleSelectionIndicator]="true" style="max-width: 8rem;float:right;" class="archive-toggle toggle-area" [(ngModel)]="selectedView"
      #group="matButtonToggleGroup">
      <mat-button-toggle value="listView" (click)="toggleArchived(false);" checked matTooltip="All items">
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="archiveView" (click)="toggleArchived(true);" matTooltip="Archived items">
        <mat-icon>archive</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="hide-show-worker d-flex mb-2">
    <div class="filter-string" style="margin-top:-1.43rem;" *ngIf="filterString">{{filterString}}</div>
    <span class="f-grow"></span>
    <button mat-stroked-button color="primary" (click)="showAllWorkers($event)">
      <mat-icon>{{showAllWorkersPresences ? 'visibility_off' : 'visibility'}}</mat-icon>
      <span>{{showAllWorkersPresences ?'Hide all workers':'Show all workers'}}</span>
    </button>
    <!-- <mat-checkbox [(ngModel)]="showAllWorkersPresences" (click)="showAllWorkers($event)" labelPosition="after">Show all workers</mat-checkbox> -->
  </div>
  <div class="table-card table-mobile-res">
    <div *ngIf="isArchivedShown">Archived Presences</div>
    <section class="new-table-design-container table-to-card-mb table-body mat-elevation-z8 expandable-table" tabindex="0">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
        <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
          <th mat-header-cell class="table-header" *matHeaderCellDef>
            <div class="table-header-container">
              <div class="table-header-content-text">{{column.displayName | translate}}</div>
              <!--<div class="table-header-content-spacer"></div>-->
              <div class="table-header-content-menu">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                  class="menu-button" *ngIf="column.showHeaderFilter" [class]="column.filtered ? 'filtered' : ''">
                  <mat-icon>filter_list</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                  <button mat-menu-item (click)="sortList(column.name,'asc')">
                    <mat-icon></mat-icon>
                    <span innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span>
                  </button>
                  <button mat-menu-item (click)="sortList(column.name,'desc')">
                    <mat-icon></mat-icon>
                    <span innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span>
                  </button>
                  <form class="example-form" (click)="$event.stopPropagation()">
                    <mat-form-field class="filter">
                      <mat-label>{{'DASHBOARD.TABLE.FILTERVALULE.LABEL.TEXT' | translate}}</mat-label>
                      <input type="text" placeholder="" aria-label="Filter Value" matInput
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="column.filterValue" [matAutocomplete]="auto"
                        (keyup)="headerFilter($event, column.name)" (focus)="headerFilter($event, column.name)" />
                        <button mat-icon-button matSuffix (click)="removeFilters()">
                          <mat-icon>close</mat-icon>
                        </button>
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of column.filterOptions" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </form>
                  <button mat-menu-item (click)="removeFilters()">
                    <mat-icon>filter_list_off</mat-icon>
                    <span> {{'DASHBOARD.TABLE.REMOVEALLFILTERS.LABEL.TEXT' | translate}}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" [attr.dataName]="column.displayName | translate">
            <span>{{element[column.name]}}</span>
          </td>
          <td mat-footer-cell *matFooterCellDef [attr.dataName]="column.displayName | translate">{{column.footerValue}} </td>
        </ng-container>
        <ng-container matColumnDef="checkColumn">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="listing-table-tools bottom-full-w static-td left-corner bg-transparent-sm">
            <div class="duration-error-icon">
              <mat-icon style="color: #d81159" class="material-symbols-outlined"
                matTooltip="The end time is missing for one or more registrations" *ngIf="element.isEndTimeMissing"
                matTooltipClass="tooltip-class" matTooltipHideDelay="100" matTooltipPosition="below">error</mat-icon>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">
            <button mat-icon-button (click)="toggleExpandAll()">
              @if (allRowsExpanded) {
              <mat-icon matTooltip="Collapse the rows">unfold_less</mat-icon>
              } @else {
              <mat-icon matTooltip="Expand the rows">unfold_more</mat-icon>
              }
            </button>
          </th>
          <td mat-cell *matCellDef="let element">

          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="getDisplayedCols().length">
            <mat-icon (click)="expandedElement = null" class="material-symbols-outlined close-expandable d-block d-md-none">
              close
            </mat-icon>
            <div class="row-detail-expanded" [@detailExpand]="
                  element.workerId == expandedElement?.workerId || allRowsExpanded ? 'expanded' : 'collapsed'">
              <div class="row-detail-description">
                <div class="example-element-description__header hide-sm">
                  <div [ngClass]="{'small-col':column === 'checkColumn' || column === 'edit'}" class="example-element-description__cell" *ngFor="let column of columnsHeadersToDisplayNested">
                    <div>{{workerNestedAttributesReadableMap.get(column) | translate}}</div>
                  </div>
                </div>
                <div *ngFor="let nestedColumn of element['nestedRows']; let idx = index" class="mobile-row">
                  <div class="dsecription-header-mb">
                    <div [ngClass]="{'small-col':column === 'checkColumn' || column === 'edit'}" class="example-element-description__cell" *ngFor="let column of columnsHeadersToDisplayNested">
                      <div>{{workerNestedAttributesReadableMap.get(column) | translate}}</div>
                    </div>
                  </div>
                  <div class="example-element-description__content">

                  <div class="example-element-description__cell" *ngFor="let column of columnsToDisplayNested" [ngClass]="{'small-col':column === 'checkColumn' || column === 'edit'}">
                    <span *ngIf="column !== 'edit' && column !== 'checkColumn'">{{nestedColumn[column]}}</span>
                      <button
                        *ngIf="column === 'edit' && nestedColumn.presenceDoc && !isArchivedShown"
                        [disabled]="!perms?.canUpdate"
                        class="help-button"
                        mat-icon-button
                        style="margin-top: -0.8rem;"
                        (click)="openEditDialog($event, nestedColumn)"
                      >
                      <mat-icon style="transform: scale(0.8); cursor: pointer;">edit</mat-icon>
                      </button>
                    <button
                      *ngIf="column === 'edit' && nestedColumn.presenceDoc && isArchivedShown"
                      [disabled]="!perms?.canDelete || unarchivingPresence"
                      class="help-button unarchive-btn"
                      mat-icon-button
                      style="margin-top: -0.8rem;"
                      (click)="unarchivePresence($event, nestedColumn)"
                    >
                      <mat-icon style="transform: scale(0.8); cursor: pointer;">unarchive</mat-icon>
                    </button>
                    <span *ngIf="column === 'checkColumn'" class="error-btn" >
                      <mat-icon style="transform: scale(0.9); cursor: pointer;color:#d81159"
                        class="material-symbols-outlined"
                        matTooltip="{{'DASHBOARD.REGISTRATIONS.PRESENCES.ENDTIMEERROR.LABEL.TEXT' |  translate}}"
                        *ngIf="nestedColumn.isEndTimestampMissing">error</mat-icon>
                    </span>
                  </div>
                </div>
                </div>

              </div>

            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedCols(); sticky: true"></tr>
        <tr mat-row [class.hidden]="toggleTableChart !== 'table'" *matRowDef="let element; columns: getDisplayedCols();"
          [class.expanded-row]="applyExpandedClass(element)" (click)="expandRow(element)"></tr>
        <tr style="height: 0;" mat-row *matRowDef="let element; columns: ['expandedDetail']"
          class="row-detail expandable-row"></tr>
        <tr mat-footer-row class="footer-row hide-sm" *matFooterRowDef="displayedColumns; sticky: true"></tr>
      </table>
    </section>

    <section class="new-table-design-container mat-elevation-z8" tabindex="0">
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons aria-label="Select page">
      </mat-paginator>
    </section>
  </div>
</section>
<!-- <mat-menu #workerDisplayOptionsMenu="matMenu">
  <mat-checkbox [(ngModel)]="showAllWorkersPresences" (click)="showAllWorkers($event)" labelPosition="after">Show all workers</mat-checkbox>
</mat-menu> -->
