import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {limit} from "@angular/fire/firestore";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-create-beacon-dialog',
  templateUrl: './create-beacon-dialog.component.html',
  styleUrl: './create-beacon-dialog.component.scss'
})
export class CreateBeaconDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  description: string;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  allLocationsList: any[];
  locationListSubscription: Subscription;
  macAddressSubscription: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreateBeaconDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.description = data.description;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => {
        this.allLocationsList = locationsList?.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        });
        this.form.patchValue({locationIds: locationsList.map(loc => loc.id)});
      });
    });
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.macAddressSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      mac: ['', [Validators.required]],
      beaconType: [null, [Validators.required]],
      /*batteryLevel: [{value: '', disabled: true}, []],
      assignedType: [{value: null, disabled: true}, []],
      assignedId: [{value: null, disabled: true}, []],
      assignedName: [{value: null, disabled: true}, []],
      stickDuration: [{value: '', disabled: true}, []],
      rssiThreshold: [{value: '', disabled: true}, []],*/
    });
  }

  async createBeacon() {
    if (!this.form.valid) {
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    const beaconToCreate = this.form.value;
    beaconToCreate.name = beaconToCreate.name?.trim();

    try {
      this.macAddressSubscription = this.firestoreService.getBeaconForMACAddress(beaconToCreate.mac, this.selectedClientDocData.id)
      .pipe(take(1)).subscribe(async (beaconList) => {
        this.macAddressSubscription?.unsubscribe();
        if (beaconList?.length > 0) {
          this.openSnackBar('MAC Address already in use', 'error');
          return;
        }
        if (!beaconToCreate.assignedId) {
          beaconToCreate.assignedId = null;
        }
        if (!beaconToCreate.assignedName) {
          beaconToCreate.assignedName = null;
        }
        if (!beaconToCreate.assignedType) {
          beaconToCreate.assignedType = null;
        }

        beaconToCreate.rssiThreshold = -46;

        await this.firestoreService.createBeaconForClientId(beaconToCreate, this.selectedClientDocData.id);
        this.dialogRef.close(this.form.value);
        this.openSnackBar(`Beacon '${beaconToCreate.name}' created successfully.`, 'success');
        this.form.reset();
      });

    } catch (error) {
      this.openSnackBar('Error in beacon creation:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}



