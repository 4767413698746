import {AfterViewInit, Component, DestroyRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../services/firestore.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ClientInContextService} from '../../services/client-in-context.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BreakpointService} from '../../services/breakpoint.service';
import {ConfirmationDialogComponent} from '../utility/confirmation-dialog/confirmation-dialog.component';
import {SNACKBAR_CLASSES} from '../../common/utils/utils';
import firebase from 'firebase/compat/app';
import {Timestamp} from 'firebase/firestore';
import {CreatePositionDialogComponent} from "./create-position-dialog/create-position-dialog.component";
import {EditPositionDialogComponent} from "./edit-position-dialog/edit-position-dialog.component";
import {PositionMainAttributes} from "../../common/interfaces/clock-interfaces";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { FilterProcessingService } from 'src/app/services/filter-processing.service';
import {getPermissions} from '../../common/utils/permission-utils';

@Component({
  selector: 'app-client-positions',
  templateUrl: './client-positions.component.html',
  styleUrls: ['./client-positions.component.scss',
    '../../common/styles/listing.scss',]
})
export class ClientPositionsComponent implements OnDestroy, AfterViewInit {
  unArchivedVsArchived = 'unarchived';

  screenSize = 'default';

  columnsToDisplay: any[] = [
    {
      name: 'id',
      displayName: 'ID',
      showHeader: true,
      showHeaderFilter: false,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: false,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'name',
      displayName: 'SETTINGS.TABLEHEAD.NAME.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'taskNames',
      displayName: 'SETTINGS.TABLEHEAD.TASKNAMES.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'beaconNames',
      displayName: 'SETTINGS.TABLEHEAD.BEACONS.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'stickDuration',
      displayName: 'SETTINGS.TABLEHEAD.STICKDUR.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'rssiThreshold',
      displayName: 'SETTINGS.TABLEHEAD.RSSITHRSHLD.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'creationTimestamp',
      displayName: 'SETTINGS.TABLEHEAD.CREATEDAT.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
  ];
  columnsHeadersToDisplay: string[] = ['name','taskNames', 'beaconNames', 'stickDuration', 'rssiThreshold', 'creationTimestamp','edit'];
  dataSource: MatTableDataSource<PositionMainAttributes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  positionsList: any[];
  dateColumns: string[] = ['creationTimestamp'];
  datePipe = new DatePipe('en-US');
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  unarchivedPositionsSubscription: Subscription;
  archivedPositionsSubscription: Subscription;
  breakpointSubscription: Subscription;
  filterValue: string
  perms: any;

  constructor(public firestoreService: FirestoreService,
              public authService: AuthService,
              public route: ActivatedRoute,
              private dialog: MatDialog,
              private clientInContextService: ClientInContextService,
              private snackBar: MatSnackBar,
              private breakpointService: BreakpointService,
              private destroyRef: DestroyRef,private filterProcessService:FilterProcessingService) {
    this.breakpointSubscription = this.breakpointService.screenSize$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(screenSize => this.screenSize = screenSize);
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
          this.loggedInUserDocData = userDocData;
      });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        if (this.selectedClientDocData.rolePermissionsMap) {
          this.perms = getPermissions('settings', 'tasks', selectedClientDocData.rolePermissionsMap);
        }
        this.fetchUnarchivedPositions();
      });
  }

  ngAfterViewInit() {
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreatePositionDialogComponent, dialogConfig);
  }

  openEditDialog(record) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      positionRecord: record
    };

    this.dialog.open(EditPositionDialogComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.archivedPositionsSubscription?.unsubscribe();
    this.unarchivedPositionsSubscription?.unsubscribe();
    this.breakpointSubscription?.unsubscribe();
  }

  async archivePosition(position) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive position '${position?.name ?? ''}' ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        position
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        try {
          await this.firestoreService.updatePositionByIdForClientId(value?.position?.id, this.selectedClientDocData?.id, {isArchived: true});
          this.openSnackBar(`Position '${value?.position?.name}' archived successfully`, 'success');
        } catch (error) {
          this.openSnackBar('Error in position archival: ' + error.message, 'error');
          console.log(error.message);
        }
      }
    });
  }

  async unarchivePosition(position) {
    try {
      await this.firestoreService.updatePositionByIdForClientId(position?.id, this.selectedClientDocData?.id, {isArchived: false});
      this.openSnackBar(`Position '${position?.name}' unarchived successfully`, 'success');
    } catch (error) {
      this.openSnackBar('Error in position archival: ' + error.message, 'error');
      console.log(error.message);
    }
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  toggleArchived() {
    if (this.areUnArchivedPositionsShown()) {
      this.fetchUnarchivedPositions();
    } else {
      this.fetchAllArchivedPositions();
    }
  }

  fetchUnarchivedPositions() {
    this.positionsList = [];
    this.archivedPositionsSubscription?.unsubscribe();
    this.unarchivedPositionsSubscription = this.firestoreService
      .getAllUnarchivedPositionsForClientId(this.selectedClientDocData.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((positionsList) => {
        this.positionsList = positionsList.map((position) => {
          for (const [key, value] of Object.entries(position)) {
            if (this.dateColumns.includes(key)) {
              const timeValue = value as Timestamp;
              position[key] = this.datePipe.transform(
                timeValue.toMillis(),
                'yyyy-MM-dd HH:mm'
              );
            }
          }
          return position;
        });

        this.positionsList.sort((positionA: any, positionB: any) => {
          return positionA.name < positionB.name ? -1 : positionA.name > positionB.name ? 1 : 0;
        });

        this.dataSource = new MatTableDataSource(this.positionsList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.filterProcessService.fliterPridecate(this.dataSource);
        this.filterProcessService.postProcessingFilterSort(this.columnsToDisplay, this.dataSource);
      });
  }

  fetchAllArchivedPositions() {
    this.positionsList = [];
    this.unarchivedPositionsSubscription?.unsubscribe();
    this.archivedPositionsSubscription = this.firestoreService
      .getAllArchivedPositionsForClientId(this.selectedClientDocData.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((positionsList) => {
        this.positionsList = positionsList.map((position) => {
          for (const [key, value] of Object.entries(position)) {
            if (this.dateColumns.includes(key)) {
              const timeValue = value as Timestamp;
              position[key] = this.datePipe.transform(
                timeValue.toMillis(),
                'yyyy-MM-dd HH:mm'
              );
            }
          }
          return position;
        });

        this.positionsList.sort((positionA: any, positionB: any) => {
          return positionA.name < positionB.name ? -1 : positionA.name > positionB.name ? 1 : 0;
        });

        this.dataSource = new MatTableDataSource(this.positionsList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.filterProcessService.fliterPridecate(this.dataSource);
        this.filterProcessService.postProcessingFilterSort(this.columnsToDisplay, this.dataSource);
      });
  }


  areArchivedPositionsShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedPositionsShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }
}
