import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {pairwise, startWith, Subscription} from "rxjs";
import {FirestoreService} from "../../../services/firestore.service";
import {ClientInContextService} from "../../../services/client-in-context.service";
import {AuthService} from "../../../services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";
import {CreateTaskGroupDialogComponent} from '../../client-task-groups/create-task-group-dialog/create-task-group-dialog.component';
import {CreateLabelDialogComponent} from '../../client-labels/create-label-dialog/create-label-dialog.component';

@Component({
  selector: 'app-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss']
})
export class CreateTaskDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  description: string;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  taskGroupsSubscription: Subscription;
  allTaskGroupsList: any[];
  allFunctionsList: any[];
  allClockFunctionsList = [
    {
      id: 'START_DAY',
      name: 'Start working day'
    },
    {
      id: 'END_DAY',
      name: 'End working day'
    },
    {
      id: 'START_BREAK_PAID',
      name: 'Start break paid'
    },
    {
      id: 'END_BREAK_PAID',
      name: 'End break paid'
    },
    {
      id: 'START_BREAK_UNPAID',
      name: 'Start break unpaid'
    },
    {
      id: 'END_BREAK_UNPAID',
      name: 'End break unpaid'
    },
    {
      id: 'START_BREAK',
      name: 'Start break'
    },
    {
      id: 'END_BREAK',
      name: 'End break'
    },
    {
      id: 'START_TASK',
      name: 'Start task'
    },
    {
      id: 'END_TASK',
      name: 'End task'
    }
  ];
  allWatchWebappFunctionsList = [
    {
      id: 'TASK',
      name: 'Task'
    },
    {
      id: 'BREAK_PAID',
      name: 'Break paid'
    },
    {
      id: 'BREAK_UNPAID',
      name: 'Break unpaid'
    },
    {
      id: 'ROW_TASK',
      name: 'Row task'
    },
    {
      id: 'TASK_ROW',
      name: 'Task row'
    },
    {
      id: 'TASK_VARIETY',
      name: 'Task variety'
    },
    {
      id: 'ROW_TASK_COUNT',
      name: 'Row task count'
    },
    {
      id: 'TASK_ROW_COUNT',
      name: 'Task row count'
    },
    {
      id: 'TASK_ROW_ASSET',
      name: 'Task row asset'
    }
  ];

  allTargetDevicesList = [
    {
      id: 'WATCH',
      name: 'Watch'
    },
    {
      id: 'CLOCKWEB',
      name: 'ClockWeb'
    },
    {
      id: 'CLOCK',
      name: 'Clock'
    }

  ];
  private loggedInUserFromAuthServiceSubscription: Subscription;
  private loggedInUserDocData: any;
  locationListSubscription: Subscription;
  allLocationsList: any[];
  labelsList: any[];
  unarchivedLabelsSubscription: Subscription;
  showAssetDetectionFields = false;
  showVarietySelectionField = false;
  varietyListSubscription: Subscription;
  allVarietiesList: any[];

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreateTaskDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.description = data.description;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.varietyListSubscription = this.firestoreService.getAllUnarchivedVarietiesForClientId(this.selectedClientDocData.id)
        .subscribe((varietyList) => {
          this.form.patchValue({varietyIds: varietyList.map(variety => variety.id)});
          this.allVarietiesList = varietyList.sort((varietyA: any, varietyB: any) => {
            return varietyA.name?.toLowerCase() < varietyB.name?.toLowerCase() ? -1 :
              varietyA.name?.toLowerCase() > varietyB.name?.toLowerCase() ? 1 : 0;
          });
        });

      this.locationListSubscription = this.firestoreService
        .getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id)
        .subscribe((locationsList) => {
          this.form.patchValue({locationIds: locationsList.map(loc => loc.id)});
          this.allLocationsList = locationsList.sort((locA: any, locB: any) => {
            return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
          });
        });

      this.taskGroupsSubscription = this.firestoreService
        .getAllUnarchivedTaskGroupsForClientId(this.selectedClientDocData.id).subscribe(taskGroupsList => (this.allTaskGroupsList = taskGroupsList));
    });

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
      }
    );
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.taskGroupsSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.unarchivedLabelsSubscription?.unsubscribe();
    this.varietyListSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      deviceTarget: [[], []],
      taskGroupIds: [[], []],
      labelIds: [[], []],
      locationIds: [[], [Validators.required]],
      externalId: ['', []],
      func: [{value: null, disabled: true}, []],
      assetDetectionRssiThreshold: [-55, [Validators.required]],
      assetDetectionInterval: [240, [Validators.required]],
      varietyIds: []
    });

    this.form.controls.deviceTarget?.valueChanges
      .pipe(startWith(this.form.controls.value), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        //console.log('prev:' + JSON.stringify(prev));
        //console.log('next:' + JSON.stringify(next));
        //console.log('------------------------------------------')
        if (prev?.includes('CLOCK') && next?.includes('CLOCK') && (next?.includes('WATCH') || next?.includes('CLOCKWEB'))) {
          const toSet = [];
          if (next?.includes('WATCH')) {
            toSet.push('WATCH');
          }
          if (next?.includes('CLOCKWEB')) {
            toSet.push('CLOCKWEB');
          }
          this.allFunctionsList = this.allWatchWebappFunctionsList;
          this.form.controls.func.enable();
          this.form.patchValue({
            deviceTarget: toSet,
            func: null
          });
        } else if ((prev?.includes('WATCH') || prev?.includes('CLOCKWEB')) && (next?.includes('CLOCK'))) {
          const toSet = ['CLOCK'];
          this.allFunctionsList = this.allClockFunctionsList;
          this.form.controls.func.enable();
          this.form.patchValue({
            deviceTarget: toSet,
            func: null
          });
        } else if (next?.includes('WATCH') || next?.includes('CLOCKWEB')) {
          this.allFunctionsList = this.allWatchWebappFunctionsList
          this.form.controls.func.enable();
        } else if (next?.includes('CLOCK')) {
          this.allFunctionsList = this.allClockFunctionsList;
          this.form.controls.func.enable();
        } else {
          this.form.patchValue({
            func: null
          });
          this.form.controls.func.disable();
        }
      });
    this.form.controls.func?.valueChanges
      .pipe(startWith(this.form.controls.value), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        this.showAssetDetectionFields = next === 'TASK_ROW_ASSET';
        this.showVarietySelectionField = next === 'TASK_VARIETY';
      })
    this.fetchAllLabels()
  }

  async createTask() {
    if (!this.form.valid) {
      this.openSnackBar('Please enter all mandatory values', 'error');
      return;
    }

    if (this.form?.value?.name && (this.form?.value?.name?.trim() === '')) {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    const taskToCreate = this.form.value;
    taskToCreate.name = taskToCreate.name?.trim();
    taskToCreate.externalId = taskToCreate.externalId?.trim() ?? null;

    if (taskToCreate.labelIds && (taskToCreate.labelIds.length > 0)) {
      taskToCreate.labels = this.labelsList.filter(label => taskToCreate.labelIds.includes(label.id)).map(label => label.name);
    } else {
      taskToCreate.labelIds = null;
      taskToCreate.labels = null;
    }

    const taskGroups = [];
    if (taskToCreate.taskGroupIds && taskToCreate.taskGroupIds.length > 0) {
      for (const taskGroupId of taskToCreate.taskGroupIds) {
        taskGroups.push(this.allTaskGroupsList
          .filter((taskGroup) => taskGroup.id === taskGroupId)
          .map((taskGroup) => (taskGroup.name ? taskGroup.name : null))[0]);
      }
    }

    taskToCreate.taskGroups = taskGroups;

    if (this.allClockFunctionsList.map(func => func.id).includes(taskToCreate.func)) {
      taskToCreate.type = 'PIT';
    }

    if (this.allWatchWebappFunctionsList.map(func => func.id).includes(taskToCreate.func)) {
      if (['TASK', 'ROW_TASK', 'TASK_ROW', 'TASK_VARIETY', 'ROW_TASK_COUNT', 'TASK_ROW_COUNT', 'TASK_ROW_ASSET'].includes(taskToCreate.func)) {
        taskToCreate.type = 'TASK';
      } else if (['BREAK_PAID', 'BREAK_UNPAID'].includes(taskToCreate.func)) {
        taskToCreate.type = 'BREAK'
      }
    }

    taskToCreate.createdByUserId = this.loggedInUserDocData.id ?? null;
    taskToCreate.createdByUserName = this.loggedInUserDocData.name ?? null;

    if (!this.showVarietySelectionField) {
      taskToCreate.varietyIds = [];
    }

    try {
      await this.firestoreService.createTaskForClientId(taskToCreate, this.selectedClientDocData.id);
      this.dialogRef.close(this.form.value);
      this.openSnackBar(`Task '${taskToCreate.name}' created successfully.`, 'success');
      this.form.reset();
    } catch (error) {
      this.openSnackBar('Error in task creation:' + error.message, 'error');
      console.log(error.message);
    }
  }

  openAddTaaskGroup() {
    this.dialog.open(CreateTaskGroupDialogComponent, {});
  }

  openAddLabel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateLabelDialogComponent, dialogConfig);
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  fetchAllLabels() {
    this.labelsList = [];
    this.unarchivedLabelsSubscription = this.firestoreService
      .getAllUnarchivedLabelsForClientId(this.selectedClientDocData.id)
      .subscribe((labelsList) => {
        this.labelsList = labelsList.sort((labelA: any, labelB: any) => {
          return labelA.name < labelB.name ? -1 : labelA.name > labelB.name ? 1 : 0;
        });
      });
  }
}


