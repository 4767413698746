<div class="page-top-space">
  <mat-card class="page-main-card">
    <mat-card-content>
      <div class="d-flex align-item-center page-action">

        <nav class="tabs-selection" mat-tab-nav-bar mat-align-tabs="center" [tabPanel]="tabPanel">
          <a mat-tab-link label="ROW MAP" (click)="navigateToRowMapTab()" [active]="tabIndex === 0">
            {{'DASHBOARD.PRODUCTIVITY.TABS.ROWMAP.LABEL.TEXT' |  translate}}
          </a>
          <a mat-tab-link label="ROW" (click)="navigateToRowTab()" [active]="tabIndex === 1">
            {{'DASHBOARD.PRODUCTIVITY.TABS.ROW.LABEL.TEXT' |  translate}}
          </a>
          <a mat-tab-link label="VARIETY" (click)="navigateToVarietyTab()" [active]="tabIndex === 2">
            {{'DASHBOARD.PRODUCTIVITY.TABS.VARIETY.LABEL.TEXT' |  translate}}
          </a>
        </nav>
      </div>

      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>


</div>
