<div style="width: 600px; max-width: 100%;">
  <h2 mat-dialog-title> {{ 'SETTINGS.ASSETS.CREATEASSET.TITLE.TEXT' | translate }}</h2>

  <mat-dialog-content [formGroup]="form" style="text-align: center;">
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.TYPE.LABEL.TEXT' | translate }}</mat-label>
          <mat-select formControlName="type">
            <mat-option value="TROLLEY">TROLLEY</mat-option>
            <mat-option value="MAT">MAT</mat-option>
          </mat-select>

        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate }}</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.NUMBER.LABEL.TEXT' | translate }}</mat-label>
          <input matInput formControlName="number">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label> {{ 'SETTINGS.MODAL.LOCATIONS.LABEL.TEXT' | translate }}</mat-label>
          <mat-select formControlName="locationIds" multiple>
            <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
              {{ location.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <app-beacon-selection [beaconIds]="form.get('beaconIds')" [allBeaconsList]="allBeaconsList"></app-beacon-selection>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.RSSITHRSHLD.LABEL.TEXT' | translate }}</mat-label>
          <input matInput required formControlName="rssiThreshold" type="number" placeholder="0.00" step="0.1"
            class="rssi" max="0" min="-127">
          <div matSuffix class="mat-suffix">dBm</div>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.EXTERNALID.LABEL.TEXT' | translate }}</mat-label>
          <input matInput formControlName="externalId">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="accent" (click)="close()">{{ 'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="createAsset()" style="margin-left: 20px;">{{
        'SETTINGS.ASSETS.CREATEASSET.TITLE.TEXT' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
