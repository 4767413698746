<div class="large-modal">
  <h2 mat-dialog-title>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.SUMMARY.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content class="text-white">

    <form #createForm="ngForm">
      <div id='Summary'>
        <div class="row pb-4">
          <div class="col-lg-3">
            <mat-form-field class="w-100">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.WORKER.LABEL.TEXT' | translate}}</mat-label>
              <mat-select [(ngModel)]="presenceWorkerId" name="workerId" (ngModelChange)="handleWorkerChange()"
                autofocus required>
                <mat-option *ngFor="let worker of allWorkersList" [value]="worker.id">
                  {{worker.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <div class="sm-flex-item">
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.WORKERGROUP.LABEL.TEXT' | translate}}</div>
              <div>{{presenceWorkerGroupName ?? '&nbsp;'}}</div>
            </div>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.LOCATION.LABEL.TEXT' |
                translate}}</mat-label>
              <mat-select [(ngModel)]="presenceLocationId" name="locationId" (selectionChange)="onLocationChange()" autofocus required>
                <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
                  {{location.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row pb-4 pt-2">
          <div class="col-lg-3">
            <opus-datepicker (selectedDateChange)="onDateChange($event)" [selectedValue]="selectedDate"></opus-datepicker>
          </div>
          <div class="col-lg-3">
            <div class="sm-flex-item">
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.HOURSWORKED.LABEL.TEXT' | translate}}</div>
              <div>{{hoursWorked ?? ''}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="sm-flex-item">
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.BREAKPAID.LABEL.TEXT' | translate}}</div>
              <div>{{this.hoursBreaksPaid ?? '00:00'}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="sm-flex-item">
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.BREAKUNPAID.LABEL.TEXT' | translate}}</div>
              <div>{{this.hoursBreaksUnpaid ?? '00:00'}}</div>
            </div>
          </div>
          <div class="col-lg-3 hide-sm">
            <div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </div>
          </div>
          <div class="col-lg-3 hide-sm">
            <div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="sm-flex-item">
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.LEAVESPAID.LABEL.TEXT' | translate}}</div>
              <div>{{this.hoursLeavesPaid ?? '00:00'}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="sm-flex-item">
              <div> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.LEAVESUNPAID.LABEL.TEXT' | translate}}</div>
              <div>{{this.hoursLeavesUnpaid ?? '00:00'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="presenceExistsForSelectedDate && !selectedClientDocData.isPresenceLocation">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ALREADYREGISTERED.T_CONTENT.TEXT' | translate}}</div>
      <div *ngIf="archivedPresenceExistsForSelectedDate && !selectedClientDocData.isPresenceLocation">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ARCHIVEDREGISTERED.T_CONTENT.TEXT' | translate}}
      </div>
      <div *ngIf="presenceExistsForSelectedDate && selectedClientDocData.isPresenceLocation">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ALREADYREGISTEREDLOC.T_CONTENT.TEXT' | translate}}</div>
      <div *ngIf="archivedPresenceExistsForSelectedDate && selectedClientDocData.isPresenceLocation">
        {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ARCHIVEDREGISTEREDLOC.T_CONTENT.TEXT' | translate}}
      </div>
      <div id='Details' class="mt-1">
        <!--<h5 class="mb-4">Details</h5>-->
        <div *ngIf="startDayTaskRegn" class="gap-12 startTime-detail d-flex align-items-center position-relative pb-2">
          <mat-card class="d-flex flex-row align-items-center transparent-effect-card mb-1" style="width:100%;">
            <mat-icon>play_circle</mat-icon>
            <div class="f-grow">
              <mat-form-field>
                <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.STARTTIME.LABEL.TEXT' |
                  translate}}</mat-label>
                <input matInput type="time" required [(ngModel)]="startDayTaskRegn.startTime"
                  (ngModelChange)="calculateDurations()" name="startDayStartTime">
                <mat-hint>{{startTimeRoundedHint ?? ''}}</mat-hint>
              </mat-form-field>
            </div>
          </mat-card>
          <div class="pb-1 d-flex justify-content-center align-items-center addBreakLeaveCta">
            <button class="ml-0 add-break-leave-btn" matTooltip="Add break" (click)="addBreakRegnOnTop()" mat-stroked-button extended="true">
              <mat-icon>add</mat-icon>
              <span>Break</span>
            </button>
            <button class="ml-2 add-break-leave-btn" matTooltip="Add leave" (click)="addLeaveRegnOnTop()" mat-stroked-button extended="true">
              <mat-icon>add</mat-icon>
              <span>Leave</span>
            </button>
          </div>
        </div>

        <div *ngFor="let regn of getBreakOrLeaveRegnsToDisplay();let idx = index" class="pb-2 position-relative">
          <mat-card class="d-flex flex-row flex-column-mb-card align-items-center transparent-effect-card mb-1" *ngIf="regn.type === 'BREAK'">
            <mat-icon class="material-symbols-outlined left-icon">free_breakfast_outline</mat-icon>
            <mat-form-field style="margin-left: 1rem;">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
              <input required matInput type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.taskName"
                name="taskName">
            </mat-form-field>
            <mat-form-field style="margin-left: 1rem;" class="f-grow">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.STARTTIME.LABEL.TEXT' |
                translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.breakTime" (ngModelChange)="calculateDurations()"
                name="breakTime">
            </mat-form-field>
            <mat-form-field style="margin-left: 1rem;" class="f-grow">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ENDTIME.LABEL.TEXT' | translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.endTime" (ngModelChange)="calculateDurations()"
                name="endTime">
            </mat-form-field>
            <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [(ngModel)]="regn.isPaid"
              class="custom-toggle-btn" name="isPaid" [ngModelOptions]="{standalone: true}" required (ngModelChange)="calculateDurations()"
              aria-label="Font Style">
              <mat-button-toggle [value]="false">
                {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEUNPAID.BUTTON.TEXT' |
                translate}}</mat-button-toggle>
              <mat-button-toggle [value]="true">
                {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEPAID.BUTTON.TEXT' |
                translate}}</mat-button-toggle>

            </mat-button-toggle-group>
            <div class="d-flex align-items-center right-icon">
              <button mat-icon-button matTooltip="Remove break" (click)="deleteBreakOrLeaveRegn(regn, idx)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div>
            </div>
          </mat-card>
          <mat-card class="d-flex flex-row flex-column-mb-card align-items-center transparent-effect-card mb-1" *ngIf="regn.type === 'LEAVE'">
            <mat-icon class="material-symbols-outlined left-icon">event_busy</mat-icon>
            <mat-form-field style="margin-left: 1rem;">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
              <mat-select [(ngModel)]="regn.leaveTypeId" name="leaveTypeId" [ngModelOptions]="{standalone: true}" autofocus required (ngModelChange)="leaveSelectionChanged(regn, idx)">
                <mat-option *ngFor="let leaveType of allLeaveTypesList" [value]="leaveType.id">
                  {{leaveType.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 1rem;" class="f-grow">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.STARTTIME.LABEL.TEXT' |
                translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.startTime" (ngModelChange)="calculateDurations()"
                name="breakTime">
            </mat-form-field>
            <mat-form-field style="margin-left: 1rem;" class="f-grow">
              <mat-label> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ENDTIME.LABEL.TEXT' | translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}" [(ngModel)]="regn.endTime" (ngModelChange)="calculateDurations()"
                name="endTime">
            </mat-form-field>
            <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [(ngModel)]="regn.isPaid"
              class="custom-toggle-btn" name="isPaid" [ngModelOptions]="{standalone: true}" required (ngModelChange)="calculateDurations()"
              aria-label="Font Style">
              <mat-button-toggle [value]="false">
                {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEUNPAID.BUTTON.TEXT' |
                translate}}</mat-button-toggle>
              <mat-button-toggle [value]="true">
                {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEPAID.BUTTON.TEXT' |
                translate}}</mat-button-toggle>
            </mat-button-toggle-group>
            <!-- <mat-slide-toggle class="mx-2" [(ngModel)]="regn.isPaid" name="isPaid" required>
              <mat-label>Paid</mat-label>
            </mat-slide-toggle> -->
            <div class="d-flex align-items-center right-icon">
              <button mat-icon-button matTooltip="Remove leave" (click)="deleteBreakOrLeaveRegn(regn, idx)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div>
            </div>
          </mat-card>
          <div style="display: flex;flex-wrap: wrap;justify-content: center;" class="addBreakLeaveCta">
            <button class="ml-0 add-break-leave-btn" matTooltip="Add break" (click)="addNewBreakRegn(regn, idx)" mat-stroked-button extended="true">
              <mat-icon>add</mat-icon>
              <span>Break</span>
            </button>
            <button class="ml-2 add-break-leave-btn" matTooltip="Add leave" (click)="addNewLeaveRegn(regn, idx)" mat-stroked-button extended="true">
              <mat-icon>add</mat-icon>
              <span>Leave</span>
            </button>

          </div>

        </div>

        <div *ngIf="endDayTaskRegn" class="gap-12 startTime-detail pb-2 d-flex align-items-center">
          <mat-card class="d-flex flex-row align-items-center transparent-effect-card mb-1" style="width:100%;">
            <mat-icon>stop_circle</mat-icon>
            <mat-form-field>
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ENDTIME.LABEL.TEXT' | translate}}</mat-label>
              <input matInput type="time" required [(ngModel)]="endDayTaskRegn.endTime"
                (ngModelChange)="calculateDurations()" name="endDayEndTime">
              <mat-hint>{{endTimeRoundedHint ?? ''}}</mat-hint>
            </mat-form-field>
          </mat-card>
        </div>

      </div>


    </form>
  </mat-dialog-content>

  <div id='ButtonPanel'>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="primary" (click)="cancelPresenceSave()"
        style="margin-right: 20px;">{{'DASHBOARD.MODAL.CANCEL.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="presenceExistsForSelectedDate || beingSaved"
        (click)="createPresenceNRegistrations()">{{'DASHBOARD.MODAL.SAVE.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
