<div class="listing-container" *ngIf="perms?.canRead">

  <div class="controls-container px-2 px-md-0">
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
      *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create Task" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'SETTINGS.TASK.CREATETASK.LABEL.TEXT' | translate}}</button>
      <span class="f-grow"></span>
    <button mat-stroked-button color="primary" class="mr-3 setting-add-button hide-sm" (click)="openVariety()">
      <mat-icon class="material-symbols-outlined" matTooltip="Worker Groups" matTooltipClass="tooltip-class"
                matTooltipHideDelay="100000">
        stacks
      </mat-icon> {{'SETTINGS.VARITIES.BUTTON.TEXT' | translate}}</button>
    <button mat-stroked-button color="primary" class="setting-add-button mr-3 hide-sm" (click)="openLabels()">
      <mat-icon class="material-symbols-outlined" matTooltip="Labels" matTooltipClass="tooltip-class"
        matTooltipHideDelay="100000">
        label
      </mat-icon> {{'SETTINGS.LABELS.BUTTON.TEXT' | translate}}</button>
      <button mat-stroked-button color="primary" class="setting-add-button mr-3 hide-sm" (click)="openTaskGroup()" *ngIf="perms?.canRead">
        <mat-icon  matTooltip="Worker Groups" matTooltipClass="tooltip-class"
          matTooltipHideDelay="100000">
          task
        </mat-icon> {{'SETTINGS.TASKGROUP.LABEL.TEXT' | translate}}</button>

    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [(ngModel)]="unArchivedVsArchived"
        (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon matTooltip="Show Tasks" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon matTooltip="Show archived tasks" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>


  <div class="table-card table-mobile-res table-to-card-mb">
    <div *ngIf="areArchivedTasksShown()"> {{'SETTINGS.TASK.ARCHIVEDTASK.TITLE.TEXT' | translate}}</div>
    <div class="mat-elevation-z8" style="clear:both;">

      <app-common-table [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay" [gridList]="tasksList" [tableName]="'taskTable'" (editDialogOpened)="openEditDialog($event)" (onArchiveRow)="archiveTask($event)" (onUnArchiveRow)="unarchiveTask($event)" [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'task'">

      </app-common-table>
    </div>
  </div>

</div>
