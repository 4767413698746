<div style="padding:0;" class="api-wrapper">
  <div class="d-flex align-item-center page-action transparent-effect-card">
    <mat-tab-group animationDuration="0ms" class="f-grow">
      <mat-tab label="{{'SETTINGS.APIS.TABS.APIKEYS.LABEL.TEXT' |  translate}}">
        <ng-template mat-tab-label>
          <div class="layout-vetical-tabs d-flex align-items-center">
            <span class="material-symbols-outlined tab_icon material-icons mr-3">
              api
            </span>
            <span>{{'SETTINGS.APIS.TABS.APIKEYS.LABEL.TEXT' |  translate}}</span>
          </div>
        </ng-template>
        <app-client-apis-list></app-client-apis-list>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="layout-vetical-tabs d-flex align-items-center">
            <span class="material-symbols-outlined tab_icon material-icons mr-3">
              overview
            </span>
            <span>{{'SETTINGS.APIS.TABS.APILOGS.LABEL.TEXT' | translate}}</span>
          </div>
        </ng-template>
        <app-client-apis-logs></app-client-apis-logs>
      </mat-tab>
    </mat-tab-group>
  </div>


</div>
