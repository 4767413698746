<div style="min-width: 400px;">
    <mat-dialog-content [formGroup]="editDeviceForm" style="text-align: center;max-height: 100%;">
        <mat-form-field style="width: 18rem;margin-bottom: 0.5rem;">
          <mat-label>{{'SETTINGS.MODAL.NEXTDEVICENUMBER.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="deviceNumber" maxlength="8">
        </mat-form-field>
        <br/>
        <mat-form-field  style="width: 18rem;margin-bottom: 0.5rem;">
            <mat-label>{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="locationId">
                <mat-option [value]="null">{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-option>
                <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
                    {{location.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br/>
      <mat-form-field style="width: 18rem;">
        <mat-label>{{'SETTINGS.MODAL.APPMODES.LABEL.TEXT' | translate}}</mat-label>
        <mat-select formControlName="appModes" multiple>
          <mat-option *ngFor="let appMode of allAppModesList" [value]="appMode.id">
            {{appMode.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-slide-toggle
      class="edit_device_toggles"
      style="margin-top: 0.4rem !important;"
      formControlName="enableAutoTouchLock"
      style="margin-left: 2rem;"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLELOCK.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableGPS"
      style="margin-left: 2rem;"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEGPS.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableOutOfRange"
      style="margin-left: 2rem;"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLERANGE.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableTrainingMode"
      style="margin-left: 2rem;"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEMODE.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableVoiceAssistance"
      style="margin-left: 2rem;"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEVOICE.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
      <mat-slide-toggle
        class="edit_device_toggles"
        formControlName="isLocked"
        style="margin-left: 2rem;"
      >
        <mat-label> {{'SETTINGS.MODAL.LOCKDEVICE.LABEL.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <br/>
      <mat-form-field style="width: 18rem; margin-top: 0.8rem;">
        <mat-label>{{'SETTINGS.TABLEHEAD.NOTES.TITLE.TEXT' | translate}}</mat-label>
        <textarea cdkTextareaAutosize
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="2" matInput placeholder="Notes" formControlName="notes" style="overflow: hidden;"
        ></textarea>
      </mat-form-field>
      <br/>
    </mat-dialog-content>
    <div class="footer-with-Id" style="text-align: right;margin-bottom: 0.4rem;">
      <div class="col-lg-12 d-flex copyEntityId">
        <input matInput [(ngModel)]="devicesId" readonly #copyTarget >
        <button mat-icon-button appCopySuccess [iconTarget]="icon" color="primary" class="copy-inside-input"
          [ngxClipboard]="copyTarget">
          <mat-icon class="material-icons-outlined m-0" #icon>content_copy</mat-icon>
        </button>
      </div>
        <mat-dialog-actions>
            <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
            <button mat-raised-button color="primary" (click)="updateDevice()" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
          </mat-dialog-actions>
    </div>
</div>
