<div class="modal-width">
  <mat-dialog-content>
    <div class="flex-container">
      <div style="flex-grow: 8">
        <!-- <h2 mat-dialog-title style="text-align: center">Activate Device</h2> -->
        <mat-vertical-stepper [linear]="true" #stepper class="device-stepper">
          <mat-step>
            <ng-template matStepLabel> {{'SETTINGS.MODAL.OPENOPUS.LABEL.TEXT' | translate}}</ng-template
            >
            <div style="margin-top: 0.4rem;">
              <button mat-raised-button matStepperNext color="primary">

                {{'SETTINGS.MODAL.YESKEY.BUTTON.TEXT' | translate}}
              </button>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>
              {{'SETTINGS.MODAL.FILLKEY.LABEL.TEXT' | translate}}
            </ng-template>

            <mat-form-field style="margin-top: 0.4rem;">
              <mat-label> {{'SETTINGS.MODAL.ACTIVATIONKEY.LABEL.TEXT' | translate}}</mat-label>
              <input
                matInput
                required
                [(ngModel)]="activationKey"
                (input)="autocapitalize($event)"
                class="keyInput"
                maxlength="4"
              />
            </mat-form-field>
            <div style="margin-bottom: 20px; text-align: center">
              <div style="color: #0085fc" *ngIf="progressMessage">
                {{ progressMessage }}
              </div>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel> {{'SETTINGS.MODAL.EDITDEVICE.LABEL.TEXT' | translate}}</ng-template>
            <mat-form-field style="width:5.3rem;margin-top: 0.4rem;" appearance="fill">
              <input matInput [(ngModel)]="deviceNumber" maxlength="4"/>
            </mat-form-field>
            <br/>
            <button style="width: 5rem;" mat-raised-button color="primary" (click)="deviceNumberConfirmed($event)">
               {{'SETTINGS.MODAL.CONFIRM.BUTTON.TEXT' | translate}}
            </button>
            <br/>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel> {{'SETTINGS.MODAL.DONE.LABEL.TEXT' | translate}}</ng-template>
            <br/>
            <div>
              <button mat-raised-button color="primary" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
            </div>
          </mat-step>
        </mat-vertical-stepper>
      </div>
    </div>
  </mat-dialog-content>
  <div class="footer-with-Id" style="text-align: right;margin-bottom: 0.4rem;">
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" class="d-block b-md-none" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' |
        translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
