import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import * as moment from 'moment/moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivitySession, Column} from '../../../common/interfaces/new-table-interfaces';
import {RegistrationMainAttributes} from '../../../common/interfaces/clock-interfaces';
import {TranslateService} from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-client-apis-logs',
  templateUrl: './client-apis-logs.component.html',
  styleUrls: ['./client-apis-logs.component.scss',
    '../../../common/styles/listing.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ClientApisLogsComponent implements AfterViewInit, OnDestroy {
  columns: Column[] = [
    {
      name: 'service',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.SERVICE.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: 'total',
      footerValue: 'Totaal',
    },
    {
      name: 'timestamp',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.TIMESTAMP.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'type',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.LOGTYPE.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'endpoint',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.ENDPOINT.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'method',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.METHOD.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'records',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.RECORDS.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'responseTime',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.RSPNSTIME.TITLE.TEXT',
      showHeader: false,
      showHeaderFilter: false,
      showTopFilter: true,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: 'sum',
      footerValue: '',
    },
    {
      name: 'status',
      displayName: 'SETTINGS.APILOGS.TABLEHEAD.STATUS.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: 'count',
      footerValue: '',
    }
  ];

  toggleTableChart: 'table' | 'chart' = 'table';
  // TABLE
  displayedColumns: string[] = this.columns.filter((column) => column.showHeader).map((column) => column.name);

  displayedTopFilters: any[] = this.columns.filter(
    (column) => column.showTopFilter
  );

  dataSource: MatTableDataSource<RegistrationMainAttributes>;
  lastDirection: '' | 'asc' | 'desc' = '';
  filterDictionary = new Map<string, string | number | boolean>();


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dateInContextSubscription: Subscription;
  selectedDate: Date;
  apiLogsData: any[];
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  apiLogsSubscription: Subscription;
  filterString: string;
  expandedElement: any = null;
  dateToday: Date = new Date();

  constructor(private firestoreService: FirestoreService,
              private clientInContextService: ClientInContextService,
              private dialogRef: MatDialogRef<ClientApisLogsComponent>) {

    this.dateInContextSubscription = this.clientInContextService.dateInContextSubject.subscribe(dateInContext => {
      if (!dateInContext) {
        const dateNow = new Date();
        this.selectedDate = dateNow;
        this.clientInContextService.dateInContextSubject.next(dateNow);
      } else {
        this.selectedDate = dateInContext;
      }
      this.apiLogsData = [];

      this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        this.fetchAPILogs();
      });
    });
  }

  ngAfterViewInit() {

  }

  getDisplayedCols() {
    return [...this.displayedColumns];
  }


  fetchAPILogs() {
    this.apiLogsSubscription =
      this.firestoreService.getAPILogsForClientId(this.selectedClientDocData.id, this.selectedDate).subscribe((apiLogs: any[]) => {
        this.apiLogsData = apiLogs;
        const tableData = this.mapFirestoreDataToTableData(apiLogs);

        tableData.sort((n1, n2) => {
          return +n1.startTimestamp > +n2.startTimestamp ? -1 : +n1.startTimestamp < +n2.startTimestamp ? 1 : 0;
        });

        this.dataSource = new MatTableDataSource(tableData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setUpFiltering();
      });
  }

  mapFirestoreDataToTableData(apiLogs) {
    return apiLogs.map(apiLogEntry => {
      const timestamp = apiLogEntry.timestampFS ? moment(apiLogEntry.timestampFS.toMillis()).format('HH:mm') : '';
      return {
        timestamp,
        service: apiLogEntry.service ?? '',
        type: apiLogEntry.type ? apiLogEntry.type.toUpperCase() : '',
        endpoint: apiLogEntry.endpoint ?? '',
        method: apiLogEntry.method ?? '',
        records: apiLogEntry.records ?? 0,
        responseTime: apiLogEntry.responseTime ? (+apiLogEntry.responseTime / 1000).toFixed(2) + 's' : '',
        status: apiLogEntry.status ?? '',
        url: apiLogEntry.url ?? '',
        requestId: apiLogEntry.id,
        sourceIP: apiLogEntry.sourceIP ?? ''
      }
    });
  }

  setUpFiltering() {
    this.dataSource.filterPredicate = (record, filter) => {
      const map = new Map(JSON.parse(filter));
      const returnValues = [];

      for (const [key, value] of map) {
        // @ts-ignore
        const recordValue: string | number | boolean | undefined =
          record[key as keyof ActivitySession];

        if (typeof recordValue === 'string') {
          const regex = new RegExp(`${value}`, 'i');
          returnValues.push(regex.test(recordValue));
        }
        if (typeof recordValue === 'number') {
          returnValues.push(recordValue === Number(value));
        }
        if (typeof recordValue === 'boolean') {
          returnValues.push(recordValue === value);
        }
      }
      return returnValues.every(Boolean);
    };
    this.postProcessingFilterSort();
  }

  /**
   * Sorts the list of columns based on the specified column name and direction.
   *
   * @param  columnName - The name of the column to sort by.
   * @param  direction - The sorting direction, either 'asc' (ascending) or 'desc' (descending).
   * @returns void
   */
  sortList(columnName: string, direction: 'asc' | 'desc'): void {
    this.columns.forEach((column, index) => {
      this.columns[index].filtered = column.name === columnName;
    });
    if (!this.dataSource.sort) {
      return;
    }
    if (
      direction === 'asc' &&
      (this.dataSource.sort.direction !== 'asc' ||
        this.dataSource.sort.active !== columnName)
    ) {
      this.dataSource.sort.sort({
        id: columnName,
        start: 'asc',
        disableClear: true,
      });
    } else if (
      direction === 'desc' &&
      (this.dataSource.sort.direction !== 'desc' ||
        this.dataSource.sort.active !== columnName)
    ) {
      this.dataSource.sort.sort({
        id: columnName,
        start: 'desc',
        disableClear: true,
      });
    }
    this.postProcessingFilterSort();
  }

  /**
   * Applies header filter to the data based on the specified column name and filter value.
   *
   * @param event - The event object containing filter-related information.
   * @param columnName - The name of the column to be filtered.
   * @returns void
   */
  headerFilter(event: any, columnName: string): void {
    if (event.target.value) {
      this.filterDictionary.set(columnName, event.target.value);
    }
    if (!event.target.value) {
      this.filterDictionary.delete(columnName);
    }
    this.applyPredicateFilter();
    this.postProcessingFilterSort();
  }

  applyPredicateFilter(): void {
    const jsonString = JSON.stringify(
      Array.from(this.filterDictionary.entries())
    );
    this.dataSource.filter = jsonString;
    const filters: string[] = [];
    this.filterDictionary.forEach((value, key) => {
      if (key !== 'archived') {
        filters.push(`${key}: ${value}`);
      }
    });
    this.filterString = filters.length > 0 ? 'Filtered on' + filters.join(' and ') : '';
  }

  /**
   * Applies a predicate filter to the data source using the filter dictionary.
   * Converts the filter dictionary to a JSON string and sets it as the data source filter.
   *
   */
  postProcessingFilterSort(): void {
    this.columns.forEach((column, index) => {
      if (
        this.dataSource.sort?.active === column.name ||
        column.filterValue !== ''
      ) {
        column.filtered = true;
      } else {
        column.filtered = false;
      }
    });

    this.columns.forEach((column, columnIndex) => {
      this.columns[columnIndex].filterOptions = [];
      const optionsSet: Set<any> = new Set();
      this.dataSource.filteredData.forEach((row, rowIndex) => {
        for (const [key, value] of Object.entries(row)) {
          if (key === column.name) {
            optionsSet.add(value);
          }
        }
      });
      column.filterOptions = [...optionsSet].sort();
    });

    this.columns.forEach((column, columnIndex) => {
      const footerType = this.columns[columnIndex].footerType;
      const filteredDatasource: any[] = [];
      this.dataSource.filteredData.forEach((row, rowIndex) => {
        for (const [key, value] of Object.entries(row)) {
          if (key === column.name) {
            filteredDatasource.push(value);
          }
        }
      });
      if (footerType === 'total') {
        column.footerValue = 'Total';
      }
      if (footerType === 'sum') {
        column.footerValue = filteredDatasource.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue;
          },
          0
        );
      }
      if (footerType === 'count') {
        column.footerValue = filteredDatasource.length;
      }
    });
  }

  /**
   * Clears filters and resets sorting for the data source.
   */
  removeFilters(): void {
    this.filterString = '';
    this.lastDirection = '';
    this.filterDictionary.clear();
    this.columns.forEach((column, index) => {
      this.columns[index].filterValue = '';
    });
    if (this.dataSource.sort) {
      this.dataSource.sort.active = '';
      this.dataSource.sort.direction = '';
      this.dataSource.filter = '';
    }
    this.postProcessingFilterSort();
  }

  expandRow(element: any) {
    this.expandedElement = this.expandedElement?.requestId === element.requestId ? null : element;
  }

  applyExpandedClass(element: any) {
    return this.expandedElement?.requestId === element.requestId;
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.dateInContextSubscription?.unsubscribe();
    this.apiLogsSubscription?.unsubscribe();
  }

  futureFilter = (d: Date | null): boolean => {
    return d <= this.dateToday;
  };

  closeModal() {
    this.dialogRef.close();
  }
}
