<div class="large-modal">
  <h2 mat-dialog-title> {{'SETTINGS.ARCHIVED.ROWS.MODAL.HEADING.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content class="text-white">
    <div class="listing-container">
      <div class="table-card">
        <div class="mat-elevation-z8" style="clear:both;">
          <table class="listing-table" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
            <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ archivedRowsAttributesReadableMap.get(column) | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
            </ng-container>

            <ng-container matColumnDef="unarchived">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="listing-table-tools">
                <button mat-icon-button color="accent" (click)="unarchiveRow(element)" class="listing-table-tools"
                        [matTooltip]="'SETTINGS.ARCHIVED.ROWS.UNARCHIVE.TOOLTIP.TEXT' | translate" matTooltipClass="tooltip-class"
                        matTooltipHideDelay="100" matTooltipPosition="below">
                  <mat-icon>unarchive
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="100"
                         [showFirstLastButtons]="true"></mat-paginator>
        </div>
      </div>

    </div>
  </mat-dialog-content>
  <div id='ButtonPanel'>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="primary" (click)="closeModal()" style="margin-right: 20px;">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>

    </mat-dialog-actions>
  </div>
</div>
