<div class="modal-width">
  <h2 mat-dialog-title> {{'DASHBOARD.SESSIONS.MODAL.EDITSESSIONS.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="form">
    <div class="row pb-4 mx-0">
      <div class="col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.LOCATION.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="locationId" (selectionChange)="onLocationChange()">
            <mat-option [value]="null">{{'DASHBOARD.SESSIONS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
              {{location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.ROW.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="rowId">
            <mat-option [value]="null">{{'DASHBOARD.SESSIONS.MODAL.SELECTROW.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let row of mappedRowsList" [value]="row.id">
              {{row.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4 mx-0 mx-0">
      <div class="col-lg-6">
        <mat-form-field appearance="outline" class="w-100" *ngIf="!selectedLocation">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.TROLLEY.LABEL.TEXT' | translate}}</mat-label>
          <input matInput [disabled]="true">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100" *ngIf="selectedLocation?.trolleySelectorMode === 'RANGE'">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.TROLLEY.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="trolleyId">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100" *ngIf="selectedLocation?.trolleySelectorMode === 'ASSETS'">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.TROLLEY.LABEL.TEXT' | translate}} </mat-label>
          <mat-select formControlName="trolleyIdSelected">
            <mat-option *ngFor="let trolley of allTrolleysList" [value]="trolley.id">
              {{trolley.name + ' ' + trolley.number}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'DASHBOARD.SESSIONS.MODAL.COUNT.LABEL.TEXT' | translate}}</mat-label>
          <input matInput  formControlName="count">
        </mat-form-field>
      </div>
    </div>
     <div class="row pb-4 mx-0">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.WORKER.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="workerId">
            <mat-option [value]="null">{{'DASHBOARD.SESSIONS.MODAL.SELECTWORKER.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let worker of allWorkersList" [value]="worker.id">
              {{worker.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
     </div>

    <div class="row mx-0">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label style="font-size: 1rem;">{{'DASHBOARD.SESSIONS.MODAL.GROSSSTARTTIME.LABEL.TEXT' | translate}}</mat-label>
            <input matInput type="time" formControlName="startTimestamp">
        </mat-form-field>

      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label style="font-size: 1rem;">{{'DASHBOARD.SESSIONS.MODAL.GROSSENDTIME.LABEL.TEXT' | translate}}</mat-label>
            <input matInput type="time" formControlName="endTimestamp">
          </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div >
      <mat-dialog-actions class="flex-nowrap">
        <button
          mat-stroked-button
          color="accent"
          (click)="revertSession()"
          class="listing-table-tools tdhover"
          style="margin-left: 2px;padding-left: 22px;"
          matTooltip="Revert to original"
          matTooltipClass="tooltip-class"
          matTooltipHideDelay="100"
          matTooltipPosition="below"
          [disabled]="beingSaved || originalSession.isOriginal"
        >
          <mat-icon>settings_backup_restore</mat-icon>
        </button>
        <button mat-stroked-button color="accent" (click)="close()" style="margin-left: 12px;">{{'DASHBOARD.SESSIONS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
        <button mat-raised-button color="primary" (click)="updateSession()" style="margin-left: 12px;">{{'DASHBOARD.SESSIONS.MODAL.UPDATESESSION.BUTTON.TEXT' | translate}}</button>
      </mat-dialog-actions>
  </div>
</div>
