<div>
  <h2 mat-dialog-title>{{'DASHBOARD.SESSIONS.MODAL.ADDSESSIONS.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="form">
    <div class="row ">
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.LOCATION.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="locationId" (selectionChange)="onLocationChange()">
            <mat-option [value]="null">{{'DASHBOARD.SESSIONS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}} </mat-option>
            <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
              {{location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.ROW.LABEL.TEXT' | translate}} </mat-label>
          <mat-select formControlName="rowId" [disabled]="!locationSelected">
            <mat-option [value]="null"> {{'DASHBOARD.SESSIONS.MODAL.SELECTROW.LABEL.TEXT' | translate}} </mat-option>
            <mat-option *ngFor="let row of mappedRowsList" [value]="row.id">
              {{row.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100" *ngIf="!selectedLocation">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.TROLLEY.LABEL.TEXT' | translate}}</mat-label>
          <input matInput [disabled]="true">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100" *ngIf="selectedLocation?.trolleySelectorMode === 'RANGE'">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.TROLLEY.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="trolleyId">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100" *ngIf="selectedLocation?.trolleySelectorMode === 'ASSETS'">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.TROLLEY.LABEL.TEXT' | translate}} </mat-label>
          <mat-select formControlName="trolleyIdSelected">
            <mat-option *ngFor="let trolley of allTrolleysList" [value]="trolley.id">
              {{trolley.name + ' ' + trolley.number}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'DASHBOARD.SESSIONS.MODAL.COUNT.LABEL.TEXT' | translate}}</mat-label>
          <input matInput  formControlName="count">
        </mat-form-field>
      </div>
     </div>
     <div class="row">
      <div class="col-lg-12 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.WORKER.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="workerId">
            <mat-option [value]="null">{{'DASHBOARD.SESSIONS.MODAL.SELECTWORKER.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let worker of allWorkersList" [value]="worker.id">
              {{worker.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
     </div>

    <div class="row">
      <div class="col-lg-12 pb-4">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.DATE.LABEL.TEXT' | translate}}</mat-label>
          <opus-datepicker (selectedDateChange)="onDateChange($event)" [selectedValue]="selectedDate"></opus-datepicker>
          <!-- <div class="date-input">
            <button mat-icon-button [matMenuTriggerFor]="dateMenu" aria-label="Example icon-button with a menu">
              <mat-icon>event</mat-icon>
            </button>
            <mat-menu #dateMenu="matMenu" class="date-menu customize_date">
              <mat-card appearance="outlined" class="demo-inline-calendar-card">
                <mat-calendar [(selected)]="selectedDate" (selectedChange)="onDateChange($event)"></mat-calendar>
              </mat-card>
            </mat-menu>
            <span>{{selectedDate | date: 'd MMM y'}}</span>
          </div> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'DASHBOARD.SESSIONS.MODAL.GROSSSTARTTIME.LABEL.TEXT' | translate}}</mat-label>
            <input matInput type="time" formControlName="startTimestamp">
          </mat-form-field>
      </div>
      <div class="col-md-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.SESSIONS.MODAL.GROSSENDTIME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput type="time" formControlName="endTimestamp">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="accent" (click)="close()">{{'DASHBOARD.SESSIONS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="createSession()" style="margin-left: 20px;">{{'DASHBOARD.SESSIONS.MODAL.CREATESESSION.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
