<div class="modal-width">
  <mat-dialog-content [formGroup]="editDeviceForm" style="text-align: center;max-height: 80vh;">
    <mat-form-field class="w-100 mb-3">
      <mat-label>{{'SETTINGS.MODAL.NEXTDEVICENUMBER.LABEL.TEXT' | translate}}</mat-label>
      <input matInput formControlName="deviceNumber" maxlength="8">
    </mat-form-field>
    <mat-form-field class="w-100 mb-3">
      <mat-label>{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="locationId">
        <mat-option [value]="null">{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-option>
        <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-100 mb-3">
      <mat-label>{{'SETTINGS.MODAL.APPMODES.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="appModes" multiple>
        <mat-option *ngFor="let appMode of allAppModesList" [value]="appMode.id">
          {{appMode.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="toggler-options d-flex flex-column mb-3">
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableAutoTouchLock">
        <mat-label class="mb-0 ml-2">{{'SETTINGS.TABLEHEAD.ENABLELOCK.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableGPS">
        <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEGPS.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableOutOfRange">
        <mat-label>{{'SETTINGS.TABLEHEAD.ENABLERANGE.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableTrainingMode">
        <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEMODE.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="enableVoiceAssistance">
        <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEVOICE.TITLE.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
      <mat-slide-toggle class="edit_device_toggles text-left ml-0" formControlName="isLocked">
        <mat-label> {{'SETTINGS.MODAL.LOCKDEVICE.LABEL.TEXT' | translate}}</mat-label>
      </mat-slide-toggle>
    </div>


    <mat-form-field class="w-100 mt-2">
      <mat-label>{{'SETTINGS.TABLEHEAD.NOTES.TITLE.TEXT' | translate}}</mat-label>
      <textarea cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="2" matInput placeholder="Notes"
        formControlName="notes" style="overflow: hidden;"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <div class="footer-with-Id" style="text-align: right;margin-bottom: 0.4rem;">
    <div class="col-lg-12 d-flex copyEntityId">
      <input matInput [(ngModel)]="devicesId" readonly #copyTarget>
      <button mat-icon-button appCopySuccess [iconTarget]="icon" color="primary" class="copy-inside-input"
        [ngxClipboard]="copyTarget">
        <mat-icon class="material-icons-outlined m-0" #icon>content_copy</mat-icon>
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' |
        translate}}</button>
      <button mat-raised-button color="primary" (click)="updateDevice()"
        style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
