<h2 mat-dialog-title class="d-flex align-items-center"><span
    class="f-grow">{{'DASHBOARD.LABOR.OVERVIEW.DETAILMODAL.TITLE.TEXT' | translate}}</span>
  <mat-icon class="material-symbols-outlined d-block d-md-none" mat-dialog-close>
    close
  </mat-icon>
</h2>
<p class="px-2 px-md-4 cstm-color mb-0"><span class="pr-2">Name: </span>{{nestedTableData[0].workerName}}</p>
<mat-dialog-content class="table-styled">
  <div class="table-responsive h-100 expandable-table">
    <table mat-table multiTemplateDataRows [dataSource]="nestedTableData" class="mat-elevation-z8 layout-fixed-sm">

      <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsHeadersToDisplayNested">
        <th mat-header-cell *matHeaderCellDef>
          {{workerAttributesReadableMap.get(column) | translate}}
        </th>
        <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
      </ng-container>

      <ng-container matColumnDef="rowNumber">
        <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.ROW.TITLE.TEXT' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="hide-sm">
          {{element.rowNumber}}
        </td>
      </ng-container>
      <ng-container matColumnDef="trolleyNumber">
        <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.TROLLEY.TITLE.TEXT' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="hide-sm">
          {{element.trolleyNumber}}
        </td>
      </ng-container>
      <ng-container matColumnDef="perfRatio">
        <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.PERFRATIO.TITLE.TEXT' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="hide-sm">
          {{element.perfRatio}}
        </td>
      </ng-container>
      <ng-container matColumnDef="netPerformance">
        <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.PERFORMANCE.TITLE.TEXT' |
          translate}}</th>
        <td mat-cell *matCellDef="let element" class="hide-sm">
          {{element.netPerformance}}
        </td>
      </ng-container>


      <ng-container matColumnDef="red-dot">
        <th mat-header-cell *matHeaderCellDef class="hide-sm"></th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools hide-sm">
          <mat-icon [mtxTooltip]="workerPerfTooltip"
            *ngIf="element.workerPerformanceComparedToPast && (element.workerPerformanceComparedToPast >= 0.1)"
            class="material-symbols-outlined success">
            trending_up
          </mat-icon>
          <mat-icon [mtxTooltip]="workerPerfTooltip"
            *ngIf="element.workerPerformanceComparedToPast && (element.workerPerformanceComparedToPast < -0.1)"
            class="material-symbols-outlined failure">
            trending_down
          </mat-icon>
          <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceComparedToPast &&
              (element.workerPerformanceComparedToPast < 0.1 && element.workerPerformanceComparedToPast >-0.1)"
            class="material-symbols-outlined equal">
            trending_flat
          </mat-icon>
          <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceRelativeToGroupPast > 0"
            class="material-symbols-outlined success">
            arrow_upward
          </mat-icon>
          <mat-icon [mtxTooltip]="workerPerfTooltip" *ngIf="element.workerPerformanceRelativeToGroupPast < 0"
            class="material-symbols-outlined failure">
            arrow_downward
          </mat-icon>

          <ng-template #workerPerfTooltip>
            <div
              style="background: #5b5f66; color: mintcream;padding:4px;border-radius:5px; text-align: center;font-size: 0.92rem;width:12rem !important;">
              <div style="margin-bottom: 4px;font-weight: 500;">Performance indicator</div>
              <span style="font-weight: 300;">Personal norm {{getPersonalNormPercentage(element)}}</span> <br />
              <span style="font-weight: 300;">Groups norm {{getGroupsNormPercentage(element)}}</span> <br />
            </div>
          </ng-template>
          <span class="ml-2">{{element.isAnyNestedRowRed}}</span>

          <img [mtxTooltip]="redDotTooltip" ngSrc="assets/lightgray.ico" height="18" width="18" style="margin-top: 4px;"
            *ngIf="element.showRedButton">
          <ng-template #redDotTooltip>
            <div
              style="background: #5b5f66; color: mintcream;padding:4px;border-radius:5px; text-align: center;font-size: 0.92rem;width:12rem !important;">
              <div style="margin-bottom: 4px;">Check if the session is correct</div>
              <span>Gross Performance is {{element.grossPerformance}}</span> <br />
              <span style="">Net Performance is {{element.netPerformance}}</span> <br />
            </div>
          </ng-template>
        </td>
      </ng-container>


      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsHeadersToDisplay.length">
          <div class="row-detail" [@detailExpand]=" element.id == expandedElement?.id ? 'expanded' : 'collapsed'">
            <div class="row-detail-description">
              <div class="row-detail-item">
                <strong>{{'DASHBOARD.TABLEHEAD.ROW.TITLE.TEXT' | translate}}:</strong>
                <span>{{element['rowNumber']}}</span>
              </div>
              <div class="row-detail-item">
                <strong>{{'DASHBOARD.TABLEHEAD.TROLLEY.TITLE.TEXT' | translate}}:</strong>
                <span>{{element['trolleyNumber']}}</span>
              </div>
              <div class="row-detail-item">
                <strong>{{'DASHBOARD.TABLEHEAD.PERFRATIO.TITLE.TEXT' | translate}}:</strong>
                <span>{{element['perfRatio']}}</span>
              </div>

            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay;"
        [class.expanded-row]="applyExpandedClass(element)" (click)="expandRow(element)"></tr>
      <tr mat-row style="height: 0;" *matRowDef="let row; columns: ['expandedDetail']"
        class="row-detail expandable-row show-sm">
      </tr>
    </table>
  </div>
</mat-dialog-content>
<!-- <div>
  <mat-dialog-actions class="w-100">
    <button mat-stroked-button color="accent" mat-dialog-close>{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
  </mat-dialog-actions>
</div> -->
