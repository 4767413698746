import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {FirestoreService} from "../../../services/firestore.service";
import {AuthService} from "../../../services/auth.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import firebase from 'firebase/compat/app';
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ClientInContextService} from "../../../services/client-in-context.service";

@Component({
  selector: 'app-edit-location-dialog',
  templateUrl: './edit-location-dialog.component.html',
  styleUrls: ['./edit-location-dialog.component.scss']
})
export class EditLocationDialogComponent implements OnInit, OnDestroy {

  clientId: string;
  locationId: string;
  locationName: string;
  location: any;
  editLocationForm: UntypedFormGroup;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  beaconListSubscription: Subscription;
  allBeaconsList: any[];
  trolleySelectorMode: string;

  constructor(
    private firestoreService: FirestoreService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditLocationDialogComponent>,
    private clientInContextService: ClientInContextService,
    @Inject(MAT_DIALOG_DATA) data) {
      console.log('loc id',data)
    this.clientId = data.clientId;
    this.location = data.location;
    this.locationId = this.location.id
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      
      this.beaconListSubscription = this.firestoreService.getAllUnarchivedBeaconsByTypeForClientId(this.selectedClientDocData?.id, 'LOCATION').subscribe((beaconsList) => {
        const beaconsAssignedToThisLoc = beaconsList.filter(beacon => beacon.assignedId === this.location.id);
        const nonAssignedBeaconsList = beaconsList.filter(beacon => !beacon.hasOwnProperty('assignedId') || !beacon.assignedId);
        const listsCombined = beaconsAssignedToThisLoc.concat(nonAssignedBeaconsList);
        this.allBeaconsList = listsCombined?.sort((beaconA: any, beaconB: any) => {
          return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
        });
      });
    });

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(userDocData => {
      this.loggedInUserDocData = userDocData;
    });
  }

  openInGoogleMap(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.beaconListSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.editLocationForm = this.fb.group({
      name: [this.location.name, [Validators.required]],
      entrancePointLatitude: [this.location.entrancePoint?._lat, []],
      entrancePointLongitude: [this.location.entrancePoint?._long, []],
      trolleysStart: [{
        value: this.location.trolleys?.start,
        disabled: this.location.trolleySelectorMode === 'ASSETS'
      }, []],
      trolleysEnd: [{value: this.location.trolleys?.end, disabled: this.location.trolleySelectorMode === 'ASSETS'}, []],
      externalId: [this.location?.externalId, []],
      beaconIds: [this.location?.beaconIds ?? [], []],
      trolleySelectorMode: [this.location.trolleySelectorMode, []]
    });
    this.editLocationForm.controls.trolleySelectorMode.valueChanges.subscribe(value => {
      if (value === 'ASSETS') {
        this.editLocationForm.controls.trolleysStart.disable();
        //this.editLocationForm.controls.trolleysStart.setValue(null);
        this.editLocationForm.controls.trolleysEnd.disable();
        //this.editLocationForm.controls.trolleysEnd.setValue(null);
      } else {
        this.editLocationForm.controls.trolleysStart.enable();
        this.editLocationForm.controls.trolleysEnd.enable();
      }
      this.trolleySelectorMode = value;
    });
  }

  async updateLocation() {
    if (!this.editLocationForm.valid) {
      this.openSnackBar('Name is mandatory', 'error');
      return;
    }

    if (this.editLocationForm?.value?.name && (this.editLocationForm?.value?.name?.trim() === '')) {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    const updateLocationFormValue = this.editLocationForm.value;
    const locationToUpdate: any = {};
    locationToUpdate.name = updateLocationFormValue.name?.trim();
    locationToUpdate.externalId = updateLocationFormValue.externalId?.trim() ?? null;
    locationToUpdate.trolleySelectorMode = updateLocationFormValue.trolleySelectorMode;


    let beaconNames = [];
    if (updateLocationFormValue.beaconIds && Array.isArray(updateLocationFormValue.beaconIds) && (updateLocationFormValue.beaconIds.length > 0)) {
      beaconNames = this.allBeaconsList.filter(beacon => updateLocationFormValue.beaconIds.includes(beacon.id)).map(beacon => beacon.name);
    }
    locationToUpdate.beaconIds = updateLocationFormValue.beaconIds ?? [];
    locationToUpdate.beaconNames = beaconNames;

    if ((updateLocationFormValue.entrancePointLatitude && !updateLocationFormValue.entrancePointLongitude)
      || (!updateLocationFormValue.entrancePointLatitude && updateLocationFormValue.entrancePointLongitude)) {
      this.openSnackBar('Either both Latitude & Longitude should be entered or none!', 'error');
      return;
    }

    if (updateLocationFormValue.entrancePointLatitude) {
      try {
        locationToUpdate.entrancePoint = new firebase.firestore.GeoPoint(updateLocationFormValue.entrancePointLatitude, updateLocationFormValue.entrancePointLongitude);
      } catch (error) {
        this.openSnackBar(error.message, 'error');
        return;
      }
    } else {
      locationToUpdate.entrancePoint = null;
    }

    if (updateLocationFormValue.trolleySelectorMode === 'ASSETS') {
      if (this.editLocationForm.getRawValue().trolleysStart || this.editLocationForm.getRawValue().trolleysEnd) {
        locationToUpdate.trolleys = {};
        locationToUpdate.trolleys.start = this.editLocationForm.getRawValue().trolleysStart;
        locationToUpdate.trolleys.end = this.editLocationForm.getRawValue().trolleysEnd;
      } else {
        locationToUpdate.trolleys = null;
      }
    } else if (updateLocationFormValue.trolleySelectorMode === 'RANGE') {
      if (updateLocationFormValue.trolleysStart || updateLocationFormValue.trolleysEnd) {
        locationToUpdate.trolleys = {};
        locationToUpdate.trolleys.start = updateLocationFormValue.trolleysStart;
        locationToUpdate.trolleys.end = updateLocationFormValue.trolleysEnd;
      } else {
        locationToUpdate.trolleys = null;
      }
    }

    try {
      await this.firestoreService.updateLocByIdForClientId(this.clientId, this.location.id, locationToUpdate);
      this.openSnackBar('Location updated successfully', 'success');
      this.editLocationForm.markAsPristine();
      this.dialogRef.close();
    } catch (error) {
      this.openSnackBar('Error in updating location:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
