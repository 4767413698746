import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentData, DocumentReference, DocumentSnapshot} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import firebase from 'firebase/compat/app';
import {Timestamp} from 'firebase/firestore'
import * as moment from "moment";
import {TIME_ZONE} from '../common/utils/time-utils';
import {doc, collection, getDoc, getDocs, Firestore} from "@angular/fire/firestore";
import {id} from "@swimlane/ngx-charts";

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(
    private firestore: Firestore,
    private afs: AngularFirestore,
    private angularFireFunctions: AngularFireFunctions,
    private authService: AuthService,
    public http: HttpClient) {
  }

  // CLIENTS
  getAllClients(): Observable<any> {
    return this.afs.collection('clients').valueChanges({idField: 'id'});
  }

  getClientById(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).valueChanges({idField: 'id'});
  }

  getLocationByIdForClientId(locationId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).ref.get();
  }

  getLayoutByIdForLocIdClientId(layoutId: string, locationId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).ref.get();
  }

  // DEVICES
  getAllDevices(): Observable<any> {
    return this.afs.collection('devices').valueChanges({idField: 'id'});
  }

  getAllDevicesForClientId(clientId: string): Observable<any> {
    return this.afs.collection('devices', (ref) => ref.where('clientId', '==', clientId)).valueChanges({idField: 'id'});
  }

  getUnArchivedDevicesForClientId(clientId: string): Observable<any> {
    return this.afs.collection('devices', (ref) => ref.where('clientId', '==', clientId).where('isArchived', '==', false)).valueChanges({idField: 'id'});
  }

  getArchivedDevicesForClientId(clientId: string): Observable<any> {
    return this.afs.collection('devices', (ref) => ref.where('clientId', '==', clientId).where('isArchived', '==', true)).valueChanges({idField: 'id'});
  }

  updateDevice(device, isActivated): Promise<any> {
    const updateObject: any = {
      notes: device.notes,
      deviceNumber: device.deviceNumber ?? null
    };

    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    if (isActivated) {
      updateObject.deviceActivatedAt = new Date();
    } else {
      updateObject.locationId = device.locationId;
      updateObject.locationName = device.locationName;
      updateObject.enableGPS = device.enableGPS;
      updateObject.enableAutoTouchLock = device.enableAutoTouchLock;
      updateObject.enableOutOfRange = device.enableOutOfRange;
      updateObject.enableTrainingMode = device.enableTrainingMode;
      updateObject.enableVoiceAssistance = device.enableVoiceAssistance;
      updateObject.isLocked = device.isLocked;
      updateObject.appModes = device.appModes;
      updateObject.appModeIds = device.appModeIds;
    }
    return this.afs.collection('devices').doc(device.id).update(updateObject);
  }

  updateDevices(deviceIds, deviceUpdate): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('bulkUpdateDevices');
    return callable({
      secret: 'orangeswereneverapples',
      deviceIds,
      settings: deviceUpdate
    });

  }

  updateIssueForDevice(deviceId, hasIssue): Promise<any> {
    return this.afs.collection('devices').doc(deviceId).update({
      hasIssue,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  updateLastSeenForDevice(deviceId: string): Promise<any> {
    return this.afs.collection('devices').doc(deviceId).update({
      lastSeenRequestTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  async updateLastSeenForDevices(deviceIds: string[]): Promise<any> {
    const batch = this.afs.firestore.batch();
    for (const deviceId of deviceIds) {
      batch.update(this.afs.collection('devices').doc(deviceId).ref, {
        lastSeenRequestTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
    }
    await batch.commit();
  }

  archiveDeviceById(deviceId): Promise<any> {
    return this.afs.collection('devices').doc(deviceId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: true,
      archivedAt: Timestamp.now()
    });
  }

  unArchiveDeviceById(deviceId): Promise<any> {
    return this.afs.collection('devices').doc(deviceId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: false,
      restoredAt: Timestamp.now()
    });
  }

  archiveRegistrationByIdForClientId(registrationId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('registrations').doc(registrationId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: true,
      archivedAt: Timestamp.now()
    });
  }

  archiveRegnBelowPresenceByIdForClientId(registrationId: string, clientId: string, presenceId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').doc(registrationId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: true,
      archivedAt: Timestamp.now()
    });
  }

  unArchiveRegistrationByIdForClientId(registrationId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('registrations').doc(registrationId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: false,
      restoredAt: Timestamp.now()
    });
  }

  unArchiveRegnBelowPresenceByIdForClientId(registrationId: string, clientId: string, presenceId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').doc(registrationId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: false,
      restoredAt: Timestamp.now()
    });
  }

  updateWorkerForDevice(device): Promise<any> {
    const updateObject: any = {
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      workerId: device.workerId,
      workerName: device.workerName,
    };
    return this.afs.collection('devices').doc(device.id).update(updateObject);
  }

  updateLytByIdForLocationIdClientId(clientId, locationId, layoutId, attribsToUpdate): Promise<any> {
    attribsToUpdate.updatedTimestamp = new Date();
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).update(attribsToUpdate);
  }

  updateLocByIdForClientId(clientId, locationId, attribsToUpdate): Promise<any> {
    attribsToUpdate.updatedTimestamp = new Date();
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).update(attribsToUpdate);
  }

  // WORKERS - note not refactoring below worker design to below client to check if the module dev-workers is used somewhere
  getAllWorkers(): Observable<any> {
    return this.afs.collection('workers', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllWorkersForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('workers').valueChanges({idField: 'id'});
  }

  getUnArchivedWorkersForClientId(clientId: string, locationId = null): Observable<any> {
    if (!locationId) {
      return this.afs.collection('clients').doc(clientId).collection('workers', (ref) => ref.where('isArchived', '==', false)
      ).valueChanges({idField: 'id'});
    } else {
      return this.afs.collection('clients').doc(clientId).collection('workers', (ref) => ref.where('locationIds', 'array-contains', locationId).where('isArchived', '==', false)
      ).valueChanges({idField: 'id'});
    }
  }

  getArchivedWorkersForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('workers', (ref) => ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  createWorkerForClientId(worker: any, clientId: string): Promise<any> {
    worker.creationTimestamp = new Date();
    worker.updatedTimestamp = new Date();
    return this.afs.collection('clients').doc(clientId).collection('workers').add(worker);
  }

  createLocationForClientId(clientId: string, location: any): Promise<any> {
    location.creationTimestamp = new Date();
    location.updatedTimestamp = new Date();
    location.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('locations').add(location);
  }

  createLayoutForLocationIdClientId(clientId, locationId, layout): Promise<any> {
    layout.creationTimestamp = new Date();
    layout.updatedTimestamp = new Date();
    layout.clientId = clientId;
    layout.locationId = locationId;
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').add(layout);
  }

  updateLayoutForLocationIdClientId(clientId, locationId, layoutId, attribsToUpdate): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).update(attribsToUpdate);
  }

  // TRAININGS
  getAllTrainings(clientIds?): Observable<any> {
    return this.afs.collection('trainings', (ref) =>
      ref.where('clientId', 'in', clientIds).orderBy('creationTimestamp', 'desc')
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedTrainings(clientIds?): Observable<any> {
    return this.afs.collection('trainings', (ref) =>
      ref.where('isArchived', '==', true).where('clientId', 'in', clientIds).orderBy('creationTimestamp', 'desc')
    ).valueChanges({idField: 'id'});
  }

  getUnArchivedTrainings(clientIds?): Observable<any> {
    return this.afs.collection('trainings', (ref) =>
      ref.where('isArchived', '==', false).where('clientId', 'in', clientIds).orderBy('creationTimestamp', 'desc')
    ).valueChanges({idField: 'id'});
  }

  getTrainingById(id): Observable<any> {
    return this.afs.collection('trainings').doc(id).valueChanges({idField: 'id'});
  }

  getAllTrainingsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('trainings', (ref) =>
      ref.where('activityType', '==', 'training').where('clientId', '==', clientId).orderBy('creationTimestamp', 'desc')
    ).valueChanges({idField: 'id'});
  }

  updateTraining(training): Promise<any> {
    const updateObject: any = {
      notes: training.notes,
      youtubeLink: training.youtubeLink,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    };
    return this.afs.collection('trainings').doc(training.id).update(updateObject);
  }

  archiveUnarchiveTraining(trainingId: string, isArchived: boolean): Promise<any> {
    const updateObject: any = {
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived
    };
    return this.afs.collection('trainings').doc(trainingId).update(updateObject);
  }

  partiallyUpdateTraining(updateObject): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('trainings').doc(updateObject.id).update(updateObject);
  }

  saveAnnotationForTraining(trainingId: string, annotationObject): Promise<any> {
    annotationObject.creationTimestamp = new Date();
    annotationObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('trainings').doc(trainingId).collection('annotations').add(annotationObject);
  }

  getAllAnnotationsForTrainingId(trainingId: string): Observable<any> {
    return this.afs.collection('trainings').doc(trainingId).collection('annotations', (ref) =>
      ref.orderBy('videoTime', 'desc')
    ).valueChanges({idField: 'id'});
  }

  async deleteAnnotationsForTrainingId(trainingId: string, annotationIds: string[]): Promise<any> {
    const batch = this.afs.firestore.batch();
    for (const annotationId of annotationIds) {
      batch.delete(this.afs.collection('trainings').doc(trainingId).collection('annotations').doc(annotationId).ref);
    }
    await batch.commit();
  }

  // MODELS
  getAllModels(): Observable<any> {
    return this.afs.collection('models').valueChanges({idField: 'id'});
  }

  updateWorkerForClientId(worker, clientId): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('workers').doc(worker.id).update({
      name: worker.name,
      workerCode: worker.workerCode ?? null,
      hourlyRate: worker.hourlyRate ?? null,
      notes: worker.notes,
      locationIds: worker.locationIds,
      isLeftHanded: worker.isLeftHanded,
      workerGroupId: worker.workerGroupId,
      workerGroupName: worker.workerGroupName,
      labelIds: worker.labelIds,
      labels: worker.labels,
      externalId: worker.externalId,
      startTimestamp: worker.startTimestamp ?? null,
      endTimestamp: worker.endTimestamp ?? null,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  patchWorkerForClientId(worker, clientId: string): Promise<any> {
    worker.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('workers').doc(worker.id).update(worker);
  }

  async restoreSessionFromVersionBackup(sessionId: string, clientId: string, backupDocId: string, loggedInUser: any) {
    this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).collection('versions').doc(backupDocId).get().subscribe(async (originalSessionVersionDS) => {
      const originalSessionDD = originalSessionVersionDS.data();
      await this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).update({
        ...originalSessionDD,
        isOriginal: true,
        revertedVersionAt: new Date(),
        backupDocId: null,
        backupVersionCreationTimestamp: null,
        revertedByUserId: loggedInUser?.id ?? null,
        revertedByUserName: loggedInUser?.name ?? null,
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
      await this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).collection('states').doc('ml').set({
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      }, {merge: true});
      await this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).collection('versions').doc(backupDocId).delete();
    });
  }

  createSessionForClientId(session: any, clientId: string): Promise<any> {
    session.creationTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    session.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('sessions').add(session);
  }

  async createOriginalVersionCopyOfSession(sessionId: string, clientId: string, originalSession: any): Promise<string | null> {
    try {
      const newVersionDocRef = await this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).collection('versions').add({
        ...originalSession,
        backupVersionCreationTimestamp: new Date()
      });
      return newVersionDocRef.id;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  async updateSessionForClientId(session: any, clientId: string, toUpdateTimestamp: boolean): Promise<any> {
    session.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    if (toUpdateTimestamp) {
      await this.afs.collection('clients').doc(clientId).collection('sessions').doc(session.id).collection('states').doc('ml').set({
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      }, {merge: true});
    }
    return this.afs.collection('clients').doc(clientId).collection('sessions').doc(session.id).update(session);
  }

  archiveWorkerByIdForClientId(workerId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('workers').doc(workerId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: true,
      archivedAt: Timestamp.now()
    });
  }

  unArchiveWorkerByIdForClientId(workerId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('workers').doc(workerId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: false,
      restoredAt: Timestamp.now()
    });
  }

  createWorkerGroupForClientId(workerGroup, clientId): Promise<any> {
    workerGroup.creationTimestamp = new Date();
    workerGroup.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    workerGroup.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('workerGroups').add(workerGroup);
  }

  updateWorkerGroupByIdForClientId(workerGroupId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('workerGroups').doc(workerGroupId).update(updateObject);
  }

  getAllUnarchivedWorkerGroupsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('workerGroups', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedWorkerGroupsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('workerGroups', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  validateKey(activationKey, clientId, token): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      responseType: 'text',
      secret: 'orangeswereneverapples'
    };
    // create user collection using the auth token
    return this.http.post<any>(`https://training-wwhzfpzgda-ew.a.run.app/web/validateKeyDevice`, {
      key: activationKey,
      clientId
    }, {
      headers,
      responseType: 'json'
    });
  }

  uploadBeaconsCSVFile(files: any, clientId, token): Observable<any> {
    const formData = new FormData();
    formData.append('clientId', clientId);
    formData.append('files', files[0]);
    const headers = {
      clientId,
      secret: 'orangeswereneverapples'
    };

    return this.http.post<any>(`${environment.functionsBase}/operation/web/uploadBeaconsCSV`, formData, {
      headers,
      responseType: 'json'
    });
  }

  getAllActivities(): Observable<any> {
    return this.afs.collection('activities').valueChanges({idField: 'id'});
  }

  getAllAppModes(): Observable<any> {
    return this.afs.collection('appModes').valueChanges({idField: 'id'});
  }

  getAllUnarchivedLocationsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations', (ref) =>
      ref.where('isArchived', '==', false)).valueChanges({idField: 'id'});
  }

  getAllLayoutsForLocIdForClientId(locationId: string, clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').valueChanges({idField: 'id'});
  }

  getAllRowsForLytLocClientId(layoutId: string, locationId: string, clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows', (ref) =>
      ref.where('isArchived', '==', false)
    ).get();
  }

  getAllRowsForClientId(clientId: string): Observable<any> {
    return this.afs.collectionGroup('rows', (ref) =>
      ref.where('clientId', '==', clientId).where('isArchived', '==', false).orderBy('rowNumber', 'asc')
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedRowsForClientId(clientId: string): Observable<any> {
    return this.afs.collectionGroup('rows', (ref) =>
      ref.where('clientId', '==', clientId).where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  getAllRowsForLocIdForClientId(clientId: string, locationId: string): Observable<any> {
    return this.afs.collectionGroup('rows', (ref) =>
      ref.where('clientId', '==', clientId).where('locationId', '==', locationId).where('isArchived', '==', false).orderBy('rowNumber', 'asc')
    ).valueChanges({idField: 'id'});
  }

  createRowForLytLocClientId(layoutId, locationId, clientId, rowData): Promise<any> {
    const newRowId = this.afs.createId();
    rowData.id = newRowId;
    rowData.clientId = clientId;
    rowData.locationId = locationId;
    rowData.layoutId = layoutId;
    rowData.added = new Date();
    rowData.latestActivityTimestamp = null;
    rowData.creationTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    rowData.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    rowData.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(newRowId).set(rowData);
  }

  createDuplicateRowForLytLocClientId(layoutId, locationId, clientId, rowData): Promise<any> {
    const newRowId = this.afs.createId();
    const newRowObj: any = {};
    newRowObj.id = newRowId;
    newRowObj.rowReference = rowData.rowReference ?? null;
    newRowObj.rowNumber = rowData.rowNumber ?? null;
    newRowObj.rowLength = rowData.rowLength ?? null;
    newRowObj.varietyId = rowData.varietyId ?? null;
    newRowObj.varietyName = rowData.varietyName ?? null;
    newRowObj.activityId = rowData.activityId ?? null;
    newRowObj.activityName = rowData.activityName ?? null;
    newRowObj.geopoint = rowData.geopoint ?? null;
    newRowObj.clientId = clientId;
    newRowObj.locationId = locationId;
    newRowObj.locationName = rowData.locationName;
    newRowObj.beaconIds = rowData.beaconIds ?? [];
    newRowObj.beaconNames = rowData.beaconNames ?? [];
    newRowObj.layoutId = layoutId;
    newRowObj.layoutName = rowData.layoutName;
    newRowObj.creationTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    newRowObj.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    newRowObj.latestActivityTimestamp = null;
    newRowObj.added = new Date();
    newRowObj.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(newRowId).set(newRowObj);
  }

  updateRowForLytLocClientId(layoutId, locationId, clientId, rowData): Promise<any> {
    if (rowData?.hasOwnProperty('rowBeaconsList')) {
      delete rowData.rowBeaconsList
    }
    rowData.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    const rowId = rowData.id;
    const rowToSave = {
      ...rowData
    }
    delete rowToSave.id; //id does not need to be saved
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(rowId).update(rowData);
  }

  unarchiveRowForLytLocClientId(locationId: string, layoutId: string, clientId: string, rowId: string): Promise<any> {
    const rowToSave = {
      isArchived: false,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    }
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(rowId).update(rowToSave);
  }

  deleteRowForLytLocClientId(layoutId, locationId, clientId, rowId): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(rowId).delete();
  }

  archiveRowForLytLocClientId(layoutId, locationId, clientId, rowId): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(rowId).update({
      isArchived: true,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  async restartRowForLytLocClientId(layoutId, locationId, clientId, rowId, row): Promise<any> {
    await this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(rowId).update({
      beaconIds: [],
      beaconNames: [],
      isArchived: true,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
    await this.createDuplicateRowForLytLocClientId(layoutId, locationId, clientId, row);
  }

  async updateRowLengthForAllRows(layoutId, locationId, clientId, rowIds, rowLength): Promise<any> {
    const batch = this.afs.firestore.batch();
    for (const rowId of rowIds) {
      batch.update(
        this.afs.collection('clients').doc(clientId).collection('locations').doc(locationId).collection('layout').doc(layoutId).collection('rows').doc(rowId).ref,
        {
          rowLength,
          updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
        }
      );
    }
    await batch.commit();
  }

  getObservationsData(clientId, todayStr): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('observations', (ref) =>
      ref.where('dateStr', '==', todayStr)
    ).valueChanges({idField: 'id'});
  }

  //This method is no longer used - instead archived & unarchived sessions fetching methods are used. Retained for any future use purposes
  getSessionsData(clientId, dateToQuery): Observable<any> {
    const fromMoment = Timestamp.fromDate(moment(dateToQuery).startOf('day').toDate());
    const toMoment = Timestamp.fromDate(moment(dateToQuery).endOf('day').toDate());
    return this.afs.collection('clients').doc(clientId).collection('sessions', (ref) =>
      ref.where('startTimestamp', '>=', fromMoment).where('startTimestamp', '<=', toMoment)
    ).valueChanges({idField: 'id'});
  }

  getArchivedSessions(clientId, dateToQuery): Observable<any> {
    const fromMoment = Timestamp.fromDate(moment(dateToQuery).startOf('day').toDate());
    const toMoment = Timestamp.fromDate(moment(dateToQuery).endOf('day').toDate());
    return this.afs.collection('clients').doc(clientId).collection('sessions', (ref) =>
      ref.where('startTimestamp', '>=', fromMoment).where('startTimestamp', '<=', toMoment).where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  getUnarchivedSessions(clientId, dateToQuery, locationId = null): Observable<any> {
    const fromMoment = Timestamp.fromDate(moment(dateToQuery).startOf('day').toDate());
    const toMoment = Timestamp.fromDate(moment(dateToQuery).endOf('day').toDate());
    if (!locationId) {
      return this.afs.collection('clients').doc(clientId).collection('sessions', (ref) =>
        ref.where('startTimestamp', '>=', fromMoment).where('startTimestamp', '<=', toMoment).where('isArchived', '==', false)
      ).valueChanges({idField: 'id'});
    } else {
      return this.afs.collection('clients').doc(clientId).collection('sessions', (ref) =>
        ref.where('startTimestamp', '>=', fromMoment).where('startTimestamp', '<=', toMoment).where('locationId', '==', locationId).where('isArchived', '==', false)
      ).valueChanges({idField: 'id'});
    }
  }

  getSessionByIdForClientId(sessionId, clientId): Observable<DocumentData> {
    return this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).get();
  }

  archiveSessionByIdForClientId(sessionId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: true,
      archivedAt: Timestamp.now()
    });
  }

  unArchiveSessionByIdForClientId(sessionId: string, clientId: string): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('sessions').doc(sessionId).update({
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      isArchived: false,
      restoredAt: Timestamp.now()
    });
  }


  createUserForClientId(user, client): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('createUser');
    return callable({
      secret: 'orangeswereneverapples',
      user,
      client
    });
  }

  createCSVRequestForTrainingId(user, trainingId, requestType): Promise<any> {
    return this.afs.collection('trainingDataDownloadRequests').add({
      user,
      trainingId,
      requestType,
      status: 'requested',
      requestedAt: Timestamp.now()
    });
  }

  copySessionToNewTraining(session): Promise<any> {
    console.log(JSON.stringify(session, undefined, 4));
    return this.afs.collection('trainings').add({
      clientId: session.clientId ?? null,
      clientName: session.clientName ?? null,
      creationTimestamp: session.creationTimestamp ?? null,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      deviceId: session.deviceId ?? null,
      isArchived: false,
      isSessionCopy: true,
      sessionId: session.id ?? null,
      trainingKey: this.newKey(4),
      trainingStartTimestamp: session.startTimestamp,
      updateTimestamp: session.updatedTimestamp ?? null,
      workerId: session.workerId ?? null,
      workerName: session.workerName ?? null,
      createdFromSessionAt: new Date()
    });
  }

  newKey(length): string {
    const chars = "ACEFHJKLMNRSTUVWXY3479";
    const charsLength = chars.length;
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
  };

  getAllTrainingDataRequests(): Observable<any> {
    return this.afs.collection('trainingDataDownloadRequests').valueChanges({idField: 'id'});
  }

  getAllTrainingDataRequestsForTrainingId(trainingId: string): Observable<any> {
    return this.afs.collection('trainingDataDownloadRequests', (ref) =>
      ref.where('trainingId', '==', trainingId)
    ).valueChanges({idField: 'id'});
  }

  getAllUnarchivedUsersForClientId(clientId: string): Observable<any> {
    return this.afs.collection('users', (ref) =>
      ref.where('clientIds', 'array-contains', clientId)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedUsersForClientId(clientId: string): Observable<any> {
    return this.afs.collection('users', (ref) =>
      ref.where('archivedClientIds', 'array-contains', clientId)
    ).valueChanges({idField: 'id'});
  }

  getAllUnarchivedLabelsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('labels', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedLabelsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('labels', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  createLabelForClientId(label, clientId): Promise<any> {
    label.creationTimestamp = new Date();
    label.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    label.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('labels').add(label);
  }

  updateLabelByIdForClientId(labelId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('labels').doc(labelId).update(updateObject);
  }

  getAllUnarchivedPositionsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('positions', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedPositionsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('positions', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  createPositionForClientId(position, clientId): Promise<any> {
    position.creationTimestamp = new Date();
    position.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    position.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('positions').add(position);
  }

  updatePositionByIdForClientId(positionId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('positions').doc(positionId).update(updateObject);
  }

  getAllUnarchivedAssetsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('assets', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedAssetsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('assets', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  createAssetForClientId(asset, clientId): Promise<any> {
    asset.creationTimestamp = new Date();
    asset.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    asset.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('assets').add(asset);
  }

  updateAssetByIdForClientId(assetId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('assets').doc(assetId).update(updateObject);
  }

  getAllUnarchivedBeaconsByTypeForClientId(clientId: string, beaconType: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('beacons', (ref) =>
      ref.where('isArchived', '==', false).where('type', '==', beaconType)
    ).valueChanges({idField: 'id'});
  }

  getAllUnarchivedBeaconsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('beacons', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllUnarchivedNAssgndBeaconsForClientId(clientId: string, beaconType: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('beacons', (ref) =>
      ref.where('isArchived', '==', false).where('assignedId', '==', null).where('type', '==', beaconType)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedBeaconsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('beacons', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  getBeaconForMACAddress(macAddress: string, clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('beacons', (ref) =>
      ref.where('mac', '==', macAddress)
    ).valueChanges({idField: 'id'});
  }

  createBeaconForClientId(beacon, clientId): Promise<any> {
    beacon.creationTimestamp = new Date();
    beacon.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    beacon.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('beacons').add(beacon);
  }

  updateBeaconByIdForClientId(beaconId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('beacons').doc(beaconId).update(updateObject);
  }

  async deleteBeaconsForClientId(clientId: string, beaconIds: string[]): Promise<any> {
    const batch = this.afs.firestore.batch();
    for (const beaconId of beaconIds) {
      batch.delete(this.afs.collection('clients').doc(clientId).collection('beacons').doc(beaconId).ref);
    }
    await batch.commit();
  }

  async archiveBeaconsForClientId(clientId: string, beaconIds: string[]): Promise<any> {
    const batch = this.afs.firestore.batch();
    for (const beaconId of beaconIds) {
      batch.update(this.afs.collection('clients').doc(clientId).collection('beacons').doc(beaconId).ref, {
        isArchived: true,
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
    }
    await batch.commit();
  }

  async resetAssignmentOfBeaconsForClientId(clientId: string, beacons: any[]): Promise<any> {
    for (const beacon of beacons) {
      await this.updateAssignedEntityNBeaconWithBeaconChanges(beacon, clientId);
    }
  }

  createTaskForClientId(task, clientId): Promise<any> {
    task.creationTimestamp = new Date();
    task.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    task.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('tasks').add(task);
  }

  updateTaskByIdForClientId(taskId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('tasks').doc(taskId).update(updateObject);
  }

  getAllUnarchivedTasksForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('tasks', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedTasksForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('tasks', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  createTaskGroupForClientId(taskGroup, clientId): Promise<any> {
    taskGroup.creationTimestamp = new Date();
    taskGroup.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    taskGroup.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('taskGroups').add(taskGroup);
  }

  updateTaskGroupByIdForClientId(taskGroupId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('taskGroups').doc(taskGroupId).update(updateObject);
  }

  getAllUnarchivedTaskGroupsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('taskGroups', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedTaskGroupsForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('taskGroups', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  getAllUnarchivedVarietiesForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('varieties', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  getAllArchivedVarietiesForClientId(clientId: string): Observable<any> {
    return this.afs.collection('clients').doc(clientId).collection('varieties', (ref) =>
      ref.where('isArchived', '==', true)
    ).valueChanges({idField: 'id'});
  }

  createVarietyForClientId(variety, clientId): Promise<any> {
    variety.creationTimestamp = new Date();
    variety.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    variety.isArchived = false;
    return this.afs.collection('clients').doc(clientId).collection('varieties').add(variety);
  }

  updateVarietyByIdForClientId(varietyId: string, clientId: string, updateObject: any): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('varieties').doc(varietyId).update(updateObject);
  }

  updateUserById(userId, updateObject): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('users').doc(userId).update({
      ...updateObject
    });
  }

  updateClientById(clientId, updateObject): Promise<any> {
    updateObject.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).update({
      ...updateObject
    });
  }

  async updateAssignedEntityNBeaconWithBeaconChanges(beaconRecord, clientId): Promise<any> {
    let docRef: any;
    let entityDS: any;
    let newBeaconIdsList: string[];
    let newBeaconNamesList: string[];
    switch (beaconRecord.assignedType) {
      case 'ASSET':
        docRef = doc(this.firestore, `/clients/${clientId}/assets`, beaconRecord.assignedId);
        entityDS = await getDoc(docRef);
        [newBeaconIdsList, newBeaconNamesList] = this.getNewBeaconLists(entityDS, beaconRecord);
        await this.updateAssetByIdForClientId(beaconRecord.assignedId, clientId, {
          beaconIds: newBeaconIdsList,
          beaconNames: newBeaconNamesList
        });
        break;
      case 'POSITION':
        docRef = doc(this.firestore, `/clients/${clientId}/positions`, beaconRecord.assignedId);
        entityDS = await getDoc(docRef);
        [newBeaconIdsList, newBeaconNamesList] = this.getNewBeaconLists(entityDS, beaconRecord);
        await this.updatePositionByIdForClientId(beaconRecord.assignedId, clientId, {
          beaconIds: newBeaconIdsList,
          beaconNames: newBeaconNamesList
        });
        break;
      case 'ROW': //TODO -- add locationId,layoutId in beaconRecord from controller before sending it here
        docRef = doc(this.firestore, `/clients/${clientId}/locations/${beaconRecord.locationId}/layout/${beaconRecord.layoutId}/rows`, beaconRecord.assignedId);
        entityDS = await getDoc(docRef);
        [newBeaconIdsList, newBeaconNamesList] = this.getNewBeaconLists(entityDS, beaconRecord);
        await this.updateRowForLytLocClientId(beaconRecord.layoutId, beaconRecord.locationId, clientId, {
          id: beaconRecord.assignedId,
          beaconIds: newBeaconIdsList,
          beaconNames: newBeaconNamesList
        });
        break;
      case 'LOCATION':
        docRef = doc(this.firestore, `/clients/${clientId}/locations`, beaconRecord.assignedId);
        entityDS = await getDoc(docRef);
        [newBeaconIdsList, newBeaconNamesList] = this.getNewBeaconLists(entityDS, beaconRecord);
        await this.updateLocByIdForClientId(clientId, beaconRecord.assignedId, {
          beaconIds: newBeaconIdsList,
          beaconNames: newBeaconNamesList
        });
        break;
      case 'WORKER':
        docRef = doc(this.firestore, `/clients/${clientId}/workers`, beaconRecord.assignedId);
        entityDS = await getDoc(docRef);
        [newBeaconIdsList, newBeaconNamesList] = this.getNewBeaconLists(entityDS, beaconRecord);
        await this.patchWorkerForClientId({
          id: beaconRecord.assignedId,
          beaconIds: newBeaconIdsList,
          beaconNames: newBeaconNamesList
        }, clientId);
        break;
      case 'TASK':
        docRef = doc(this.firestore, `/clients/${clientId}/tasks`, beaconRecord.assignedId);
        entityDS = await getDoc(docRef);
        [newBeaconIdsList, newBeaconNamesList] = this.getNewBeaconLists(entityDS, beaconRecord);
        await this.updateTaskByIdForClientId(beaconRecord.assignedId, clientId, {
          beaconIds: newBeaconIdsList,
          beaconNames: newBeaconNamesList
        });
        break;
    }
    const beaconUpdateObject: any = {
      assignedId: null,
      assignedName: null,
      assignedType: null,
      assetType: null,
      stickDuration: null,
      rssiThreshold: -46
    };
    if (beaconRecord.assignedType === 'ROW') {
      beaconUpdateObject.locationId = null;
      beaconUpdateObject.layoutId = null;
    }
    return this.updateBeaconByIdForClientId(beaconRecord.id, clientId, beaconUpdateObject);
  }

  getNewBeaconLists(entityDS, beaconRecord) {
    const entityDocData = entityDS.data();
    const newBeaconIdsList = entityDocData.beaconIds?.filter(beaconId => beaconId !== beaconRecord.id);
    let isSkippedOnce = false;
    const newBeaconNamesList = [];
    for (const beaconName of entityDocData.beaconNames) {
      if (isSkippedOnce || (beaconName !== beaconRecord.name)) {
        newBeaconNamesList.push(beaconName);
      } else {
        isSkippedOnce = true;
      }
    }
    return [newBeaconIdsList, newBeaconNamesList];
  }

  updateUserByIdForClientId(userId, user, clientId): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('updateUser');
    return callable({
      secret: 'orangeswereneverapples',
      clientId,
      uid: userId,
      user
    });

  }

  archiveUserByIdForClientId(userId, clientId): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('archiveUser');
    return callable({
      secret: 'orangeswereneverapples',
      clientId,
      uid: userId,
    });
  }

  unarchiveUserByIdForClientId(userId, clientId): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('unarchiveUser');
    return callable({
      secret: 'orangeswereneverapples',
      clientId,
      uid: userId,
    });
  }

  duplicateTraining(trainingId): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('duplicateTraining');
    return callable({
      secret: 'orangeswereneverapples',
      trainingId,
    });
  }

  getAllRegistrationsForClientId(clientId: string, dateToQuery: Date): Observable<any> {
    const fromTimestamp = Timestamp.fromDate(moment(dateToQuery).startOf('day').toDate());
    const toTimestamp = Timestamp.fromDate(moment(dateToQuery).endOf('day').toDate());
    return this.afs.collection('clients').doc(clientId).collection('registrations',
      (ref) => ref.where('startTimestamp', '>=', fromTimestamp).where('startTimestamp', '<=', toTimestamp)).valueChanges({idField: 'id'});
  }

  getPresencesForClientId(clientId: string, startDate: Date, isArchived: boolean, selectedMode: string, locationId = null, endDate = null): Observable<any> {
    let fromTimestamp;
    let toTimestamp;
    if (['THIS_WEEK', 'LAST_WEEK', 'WEEK_RANGE'].includes(selectedMode)) {
      fromTimestamp = Timestamp.fromDate(moment(startDate)/*.tz(TIME_ZONE)*/.startOf('isoWeek').toDate()); //startDate now comes in GMT from range picker
      toTimestamp = Timestamp.fromDate(moment(startDate)/*.tz(TIME_ZONE)*/.endOf('isoWeek').toDate());
    } else if (['THIS_MONTH', 'LAST_MONTH'].includes(selectedMode)) {
      fromTimestamp = Timestamp.fromDate(moment(startDate)/*.tz(TIME_ZONE)*/.startOf('month').toDate()); //startDate now comes in GMT from range picker
      toTimestamp = Timestamp.fromDate(moment(startDate)/*.tz(TIME_ZONE)*/.endOf('month').toDate());
    } else if ((selectedMode === 'CUSTOM_RANGE') && endDate){
      fromTimestamp = Timestamp.fromDate(moment(startDate)/*.tz(TIME_ZONE)*/.startOf('day').toDate()); //startDate now comes in GMT from range picker
      toTimestamp = Timestamp.fromDate(moment(endDate)/*.tz(TIME_ZONE)*/.endOf('day').toDate());
    }
    //console.log('start date:' + fromTimestamp.toDate());
    //console.log('end date:' + toTimestamp.toDate());
    let query;
    if (locationId) {
      query = this.afs.collection('clients').doc(clientId).collection('presences',
        (ref) => ref.where('startTimestamp', '>=', fromTimestamp)
        .where('startTimestamp', '<=', toTimestamp).where('isArchived', '==', isArchived).where('locationId', '==', locationId));
    } else {
      query = this.afs.collection('clients').doc(clientId).collection('presences',
        (ref) => ref.where('startTimestamp', '>=', fromTimestamp).where('startTimestamp', '<=', toTimestamp).where('isArchived', '==', isArchived));
    }
    return query.valueChanges({idField: 'id'});

  }

  getArchivedRegnsForClientId(clientId: string, dateToQuery: Date, locationId = null): Observable<any> {
    const fromTimestamp = Timestamp.fromDate(moment(dateToQuery).startOf('day').toDate());
    const toTimestamp = Timestamp.fromDate(moment(dateToQuery).endOf('day').toDate());
    let query;
    if (locationId) {
      query = this.afs.collectionGroup('registrations',
        (ref) => ref.where('clientId', '==', clientId)
        .where('startTimestamp', '>=', fromTimestamp).where('startTimestamp', '<=', toTimestamp).where('isArchived', '==', true).where('locationId', '==', locationId));
    } else {
      query = this.afs.collectionGroup('registrations',
        (ref) => ref.where('clientId', '==', clientId)
        .where('startTimestamp', '>=', fromTimestamp).where('startTimestamp', '<=', toTimestamp).where('isArchived', '==', true));
    }
    return query.snapshotChanges().pipe(
      map((actions: any[]) => {
        return actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          const grandParentCollection = a.payload.doc.ref.parent.parent.parent.id;
          let parentPresenceId = null;
          if (grandParentCollection === 'presences') {
            parentPresenceId = a.payload.doc.ref.parent.parent.id;
          }
          return {id, grandParentCollection, parentPresenceId, ...data};
        });
      })
    );
  }

  getUnarchivedRegnsForClientId(clientId: string, dateToQuery: Date, locationId = null): Observable<any> {
    const fromTimestamp = Timestamp.fromDate(moment(dateToQuery).startOf('day').toDate());
    const toTimestamp = Timestamp.fromDate(moment(dateToQuery).endOf('day').toDate());

    let query;
    if (locationId) {
      query = this.afs.collectionGroup('registrations',
        (ref) => ref.where('clientId', '==', clientId)
        .where('startTimestamp', '>=', fromTimestamp).where('startTimestamp', '<=', toTimestamp).where('isArchived', '==', false).where('locationId', '==', locationId));
    } else {
      query = this.afs.collectionGroup('registrations',
        (ref) => ref.where('clientId', '==', clientId)
        .where('startTimestamp', '>=', fromTimestamp).where('startTimestamp', '<=', toTimestamp).where('isArchived', '==', false));
    }

    return query.snapshotChanges().pipe(
      map((actions: any[]) => {
        return actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          const grandParentCollection = a.payload.doc.ref.parent.parent.parent.id;
          let parentPresenceId = null;
          if (grandParentCollection === 'presences') {
            parentPresenceId = a.payload.doc.ref.parent.parent.id;
          }
          return {id, grandParentCollection, parentPresenceId, ...data};
        });
      })
    );
  }

  updateRegnForClientId(regnData: any, clientId: string): Promise<any> {
    const regnId = regnData.id;
    regnData.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    delete regnData.id; //id does not need to be saved
    return this.afs.collection('clients').doc(clientId).collection('registrations').doc(regnId).update(regnData);
  }

  updateRegnBelowPresenceForClientId(regnData: any, clientId: string, presenceId: string): Promise<any> {
    regnData.updatedFromDashboard = true;
    regnData.lastUpdateFromDashboardAt = firebase.firestore.FieldValue.serverTimestamp();
    const regnId = regnData.id;
    regnData.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    delete regnData.id; //id does not need to be saved
    return this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').doc(regnId).update(regnData);
  }

  async createOriginalVersionCopyOfRegnBelowPresence(regnId: string, clientId: string, presenceId: string, originalRegn: any): Promise<string | null> {
    try {
      const newVersionDocRef = await this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').doc(regnId).collection('versions').add({
        ...originalRegn,
        backupVersionCreationTimestamp: new Date()
      });
      return newVersionDocRef.id;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  async createOriginalVersionCopyOfRegn(regnId: string, clientId: string, originalRegn: any): Promise<string | null> {
    try {
      const newVersionDocRef = await this.afs.collection('clients').doc(clientId).collection('registrations').doc(regnId).collection('versions').add({
        ...originalRegn,
        backupVersionCreationTimestamp: new Date()
      });
      return newVersionDocRef.id;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  async restoreRegnFromVersionBackup(regnId: string, clientId: string, backupDocId: string, loggedInUser: any) {
    this.afs.collection('clients').doc(clientId).collection('registrations').doc(regnId).collection('versions').doc(backupDocId).get().subscribe(async (originalRegnVersionDS) => {
      const originalRegnDD = originalRegnVersionDS.data();
      await this.afs.collection('clients').doc(clientId).collection('registrations').doc(regnId).update({
        ...originalRegnDD,
        isOriginal: true,
        revertedVersionAt: new Date(),
        backupDocId: null,
        backupVersionCreationTimestamp: null,
        revertedByUserId: loggedInUser?.id ?? null,
        revertedByUserName: loggedInUser?.name ?? null,
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
      await this.afs.collection('clients').doc(clientId).collection('registrations').doc(regnId).collection('versions').doc(backupDocId).delete();
    });
  }

  async restoreRegnFromVersionBackupBelowPresence(regnId: string, clientId: string, presenceId: string, backupDocId: string, loggedInUser: any) {
    this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId)
    .collection('registrations').doc(regnId).collection('versions').doc(backupDocId).get()
    .subscribe(async (originalRegnVersionDS) => {
      const originalRegnDD = originalRegnVersionDS.data();
      await this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').doc(regnId).update({
        ...originalRegnDD,
        isOriginal: true,
        revertedVersionAt: new Date(),
        backupDocId: null,
        backupVersionCreationTimestamp: null,
        revertedByUserId: loggedInUser?.id ?? null,
        revertedByUserName: loggedInUser?.name ?? null,
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
      await this.afs.collection('clients').doc(clientId)
      .collection('presences').doc(presenceId).collection('registrations')
      .doc(regnId).collection('versions').doc(backupDocId).delete();
    });
  }

  getWebAppConfig(): Observable<any> {
    return this.afs.collection('config').doc('webApp').get();
  }

  getAllLanguageElements(clientId: string): Observable<any> {
    return this.afs.collection('languages').doc('elements').collection('elements').valueChanges({idField: 'id'});
  }

  updateLanguageElementById(elementId, elementUpdateObj): Promise<any> {
    elementUpdateObj.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    elementUpdateObj.translations.nl.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    elementUpdateObj.translations.en.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    delete elementUpdateObj.id; //id does not need to be saved
    return this.afs.collection('languages').doc('elements').collection('elements').doc(elementId).update(elementUpdateObj);

  }

  createLanguageElement(elementCreateObj): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('createLanguageElement');
    return callable({
      secret: 'orangeswereneverapples',
      elementCreateObj,
    });
  }

  deleteLanguageElementById(elementId): Promise<any> {
    return this.afs.collection('languages').doc('elements').collection('elements').doc(elementId).delete();
  }

  getLanguageJSON(languageCode): Observable<any> {
    return this.afs.collection('languages').doc(languageCode).get();
  }

  getAllUnarchivedTaskRegnsForPresence(presence: any): Observable<any> {
    return this.afs.collection('clients').doc(presence.clientId).collection('presences').doc(presence.id).collection('registrations', (ref) =>
      ref.where('isArchived', '==', false)
    ).valueChanges({idField: 'id'});
  }

  async getAllTaskRegnsForPresence(presence: any) {
    const collectionRef = collection(this.firestore, `clients/${presence.clientId}/presences/${presence.id}/registrations`);
    return await getDocs(collectionRef);
  }

  async archiveAllTaskRegnsNPresence(taskRegns: any[], presenceId: any, clientId: string): Promise<any> {
    for (const taskRegn of taskRegns) {
      if (taskRegn.id) {
        await this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').doc(taskRegn.id).update({
          isArchived: true,
          updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
    }
    return await this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).update({
      isArchived: true,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  async unarchiveAllTaskRegnsNPresence(presenceDoc: any, clientId: string): Promise<any> {
    const taskRegnsQS = await this.getAllTaskRegnsForPresence(presenceDoc);
    const batch = this.afs.firestore.batch();
    for (const taskRegnDS of taskRegnsQS.docs) {
      batch.update(this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceDoc.id).collection('registrations').doc(taskRegnDS.id).ref, {
        isArchived: false,
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
    }
    await batch.commit();

    return await this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceDoc.id).update({
      isArchived: false,
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  async deleteRegn(regnId: string, presenceId: string, clientId: string): Promise<any> {
    return await this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').doc(regnId).delete();
  }

  async createRegn(regn: any, presenceId: string, clientId: string): Promise<any> {
    regn.creationTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    regn.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return await this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).collection('registrations').add(regn);
  }

  updatePresenceByIdForClientId(updatedData: any, clientId: string, presenceId: string): Promise<any> {
    updatedData.updatedTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.collection('clients').doc(clientId).collection('presences').doc(presenceId).update(updatedData);
  }

  async createPresenceDoc(date: Date | null, userDocData: any, worker: any, startTime: Date, endTime: Date, location, client): Promise<any> {
    let newPresenceRef;

    if (!client.isPresenceLocation) {
      newPresenceRef = this.afs.collection('clients').doc(userDocData.associatedWorkerClientId).collection('presences')
      .doc(worker.id + '_'+ moment(date).tz(TIME_ZONE).format('YYYYMMDD'));
    } else {
      newPresenceRef = this.afs.collection('clients').doc(userDocData.associatedWorkerClientId).collection('presences')
      .doc(location.id + '_' + worker.id + '_' + moment(date).tz(TIME_ZONE).format('YYYYMMDD'));
    }
    await newPresenceRef.set({
        workerId: worker.id,
        workerName: worker.name ?? '',
        workerGroupId: worker.workerGroupId ?? null,
        workerGroupName: worker.workerGroupName ?? null,
        deviceId: 'browser',
        deviceType: 'browser-dashboard',
        locationId: location.id,
        locationName: location.name,
        isArchived: false,
        clientId: client.id,
        clientName: client.name,
        startTimestamp: startTime,
        endTimestamp: endTime,
        date,
        createdByUserId: userDocData.id ?? null,
        createdByUserName: userDocData.name ?? null,
        creationTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
        updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
      },
      {
        merge: true
      });
    return newPresenceRef.ref;
  }

  getPresenceDocForDate(date: Date | null, workerId: string, clientId: string, locationId: string = null): Observable<any> {
    const fromMoment = Timestamp.fromDate(moment(date).startOf('day').toDate());
    const toMoment = Timestamp.fromDate(moment(date).endOf('day').toDate());
    if (locationId) {
      return this.afs.collection('clients').doc(clientId).collection('presences', (ref) =>
        ref.where('workerId', '==', workerId).where('startTimestamp', '>=', fromMoment).where('startTimestamp', '<', toMoment)
        .where('locationId', '==', locationId).orderBy('startTimestamp', 'asc').limit(1)).valueChanges({idField: 'id'});
    }
    return this.afs.collection('clients').doc(clientId).collection('presences', (ref) =>
      ref.where('workerId', '==', workerId).where('startTimestamp', '>=', fromMoment).where('startTimestamp', '<', toMoment).orderBy('startTimestamp', 'asc').limit(1)
    ).valueChanges({idField: 'id'});
  }

  getPresenceDocForDateForRegns(date: Date | null, workerId: string, clientId: string): Observable<any> {
    const fromMoment = Timestamp.fromDate(moment(date).startOf('day').toDate());
    const toMoment = Timestamp.fromDate(moment(date).endOf('day').toDate());
    return this.afs.collection('clients').doc(clientId).collection('presences', (ref) =>
      ref.where('workerId', '==', workerId).where('startTimestamp', '>=', fromMoment).where('startTimestamp', '<', toMoment).where('isArchived', '==', false).orderBy('startTimestamp', 'asc').limit(1)
    ).valueChanges({idField: 'id'});
  }

  addPresenceDoc(date: Date | null, userDocData: any, clientId: string, worker: any, startTimestamp: Date, location: any): Promise<any> {
    return this.afs.collection('clients').doc(clientId).collection('presences').add({
      workerId: worker.id,
      workerName: worker.name ?? '',
      workerGroupId: worker.workerGroupId ?? null,
      workerGroupName: worker.workerGroupName ?? null,
      deviceType: 'browser-dashboard',
      locationId: location?.id ?? null,
      locationName: location?.name ?? null,
      isArchived: false,
      clientId,
      //clientName: this.clientWorker.client.name,
      startTimestamp,
      //date,
      createdByUserId: userDocData.id ?? null,
      createdByUserName: userDocData.name ?? null,
      creationTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
      updatedTimestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  convertTrainingToSession(trainingId): Observable<any> {
    const callable = this.angularFireFunctions.httpsCallable('convertTrainingToSession');
    return callable({
      secret: 'orangeswereneverapples',
      trainingId,
    });
  }

  getAPILogsForClientId(clientId: string, dateToQuery: Date): Observable<any> {
    const fromTimestamp = Timestamp.fromDate(moment(dateToQuery).startOf('day').toDate());
    const toTimestamp = Timestamp.fromDate(moment(dateToQuery).endOf('day').toDate());
    return this.afs.collection('clients').doc(clientId).collection('requests',
      (ref) => ref.where('timestampFS', '>=', fromTimestamp).where('timestampFS', '<=', toTimestamp).orderBy('timestampFS', 'desc')).valueChanges({idField: 'id'});
  }

  getAllAnnttnsStateChanges(trainingId: any): Observable<any> {
    return this.afs.collection('trainings').doc(trainingId).collection('annotations').stateChanges().pipe(
      map(actions => {
        //console.log('actions:' + actions.map(ac => ac.type));

        return actions.filter(a => a.payload.doc.metadata.hasPendingWrites).map((a: any) => {
          //console.log('pending writes:' + JSON.stringify(a.payload.doc.metadata.hasPendingWrites));

          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return {
            id,
            changeType: a.type,
            ...data
          };
        });
      })
    );
  }


}
