<div class="modal-width">
  <h2 mat-dialog-title>{{'SETTINGS.USERS.CREATEUSER.TITLE.TEXT' | translate}}</h2>

  <mat-dialog-content [formGroup]="form" *ngIf="rolePermissionMapFromDB" >
    <div class="row">
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.EMAIL.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="email">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NOTES.LABEL.TEXT' | translate}}</mat-label>
          <textarea matInput formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.ASSOCIATEDWORKER.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="associatedWorkerId">
            <mat-option *ngFor="let worker of allWorkersList" [value]="worker.id">{{ worker.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'SETTINGS.MODAL.LANGUAGE.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="languageCode">
            <mat-option *ngFor="let language of languagesList" [value]="language.code">{{ language.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div style="font-size: 1.4rem; font-weight: 500; margin-bottom: 1rem; margin-left: 1rem;margin-top: 0.5rem;">Permissions</div>
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.ROLE.LABEL.TEXT' | translate }}</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let role of roles" [value]="role">{{ roleDisplayedValueMap.get(role) }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div formGroupName="permissions"  class="permissions-wrapper">
      <div formGroupName="presences" class="row">
        <div style="font-size: 1.2rem; font-weight: 400;margin-bottom: 1rem; margin-left: 1rem;">Presences</div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.TODAY.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="today" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.PRESENCES.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="presences" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="download">Download</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
              <mat-option value="updateOwn">Update Own</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="labor" class="row">
        <div style="font-size: 1.2rem; font-weight: 400;margin-bottom: 1rem; margin-left: 1rem;margin-top: 1rem;">Labor</div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.TASKSOVERVIEW.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="tasksOverview" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.OVERVIEW.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="overview" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.PERFORMANCE.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="performance" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.PRODUCTIVITY.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="productivity" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.TASKREGNS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="taskRegns" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="download">Download</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SESSIONS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="sessions" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="download">Download</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
              <mat-option value="convert">Convert</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="productivity" class="row">
        <div style="font-size: 1.2rem; font-weight: 400;margin-bottom: 1rem; margin-left: 1rem;margin-top: 1rem;">Productivity</div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.ROWMAP.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="rowMap" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.TROLLEY.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="trolley" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.ROWPRDCTVTY.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="rowPrdctvty" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.VARIETYPRDCTVTY.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="varietyPrdctvty" multiple>
              <mat-option value="read">Read</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="selectedClientDocData?.rolePermissionsMap?.name === 'developer' || selectedClientDocData?.rolePermissionsMap?.isSuperUser">
        <div style="font-size: 1.2rem; font-weight: 400;margin-bottom: 1rem; margin-left: 1rem;margin-top: 1rem;">Training</div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.TRAININGOVERVIEW.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="training" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="download">Download</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div formGroupName="settings" class="row">
        <div style="font-size: 1.2rem; font-weight: 400;margin-bottom: 1rem; margin-left: 1rem;margin-top: 1rem;">Settings</div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.GENERAL.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="general" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.USERPROFILE.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="userProfile" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.LOCATIONS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="locations" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.USERS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="users" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.WORKERS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="workers" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.SESSIONS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="sessions" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.PRESENCES.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="presences" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.POSITIONS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="positions" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.ASSETS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="assets" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.BEACONS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="beacons" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.VARIETIES.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="varieties" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.TASKS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="tasks" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.DEVICES.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="devices" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.APIS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="apis" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row " *ngIf="selectedClientDocData?.rolePermissionsMap?.name === 'developer' || selectedClientDocData?.rolePermissionsMap?.isSuperUser">
        <div style="font-size: 1.2rem; font-weight: 400;margin-bottom: 1rem; margin-left: 1rem;margin-top: 1rem;">Language</div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.LANGUAGE.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="language" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="selectedClientDocData?.rolePermissionsMap?.name === 'developer' || selectedClientDocData?.rolePermissionsMap?.isSuperUser">
        <div style="font-size: 1.2rem; font-weight: 400;margin-bottom: 1rem; margin-left: 1rem;margin-top: 1rem;">Clients</div>
        <div class="col-lg-12 pb-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ 'SETTINGS.MODAL.PERM.SETTINGS.CLIENTS.LABEL.TEXT' | translate }}</mat-label>
            <mat-select formControlName="clients" multiple>
              <mat-option value="read">Read</mat-option>
              <mat-option value="update">Update</mat-option>
              <mat-option value="create">Create</mat-option>
              <mat-option value="delete">Delete</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div >
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="createUser()" [disabled]="creatingUser" style="margin-left: 20px;">{{'SETTINGS.USERS.CREATEUSER.TITLE.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
