export function getPermissions(pageLevel1: string, pageLevel2 = null, permissionMap: any): any {
  const permissionFlags: any = {};

  if (permissionMap.isSuperUser) {
    permissionFlags.canCreate = true;
    permissionFlags.canRead = true;
    permissionFlags.canUpdate = true;
    permissionFlags.canDelete = true;
    permissionFlags.canDownload = true;
    permissionFlags.canFix = true;
    permissionFlags.canConvert = true;
    return permissionFlags;
  }

  let specificPermissionMap = permissionMap[pageLevel1];

  if (pageLevel2) {
    specificPermissionMap = specificPermissionMap[pageLevel2];
  }

  if (specificPermissionMap?.includes('create')) {
    permissionFlags.canCreate = true;
  }

  if (specificPermissionMap?.includes('read')) {
    permissionFlags.canRead = true;
  }

  if (specificPermissionMap?.includes('update')) {
    permissionFlags.canUpdate = true;
  }

  if (specificPermissionMap?.includes('delete')) {
    permissionFlags.canDelete = true;
  }

  if (specificPermissionMap?.includes('download')) {
    permissionFlags.canDownload = true;
  }

  if (specificPermissionMap?.includes('convert')) {
    permissionFlags.canConvert = true;
  }
  return permissionFlags;
}

export function determineSettingsAccess(rolePermissionsMap) {
  if (rolePermissionsMap) {
    for (const key of Object.keys(rolePermissionsMap.settings)) {
      if (rolePermissionsMap.settings[key].length > 0) {
        return true;
      }
    }
  }
  return false;
}

export function determineUserProfileAccess(rolePermissionsMap) {
  return rolePermissionsMap?.settings?.userProfile?.length > 0;
}

const roleStrength = new Map();
roleStrength.set('regular', 1);
roleStrength.set('admin', 2);
roleStrength.set('owner', 3);
roleStrength.set('developer', 4);

export {
  roleStrength
};
