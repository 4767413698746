import {AfterViewInit, Component, DestroyRef, OnDestroy, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {BeaconMainAttributes} from '../../common/interfaces/asset-interfaces';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../services/firestore.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ClientInContextService} from '../../services/client-in-context.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BreakpointService} from '../../services/breakpoint.service';
import {ConfirmationDialogComponent} from '../utility/confirmation-dialog/confirmation-dialog.component';
import {SNACKBAR_CLASSES} from '../../common/utils/utils';
import {Timestamp} from 'firebase/firestore';
import {EditBeaconDialogComponent} from './edit-beacon-dialog/edit-beacon-dialog.component';
import {CreateBeaconDialogComponent} from './create-beacon-dialog/create-beacon-dialog.component';
import {NgxMaskPipe} from 'ngx-mask';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {UploadBeaconsDialogComponent} from "./upload-beacons-dialog/upload-beacons-dialog.component";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FilterProcessingService} from 'src/app/services/filter-processing.service';
import {getPermissions} from '../../common/utils/permission-utils';

@Component({
  selector: 'app-client-beacons',
  templateUrl: './client-beacons.component.html',
  styleUrls: ['./client-beacons.component.scss',
    '../../common/styles/listing.scss']
})
export class ClientBeaconsComponent implements OnDestroy, AfterViewInit {
  unArchivedVsArchived = 'unarchived';

  screenSize = 'default';

  columnsToDisplay: any[] = [
    {
      name: 'name',
      displayName: 'SETTINGS.TABLEHEAD.NAME.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'displayedMac',
      displayName: 'SETTINGS.TABLEHEAD.MACADDR.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'displayedType',
      displayName: 'SETTINGS.TABLEHEAD.TYPE.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'assetType',
      displayName: 'SETTINGS.TABLEHEAD.ASSETTYPE.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'assignedName',
      displayName: 'SETTINGS.TABLEHEAD.ASSGNDNAME.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'assetNumber',
      displayName: 'SETTINGS.TABLEHEAD.NUMBER.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'stickDuration',
      displayName: 'SETTINGS.TABLEHEAD.STICKDUR.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'rssiThreshold',
      displayName: 'SETTINGS.TABLEHEAD.RSSITHRSHLD.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'beaconType',
      displayName: 'SETTINGS.MODAL.BEACONTYPE.LABEL.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'batteryLevel',
      displayName: 'SETTINGS.TABLEHEAD.BATTERYLEFT.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
    {
      name: 'creationTimestamp',
      displayName: 'SETTINGS.TABLEHEAD.CREATEDAT.TITLE.TEXT',
      showHeader: true,
      showHeaderFilter: true,
      showTopFilter: false,
      filterValue: '',
      filterOptions: [],
      filtered: false,
      showInFooter: true,
      footerType: null,
      footerValue: '',
    },
  ];
  displayedTopFilters: any[] = this.columnsToDisplay.filter(
    (column) => column.showTopFilter
  );
  columnsHeadersToDisplay: string[] = [
    'select', 'name', 'displayedMac', 'displayedType', 'assetType', 'assignedName', 'assetNumber', 'stickDuration', 'rssiThreshold','beaconType', 'batteryLevel', 'creationTimestamp', 'edit'
  ];
  dataSource: MatTableDataSource<BeaconMainAttributes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  beaconsList: any[];
  dateColumns: string[] = ['creationTimestamp'];
  datePipe = new DatePipe('en-US');
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  unarchivedBeaconsSubscription: Subscription;
  archivedBeaconsSubscription: Subscription;
  breakpointSubscription: Subscription;
  filterValue: string;
  selectAll = false;
  maskPipe = new NgxMaskPipe()
  beingDeleted = false;
  beingArchived = false;
  beingResetAssigned = false;
  selectedRessetableBeaconsCount = 0;
  perms: any;

  constructor(public firestoreService: FirestoreService,
              public authService: AuthService,
              public route: ActivatedRoute,
              private dialog: MatDialog,
              private clientInContextService: ClientInContextService,
              private snackBar: MatSnackBar,
              private breakpointService: BreakpointService,
              private auth: AngularFireAuth,
              private destroyRef: DestroyRef,private filterProcessService:FilterProcessingService) {
    this.breakpointSubscription = this.breakpointService.screenSize$.pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(screenSize => this.screenSize = screenSize);

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((userDocData) => {
        this.loggedInUserDocData = userDocData;
    });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      if (this.selectedClientDocData.rolePermissionsMap) {
        this.perms = getPermissions('settings', 'beacons', selectedClientDocData.rolePermissionsMap);
      }
      this.fetchUnarchivedBeacons();
    });
  }

  ngAfterViewInit() {
  }


  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateBeaconDialogComponent, dialogConfig);
  }

  openEditDialog(record) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      beaconRecord: record
    };

    this.dialog.open(EditBeaconDialogComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.archivedBeaconsSubscription?.unsubscribe();
    this.unarchivedBeaconsSubscription?.unsubscribe();
    this.breakpointSubscription?.unsubscribe();
  }

  async archiveBeacon(beacon) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive beacon '${beacon?.name ?? ''}' ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        beacon
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        try {
          await this.firestoreService.updateBeaconByIdForClientId(value?.beacon?.id, this.selectedClientDocData?.id, {isArchived: true});
          this.openSnackBar(`Beacon '${value?.beacon?.name}' archived successfully`, 'success');
        } catch (error) {
          this.openSnackBar('Error in beacon archival: ' + error.message, 'error');
          console.log(error.message);
        }
      }
    });
  }

  async unarchiveBeacon(beacon) {
    try {
      await this.firestoreService.updateBeaconByIdForClientId(beacon?.id, this.selectedClientDocData?.id, {isArchived: false});
      this.openSnackBar(`Beacon '${beacon?.name}' unarchived successfully`, 'success');
    } catch (error) {
      this.openSnackBar('Error in beacon archival: ' + error.message, 'error');
      console.log(error.message);
    }
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  toggleArchived() {
    if (this.areUnArchivedBeaconsShown()) {
      this.fetchUnarchivedBeacons();
    } else {
      this.fetchAllArchivedBeacons();
    }
  }

  fetchUnarchivedBeacons() {
    this.beaconsList = [];
    this.archivedBeaconsSubscription?.unsubscribe();
    this.unarchivedBeaconsSubscription = this.firestoreService.getAllUnarchivedBeaconsForClientId(this.selectedClientDocData.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((beaconsList) => {
      this.beaconsList = beaconsList.map((beacon) => {
        for (const [key, value] of Object.entries(beacon)) {
          if (key === 'type') {
            switch (beacon.type) {
              case 'ASSET':
                beacon.displayedType = 'Asset';
                break;
              case 'POSITION':
                beacon.displayedType = 'Position';
                break;
              case 'ROW':
                beacon.displayedType = 'Row';
                break;
              case 'LOCATION':
                beacon.displayedType = 'Location';
                break;
              case 'TASK':
                beacon.displayedType = 'Task';
                break;
              case 'WORKER':
                beacon.displayedType = 'Worker';
                break;
            }
          }
          if (key === 'mac' && beacon.mac) {
            beacon.displayedMac = this.maskPipe.transform(beacon.mac, 'AA:AA:AA:AA:AA:AA');
          }
          if (this.dateColumns.includes(key)) {
            const timeValue = value as Timestamp;
            beacon[key] = this.datePipe.transform(
              timeValue.toMillis(),
              'yyyy-MM-dd HH:mm'
            );
          }
        }
        if (beacon.lastBatteryPercentage) {
          beacon.batteryLevel = this.getBatteryLevelPercentage(beacon.lastBatteryPercentage);
        } else {
          beacon.batteryLevel = null;
        }
        return beacon;
      });

      this.beaconsList.sort((beaconA: any, beaconB: any) => {
        return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
      });

      if (this.areArchivedBeaconsShown()) {
        return;
      }

      this.dataSource = new MatTableDataSource(this.beaconsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.filterProcessService.fliterPridecate(this.dataSource);
      this.filterProcessService.postProcessingFilterSort(this.columnsToDisplay, this.dataSource);
    });
  }

  fetchAllArchivedBeacons() {
    this.beaconsList = [];
    this.unarchivedBeaconsSubscription?.unsubscribe();
    this.archivedBeaconsSubscription = this.firestoreService.getAllArchivedBeaconsForClientId(this.selectedClientDocData.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((beaconsList) => {
      this.beaconsList = beaconsList.map((beacon) => {
        for (const [key, value] of Object.entries(beacon)) {
          if (key === 'type') {
            switch (beacon.type) {
              case 'ASSET':
                beacon.displayedType = 'Asset';
                break;
              case 'POSITION':
                beacon.displayedType = 'Position';
                break;
              case 'ROW':
                beacon.displayedType = 'Row';
                break;
              case 'LOCATION':
                beacon.displayedType = 'Location';
                break;
              case 'TASK':
                beacon.displayedType = 'Task';
                break;
              case 'WORKER':
                beacon.displayedType = 'Worker';
                break;
            }
          }
          if (key === 'mac' && beacon.mac) {
            beacon.displayedMac = this.maskPipe.transform(beacon.mac, 'AA:AA:AA:AA:AA:AA');
          }
          if (this.dateColumns.includes(key)) {
            const timeValue = value as Timestamp;
            beacon[key] = this.datePipe.transform(
              timeValue.toMillis(),
              'yyyy-MM-dd HH:mm'
            );
          }
        }
        if (beacon.lastBatteryPercentage) {
          beacon.batteryLevel = this.getBatteryLevelPercentage(beacon.lastBatteryPercentage);
        } else {
          beacon.batteryLevel = null;
        }
        return beacon;
      });

      this.beaconsList.sort((beaconA: any, beaconB: any) => {
        return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
      });

      if (this.areUnArchivedBeaconsShown()) {
        return;
      }

      this.dataSource = new MatTableDataSource(this.beaconsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.filterProcessService.fliterPridecate(this.dataSource);
      this.filterProcessService.postProcessingFilterSort(this.columnsToDisplay, this.dataSource);
    });
  }


  areArchivedBeaconsShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedBeaconsShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }

  uploadBeacons() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      clientId: this.selectedClientDocData.id
    };
    this.dialog.open(UploadBeaconsDialogComponent, dialogConfig);
  }

  getBatteryLevelPercentage(lastBatteryPercentage) {
    return lastBatteryPercentage ? (lastBatteryPercentage * 100).toFixed(0) + '%' : null;
  }

  toggleMultiSelect() {
    this.selectAll = !!this.selectAll;
    if (!this.selectAll) {
      this.beaconsList.forEach(beacon => {
        beacon.isSelected = false;
      });
    } else {
      this.dataSource.filteredData.forEach((beacon: any) => {
        beacon.isSelected = true;
      });
    }
  }

  areMultipleBeaconsSelected() {
    const selectedBeaconsCount = this.beaconsList?.filter(beacon => beacon.isSelected)?.length;
    return (selectedBeaconsCount > 1);
  }

  isArchiveMultipleEnabled() {
    const selectedArchivableBeaconsCount = this.beaconsList?.filter(beacon => beacon.isSelected)
      ?.filter(beacon => !beacon.hasOwnProperty('assignedId') || !beacon.assignedId).length;
    const selectedAssignedBeaconsCount = this.beaconsList?.filter(beacon => beacon.isSelected)
      ?.filter(beacon => beacon.hasOwnProperty('assignedId') && beacon.assignedId) .length;
    return (selectedArchivableBeaconsCount > 1 && selectedAssignedBeaconsCount === 0);
  }

  isResetAssignedMultipleEnabled() {
    this.selectedRessetableBeaconsCount = this.beaconsList?.filter(beacon => beacon.isSelected)
      ?.filter(beacon => beacon.hasOwnProperty('assignedId') && beacon.assignedId).length;
    const selectedNonRessetbleBeaconsCount = this.beaconsList?.filter(beacon => beacon.isSelected)
      ?.filter(beacon => !beacon.hasOwnProperty('assignedId')  || !beacon.assignedId).length;
    return (this.selectedRessetableBeaconsCount > 1);
  }

  determineIndeterminate() {
    const beaconCount = this.beaconsList?.length;
    const selectedBeaconsCount = this.beaconsList?.filter(beacon => beacon.isSelected)?.length;
    if (selectedBeaconsCount === beaconCount || selectedBeaconsCount === 0) {
      return false;
    } else if (selectedBeaconsCount > 0 && selectedBeaconsCount < beaconCount) {
      return true;
    }
  }

  getSelectedBeaconsCount() {
    const selectedCount = this.beaconsList?.filter(beacon => beacon.isSelected).map(beacon => beacon.id).length;
    if (selectedCount <= 1) {
      return '';
    } else {
      return `(${selectedCount})`;
    }
  }

  deleteMultipleBeacons() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to delete ${this.beaconsList?.filter(beacon => beacon.isSelected).map(beacon => beacon.id).length} beacons ?`,
        buttonText: {
          ok: 'Delete',
          cancel: 'Cancel'
        },
        selectedBeaconIds: this.beaconsList?.filter(beacon => beacon.isSelected).map(beacon => beacon.id)
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        this.beingDeleted = true;
        try {
          await this.firestoreService.deleteBeaconsForClientId(this.selectedClientDocData.id,
            this.beaconsList?.filter(beacon => beacon.isSelected).map(beacon => beacon.id));
          this.openSpecificSnackBar('All beacons have been deleted', 'success');
          this.beingDeleted = false;
          this.selectAll = false;
        } catch (error: any) {
          this.beingDeleted = false;
          this.openSpecificSnackBar('Error in deleting beacons:' + error.message, 'error');
        }
      }
    });
  }

  archiveMultipleBeacons() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive ${this.beaconsList?.filter(beacon => beacon.isSelected).map(beacon => beacon.id).length} beacons ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        selectedBeaconIds: this.beaconsList?.filter(beacon => beacon.isSelected).map(beacon => beacon.id)
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        this.beingArchived = true;
        try {
          await this.firestoreService.archiveBeaconsForClientId(this.selectedClientDocData.id,
            this.beaconsList?.filter(beacon => beacon.isSelected).map(beacon => beacon.id));
          this.beingArchived = false;
          this.openSpecificSnackBar('All selected beacons have been archived', 'success');
        } catch (error: any) {
          this.beingArchived = false;
          this.openSpecificSnackBar('Error in archiving beacons:' + error.message, 'error');
        }
      }
    });
  }

  resetAssignmentMultipleBeacons() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to reset assignment for ${this.selectedRessetableBeaconsCount} beacons ?`,
        buttonText: {
          ok: 'Reset Assignment',
          cancel: 'Cancel'
        },
        selectedBeaconIds: this.beaconsList?.filter(beacon => beacon.isSelected).filter(beacon => beacon.hasOwnProperty('assignedId') && beacon.assignedId).map(beacon => beacon.id)
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        this.beingResetAssigned = true;
        try {
          await this.firestoreService.resetAssignmentOfBeaconsForClientId(this.selectedClientDocData.id,
            this.beaconsList?.filter(beacon => beacon.isSelected).filter(beacon => beacon.hasOwnProperty('assignedId') && beacon.assignedId));
          this.beingResetAssigned = false;
          this.selectAll = false;
          this.openSpecificSnackBar(`All selected beacons' assignment has been reset`, 'success');
        } catch (error: any) {
          this.beingResetAssigned = false;
          this.openSpecificSnackBar('Error in resetting assignment of beacons:' + error.message, 'error');
        }
      }
    });
  }

  openSpecificSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  async deleteBeacon(beacon: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to delete beacon named '${beacon.name}' ?`,
        buttonText: {
          ok: 'Delete',
          cancel: 'Cancel'
        }
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        this.beingDeleted = true;
        try {
          await this.firestoreService.deleteBeaconsForClientId(this.selectedClientDocData.id, [beacon.id]);
          this.openSnackBar(`Beacon '${beacon.name}' deleted successfully`, 'success');
          this.beingDeleted = false;
        } catch (error: any) {
          this.beingDeleted = false;
          this.openSnackBar('Error in deleting beacon: ' + error.message, 'error');
        }
      }
    });
  }
}
