<div class="listing-container" *ngIf="perms?.canRead">
  <div class="controls-container">
    <div class="date-container">
      <mat-form-field appearance="outline" class="listing-filter-with-archival">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label style="color: gray">{{'SETTINGS.FILTER.LABEL.TEXT' | translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterValue" placeholder="language" #input />
      </mat-form-field>
    </div>
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
      *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create element" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon>Create new element</button>
  </div>
  <div class="table-card">
    <div class="mat-elevation-z8" style="clear:both;">
      <table
        class="listing-table"
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSort
      >
        <ng-container
          matColumnDef="{{ column }}"
          *ngFor="let column of columnsToDisplay"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ labelAttributesReadableMap.get(column) }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
        </ng-container>

        <ng-container matColumnDef="Edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper">
            <div class="grid-control-column">
              <button
              mat-icon-button
              color="accent"
              (click)="openEditDialog(element)"
              class="listing-table-tools"
              [disabled]="!perms?.canUpdate"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              color="accent"
              (click)="deleteElement(element)"
              class="listing-table-tools"
              [disabled]="!perms?.canDelete"
              >
              <mat-icon matTooltip="Delete element"
                        matTooltipClass="tooltip-class"
                        matTooltipHideDelay="100"
                        matTooltipPosition="below">delete
              </mat-icon>
            </button>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsHeadersToDisplay"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 25, 100,250,500]"
        [pageSize]="100"
        [showFirstLastButtons]="true"
      ></mat-paginator>
    </div>
  </div>

</div>
