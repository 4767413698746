<div style="min-width: 500px;">
  <h2 mat-dialog-title>{{'SETTINGS.LOCATIONS.MODAL.EDITLOCATION.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="editLocationForm" style="text-align: center;">
    <div>
      <div class="row">
        <div class="col-lg-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'SETTINGS.LOCATIONS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
            <input matInput required formControlName="name" />
          </mat-form-field>
        </div>
      </div>
      <h2 class="mt-3">{{'SETTINGS.LOCATIONS.MODAL.ROWREFERENCE.TITLE.TEXT' | translate}}</h2>
      <div class="row">
        <div class="col-lg-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'SETTINGS.LOCATIONS.MODAL.LATITUDE.LABEL.TEXT' | translate}}</mat-label>
            <input type="number" #latitude matInput formControlName="entrancePointLatitude">
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'SETTINGS.LOCATIONS.MODAL.LONGITUDE.LABEL.TEXT' | translate}}</mat-label>
            <input type="number" #longitude matInput formControlName="entrancePointLongitude">
          </mat-form-field>
        </div>
      </div>
      <a class="hint" (click)="openInGoogleMap(latitude.value, longitude.value)">
        {{'SETTINGS.LOCATIONS.MODAL.GOOGLEMAP.BUTTON.TEXT' | translate}}</a>
      <div class="row pb-2">
        <div class="col-lg-12">
          <app-beacon-selection [beaconIds]="editLocationForm.get('beaconIds')"
            [allBeaconsList]="allBeaconsList"></app-beacon-selection>
        </div>
      </div>
      <h2 class="mt-3">{{'SETTINGS.LOCATIONS.MODAL.TROLLEYS.TITLE.TEXT' | translate}}</h2>
      <div class="row">
        <div class="col-lg-12">
          <mat-button-toggle-group [hideSingleSelectionIndicator]="true" formControlName="trolleySelectorMode"
            class="custom-toggle-btn" name="trolleySelectorMode">
            <mat-button-toggle value="RANGE">{{'SETTINGS.LOCATIONS.MODAL.RANGE.BUTTON.TEXT' |
              translate}}</mat-button-toggle>
            <mat-button-toggle value="ASSETS">{{'SETTINGS.LOCATIONS.MODAL.ASSETS.BUTTON.TEXT' |
              translate}}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-6">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'SETTINGS.LOCATIONS.MODAL.START.LABEL.TEXT' | translate}}</mat-label>
            <input type="number" matInput formControlName="trolleysStart">
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SETTINGS.LOCATIONS.MODAL.NAME.LAST.TEXT' | translate}}</mat-label>
            <input type="number" matInput formControlName="trolleysEnd">
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'SETTINGS.MODAL.EXTERNALID.LABEL.TEXT' | translate}}</mat-label>
            <input matInput formControlName="externalId" />
          </mat-form-field>
        </div>
      </div>
      
    </div>
  </mat-dialog-content>
  <br />
  <div style="text-align: right; margin-bottom: 0.8rem;" class="footer-with-Id">
    <div class="col-lg-12 d-flex copyEntityId">
      <input matInput [(ngModel)]="locationId" readonly #copyTarget >
      <button mat-icon-button appCopySuccess [iconTarget]="icon" color="primary" class="copy-inside-input"
        [ngxClipboard]="copyTarget">
        <mat-icon class="material-icons-outlined m-0" #icon>content_copy</mat-icon>
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CANCEL.BUTTON.TEXT' |
        translate}}</button>
      <button mat-raised-button color="primary" (click)="updateLocation()" style="margin-left: 20px;">
        {{'SETTINGS.LOCATIONS.MODAL.UPDATELOCATION.BUTTON.TEXT' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
