<div class="video-translation-modal" >
  <mat-dialog-content class="p-0">

    <youtube-player class="h-100 w-100 youtube-main-w d-block" [videoId]="videoURL"/>
  </mat-dialog-content>
  <div>
    <mat-dialog-actions class="w-100">
      <button mat-icon-button (click)="copyVideoLink()" title="copy video link">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>