import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { FirestoreService } from "../../../services/firestore.service";
import { ClientInContextService } from "../../../services/client-in-context.service";
import { AuthService } from "../../../services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { SNACKBAR_CLASSES } from "../../../common/utils/utils";
import { CreateLabelDialogComponent } from '../../client-labels/create-label-dialog/create-label-dialog.component';

@Component({
  selector: 'app-edit-variety-dialog',
  templateUrl: './edit-variety-dialog.component.html',
  styleUrls: ['./edit-variety-dialog.component.scss']
})
export class EditVarietyDialogComponent implements OnInit, OnDestroy {
  editVarietyForm: UntypedFormGroup;

  varietyRecordBeingEdited: any;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  labelsList: any[];
  unarchivedLabelsSubscription: Subscription;
  locationListSubscription: Subscription;
  allLocationsList: any[];

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditVarietyDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.varietyRecordBeingEdited = data.varietyRecord;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.locationListSubscription = this.firestoreService
      .getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id)
      .subscribe((locationsList) => {
        this.allLocationsList = locationsList.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        });
      });
    });
  }

  ngOnInit() {
    this.editVarietyForm = this.fb.group({
      name: [this.varietyRecordBeingEdited.name, [Validators.required]],
      labelIds: [this.varietyRecordBeingEdited.labelIds, []],
      assignedLocationIds: [this.varietyRecordBeingEdited.assignedLocationIds, []],
      performanceNorm: [this.varietyRecordBeingEdited.performanceNorm ?? null, []],
      externalId: [this.varietyRecordBeingEdited.externalId, []],
    });

    this.fetchAllLabels();
  }

  async updateVariety() {
    if (!this.editVarietyForm.valid) {
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    if (this.editVarietyForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    if (this.editVarietyForm?.value?.name && this.editVarietyForm?.value?.name?.trim() === '') {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    const varietyDataToUpdate = this.editVarietyForm.value;
    varietyDataToUpdate.name = varietyDataToUpdate.name?.trim();
    varietyDataToUpdate.externalId = varietyDataToUpdate.externalId?.trim() ?? null;

    varietyDataToUpdate.performanceNorm = varietyDataToUpdate.performanceNorm? +varietyDataToUpdate.performanceNorm : null;

    if (varietyDataToUpdate.labelIds && (varietyDataToUpdate.labelIds.length > 0)) {
      varietyDataToUpdate.labels = this.labelsList.filter(label => varietyDataToUpdate.labelIds.includes(label.id)).map(label => label.name);
    } else {
      varietyDataToUpdate.labelIds = null;
      varietyDataToUpdate.labels = null;
    }

    try {
      await this.firestoreService
        .updateVarietyByIdForClientId(this.varietyRecordBeingEdited.id, this.selectedClientDocData.id, varietyDataToUpdate);
      this.openSnackBar('Changes have been saved', 'success');
      this.editVarietyForm.markAsPristine();
      this.dialogRef.close();
    } catch (error) {
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  openAddLabel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateLabelDialogComponent, dialogConfig);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.unarchivedLabelsSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  fetchAllLabels() {
    this.labelsList = [];
    this.unarchivedLabelsSubscription = this.firestoreService
    .getAllUnarchivedLabelsForClientId(this.selectedClientDocData.id)
    .subscribe((labelsList) => {
      this.labelsList = labelsList?.sort((labelA: any, labelB: any) => {
        return labelA.name?.toLowerCase() < labelB.name?.toLowerCase() ? -1 : labelA.name?.toLowerCase() > labelB.name?.toLowerCase() ? 1 : 0;
      });
    });
  }
}

