<div class="page-top-space" *ngIf="perms?.canRead">
  <mat-card class="mat-mdc-card mdc-card page-main-card">
    <mat-icon [matTooltip]="getTooltipForStreamingButton()" class="video-streaming" [inline]="true"
      (click)="toggleStreaming()" *ngIf="tableData?.length > 0">
      {{displayedIcon}}
    </mat-icon>
    <section class="new-table-design-container mat-elevation-z8 " tabindex="0">
      <div class="controls-container ">
        <div class="filter-container w-100 sm-grid-areas">
          <button mat-flat-button color="primary" class="add-area" (click)="openCreateSessionDialog()"
            *ngIf="perms?.canCreate">
            <mat-icon matTooltip="Create Session" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
              add
            </mat-icon>{{'DASHBOARD.SESSIONS.CREATE.BUTTON.TEXT' | translate}} </button>
          <div class="date-container justify-content-center date-area">
            <app-date-selection></app-date-selection>
          </div>

          <button mat-icon-button class="download-area hide-sm" aria-label="" (click)="downloadAsCSV('Sessions')"
            matTooltip="Download data">
            <mat-icon>download</mat-icon>
          </button>
          <button mat-icon-button class="filter-area hide-sm" [matMenuTriggerFor]="filterMenu"
            aria-label="Example icon-button with a menu">
            <mat-icon>filter_alt</mat-icon>
          </button>

          <mat-menu #filterMenu="matMenu" class="customize">
            <app-table-filter [isTopFilter]="true" [columns]="columns" [displayedTopFilters]="displayedTopFilters"
              [dataSource]="dataSource"></app-table-filter>
          </mat-menu>
          <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="archive-toggle toggle-area"
            #group="matButtonToggleGroup">
            <mat-button-toggle value="listView" (click)="toggleArchived(false)" checked matTooltip="All items">
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="archiveView" (click)="toggleArchived(true);" matTooltip="Archived items">
              <mat-icon>archive</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="filter-string" *ngIf="filterString">{{filterString}}</div>
    </section>

    <div class="table-card table-mobile-res">
      <section class="new-table-design-container expandable-table mat-elevation-z8 table-to-card-mb" tabindex="0">
        <app-common-table (onFixSession)="fixSession($event)" (onCopyAsTraining)="copyAsTraining($event)" [isRowExpandable]="true" [columnsHeadersToDisplay]="getDisplayedCols()" [columnsToDisplay]="columns" [gridList]="tableData" [tableName]="'sessionTable'" (editDialogOpened)="editSession($event)" (onArchiveRow)="archiveSession($event)" (onUnArchiveRow)="unarchiveSession($event)" [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'session'">

        </app-common-table>
      </section>

    </div>
  </mat-card>



</div>
