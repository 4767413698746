<div class="w-100">
  <div appearance="outlined">
    <div style="display: flex; flex-direction: column;">
      <div class="d-flex justify-content-between my-3">
        <button mat-flat-button *ngIf="areUnArchivedAPIKeysShown() && perms?.canCreate" color="primary" (click)="createNewAPIKey()">
          <mat-icon matTooltip="Add new API key" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
            playlist_add
          </mat-icon>
          {{ 'SETTINGS.APIS.APIKEYS.ADDNEW.BUTTON.TEXT' | translate }}
        </button>
        <span class="f-grow"></span>
        <button mat-stroked-button color="primary" (click)="openApiLogs()" class="setting-add-button mr-3">{{'SETTINGS.APIS.TABS.APILOGS.LABEL.TEXT' | translate}}</button>
        <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
          [(ngModel)]="unArchivedVsArchived">
          <mat-button-toggle value="unarchived" aria-label="Text align left">
            <mat-icon matTooltip="Show API keys" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
              matTooltipPosition="below">list
            </mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="archived" aria-label="Text align center">
            <mat-icon matTooltip="Show archived API keys" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
              matTooltipPosition="below">archive
            </mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div>
      <div>
        <span *ngIf="areArchivedAPIKeysShown()">{{ 'SETTINGS.APIS.ARCHIVED.TITLE.TEXT' | translate }}</span>
      </div>
      <div *ngIf="getAPIKeysToDisplay().length > 0">
        <div>
          <div class="mat-elevation-z8" *ngFor="let apiKey of getAPIKeysToDisplay(); let i = index">
            <div appearance="outlined" class="outline-card">
              <div class="outline-card-content">
                <div class="card-wrapper">
                  <div class="card-form">
                    <div class="apiKeyFields-wrapper">
                      <div class="card-action">
                        <mat-slide-toggle [(ngModel)]="apiKey.getTransactional">
                          <mat-label class="mb-0"> {{ 'SETTINGS.APIS.GETTXNL.LABEL.TEXT' | translate }}</mat-label>
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="apiKey.getMaster">
                          <mat-label> {{ 'SETTINGS.APIS.GETMSTR.LABEL.TEXT' | translate }}</mat-label>
                        </mat-slide-toggle>
                        <mat-slide-toggle [(ngModel)]="apiKey.putMaster">
                          <mat-label> {{ 'SETTINGS.APIS.PUTMSTR.LABEL.TEXT' | translate }}</mat-label>
                        </mat-slide-toggle>
                        <mat-form-field appearance="outline" style="max-width: 8.5rem;margin-left: 1rem;">
                          <mat-label>{{ 'SETTINGS.APIS.SERVICE.LABEL.TEXT' | translate }}</mat-label>
                          <input matInput [(ngModel)]="apiKey.service">
                        </mat-form-field>
                        <div class="position-relative f-grow">
                          <mat-form-field appearance="outline" class="api-input">
                            <mat-label>{{ 'SETTINGS.APIS.APIKEY.LABEL.TEXT' | translate }}</mat-label>
                            <input matInput [(ngModel)]="apiKey.key" readonly #inputTarget>
                          </mat-form-field>
                          <button mat-raised-button (cbOnSuccess)="onCopySucess('row_'+i)" color="primary"
                            class="copy-inside-input" [ngxClipboard]="inputTarget">
                            <mat-icon class="material-icons-outlined m-0" *ngIf="isCopied !== 'row_'+i">content_copy</mat-icon>
                            <mat-icon class="material-icons-outlined m-0" *ngIf="isCopied === 'row_'+i">check</mat-icon>
                          </button>
                        </div>
                        <!-- <button mat-icon-button [ngxClipboard]="inputTarget">
                          <mat-icon class="material-icons-outlined">content_copy</mat-icon>
                        </button> -->


                      </div>
                    </div>
                  </div>
                  <div>
                    <button mat-icon-button color="accent" (click)="archiveAPIKey($event, apiKey, true)"
                      matTooltip="Archive API key" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below" *ngIf="!apiKey.isArchived && perms?.canDelete">
                      <mat-icon>archive</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" (click)="archiveAPIKey($event, apiKey, false)"
                      matTooltip="Unarchive API key" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below" *ngIf="apiKey.isArchived && perms?.canDelete">
                      <mat-icon>unarchive</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-left: 16px; display:flex; flex-direction: column;" *ngIf="getAPIKeysToDisplay().length === 0">
        <div style="min-width: 95%;">
          <div
            style="margin-left: 35%; margin-top: 1.5rem; margin-bottom: 2px; display:flex; flex-direction: column;justify-content: space-around;">
            <span *ngIf="areArchivedAPIKeysShown()">{{'SETTINGS.APIS.NOARCHIVED.TEXTCONTENT.TEXT' | translate}}</span>
            <span *ngIf="areUnArchivedAPIKeysShown()">{{'SETTINGS.APIS.NOUNARCHIVED.CONTENT.TEXT' | translate}}</span>
          </div>
        </div>

      </div>

    </div>
    <mat-card-actions align="end" class="px-0">
      <button mat-stroked-button (click)="cancelClicked()" color="primary" style="margin-right: 2rem;">{{
        'SETTINGS.CANCEL.BUTTON.TEXT' | translate }}
      </button>
      <button (click)="saveAPIKeys()" mat-raised-button color="primary">{{ 'SETTINGS.SAVE.BUTTON.TEXT' | translate }}
      </button>
    </mat-card-actions>
  </div>
</div>
