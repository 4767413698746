import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'macAddressFromat'
})
export class MacAddressFromatPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let strValue = value?.toString();
  
      // Pad the string with leading zeros if it's not long enough
      while (strValue.length < 10) {
        strValue = '0' + strValue;
      }
  
      // Split the string into chunks of two characters
      const parts = strValue.match(/.{1,2}/g);
  
      // Join the parts with colons
      return parts.join(':');
  }

}
