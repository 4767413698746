import * as moment from 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);

export const TIME_FORMAT = '*HH:mm';
export const TIME_ZONE = 'Europe/Amsterdam';

export function time(timeStr: string | null): moment.Moment {
  return moment(timeStr, TIME_FORMAT);
}

export function dateIsToday(date: moment.Moment): boolean {
  return date.isSame(new Date(), 'day');
}


export function timeAddDate(date: Date |null, timeStr: string|null): Date {
  const t = moment(timeStr, TIME_FORMAT);
  return moment(date?.toLocaleString())
    .tz(TIME_ZONE)
    .hours(t.hours())
    .minutes(t.minutes())
    .seconds(0)
    .millisecond(0)
    .toDate();
}

export function timeAsSeconds(timeStr: string): number {
  return moment.duration(timeStr).as('seconds');
}

export const DATE_FORMATS = {
  parse: {
    dateInput: ['l', 'LL'],
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function roundTimeValue(momentORFirestoreTimestamp, roundingContainerDoc) {
  const timeValue = moment(momentORFirestoreTimestamp.toDate()).tz(TIME_ZONE).format('HH:mm');

  let roundedTimeValue;
  let roundingDown = 0;
  let roundingUp = 0;
  const roundingInterval = roundingContainerDoc.roundingInterval;
  const rounding = roundingContainerDoc.rounding;
  const momentTimeValue = moment(timeValue, 'HH:mm');

  if (momentTimeValue.isSameOrBefore(moment('12:00', 'HH:mm'))) {
    roundingDown = rounding.roundingDown0;
    roundingUp = rounding.roundingUp0;
  } else {
    roundingDown = rounding.roundingDown12;
    roundingUp = rounding.roundingUp12;
  }

  // Calculate the nearest multiple of roundingInterval less than the input time
  const intervalStart = momentTimeValue.clone()
  .startOf('day').add(Math.floor(momentTimeValue.diff(momentTimeValue.clone().startOf('day'), 'minutes') / roundingInterval) * roundingInterval, 'minutes');

  // Calculate the nearest multiple of roundingInterval greater than the input time
  const intervalEnd = momentTimeValue.clone()
  .startOf('day').add(Math.ceil(momentTimeValue.diff(momentTimeValue.clone().startOf('day'), 'minutes') / roundingInterval) * roundingInterval, 'minutes');


  // Calculate the distance to the interval boundaries
  const distanceToIntervalStart = momentTimeValue.diff(intervalStart, 'minutes');
  const distanceToIntervalEnd = momentTimeValue.diff(intervalEnd, 'minutes');

  const needsRoundingUp = Math.abs(distanceToIntervalEnd) < roundingUp;

  // Determine whether to round up or down based on the distance to the boundaries
  if (!needsRoundingUp) {
    // Round down to the previous interval block boundary
    roundedTimeValue = moment(intervalStart.toDate()).format('HH:mm');
  } else {
    // Round up to the next interval block boundary
    roundedTimeValue = moment(intervalEnd.toDate()).format('HH:mm');
  }
  return roundedTimeValue;
}
