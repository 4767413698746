<mat-form-field class="w-100" [ngClass]="{'custom-height-field': isFromGrid}">
  <mat-label> {{ 'SETTINGS.MODAL.BEACONS.LABEL.TEXT' | translate }}</mat-label>
  <mat-select [disabled]="isAdmin"  panelClass="wide-options-panel" [formControl]="beaconIds" multiple (selectionChange)="onSelectionFn()" (ngModelChange)="onModelChange($event)">
    <div class="filter-controls">
      <mat-form-field subscriptSizing="dynamic" class="w-100">
        <mat-label>filter</mat-label>
        <input matInput [(ngModel)]="filterValue"  />
        <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="filterValue=''">
          <mat-icon>close</mat-icon>
        </button>
        <mat-hint class="d-flex align-items-center w-100">
          <span class="f-grow">Selected: {{beaconIds.value? beaconIds.value?.length :
            0}} of
            {{allBeaconsList?.length}}</span>
          &emsp;
          <a (click)="beaconIds.reset()" class="deselect-filter">deselect all</a></mat-hint>
      </mat-form-field>
    </div>
    <mat-select-trigger>
      {{ selectedBeaconName }}
      <span class="example-additional-selection" *ngIf="beaconAdditionalCount > 0">
        (+{{ beaconAdditionalCount }} {{ beaconAdditionalCount === 1 ? 'other' : 'others' }})
      </span>
    </mat-select-trigger>
    <ng-container *ngFor="let beacon of allBeaconsList">
      
      <mat-option [value]="beacon.id"  [ngStyle]="{'display': (beacon.name.toLowerCase().includes(filterValue.toLowerCase()) || beacon.mac.toLowerCase().includes(filterValue.toLowerCase())) ? '' : 'none' }"
        >{{beacon.name}} <br> <span class="additional-option-info">[{{beacon.mac | macAddressFromat}}]</span></mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>


