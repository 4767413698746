import {element} from 'protractor';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter,
  Input, input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatColumnDef, MatTable} from '@angular/material/table';
import {CommonDataSource} from 'src/app/services/custom-data-source';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrl: './common-table.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CommonTableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  selectAll = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() gridList: any[] = [];
  @Input() columnsToDisplay: any;
  @Input() columnsHeadersToDisplay: any;
  @Input() expandableAttributeList: any;
  @Input() staticColumnToDis: any;
  @Input() selectedClientDocData: any;
  @Input() tableName = '';
  @Input() showActionColumn = true;
  @Input() isRowEditable = true;
  @Input() isArchivedAble = true;
  @Input() isToggleBtn = true;
  @Input() isRowExpandable = false;
  @Input() isRowSelectable: boolean = false;
  @Input() isRowDeleteable: boolean = false;
  @Input() isMobileCard: boolean = true;
  dataSource: CommonDataSource<any>;
  bindGridList: any[] = [];
  columnFilteredValue = '';
  count = 0;
  filteredDataSubscription : Subscription

  private currentFilters: {[key: string]: string} = {}; // Store current filters
  private currentSort: {[key: string]: string} = {}; // Store current filters

  filterOptions: {[key: string]: string[]} = {};

  expandedElement: any = null;
  private clearTimer: any;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  @ViewChild(MatTable) table: MatTable<any>;
  @ContentChildren('staticColumn') staticColumns: QueryList<TemplateRef<any>>;

  @Output() editDialogOpened = new EventEmitter<any>();
  @Output() onArchiveRow = new EventEmitter<any>();
  @Output() onUnArchiveRow = new EventEmitter<any>();
  @Output() onDeleteRow = new EventEmitter<any>();
  @Output() visibilityChange = new EventEmitter<{event: Event, device: any}>();
  @Output() expandedModal = new EventEmitter<any>();
  @Output() onCopyAsTraining = new EventEmitter<any>();
  @Output() onFixSession = new EventEmitter<any>();
  @Output() retrunFilteredData = new EventEmitter<any>();

  columnTemplates: {[key: string]: any} = {};
  @Input() perms: any;
  @Input() entityName: string;

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.gridList?.currentValue?.length > 0 || changes.gridList?.previousValue?.length > 0)) {
      this.bindGridList = this.gridList;
      this.clearTimer = setTimeout(() => {
        this.methodOnChange();
      }, 100);

    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

    this.columnsToDisplay.forEach(column => {
      if (column.filterOptions && Array.isArray(column.filterOptions)) {
        column.filteredOptions = column.filterOptions
          .sort((a, b) => a.toString().localeCompare(b.toString()));
      }
    });


  }

  ngOnDestroy(): void {
    clearTimeout(this.clearTimer);
    this.filteredDataSubscription?.unsubscribe()
  }

  methodOnChange() {
    this.dataSource = new CommonDataSource(this.paginator, this.sort);
    this.dataSource.data = this.bindGridList; // Set initial data
    // this.dataSource.updateData(this.bindGridList);
    this.generateFilterOptions();
    this.reapplyFilters();

  }

  reapplyFilters() {
    // Reapply all current filters after data update
    Object.keys(this.currentFilters).forEach(column => {
      this.dataSource.setFilter(column, this.currentFilters[column]);
    });
    Object.keys(this.currentSort).forEach(column => {
      this.dataSource.setSort(column, this.currentSort[column])
    })
  }

  generateFilterOptions() {
    this.columnsToDisplay.forEach(column => {
      const columnName = column.name; // Get the column name
      const uniqueValues = [...new Set(this.bindGridList.map(item => item[columnName]).filter(value => value != null))];
      column.filterOptions = uniqueValues; // Assign unique values to the column's filterOptions
      if (column.filterOptions && Array.isArray(column.filterOptions)) {
        if (typeof (column.filterOptions[0]) === 'number') {
          column.filteredOptions = column.filterOptions.sort((a, b) => b - a);
        } else {
          column.filteredOptions = column.filterOptions
            .sort((a, b) => a.toString().localeCompare(b.toString()));
        }

      }

    });
  }

  sortList(column: string, direction: string) {
    if (!this.dataSource) {
      console.error('Data source is not initialized.');
      return;
    }
    this.currentSort[column] = direction
    this.dataSource.setSort(column, direction);
  }

  headerFilter(event: Event, column: string) {
    const filterValue = (event.target as HTMLInputElement).value.toLocaleLowerCase();
    this.currentFilters[column] = filterValue.toLowerCase();
    if (filterValue && filterValue !== '') {
      this.dataSource.setFilter(column, filterValue);
      const currentData = this.dataSource.getData();
      this.dataSource.updateData(currentData);

      this.filterAutocompleteOptions(column, filterValue);
      if(this.tableName === 'deviceTable'){
       this.filteredDataSubscription = this.dataSource?.connect().subscribe(data => {
          this.retrunFilteredData.emit(data)
        });
      }

    }
    else {
      this.removeFilterFocus(event)
    }
  }

  filterAutocompleteOptions(column: string, filterValue: string) {
    const columnData = this.columnsToDisplay.find(col => col.name === column);
    if (columnData) {
      columnData.filteredOptions = columnData.filterOptions.filter(option =>
        option.toLowerCase().includes(filterValue)
      ).sort((a, b) => a.localeCompare(b));
    }
  }

  removeFilterFocus(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue === '') {
      this.columnsToDisplay.forEach(element => {
        element.filterValue = '';
        element.filteredOptions = element.filterOptions;
      });
      this.dataSource.clearFilters();
      this.currentFilters = {};
    }
  }

  removeFilters(event: any, column: any) {
    event?.stopPropagation()
    this.columnsToDisplay.forEach(element => {
      element.filterValue = '';
      element.filteredOptions = element.filterOptions;
    });

    this.dataSource.clearFilters();
    this.currentFilters = {}
  }

  determineIndeterminate() {
    if (this.tableName === 'deviceTable') {
      const deviceCount = this.gridList?.length;
      const selectedDevicesCount = this.gridList?.filter(device => device.isSelected)?.length;
      if (selectedDevicesCount === deviceCount || selectedDevicesCount === 0) {
        return false;
      } else if (selectedDevicesCount > 0 && selectedDevicesCount < deviceCount) {
        return true;
      }
    }

  }

  toggleMultiSelect() {
    if (this.isRowSelectable) {
      this.selectAll = !!this.selectAll;
      this.gridList.forEach(row => {
        row.isSelected = this.selectAll;
      });
    }
  }

  openEditDialog(event: any, element: any) {
    if (this.isRowEditable) {
      event.stopPropagation();
      this.editDialogOpened.emit(element)
    }
  }

  archiveRow(event: any, element: any) {
    if (this.isArchivedAble) {
      event.stopPropagation();
      this.onArchiveRow.emit(element)
    }
  }

  unarchiveRow(event: any, element: any) {
    if (this.isArchivedAble) {
      this.onUnArchiveRow.emit(element)
    }
  }

  deleteRow(event: any, element: any) {
    if (this.isRowDeleteable) {
      this.onDeleteRow.emit(element)
    }
  }

  expandRow(element: any) {
    if (this.entityName === 'device') {
      this.expandedElement = this.expandedElement?.deviceId === element.deviceId ? null : element;
    }
    if (this.tableName === 'taskRegistrationTable') {
      this.expandedElement = this.expandedElement?.id === element.id ? null : element;
    }
    if (this.tableName === 'sessionTable') {
      this.expandedElement = this.expandedElement?.sessionId === element.sessionId ? null : element;
    }

  }

  applyExpandedClass(element: any) {
    if (this.entityName === 'device') {
      return this.expandedElement?.deviceId === element.deviceId;
    }
    if (this.tableName === 'taskRegistrationTable') {
      return this.expandedElement?.id === element.id;
    }
    if (this.tableName === 'sessionTable') {
      return this.expandedElement?.sessionId === element.sessionId;
    }

  }

  visibilityClicked(event: Event, device: any) {
    if (this.entityName === 'device') {
      this.visibilityChange.emit({event, device});
    }
  }
  openExpandedModal(element: any) {
    if (this.entityName === 'overview') {
      this.expandedModal.emit(element);
    }
  }

  copyAsTraining(event: any, element: any) {
    if (this.tableName === 'sessionTable') {
      event.stopPropagation();
      this.onCopyAsTraining.emit(element)
    }

  }
  fixSession(event: Event, element: any) {
    if (this.tableName === 'sessionTable') {
      event.stopPropagation();
      this.onFixSession.emit(element)
    }

  }
  getFailedToolTip(element) {
    return {
      topline: `Re-initiate failed attempt to fix`,
      bottomLine: `${element.failureReason}`
    }
  }
}
