<div class="listing-container">
  <div class="mat-elevation-z8">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData()"
        class="listing-table">
        <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column === 'labelIds'">
            {{ rowAttributesReadableMap.get(column) | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field class="custom-height-field" style="width: 7rem;" *ngIf="column === 'rowReference'"
              appearance="outline">
              <input [type]="rowAttributesSchema[column]" matInput [(ngModel)]="element[column]"
                (focusout)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
                [disabled]="!selectedClientDocData?.hasAdminRole"
                *ngIf="!['id', 'activityId', 'varietyId', 'beaconIds', 'labelIds', 'rowNumber', 'rowLength'].includes(column)">
            </mat-form-field>
            <mat-form-field class="custom-height-field" style="width: 7rem;" *ngIf="column === 'rowNumber'"
              appearance="outline">
              <input [type]="rowAttributesSchema[column]" matInput [(ngModel)]="element[column]"
                (focusout)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
                [disabled]="!selectedClientDocData?.hasAdminRole"
                *ngIf="!['id', 'activityId', 'varietyId', 'beaconIds', 'labelIds', 'rowReference', 'rowLength'].includes(column)">
            </mat-form-field>
            <mat-form-field class="custom-height-field" style="width: 7rem;height: 2.9rem;padding: 2px 0;"
              *ngIf="column === 'rowLength'" appearance="outline">
              <mat-label> {{'SETTINGS.TABLE.LOCATION.LENGTH.LABEL.TEXT' | translate}}</mat-label>
              <input [type]="rowAttributesSchema[column]" matInput [(ngModel)]="element[column]"
                (focusout)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
                [disabled]="!selectedClientDocData?.hasAdminRole"
                *ngIf="!['id', 'activityId', 'varietyId', 'beaconIds', 'labelIds', 'rowReference', 'rowNumber'].includes(column)">
              <button matSuffix matTooltip="Copy for all rows" mat-icon-button
                [disabled]="rowLengthBulkUpdateInProgress"
                (click)="copyLengthToAllRows(element, dataSource.data.indexOf(element))" aria-label="Clear">
                <mat-icon>content_paste_go</mat-icon>
              </button>
            </mat-form-field>
            <mat-select [(ngModel)]="element[column]"
              placeholder="{{'SETTINGS.SELECTOPTION.ACTIVITY.LABEL.TEXT' | translate}}"
              style="margin-left:-5px; margin-right: 5px; margin-top: 0.5rem; padding-left: 5px;height:2rem; width: 8rem;"
              [disabled]="!selectedClientDocData?.hasAdminRole"
              (selectionChange)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
              *ngIf="column === 'activityId'">
              <mat-option value="-1">{{'SETTINGS.SELECTOPTION.ACTIVITY.LABEL.TEXT' | translate}}</mat-option>
              <mat-option *ngFor="let activity of allActivitiesList" [value]="activity.id">
                {{activity.name}}
              </mat-option>
            </mat-select>
            <mat-select [(ngModel)]="element[column]"
              placeholder="{{'SETTINGS.SELECTOPTION.VARIETY.LABEL.TEXT' | translate}}"
              style="margin-left:-5px; margin-right: 5px; margin-top: 0.5rem; padding-left: 5px;height:2rem; width: 8rem;"
              [disabled]="!selectedClientDocData?.hasAdminRole"
              (selectionChange)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
              *ngIf="column === 'varietyId'">
              <mat-option value="-1">{{'SETTINGS.SELECTOPTION.VARIETY.LABEL.TEXT' | translate}}</mat-option>
              <mat-option *ngFor="let variety of allVarietiesList" [value]="variety.id">
                {{variety.name}}
              </mat-option>
            </mat-select>
            <ng-container *ngIf="column === 'beaconIds'">
              <div class="w-100 custom-height-field" [disabled]="!selectedClientDocData?.hasAdminRole">
                <mat-select style="width: 8rem;" [(ngModel)]="element[column]" panelClass="wide-options-panel"
                  (selectionChange)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))" multiple placeholder="{{'SETTINGS.SELECTOPTION.BEACON.LABEL.TEXT' | translate}}">
                  <div class="filter-controls">
                    <mat-form-field subscriptSizing="dynamic" class="w-100">
                      <mat-label>filter</mat-label>
                      <input matInput [(ngModel)]="filterValue" />
                      <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="filterValue=''">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-hint class="d-flex align-items-center w-100">
                        <span class="f-grow">Selected: {{element[column]? element[column]?.length :
                          0}} of
                          {{element.rowBeaconsList?.length}}</span>

                        &emsp;
                        <a (click)="element[column] = [''];saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
                          class="deselect-filter">deselect all</a></mat-hint>
                    </mat-form-field>
                  </div>
                  <mat-select-trigger>
                    <span>{{ getFirstSelectedBeaconName(element) }}</span>

                    <span class="example-additional-selection" *ngIf="element[column]?.length > 1">
                      (+{{ element[column].length -1 }} {{ element[column].length -1 === 1 ? 'other' : 'others' }})
                    </span>
                  </mat-select-trigger>
                  <ng-container *ngFor="let beacon of element.rowBeaconsList">

                    <mat-option [value]="beacon.id"
                      [ngStyle]="{'display': (beacon.name.toLowerCase().includes(filterValue.toLowerCase()) || beacon.mac.toLowerCase().includes(filterValue.toLowerCase())) ? '' : 'none' }">{{beacon.name}}
                      <br> <span class="additional-option-info">[{{beacon.mac | macAddressFromat}}]</span></mat-option>
                  </ng-container>
                </mat-select>
              </div>
            </ng-container>


            <!-- <mat-select [(ngModel)]="element[column]"
              placeholder="{{'SETTINGS.SELECTOPTION.BEACON.LABEL.TEXT' | translate}}"
              style="margin-left:-5px; margin-right: 5px; margin-top: 0.5rem; padding-left: 5px;height:2rem; width: 8rem;"
              [disabled]="!selectedClientDocData?.hasAdminRole"
              (selectionChange)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
              *ngIf="column === 'beaconIds'" multiple>
              <mat-option *ngFor="let beacon of element.rowBeaconsList" [value]="beacon.id">
                {{ beacon.name }}
              </mat-option>
            </mat-select> -->

            <mat-select [(ngModel)]="element[column]" multiple
              placeholder="{{'SETTINGS.SELECTOPTION.LABELS.LABEL.TEXT' | translate}}"
              style="margin-left:-5px; margin-right: 5px; margin-top: 0.5rem; padding-left: 5px;height:2rem; width: 8rem;"
              [disabled]="!selectedClientDocData?.hasAdminRole"
              (selectionChange)="saveRowOnFocusOut(element, dataSource.data.indexOf(element))"
              *ngIf="column === 'labelIds'">
              <mat-option *ngFor="let label of allLabelsList" [value]="label.id">
                {{label.name}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="listing-table-tools listing-table-tools-wrapper">
            <div class="grid-control-column">
              <button [matTooltip]="'SETTINGS.LOCATIONS.ROWS.RESTART.TOOLTIP.TEXT' | translate" mat-icon-button
              color="accent" (click)="restartCurrentRow(element, dataSource.data.indexOf(element))"
              [disabled]="!selectedClientDocData?.hasAdminRole || !element.id || restartInProgress"
              *ngIf="!element.isCreatedToday">
              <mat-icon>restart_alt</mat-icon>
            </button>
            <button [matTooltip]="'SETTINGS.LOCATIONS.ROWS.RESTART.TOOLTIP.TEXT' | translate" mat-icon-button
              color="primary" (click)="restartCurrentRow(element, dataSource.data.indexOf(element))"
              [disabled]="!selectedClientDocData?.hasAdminRole || !element.id || restartInProgress"
              *ngIf="element.isCreatedToday">
              <mat-icon>restart_alt</mat-icon>
            </button>
            <!--<button matTooltip="Save row" mat-icon-button color="accent" (click)="saveRow(element)" [disabled]="!selectedClientDocData?.hasAdminRole">
              <mat-icon>save</mat-icon>
            </button>-->
            <button [matTooltip]="'SETTINGS.LOCATIONS.ROW.ARCHIVE.TOOLTIP.TEXT' | translate" mat-icon-button
              color="accent" (click)="archiveCurrentRow(element, dataSource.data.indexOf(element))"
              [disabled]="!selectedClientDocData?.hasAdminRole">
              <mat-icon>archive</mat-icon>
            </button>
            </div>

          </td>
        </ng-container>
        <ng-container matColumnDef="floorArea">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'SETTINGS.TABLEHEAD.FLOORAREA.TITLE.TEXT' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="listing-table-tools">
            {{element.floorArea?.toFixed(1) ?? ''}}㎡
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>

        <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="100" [showFirstLastButtons]="true"></mat-paginator>
    <button matTooltip="Add new row" mat-icon-button color="accent" (click)="addNewEmptyRow()"
      style="position: relative; top: -3.3rem; left:20px" *ngIf="selectedClientDocData?.hasAdminRole">
      <mat-icon>add_circle</mat-icon>
    </button>
  </div>
</div>
