import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-report-export-modal',
  templateUrl: './report-export-modal.component.html',
  styleUrls: ['./report-export-modal.component.scss']
})
export class ReportExportModalComponent {
  title: string;
  downloadOptions: any[];
  isTitled = false;

  constructor(private dialogRef: MatDialogRef<ReportExportModalComponent>,
              @Inject(MAT_DIALOG_DATA) data,) {
    this.title = data.title;
    this.downloadOptions = data.downloadOptions;
  }

  close() {
    this.dialogRef.close();
  }

  downloadAs(option) {
    this.dialogRef.close({
      downloadAs: option,
      isTitled: this.isTitled
    });
  }

}
