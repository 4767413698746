import {AfterViewInit, Component, DestroyRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {DatePipe} from '@angular/common';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../services/firestore.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ClientInContextService} from '../../services/client-in-context.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BreakpointService} from '../../services/breakpoint.service';
import {ConfirmationDialogComponent} from '../utility/confirmation-dialog/confirmation-dialog.component';
import {SNACKBAR_CLASSES} from '../../common/utils/utils';
import firebase from 'firebase/compat/app';
import {Timestamp} from 'firebase/firestore';
import {CreateAssetDialogComponent} from './create-asset-dialog/create-asset-dialog.component';
import {EditAssetDialogComponent} from './edit-asset-dialog/edit-asset-dialog.component';
import {AssetMainAttributes} from '../../common/interfaces/asset-interfaces';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-client-assets',
  templateUrl: './client-assets.component.html',
  styleUrls: ['./client-assets.component.scss',
    '../../common/styles/listing.scss',]
})
export class ClientAssetsComponent implements OnDestroy, AfterViewInit {
  unArchivedVsArchived = 'unarchived';
  assetAttributesReadableMap: Map<string, string> = new Map([
    ['name', 'SETTINGS.TABLEHEAD.NAME.TITLE.TEXT'],
    ['type', 'SETTINGS.TABLEHEAD.TYPE.TITLE.TEXT'],
    ['number', 'SETTINGS.TABLEHEAD.NUMBER.TITLE.TEXT'],
    ['beaconNames', 'SETTINGS.TABLEHEAD.BEACONS.TITLE.TEXT'],
    ['creationTimestamp', 'SETTINGS.TABLEHEAD.CREATEDAT.TITLE.TEXT']
  ]);

  screenSize = 'default';

  columnsToDisplay: string[] = ['name', 'type', 'number', 'beaconNames', 'creationTimestamp'];
  columnsHeadersToDisplay: string[] = [
    'name',
    'type',
    'number',
    'beaconNames',
    'creationTimestamp',
    'Edit',
  ];
  dataSource: MatTableDataSource<AssetMainAttributes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  assetsList: any[];
  dateColumns: string[] = ['creationTimestamp'];
  datePipe = new DatePipe('en-US');
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  unarchivedAssetsSubscription: Subscription;
  archivedAssetsSubscription: Subscription;
  breakpointSubscription: Subscription;
  filterValue: string

  constructor(public firestoreService: FirestoreService,
              public authService: AuthService,
              public route: ActivatedRoute,
              private dialog: MatDialog,
              private clientInContextService: ClientInContextService,
              private snackBar: MatSnackBar,
              private breakpointService: BreakpointService,
              private destroyRef: DestroyRef) {
    this.breakpointSubscription = this.breakpointService.screenSize$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(screenSize => this.screenSize = screenSize);
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
        this.loggedInUserDocData = userDocData;
      });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        this.fetchUnarchivedAssets();
      });
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateAssetDialogComponent, dialogConfig);
  }

  openEditDialog(record) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      assetRecord: record
    };

    this.dialog.open(EditAssetDialogComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.archivedAssetsSubscription?.unsubscribe();
    this.unarchivedAssetsSubscription?.unsubscribe();
    this.breakpointSubscription?.unsubscribe();
  }

  async archiveAsset(asset) {

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive asset '${asset?.name ?? ''}' ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        asset
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        try {
          await this.firestoreService.updateAssetByIdForClientId(value?.asset?.id, this.selectedClientDocData?.id, {isArchived: true});
          this.openSnackBar(`Asset '${value?.asset?.name}' archived successfully`, 'success');
        } catch (error) {
          this.openSnackBar('Error in asset archival: ' + error.message, 'error');
          console.log(error.message);
        }
      }
    });
  }

  async unarchiveAsset(asset) {
    try {
      await this.firestoreService.updateAssetByIdForClientId(asset?.id, this.selectedClientDocData?.id, {isArchived: false});
      this.openSnackBar(`Asset '${asset?.name}' unarchived successfully`, 'success');
    } catch (error) {
      this.openSnackBar('Error in asset archival: ' + error.message, 'error');
      console.log(error.message);
    }
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  toggleArchived() {
    if (this.areUnArchivedAssetsShown()) {
      this.fetchUnarchivedAssets();
    } else {
      this.fetchAllArchivedAssets();
    }
  }

  fetchUnarchivedAssets() {
    this.assetsList = [];
    this.archivedAssetsSubscription?.unsubscribe();
    this.unarchivedAssetsSubscription = this.firestoreService
    .getAllUnarchivedAssetsForClientId(this.selectedClientDocData.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((assetsList) => {
      this.assetsList = assetsList.map((asset) => {
        for (const [key, value] of Object.entries(asset)) {
          if (this.dateColumns.includes(key)) {
            const timeValue = value as Timestamp;
            asset[key] = this.datePipe.transform(
              timeValue.toMillis(),
              'yyyy-MM-dd HH:mm'
            );
          }
        }
        return asset;
      });

      this.assetsList.sort((assetA: any, assetB: any) => {
        return assetA.name < assetB.name ? -1 : assetA.name > assetB.name ? 1 : 0;
      });

      this.dataSource = new MatTableDataSource(this.assetsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.initiateFiltering();
    });
  }

  fetchAllArchivedAssets() {
    this.assetsList = [];
    this.unarchivedAssetsSubscription?.unsubscribe();
    this.archivedAssetsSubscription = this.firestoreService
    .getAllArchivedAssetsForClientId(this.selectedClientDocData.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((assetsList) => {
      this.assetsList = assetsList.map((asset) => {
        for (const [key, value] of Object.entries(asset)) {
          if (this.dateColumns.includes(key)) {
            const timeValue = value as Timestamp;
            asset[key] = this.datePipe.transform(
              timeValue.toMillis(),
              'yyyy-MM-dd HH:mm'
            );
          }
        }
        return asset;
      });

      this.assetsList.sort((assetA: any, assetB: any) => {
        return assetA.name < assetB.name ? -1 : assetA.name > assetB.name ? 1 : 0;
      });

      this.dataSource = new MatTableDataSource(this.assetsList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.initiateFiltering();
    });
  }

  initiateFiltering() {
    if (this.filterValue) {
      this.dataSource.filter = this.filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  areArchivedAssetsShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedAssetsShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }
}
