<div style="min-width: 400px;">
  <h2 mat-dialog-title>Edit Task Group</h2>

  <mat-dialog-content [formGroup]="editTaskGroupForm" style="text-align: center;">
   <div class="row">
    <div class="col-lg-12">
      <mat-form-field class="w-100">
        <mat-label>Name</mat-label>
        <input matInput required
               formControlName="name">
      </mat-form-field>
    </div>
   </div>
  </mat-dialog-content>
  <div class="pb-2 footer-with-Id">
    <div class="col-lg-12 d-flex copyEntityId">
      <input matInput [(ngModel)]="taskGroupId" readonly #copyTarget >
      <button mat-icon-button appCopySuccess [iconTarget]="icon" color="primary" class="copy-inside-input"
        [ngxClipboard]="copyTarget">
        <mat-icon class="material-icons-outlined m-0" #icon>content_copy</mat-icon>
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">Close</button>
      <button mat-raised-button color="primary" (click)="updateTaskGroup()" style="margin-left: 20px;">Save Changes</button>
    </mat-dialog-actions>
  </div>
</div>
