<div class="modal-width">
  <h2 mat-dialog-title>{{'SETTINGS.USERS.EDITUSER.TITLE.TEXT' | translate}}</h2>
    <mat-dialog-content [formGroup]="editUserForm">
      <div class="row pb-4">
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
            <input matInput required formControlName="name">
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SETTINGS.MODAL.EMAIL.LABEL.TEXT' | translate}}</mat-label>
            <input matInput disabled value="{{userRecordBeingEdited?.email}}">
          </mat-form-field>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-lg-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SETTINGS.MODAL.NOTES.LABEL.TEXT' | translate}}</mat-label>
            <textarea matInput formControlName="notes"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SETTINGS.MODAL.ROLE.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="role">
              <mat-option value="regular">regular</mat-option>
              <mat-option value="manager">manager</mat-option>
              <mat-option value="admin">admin</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SETTINGS.MODAL.LANGUAGE.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="languageCode">
              <mat-option *ngFor="let language of languagesList" [value]="language.code">{{ language.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      
      <div class="row pb-4">
        <div class="col-lg-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{'SETTINGS.MODAL.ASSOCIATEDWORKER.LABEL.TEXT' | translate}}</mat-label>
            <mat-select formControlName="associatedWorkerId">
              <mat-option *ngFor="let worker of allWorkersList" [value]="worker.id">{{ worker.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <div>
        <mat-dialog-actions>
            <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
            <button mat-raised-button color="primary" (click)="updateUser()" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
        </mat-dialog-actions>
    </div>
</div>
