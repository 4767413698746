<div style="min-width: 36rem;min-height: 30rem;">
  <h2 mat-dialog-title>Create New Element</h2>
  <mat-dialog-content  [formGroup]="createLanguageElementForm" class="lang-dialog-content" >
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>Reference</mat-label>
          <input matInput required
                 formControlName="pathStr" [disabled]="true">
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>Type</mat-label>
          <mat-select formControlName="typeCode">
            <mat-option *ngFor="let elementType of elementTypes" [value]="elementType.type">
              {{elementType.friendlyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> 
    </div>
    
    <div class="row pb-4">
      <div class="col-lg-6">
        <mat-form-field class="w-100">
          <mat-label>Release Date(coming soon...)</mat-label>
          <input matInput
                 formControlName="releaseDate" [disabled]="true">
        </mat-form-field>
      </div>
     <div class="col-lg-6"></div>
      <!--<div style="font-style: italic;margin-top:1.6rem;font-size: 0.8rem;">(Only applicable for 'Release Notes' elements)</div>-->
    </div>
    <div style="display: flex;justify-content: center;min-height: 1px;" class="lang-tabs f-grow">
      <mat-tab-group mat-align-tabs="center" class="d-flex" style="width:100%;" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="EN">
          <div style="display: flex;flex-direction: column;flex: 1;">
            <mat-form-field  style="margin-top: 0.8rem;width:46%;">
              <mat-label>Header</mat-label>
              <input matInput
                     formControlName="header_en">
            </mat-form-field>
            <div style="display: flex;justify-content: space-between;overflow: auto;flex: 1;">
              <mat-form-field  style="margin-top: 15px;width:46%" class="lang-text-area">
                <mat-label>Plain/Markup</mat-label>
                <textarea matInput rows="10"
                          formControlName="plain_en"></textarea>
              </mat-form-field>
              <div style="margin-top: 0.4rem;width:46%; display: flex;flex-direction: column;">
                <mat-label>Preview</mat-label>
                <mat-divider></mat-divider>
                <div [innerHTML]="getPreview('EN')" style="flex: 1 1 auto;overflow-y: auto;overflow-x: hidden;"></div>
              </div>
            </div>
            <mat-slide-toggle class="lang-enabled-toggle" formControlName="enabled_en">Language enabled</mat-slide-toggle>
          </div>
        </mat-tab>
        <mat-tab label="NL">
          <div style="display: flex;flex-direction: column;overflow: hidden;flex: 1;">
            <mat-form-field  style="margin-top: 0.8rem;width:46%;">
              <mat-label>Header</mat-label>
              <input matInput
                     formControlName="header_nl">
            </mat-form-field>
            <div style="display: flex;justify-content: space-between;overflow: auto; flex: 1;">
              <mat-form-field  style="margin-top: 0.8rem;width:46%" class="lang-text-area">
                <mat-label>Plain/Markup</mat-label>
                <textarea matInput rows="10"
                          formControlName="plain_nl"></textarea>
              </mat-form-field>
              <div style="margin-top: 0.4rem;width:46%; display: flex;flex-direction: column;">
                <mat-label>Preview</mat-label>
                <mat-divider></mat-divider>
                <div class="text-preview" [innerHTML]="getPreview('NL') | safeHtml" style="flex: 1 1 auto;overflow-y: auto;overflow-x: hidden;"></div>
              </div>
            </div>
            <mat-slide-toggle class="lang-enabled-toggle" formControlName="enabled_nl">Language enabled</mat-slide-toggle>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-dialog-content>

  <div style="text-align: right;margin-right: 2rem;max-width: 100%;">
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">Close</button>
      <button mat-raised-button color="primary" (click)="updateElement()" style="margin-left: 20px;">Save</button>
    </mat-dialog-actions>
  </div>
</div>
