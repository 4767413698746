<mat-accordion>
  <mat-expansion-panel *ngFor="let layout of layoutList; let counter=index" (opened)="layout.isOpen = true" (closed)="layout.isOpen = false">
    <mat-expansion-panel-header>
      <mat-panel-title style="max-width: 10rem;">
        <span style="font-size: 1.1rem;" (focusout)="saveNewLayoutName($event, layout)" [disabled]="!this.perms?.canUpdate" ngDefaultControl contenteditable (click)="preventPropagation($event)" [(ngModel)]="layoutList[counter].name"></span>
      </mat-panel-title>
      <mat-panel-description style="display: flex; justify-content: right; margin-right: 1rem;">
        <button mat-icon-button color="accent" matTooltip='Edit {{layout.name}}'
        (click)="openEditLayoutDialog($event, layout)"
        style="margin-left: 20px;" *ngIf="this.perms?.canUpdate">
            <mat-icon>edit</mat-icon>
          </button>
      </mat-panel-description>
      </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
    <app-client-loc-lyt-rows [clientId]="clientId" [locationId]="locationId" [layoutId]="layout.id" [triggerReload]="triggerReload"
                             [rowWidth]="layout.rowWidth" *ngIf="layout.isOpen"></app-client-loc-lyt-rows>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
