<div *ngIf="perms?.canRead">
  <div class="d-flex align-items-center">
    <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
      [(ngModel)]="toggleGroupedUngrouped" (change)="onToggleChange()">
      <mat-button-toggle value="ungrouped" aria-label="Text align left">
        <mat-icon class="material-symbols-outlined"
          matTooltip=" {{'DASHBOARD.LABOR.PRESENCES.TOOLTIP.SHOWALPHABETICALLY.LABEL.TEXT' | translate}}"
          matTooltipClass="tooltip-class" matTooltipHideDelay="100" matTooltipPosition="below">person</mat-icon>

      </mat-button-toggle>
      <mat-button-toggle value="grouped" aria-label="Text align center">
        <mat-icon class="material-symbols-outlined"
          matTooltip=" {{'DASHBOARD.LABOR.PRESENCES.TOOLTIP.SHOWGROUPED.LABEL.TEXT' | translate}}"
          matTooltipClass="tooltip-class" matTooltipHideDelay="100" matTooltipPosition="below">group
        </mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="groupedbyactivity" aria-label="Text align center">
        <mat-icon class="material-symbols-outlined"
          matTooltip=" {{'DASHBOARD.LABOR.PRESENCES.TOOLTIP.SHOWGROUPEDBYACTIVITY.LABEL.TEXT' | translate}}"
          matTooltipClass="tooltip-class" matTooltipHideDelay="100" matTooltipPosition="below">category
        </mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <span class="active-group-user f-grow justify-content-end">
      <strong>Total Active </strong>
      {{getActiveWorkerLength}} of {{allWorkersList?.length}}
    </span>
  </div>

  <ng-container *ngIf="groupedListReady && toggleGroupedUngrouped=== 'grouped'">
    <div *ngFor="let group of getKeyArrayFromMap();let i=index;"
      [ngStyle]="i === 0? {'margin-top': '2rem'}:{'margin-top': '3rem'} ">
      <div class="group-name d-flex" *ngIf="group !== 'NOGROUP'">
        <span class="f-grow">{{ group }}</span>
        <span class="active-group-user">
          <strong>Active </strong>
          {{getActiveGroupedWOrker(group)}} of {{groupedWorkersMap.get(group).length}}
        </span>
      </div>
      <mat-grid-list [cols]="getNumberOfCols()" gutterSize="1rem" style="margin-top: 0.9rem;">
        <div class="worker-grid">
          <ng-container *ngFor="let worker of groupedWorkersMap.get(group)">
            <button mat-raised-button [mtxTooltip]="groupedTooltip" matTooltipClass="tooltip-class"
              matTooltipHideDelay="100" matTooltipPosition="below" class="worker-items p-0"
              [class]="getClassForWorker(worker)">
              <span class="icon">
                <mat-icon>person</mat-icon>
              </span>
              <span>{{ worker.name }}<br><small>{{worker.lastTaskOrActivityName === 'NOGROUP_TASK_ACTVTY' ? '&nbsp;' :
                  worker.lastTaskOrActivityName}}</small></span>
            </button>
            <ng-template #groupedTooltip>
              <ng-container *ngIf="worker.lastWatchNumber">
                {{'DASHBOARD.LABOR.PRESENCES.WATCHNUMBER.LABEL.TEXT' | translate}} : {{worker.lastWatchNumber}}
              </ng-container>
              <ng-container *ngIf="!worker.lastWatchNumber">
                {{'DASHBOARD.LABOR.PRESENCES.NOWATCHNUMBER.LABEL.TEXT' | translate}}
              </ng-container>
            </ng-template>
          </ng-container>
        </div>

      </mat-grid-list>
    </div>
  </ng-container>
  <ng-container *ngIf="allWorkersList?.length > 0 && toggleGroupedUngrouped=== 'ungrouped'">
    <div style="margin-top: 2rem;">
      <mat-grid-list [cols]="getNumberOfCols()" gutterSize="1rem" style="margin-top: 0.9rem;">
        <div class="worker-grid">
          <ng-container *ngFor="let worker of allWorkersList">
            <button mat-raised-button class="worker-items p-0" [mtxTooltip]="AllWorkerTooltip"
              matTooltipClass="tooltip-class" matTooltipHideDelay="100" matTooltipPosition="below"
              [class]="getClassForWorker(worker)">
              <span class="icon">
                <mat-icon>person</mat-icon>
              </span>
              <span>{{ worker.name }}<br><small>{{worker.lastTaskOrActivityName === 'NOGROUP_TASK_ACTVTY' ? '&nbsp;' :
                  worker.lastTaskOrActivityName}}</small></span>
            </button>
            <ng-template #AllWorkerTooltip>
              <ng-container *ngIf="worker.lastWatchNumber">
                {{'DASHBOARD.LABOR.PRESENCES.WATCHNUMBER.LABEL.TEXT' | translate}} : {{worker.lastWatchNumber}}
              </ng-container>
              <ng-container *ngIf="!worker.lastWatchNumber">
                {{'DASHBOARD.LABOR.PRESENCES.NOWATCHNUMBER.LABEL.TEXT' | translate}}
              </ng-container>
            </ng-template>

          </ng-container>
        </div>

      </mat-grid-list>
    </div>
  </ng-container>
  <ng-container *ngIf="allWorkersList?.length > 0 && toggleGroupedUngrouped === 'groupedbyactivity'">
    <div *ngFor="let group of getKeyArrayFromActvtyTaskMap();let i=index;"
      [ngStyle]="i === 0? {'margin-top': '2rem'}:{'margin-top': '3rem'} ">
      <div class="group-name" *ngIf="group !== 'NOGROUP_TASK_ACTVTY'">{{ group }}</div>
      <div class="group-name" *ngIf="group === 'NOGROUP_TASK_ACTVTY'">
        {{'DASHBOARD.LABOR.PRESENCES.NOACTIVEACTVTY.LABEL.TEXT' | translate}}</div>
      <mat-grid-list [cols]="getNumberOfCols()" gutterSize="1rem" style="margin-top: 0.9rem;">
        <div class="worker-grid">
          <ng-container *ngFor="let worker of groupedByActvtyOrTaskWorkersMap.get(group)">
            <button mat-raised-button [mtxTooltip]="groupedActivityTooltip" matTooltipClass="tooltip-class"
              matTooltipHideDelay="100" matTooltipPosition="below" class="worker-items p-0"
              [class]="getClassForWorker(worker)">
              <span class="icon">
                <mat-icon>person</mat-icon>
              </span>
              <span>{{ worker.name }}<br><small>{{worker.lastTaskOrActivityName === 'NOGROUP_TASK_ACTVTY' ? '&nbsp;' :
                  worker.lastTaskOrActivityName}}</small></span>
            </button>
            <ng-template #groupedActivityTooltip>
              <ng-container *ngIf="worker.lastWatchNumber">
                {{'DASHBOARD.LABOR.PRESENCES.WATCHNUMBER.LABEL.TEXT' | translate}} : {{worker.lastWatchNumber}}
              </ng-container>
              <ng-container *ngIf="!worker.lastWatchNumber">
                {{'DASHBOARD.LABOR.PRESENCES.NOWATCHNUMBER.LABEL.TEXT' | translate}}
              </ng-container>
            </ng-template>
          </ng-container>
        </div>

      </mat-grid-list>
    </div>
  </ng-container>
</div>
