import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {FirestoreService} from './firestore.service';
import {determineSettingsAccess} from '../common/utils/permission-utils';

@Injectable({
  providedIn: 'root'
})
export class ClientInContextService {

  clientInContextSubject = new BehaviorSubject(null);
  clientInContext: any;

  dateInContextSubject = new BehaviorSubject(null);
  dateInContext: Date;

  clientLocSubject = new BehaviorSubject(null);
  clientLocationInContext: any;

  constructor(private router: Router, private authService: AuthService, private firestoreService: FirestoreService) {
    this.clientInContextSubject.subscribe({
      next: (clientInContextObject) => {
        this.clientInContext = clientInContextObject;
        this.authService.loggedInUserFromAuthService$.subscribe(
          async (userDocData) => {
            if (this.clientInContext) {
              this.clientInContext.role = userDocData.clients.filter(client => client?.clientId === clientInContextObject?.id)[0]?.role;
              const roleMapFromDB = userDocData.clients.filter(client => client?.clientId === clientInContextObject?.id)[0];
              if (roleMapFromDB) {
                //console.log(`roleMapFromDB.roleId:${roleMapFromDB.roleId}`);
                //console.log(`Fetching role:${roleMapFromDB.roleId ?? roleMapFromDB.role}`);
                const roleDD = await this.firestoreService.getStandardRoleById(roleMapFromDB.roleId ?? roleMapFromDB.role); //backward compatibility
                this.clientInContext.isDeveloper = roleDD.name === 'developer';
                this.clientInContext.rolePermissionsMap = roleDD.data();
                if (userDocData.isSuperUser) {
                  this.clientInContext.rolePermissionsMap.isSuperUser = true;
                }
                this.clientInContext.hasAccessToSettings = determineSettingsAccess(this.clientInContext.rolePermissionsMap);
              }
              //this.clientInContext.hasAdminRole = ['admin'].includes(this.clientInContext?.role);
              this.clientInContext.hasManagerOrAdminRole = ['admin', 'manager'].includes(this.clientInContext?.role);
            }
          }
        );
      }
    });

    this.dateInContextSubject.subscribe({
      next: (dateInContext) => {
        this.dateInContext = dateInContext;
      }
    });

    this.clientLocSubject.subscribe({
      next: (clientLocInContext) => {
        this.clientLocationInContext = clientLocInContext;
      }
    });
  }

}
