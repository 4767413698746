<div class="d-flex session-general-wrapper" *ngIf="perms?.canRead">
  <mat-card class="transparent-effect-card" appearance="outlined">
    <div style="display: flex; flex-direction: column;">
      <mat-card-title style="font-size: 1.2rem;margin-bottom: 0.4rem;font-weight: 500;">
        {{'SETTINGS.SESSIONS.HEADING.TITLE.TEXT' | translate}}
      </mat-card-title>
      <mat-card-subtitle style="font-weight: 200;">
        {{'SETTINGS.SESSIONS.DESCRIPTION.T_CONTENT.TEXT' | translate}}
      </mat-card-subtitle>
    </div>
    <br />
    <div>
      <div style="margin-left: 16px; display:flex; flex-direction: column;">
        <mat-slide-toggle [(ngModel)]="autoArchiveShortSessions" style="margin-top: 0.6rem;">
          <mat-label style="font-size: 1rem;font-weight: 300;"> {{'SETTINGS.SESSIONS.TOGGLE.LABEL.TEXT' |
            translate}}</mat-label>
        </mat-slide-toggle>
      </div>
    </div>
    <div align="end" style="margin-right: 1rem;">
      <button mat-flat-button (click)="saveAutoArchiveSessionFlag()" color="primary" [disabled]="!perms?.canUpdate">{{'SETTINGS.SAVE.BUTTON.TEXT' |
        translate}}</button>
    </div>
  </mat-card>
</div>
<div class="mt-4">
  <mat-card class="transparent-effect-card">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="material-symbols-outlined mr-2">
          checklist
        </mat-icon>{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.TITLE.TEXT' | translate}}
      </mat-card-title>
      <mat-card-subtitle class="mt-2">{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.SUBTITLE.TEXT' |
        translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.FACTOR.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required placeholder="00.00" [(ngModel)]="correctionFactor" />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions align="end" style="margin-right: 1rem;">
      <button mat-flat-button (click)="saveCorrectionFactor()" [disabled]="!perms?.canUpdate"
        color="primary">{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.SAVE.BUTTON.TEXT' | translate}} </button>
    </mat-card-actions>
  </mat-card>
</div>
<div class="mt-4">
  <mat-card class="transparent-effect-card">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center"
        style="font-size: 1.2rem;margin-bottom: 0.4rem;font-weight: 500;">
        Asset expiration minutes
      </mat-card-title>
      <!-- <mat-card-subtitle class="mt-2">{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.SUBTITLE.TEXT' | translate}}</mat-card-subtitle> -->
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-form-field class="pb-4 mb-1 w-100 field-with-hint">
          <mat-label>Expiration</mat-label>
          <input matInput required [(ngModel)]="assetExpiration" type="number" placeholder="0.00" step="0.1"
            class="rssi" max="300" min="0" name="assetExpiration" #assetExpirationModel="ngModel">
          <div matSuffix class="mat-suffix">minutes</div>

          <mat-error [ngClass]="{'error-hint': assetExpirationModel.invalid && assetExpirationModel.touched}">
            Expiration should be between 0 and 300
          </mat-error>

        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions align="end" style="margin-right: 1rem;">
      <button mat-stroked-button (click)="cancelAssetExpirataion()" color="primary"
        class="mr-2">{{'DASHBOARD.MODAL.CANCEL.BUTTON.TEXT' | translate}} </button>
      <button mat-flat-button (click)="saveAssetExpiration()" [disabled]="!perms?.canUpdate"
        color="primary">{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.SAVE.BUTTON.TEXT' | translate}} </button>
    </mat-card-actions>
  </mat-card>
</div>
