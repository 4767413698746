<div class="inner-page-action">
  <app-date-selection></app-date-selection>
</div>
<div class="listing-container table-card">
  <mat-form-field appearance="outline" class="listing-filter">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label style="color: gray">Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterValue" placeholder="Enter filter value..."
      #input />
  </mat-form-field>
  <div class="mat-elevation-z8 table-responsive expandable-table" style="clear: both;">
    <table class="listing-table layout-fixed-sm " mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
      <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ workerAttributesReadableMap.get(column) | translate}}
        </th>
        <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
      </ng-container>
      <ng-container matColumnDef="timeRange">
        <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.TIME.TITLE.TEXT' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="hide-sm">
          {{element.timeRange}}
        </td>
      </ng-container>
      <ng-container matColumnDef="rowNumber">
        <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.ROW.TITLE.TEXT' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="hide-sm">
          {{element.rowNumber}}
        </td>
      </ng-container>
      <ng-container matColumnDef="count">
        <th *matHeaderCellDef mat-header-cell class="hide-sm">{{'DASHBOARD.TABLEHEAD.COUNT.TITLE.TEXT' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="hide-sm">
          {{element.count}}
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="row-detail" [@detailExpand]=" element.id == expandedElement?.id ? 'expanded' : 'collapsed'">
            <div class="row-detail-description">
              <div class="row-detail-item">
                <strong>{{'DASHBOARD.TABLEHEAD.ROW.TITLE.TEXT' | translate}}:</strong>
                <span>{{element['rowNumber']}}</span>
              </div>
              <div class="row-detail-item">
                <strong>{{'DASHBOARD.TABLEHEAD.COUNT.TITLE.TEXT' | translate}}:</strong>
                <span>{{element['count']}}</span>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay" (click)="expandRow(element)"></tr>
      <tr mat-row style="height: 0;" *matRowDef="let row; columns: ['expandedDetail']"
        class="row-detail expandable-row show-sm">
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="100" [showFirstLastButtons]="true"></mat-paginator>
  </div>
</div>
