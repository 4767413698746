<div>
  <div appearance="outlined">
    <div style="display: flex; flex-direction: column;">
      <div class="d-flex justify-content-between my-3">
        <button mat-flat-button *ngIf="areUnArchivedCastingKeysShown()" color="primary" (click)="createNewCastingKey()">
          <mat-icon matTooltip="Add new casting key" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
            playlist_add
          </mat-icon>
          {{ 'SETTINGS.APIS.CASTINGKEYS.ADDNEW.BUTTON.TEXT' | translate }}
        </button>
        <span *ngIf="areArchivedCastingKeysShown()" style="font-size: 1.1rem;">{{ 'SETTINGS.CASTINGKEYS.ARCHIVED.TITLE.TEXT' | translate }}</span>
        <span class="f-grow"></span>
        <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
                                 [(ngModel)]="unArchivedVsArchived">
          <mat-button-toggle value="unarchived" aria-label="Text align left">
            <mat-icon matTooltip="Show casting keys" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below">list
            </mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="archived" aria-label="Text align center">
            <mat-icon matTooltip="Show archived casting keys" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below">archive
            </mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div>
      <div *ngIf="getCastingKeysToDisplay().length > 0">
        <div class="casting-list-items">
          <div class="mat-elevation-z8" *ngFor="let apiKey of getCastingKeysToDisplay()">
            <div appearance="outlined" class="outline-card">
              <div class="outline-card-content">
                <div class="card-wrapper">
                  <div class="card-form">
                    <div class="apiKeyFields-wrapper">
                      <div class="card-action">
                        <div class="position-relative f-grow">
                          <mat-form-field appearance="outline" class="api-input w-100">
                            <mat-label>{{ 'SETTINGS.CASTINGKEYS.CASTKEY.LABEL.TEXT' | translate }}</mat-label>
                            <input matInput [(ngModel)]="apiKey.key" readonly #inputTarget>
                          </mat-form-field>
                          <button mat-raised-button (cbOnSuccess)="onCopySucess()" color="primary"
                                  class="copy-inside-input" [ngxClipboard]="inputTarget">
                            <mat-icon class="material-icons-outlined m-0" *ngIf="!isCopied">content_copy</mat-icon>
                            <mat-icon class="material-icons-outlined m-0" *ngIf="isCopied">check</mat-icon>
                          </button>
                        </div>
                        <button mat-icon-button color="accent" (click)="archiveCastingKey($event, apiKey, true)"
                                matTooltip="Archive casting key" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                                matTooltipPosition="below" *ngIf="!apiKey.isArchived">
                          <mat-icon>archive</mat-icon>
                        </button>
                        <button mat-icon-button color="accent" (click)="archiveCastingKey($event, apiKey, false)"
                                matTooltip="Unarchive casting key" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                                matTooltipPosition="below" *ngIf="apiKey.isArchived">
                          <mat-icon>unarchive</mat-icon>
                        </button>
                        <!-- <button mat-icon-button [ngxClipboard]="inputTarget">
                          <mat-icon class="material-icons-outlined">content_copy</mat-icon>
                        </button> -->


                      </div>
                    </div>
                  </div>
                  <div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-left: 16px; display:flex; flex-direction: column;" *ngIf="getCastingKeysToDisplay().length === 0">
        <div style="min-width: 95%;">
          <div style="margin-left: 35%; margin-top: 1.5rem; margin-bottom: 2px; display:flex; flex-direction: column;justify-content: space-around;">
            <span *ngIf="areArchivedCastingKeysShown()">{{'SETTINGS.CASTINGKEYS.NOARCHIVED.CONTENT.TEXT' | translate}}</span>
            <span *ngIf="areUnArchivedCastingKeysShown()">{{'SETTINGS.CASTINGKEYS.NOUNARCHIVED.CONTENT.TEXT' | translate}}</span>
          </div>
        </div>

      </div>

    </div>
    <mat-card-actions align="end" class="px-0">
      <button mat-stroked-button (click)="cancelClicked()" color="primary" style="margin-right: 2rem;">{{
          'SETTINGS.CANCEL.BUTTON.TEXT' | translate }}
      </button>
      <button (click)="saveCastingKeys()" mat-raised-button color="primary">{{ 'SETTINGS.SAVE.BUTTON.TEXT' | translate }}
      </button>
    </mat-card-actions>
  </div>
</div>
