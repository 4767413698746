import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {FirestoreService} from "../../../services/firestore.service";
import {ClientInContextService} from "../../../services/client-in-context.service";
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import * as moment from 'moment-timezone';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {TIME_ZONE} from '../../../common/utils/time-utils';

@Component({
  selector: 'app-create-registration-dialog',
  templateUrl: './create-registration-dialog.component.html',
  styleUrls: ['./create-registration-dialog.component.scss']
})
export class CreateRegistrationDialogComponent implements OnInit, OnDestroy {
  createRegnForm: UntypedFormGroup;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  allWorkersList: any[];
  workersListSubscription: Subscription;
  allTasksList: any[];
  allLocationsList: any[];
  locationListSubscription: Subscription;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  taskListSubscription: Subscription;
  beingSaved = false;
  selectedDateInParent: string;
  private presenceDoc: any;
  private presenceId: string;
  varietyListSubscription: Subscription;
  rowListSubscription: Subscription;
  allRowsList: any[];
  allVarietiesList: any[];

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CreateRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.selectedDateInParent = data.selectedDateInParent;

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
      }
    );

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.taskListSubscription = this.firestoreService.getAllUnarchivedTasksForClientId(this.selectedClientDocData?.id).subscribe((tasksList) => {
        this.allTasksList = tasksList.filter(task => ['TASK', 'BREAK'].includes(task.type)).sort((taskA: any, taskB: any) => {
          return taskA.name?.toLowerCase() < taskB.name?.toLowerCase() ? -1 : taskA.name?.toLowerCase() > taskB.name?.toLowerCase() ? 1 : 0;
        })
      });

      this.workersListSubscription = this.firestoreService.getUnArchivedWorkersForClientId(this.selectedClientDocData?.id).subscribe((workerList) => {
        this.allWorkersList = workerList?.sort((wrkrA: any, wrkrB: any) => {
          return wrkrA.name?.toLowerCase() < wrkrB.name?.toLowerCase() ? -1 : wrkrA.name?.toLowerCase() > wrkrB.name?.toLowerCase() ? 1 : 0;
        })
      });

      this.rowListSubscription = this.firestoreService.getAllRowsForClientId(this.selectedClientDocData.id)
      .subscribe(rows => {
        this.allRowsList = rows;
      });

      this.varietyListSubscription = this.firestoreService.getAllUnarchivedVarietiesForClientId(this.selectedClientDocData.id)
      .subscribe((varietyList) =>
        (this.allVarietiesList = varietyList.sort((varietyA: any, varietyB: any) => {
          return varietyA.name?.toLowerCase() < varietyB.name?.toLowerCase() ? -1 :
            varietyA.name?.toLowerCase() > varietyB.name?.toLowerCase() ? 1 : 0;
        })));

      this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => {
        this.allLocationsList = locationsList?.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        })
      });
    });
  }

  ngOnInit() {
    this.createRegnForm = this.fb.group({
      workerId: [{value: null, disabled: false}, [Validators.required]],
      locationId: [{value: null, disabled: false}, []],
      startTime: [{value: null, disabled: false}, [Validators.required]],
      endTime: [{value: null, disabled: false}, [Validators.required]],
      taskId: [{value: null, disabled: false}, [Validators.required]],
      rowId: [{value: null, disabled: false}, []],
      count: [{value: null, disabled: false}, []],
      varietyId: [{value: null, disabled: false}, []]
    });
  }

  async createRegn() {
    if (this.createRegnForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    if (!this.createRegnForm.valid) {
      this.openSnackBar('Please fill all mandatory values', 'error');
      return;
    }

    this.beingSaved = true;

    const formValues = this.createRegnForm.value;
    //this.dialogRef.close(this.form.value);
    const taskRegnObject: any = {};

    try {
      const rawDate = moment(this.selectedDateInParent).format('YYYY-MM-DD') + ' 12:00';
      let regnStartTimestampMoment = null;
      let regnEndTimestampMoment = null;
      if (formValues.startTime) {
        regnStartTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +formValues.startTime.split(':')[0] ?? 0,
          minute: +formValues.startTime.split(':')[1] ?? 0,
        });
        taskRegnObject.startTimestamp = regnStartTimestampMoment.toDate()
      }

      if (formValues.endTime) {
        regnEndTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +formValues.endTime.split(':')[0] ?? 0,
          minute: +formValues.endTime.split(':')[1] ?? 0,
        });
        taskRegnObject.endTimestamp = regnEndTimestampMoment.toDate()
      }


      if (regnStartTimestampMoment && regnEndTimestampMoment) {
        if (regnStartTimestampMoment.isSameOrAfter(regnEndTimestampMoment)) {
          this.openSnackBar('End timestamp should be after start timestamp!', 'error');
          this.beingSaved = false;
          return;
        }
      }

      if (formValues.workerId) {
        formValues.worker = this.allWorkersList.filter((worker) => worker.id === formValues.workerId)[0];
        taskRegnObject.workerId = formValues.workerId;
        taskRegnObject.workerName = formValues.worker.name ?? null;
        taskRegnObject.workerGroupId = formValues.worker.workerGroupId ?? null;
        taskRegnObject.workerGroupName = formValues.worker.workerGroupName ?? null;
      }

      if (formValues.rowId) {
        formValues.row = this.allRowsList.filter((row) => row.id === formValues.rowId)[0];
        taskRegnObject.rowId = formValues.rowId;
        taskRegnObject.rowNumber = formValues.row.rowNumber ?? null;
      }

      if (formValues.varietyId) {
        formValues.variety = this.allVarietiesList.filter((variety) => variety.id === formValues.varietyId)[0];
        taskRegnObject.varietyId = formValues.varietyId;
        taskRegnObject.varietyName = formValues.variety.name ?? null;
      }

      if (formValues.taskId) {
        formValues.task = this.allTasksList.filter((task) => task.id === formValues.taskId)[0];
        taskRegnObject.taskId = formValues.taskId;
        taskRegnObject.taskName = formValues.task.name ?? null;
        taskRegnObject.taskFunction = formValues.task.func ?? null;
        taskRegnObject.taskType = formValues.task.type ?? null;
      }

      if (formValues.locationId) {
        formValues.location = this.allLocationsList.filter((location) => location.id === formValues.locationId)[0];
        taskRegnObject.locationId = formValues.locationId ?? null
        taskRegnObject.locationName = formValues.location.name ?? null
      } else {
        taskRegnObject.locationId = null;
        taskRegnObject.locationName = null;
      }

      taskRegnObject.count = formValues.count ?? null;

      taskRegnObject.createdFromDashboard = true; //backend will ignore creation of taskRegn & only update durations if this flag is set
      taskRegnObject.durationTotal = regnEndTimestampMoment?.diff(regnStartTimestampMoment, 'seconds');
      taskRegnObject.createdByUserId = this.loggedInUserDocData.id ?? null;
      taskRegnObject.createdByUserName = this.loggedInUserDocData.name ?? null;
      taskRegnObject.isArchived = false;
      taskRegnObject.clientId = this.selectedClientDocData.id;
      taskRegnObject.clientName = this.selectedClientDocData.name ?? null;
      taskRegnObject.deviceType = 'browser-dashboard';


      const presenceDate = moment(this.selectedDateInParent).startOf('day').toDate();
      const presenceCheckSubscription = this.firestoreService.getPresenceDocForDateForRegns(presenceDate, formValues.workerId, this.selectedClientDocData.id).subscribe({
        next: async (presenceDocs) => {
          presenceCheckSubscription.unsubscribe();
          if (presenceDocs.length > 0) {
            this.presenceDoc = presenceDocs[0];
            this.presenceId = presenceDocs[0].id;
            console.log(`Existing presenceId:${this.presenceId}`);
          } else {
            const newPresenceRef = await this.firestoreService.addPresenceDoc(presenceDate, this.loggedInUserDocData, this.selectedClientDocData.id,
              formValues.worker, this.selectedClientDocData.registerWatchCheckIn ? taskRegnObject.startTimestamp : null, formValues.location);
            this.presenceId = newPresenceRef.id;
            console.log(`Newly created presenceId:${this.presenceId}`);
            if (this.selectedClientDocData.registerWatchCheckIn) {
              const startDayTaskRegnPITObj: any = {
                clientId: this.selectedClientDocData.id,
                clientName: this.selectedClientDocData.name ?? null,
                deviceId: null,
                deviceNumber: null,
                deviceType: 'browser-dashboard',
                isArchived: false,
                locationId: formValues.location?.id ?? null,
                locationName: formValues.location?.name ?? null,
                taskFunction: 'START_DAY',
                createdFromDashboard: true,
                taskName: 'Start Day',
                taskType: 'PIT',
                timestamp: taskRegnObject.startTimestamp,
                workerId: formValues.worker?.id ?? null,
                workerName: formValues.worker?.name ?? null,
                workerGroupId: formValues.worker?.workerGroupId ?? null,
                workerGroupName: formValues.worker?.workerGroupName ?? null
              }
              await this.firestoreService.createRegn(startDayTaskRegnPITObj, this.presenceId, this.selectedClientDocData.id);
            }

          }
          try {
            await this.firestoreService.createRegn(taskRegnObject, this.presenceId, this.selectedClientDocData.id);
            this.openSnackBar('Registration created successfully', 'success');
            this.createRegnForm.markAsPristine();
            this.dialogRef.close();
            this.beingSaved = false;
          } catch(error){
            this.beingSaved = false;
            this.openSnackBar('Error in creating task:' + error.message, 'error');
            console.log(error.message);
          }
        },
        error: (error) => {
          this.beingSaved = false;
          this.openSnackBar('Error in saving changes:' + error.message, 'error');
          console.log(error.message);
        }
      });

    } catch (error) {
      this.beingSaved = false;
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }


  ngOnDestroy(): void {
    this.workersListSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.taskListSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.varietyListSubscription?.unsubscribe();
    this.rowListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

}


