<div class="listing-container" *ngIf="perms?.canRead">
  <div class="controls-container px-2 px-md-0">
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
      *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create Worker" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon>{{'SETTINGS.WORKER.CREATEWORKER.BUTTON.TEXT' | translate}}</button>

    <span class="f-grow"></span>
    <button mat-stroked-button color="primary" class="setting-add-button mr-3 hide-sm" (click)="openLabels()">
      <mat-icon class="material-symbols-outlined" matTooltip="Labels" matTooltipClass="tooltip-class"
        matTooltipHideDelay="100000">
        label
      </mat-icon> {{'SETTINGS.LABELS.BUTTON.TEXT' | translate}}</button>
    <button mat-stroked-button color="primary" class="setting-add-button mr-3 hide-sm" (click)="openWorkerGroup()">
      <mat-icon matTooltip="Worker Groups" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        group
      </mat-icon> {{'SETTINGS.WORKERGROUPS.BUTTON.TEXT' | translate}}</button>

    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
        [(ngModel)]="unArchivedVsArchived" (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon matTooltip="Show users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon matTooltip="Show archived users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="table-card table-mobile-res table-to-card-mb">
    <div *ngIf="areArchivedWorkersShown()">{{'SETTINGS.WORKERS.ARCHIVEDWORKERS.BUTTON.TEXT' | translate}}</div>
    <div class="mat-elevation-z8" style="clear: both;">
        <app-common-table [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay" [gridList]="workerList" [tableName]="'workerTable'" (editDialogOpened)="openEditDialog($event)" (onArchiveRow)="archiveWorker($event)" (onUnArchiveRow)="unarchiveWorker($event)" [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'worker'">

        </app-common-table>
    </div>
  </div>
</div>
