<div class="page-top-space">
  <mat-card class="page-main-card">
    <mat-card-content>
     
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
 

</div>
