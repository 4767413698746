import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {ThemePalette} from '@angular/material/core';
import * as moment from 'moment-timezone';
import {TIME_FORMAT, TIME_ZONE} from "../../../common/utils/time-utils";

@Component({
  selector: 'app-edit-session-dialog',
  templateUrl: './edit-session-dialog.component.html',
  styleUrls: ['./edit-session-dialog.component.scss']
})
export class EditSessionDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  originalSession: any;
  allLocationsList: any[];
  allRowsList: any[];
  allWorkersList: any[];
  allVarietiesList: any[];
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  locationListSubscription: Subscription;
  beingSaved = false;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = true;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public options = [
    {value: true, label: 'True'},
    {value: false, label: 'False'}
  ];

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];
  workerListSubscription: Subscription;
  rowListSubscription: Subscription;
  varietyListSubscription: Subscription;
  mappedRowsList: any[];
  private readonly originalSessionDocument: any;
  trolleyListSubscription: Subscription;
  allTrolleysList: any[];
  selectedLocation: any;
  locationSelected: boolean;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditSessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.originalSession = data.sessionRecord;
    this.originalSessionDocument = data.sessionRecord.originalSessionDocument;
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(userDocData => {
      this.loggedInUserDocData = userDocData;
    });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.trolleyListSubscription = this.firestoreService.getAllUnarchivedAssetsForClientId(this.selectedClientDocData.id).subscribe(assetsList => {
        this.allTrolleysList = assetsList.filter(asset => asset.type === 'TROLLEY')
      });

      this.workerListSubscription = this.firestoreService.getUnArchivedWorkersForClientId(this.selectedClientDocData?.id).subscribe((workersList) => {
        this.allWorkersList = workersList.sort((workerA: any, workerB: any) => {
          return workerA.name.toLowerCase() < workerB.name.toLowerCase() ? -1 : workerA.name.toLowerCase() > workerB.name.toLowerCase() ? 1 : 0;
        });
      });

      this.varietyListSubscription = this.firestoreService.getAllUnarchivedVarietiesForClientId(this.selectedClientDocData.id)
        .subscribe((varietyList) => this.allVarietiesList = varietyList);

      this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => {
        this.allLocationsList = locationsList.sort((locA: any, locB: any) => {
          return locA.name.toLowerCase() < locB.name.toLowerCase() ? -1 : locA.name.toLowerCase() > locB.name.toLowerCase() ? 1 : 0;
        });
        if (this.originalSession.locationId) {
          this.locationSelected = true;
          this.selectedLocation = this.allLocationsList.filter(loc => loc.id === this.originalSession.locationId)[0];
          if (this.selectedLocation.trolleySelectorMode === 'RANGE') {
            this.form.controls.trolleyId.setValidators(Validators.required);
          } else {
            this.form.controls.trolleyIdSelected.setValidators(Validators.required);
          }
        }
      });

      if (this.originalSession.locationId) {
        this.loadRowsForLocation(this.originalSession.locationId);
      }
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      count: [this.originalSessionDocument.count, [Validators.required]],
      trolleyId: [this.originalSessionDocument.trolleyName ? null : this.originalSessionDocument.trolleyId, []],
      trolleyIdSelected: [this.originalSessionDocument.trolleyName ? this.originalSessionDocument.trolleyId : null, []],
      workerId: [this.originalSessionDocument.workerId, [Validators.required]],
      locationId: [this.originalSessionDocument.locationId, [Validators.required]],
      rowId: [this.originalSessionDocument.rowId, [Validators.required]],
      startTimestamp: [moment(this.originalSession.startTs?.toDate()).tz(TIME_ZONE).format('HH:mm'), [Validators.required]],
      endTimestamp: [this.originalSession.endTs ? moment(this.originalSession.endTs?.toDate()).tz(TIME_ZONE).format('HH:mm') : null, [Validators.required]],
    });
  }

  async updateSession() {

    if (this.form.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    if (!this.form.valid) {
      this.openSnackBar('Please fill in all mandatory fields', 'error');
      return;
    }

    const isOriginal = this.originalSession.hasOwnProperty('isOriginal') ? this.originalSession.isOriginal : true;
    const isManual = this.originalSession.hasOwnProperty('isManual') ? this.originalSession.isManual : false;
    const createdFromTraining = this.originalSession.hasOwnProperty('createdFromTraining') ? this.originalSession.createdFromTraining : false;

    const sessionToUpdate = this.form.value;
    if (isOriginal && !isManual && !createdFromTraining) {
      sessionToUpdate.backupDocId = await this.firestoreService.createOriginalVersionCopyOfSession(this.originalSession.sessionId,
        this.selectedClientDocData.id, this.originalSessionDocument);
      sessionToUpdate.isOriginal = false;
    }

    if (sessionToUpdate.locationId) {
      if (sessionToUpdate.locationId !== this.originalSession.locationId) {
        sessionToUpdate.locationName = this.allLocationsList
        .filter((location) => location.id === sessionToUpdate.locationId).map((location) => (location.name ? location.name : null))[0];
      }
    } else {
      sessionToUpdate.locationName = null;
    }

    if (sessionToUpdate.count) {
      sessionToUpdate.count = +sessionToUpdate.count;
    } else {
      sessionToUpdate.count = 0;
    }

    if (sessionToUpdate.workerId) {
      if (sessionToUpdate.workerId !== this.originalSession.workerId) {
        sessionToUpdate.workerName =
          this.allWorkersList.filter((worker) => worker.id === sessionToUpdate.workerId).map((worker) => (worker.name ? worker.name : null))[0];
        sessionToUpdate.workerLabelIds =
          this.allWorkersList.filter((worker) => worker.id === sessionToUpdate.workerId).map((worker) => (worker.labelIds ? worker.labelIds : null))[0];
        sessionToUpdate.workerLabels =
          this.allWorkersList.filter((worker) => worker.id === sessionToUpdate.workerId).map((worker) => (worker.labels ? worker.labels : null))[0];
      }
    } else {
      sessionToUpdate.workerName = null;
      sessionToUpdate.workerLabelIds = [];
      sessionToUpdate.workerLabels = [];
    }

    if (sessionToUpdate.rowId) {
        const rowRecord = this.allRowsList.filter((row) => row.id === sessionToUpdate.rowId)[0];
        sessionToUpdate.rowNumber = rowRecord.rowNumber ?? null;
        sessionToUpdate.rowFloorArea = rowRecord.floorArea ?? null;
        sessionToUpdate.layoutId = rowRecord.layoutId ?? null;
        sessionToUpdate.layoutName = rowRecord.layoutName ?? null;
        sessionToUpdate.rowLabels = rowRecord.labels ?? [];
        sessionToUpdate.rowLabelIds = rowRecord.labelIds ?? [];
        sessionToUpdate.varietyId = rowRecord.varietyId ?? null;
        sessionToUpdate.varietyName = rowRecord.varietyName ?? null;
        if (rowRecord.varietyId) {
          const varietyRecord = this.allVarietiesList.filter((variety) => variety.id === rowRecord.varietyId)[0];
          sessionToUpdate.varietyLabelIds = varietyRecord.labelIds ?? [];
          sessionToUpdate.varietyLabels = varietyRecord.labels ?? [];
        } else {
          sessionToUpdate.varietyLabelIds = [];
          sessionToUpdate.varietyLabels = [];
        }
        sessionToUpdate.activityId = rowRecord.activityId ?? null;
        sessionToUpdate.activityName = rowRecord.activityName ?? null;
    } else {
      sessionToUpdate.rowNumber = null;
      sessionToUpdate.layoutId = null;
      sessionToUpdate.layoutName = null;
      sessionToUpdate.rowLabels = [];
      sessionToUpdate.rowLabelIds = [];
      sessionToUpdate.varietyLabelIds = [];
      sessionToUpdate.varietyLabels = [];
      sessionToUpdate.varietyId = null;
      sessionToUpdate.varietyName = null;
      sessionToUpdate.activityId = null;
      sessionToUpdate.activityName = null;
    }

    let toUpdateTimestamp = false;
    const rawDate = moment(this.originalSession.startTs.toDate()).format('YYYY-MM-DD') + ' 12:00';
    const regnStartTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
      hour: +sessionToUpdate.startTimestamp.split(':')[0] ?? 0,
      minute: +sessionToUpdate.startTimestamp.split(':')[1] ?? 0,
    });

    let regnEndTimestampMoment;

    if (sessionToUpdate.endTimestamp) {
      regnEndTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
        hour: +sessionToUpdate.endTimestamp.split(':')[0] ?? 0,
        minute: +sessionToUpdate.endTimestamp.split(':')[1] ?? 0,
      });
    }

    if (sessionToUpdate.trolleyIdSelected) {
      const selectedTrolley: any = this.allTrolleysList.filter(trolley => trolley.id === sessionToUpdate.trolleyIdSelected)[0];
      sessionToUpdate.trolleyId = sessionToUpdate.trolleyIdSelected;
      sessionToUpdate.trolleyName = selectedTrolley.name ?? null;
      sessionToUpdate.trolleyNumber = selectedTrolley.number ?? null;
      delete sessionToUpdate.trolleyIdSelected;
    } else if (sessionToUpdate.trolleyId) {
      sessionToUpdate.trolleyIdSelected = null;
      sessionToUpdate.trolleyName = null;
      sessionToUpdate.trolleyNumber = null;
    }

    if (isManual) {
      if (sessionToUpdate.startTimestamp) {
        sessionToUpdate.startTimestamp = regnStartTimestampMoment.toDate();
        sessionToUpdate.nettStartTimestamp = sessionToUpdate.startTimestamp;
      } else {
        sessionToUpdate.startTimestamp = null;
        sessionToUpdate.nettStartTimestamp = null;
      }

      if (sessionToUpdate.endTimestamp) {
        sessionToUpdate.endTimestamp = regnEndTimestampMoment.toDate();
        sessionToUpdate.nettEndTimestamp = sessionToUpdate.endTimestamp;
      } else {
        sessionToUpdate.endTimestamp = null;
        sessionToUpdate.nettEndTimestamp = null;
      }

      if (sessionToUpdate.startTimestamp && sessionToUpdate.endTimestamp && sessionToUpdate.count) {
        sessionToUpdate.performanceRatio = 1;
        const time = (regnEndTimestampMoment.diff(regnStartTimestampMoment, 'seconds'));
        sessionToUpdate.grossPerformance = +((+sessionToUpdate.count / time) * 3600).toFixed(0);
        sessionToUpdate.nettPerformance = +((+sessionToUpdate.count / time) * 3600).toFixed(0);
      }
    } else {
      if (sessionToUpdate.startTimestamp) {
        sessionToUpdate.startTimestamp = regnStartTimestampMoment.toDate();
      } else {
        sessionToUpdate.startTimestamp = null;
      }

      if (sessionToUpdate.endTimestamp) {
        sessionToUpdate.endTimestamp = regnEndTimestampMoment.toDate();
      } else {
        sessionToUpdate.endTimestamp = null;
      }

      if ((sessionToUpdate.count !== this.originalSessionDocument.count) && (sessionToUpdate.count)) {
        if (sessionToUpdate.startTimestamp && sessionToUpdate.endTimestamp
          && this.originalSessionDocument.nettStartTimestamp && this.originalSessionDocument.nettEndTimestamp && sessionToUpdate.count) {
          const grossTime = (regnEndTimestampMoment.diff(regnStartTimestampMoment, 'seconds'));
          const nettTime = (this.originalSessionDocument.nettEndTimestamp.toMillis() - this.originalSessionDocument.nettStartTimestamp.toMillis()) / (3600000);
          sessionToUpdate.grossPerformance = +((+sessionToUpdate.count / grossTime) * 3600).toFixed(0);
          sessionToUpdate.nettPerformance = +(+sessionToUpdate.count / nettTime).toFixed(0);
          sessionToUpdate.performanceRatio = sessionToUpdate.grossPerformance / sessionToUpdate.nettPerformance;
        }
      } else if (sessionToUpdate.count === this.originalSessionDocument.count) {
        toUpdateTimestamp = true;
      }
    }

    sessionToUpdate.updatedByUserId = this.loggedInUserDocData.id ?? null;
    sessionToUpdate.updatedByUserName = this.loggedInUserDocData.name ?? null;

    try {
      sessionToUpdate.id = this.originalSession.sessionId;
      await this.firestoreService.updateSessionForClientId(sessionToUpdate, this.selectedClientDocData.id, toUpdateTimestamp);
      this.openSnackBar('Changes have been saved', 'success');
      this.form.markAsPristine();
      this.dialogRef.close({data: {id: sessionToUpdate.id}});
    } catch (error) {
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close({data: {id: null}});
  }

  onLocationChange() {
    if (this.form.value.locationId) {
      this.locationSelected = true;
      this.selectedLocation = this.allLocationsList.filter(loc => loc.id === this.form.value.locationId)[0];
      if (this.selectedLocation.trolleySelectorMode === 'RANGE') {
        this.form.controls.trolleyId.setValidators(Validators.required);
        this.form.patchValue({
          trolleyIdSelected: null
        });
        this.form.controls.trolleyIdSelected.clearValidators();
        this.form.controls.trolleyIdSelected.updateValueAndValidity();
      } else {
        this.form.patchValue({
          trolleyId: null
        });
        this.form.controls.trolleyId.clearValidators();
        this.form.controls.trolleyId.updateValueAndValidity();
        this.form.controls.trolleyIdSelected.setValidators(Validators.required);
      }
      this.loadRowsForLocation(this.form.value.locationId);
      this.form.patchValue({
        rowId: null,
      });
    } else {
      this.locationSelected = false;
      this.selectedLocation = null;
      this.form.patchValue({
        trolleyId: null,
        trolleyIdSelected: null
      });
      this.form.controls.trolleyId.setValidators(null);
      this.form.controls.trolleyIdSelected.setValidators(null);
      this.allRowsList = [];
      this.mappedRowsList = [];
      this.form.patchValue({
        rowId: null,
      });
    }
  }

  loadRowsForLocation(locationId: string) {
    this.rowListSubscription = this.firestoreService.getAllRowsForLocIdForClientId(this.selectedClientDocData?.id, locationId).subscribe((rowsList) => {
      this.allRowsList = rowsList;
      this.mappedRowsList = rowsList.map(row => {
        return {
          ...row,
          name: `${row.rowNumber} (${row.varietyName})`
        }
      });
      this.mappedRowsList = this.mappedRowsList.sort((rowA: any, rowB: any) => {
        return +rowA.rowNumber < +rowB.rowNumber ? -1 : +rowA.rowNumber > +rowB.rowNumber ? 1 : 0;
      });
    });
  }

  async revertSession() {
    if (this.originalSession.hasOwnProperty('isOriginal') && !this.originalSession) {
      return;
    }
    await this.firestoreService.restoreSessionFromVersionBackup(this.originalSession.sessionId, this.selectedClientDocData.id,
      this.originalSessionDocument.backupDocId, this.loggedInUserDocData);
    this.openSnackBar('Session has been reverted to original values', 'success');
    this.dialogRef.close({data: {id: this.originalSession.sessionId}});
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.rowListSubscription?.unsubscribe();
    this.workerListSubscription?.unsubscribe();
    this.varietyListSubscription?.unsubscribe();
    this.trolleyListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
