import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[uppercaseHex]'
})
export class UppercaseHexDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value.toUpperCase();

    inputValue = inputValue.replace(/[^0-9A-F]/g, '');

    this.control.control?.setValue(inputValue, { emitEvent: false });
  }
}
