<ng-container *ngIf="perms?.canRead">
  <div class="performance-actions">
    <app-date-selection class="f-grow"></app-date-selection>

    <div class="sort-dropdown-wrapper hide-sm" [style.display]="isChartSectionDisplayed()">

      <mat-form-field appearance="outline" class="sort-dropdown-formfield">
        <mat-icon matPrefix>sort</mat-icon>
        <mat-select [(ngModel)]="performanceSortOption" (selectionChange)="performanceSortOptionChanged()"
          [disabled]="(!allAvgsPerformanceData || (allAvgsPerformanceData.length === 0))">
          <mat-option value="-1"> {{'DASHBOARD.LABOR.PERFORMANCE.ALLAVERAGES.LABEL.TEXT' | translate}}</mat-option>
          <mat-option *ngFor="let worker of allWorkersList" [value]="worker.workerId">
            {{worker.workerName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="sort-dropdown-wrapper hide-sm" [style.display]="isAvgPerformanceChartDisplayed()">

      <mat-form-field class="sort-dropdown-formfield" appearance="outline">
        <mat-icon matPrefix>sort</mat-icon>
        <mat-select [(value)]="dataSortOption" (selectionChange)="dataSortOptionChanged()" style="margin-left: 0.4rem;"
          [disabled]="(!allAvgsPerformanceData || (allAvgsPerformanceData.length === 0))">
          <mat-option value="AZ"><span
              innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="ZA"><span
              innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="PerfH2L"><span
              innerHTML="{{'DASHBOARD.PAGESORT.NETPERFORMACE.HIGHLOW.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="PerfL2H"><span
              innerHTML="{{'DASHBOARD.PAGESORT.NETPERFORMACE.LOWHIGH.LABEL.TEXT' |  translate}}"></span></mat-option>

        </mat-select>
      </mat-form-field>
    </div>
    <div [style.display]="isWorkerSpecificPerfChartDisplayed()" class="hide-on-small">

      <mat-form-field class="sort-dropdown-formfield" appearance="outline">
        <mat-icon matPrefix>sort</mat-icon>
        <mat-select [(value)]="dataWorkerSpecificSortOption" (selectionChange)="dataWorkerSpecificSortOptionChanged()"
          style="margin-left: 0.4rem;" [disabled]="(!allAvgsPerformanceData || (allAvgsPerformanceData.length === 0))">
          <mat-option value="AZ"><span
              innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="ZA"><span
              innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="NetPerfH2L"><span
              innerHTML="{{'DASHBOARD.PAGESORT.NETPERFORMACE.HIGHLOW.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="NetPerfL2H"><span
              innerHTML="{{'DASHBOARD.PAGESORT.NETPERFORMACE.LOWHIGH.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="RowL2H"><span
              innerHTML="{{'DASHBOARD.PAGESORT.SORTROW.HIGHLOW.LABEL.TEXT' |  translate}}"></span></mat-option>
          <mat-option value="RowH2L"><span
              innerHTML="{{'DASHBOARD.PAGESORT.SORTROW.LOWHIGH.LABEL.TEXT' |  translate}}"></span></mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="client-name-blur">
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" multiple hideMultipleSelectionIndicator="true">
        <mat-button-toggle [checked]="workerNamesBlurred" [value]="workerNamesBlurred"
          (change)="updateBlurredSettingForUser()"><mat-icon class="material-symbols-outlined"
            aria-hidden="false">person_off</mat-icon></mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="toggle-btn-wrapper">

      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true"
        [(ngModel)]="performanceChartVsTable">
        <mat-button-toggle value="chart" aria-label="Text align left">
          <mat-icon class="material-symbols-outlined"
            matTooltip="{{'DASHBOARD.PAGETOGGLE.SEECHART.LABEL.TEXT' |  translate}}" matTooltipClass="tooltip-class"
            matTooltipHideDelay="100" matTooltipPosition="below">bar_chart
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="table" aria-label="Text align center">
          <mat-icon class="material-symbols-outlined"
            matTooltip="{{'DASHBOARD.PAGETOGGLE.SEETABLE.LABEL.TEXT' |  translate}}" matTooltipClass="tooltip-class"
            matTooltipHideDelay="100" matTooltipPosition="below">table_chart
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="table-card" [ngClass]="{'worker-name-blurred':workerNamesBlurred}">
    <div class="preformance-info flex-wrap">
      <div *ngIf="allAvgsPerformanceData?.length > 0" class="flex-wrap">
        <span><span class="cstm-color">{{'DASHBOARD.PRODUCTIVITY.STATUS.FININSHED.LABEL.TEXT' | translate}}</span>
          {{countData.finished.count}} {{'DASHBOARD.PRODUCTIVITY.STATUS.PICKED.LABEL.TEXT' | translate}},
          {{countData.finished.rows}} {{'DASHBOARD.PRODUCTIVITY.STATUS.ROWS.LABEL.TEXT' | translate}}</span> <br />
        <span><span class="cstm-color">{{'DASHBOARD.PRODUCTIVITY.STATUS.ACTUAL.LABEL.TEXT' | translate}}</span>
          {{countData.totalAll.count}} {{'DASHBOARD.PRODUCTIVITY.STATUS.PICKED.LABEL.TEXT' | translate}},
          {{countData.totalAll.rows}} {{'DASHBOARD.PRODUCTIVITY.STATUS.ROWS.LABEL.TEXT' | translate}}</span> <br />
      </div>
      <mat-icon matTooltip="{{'DASHBOARD.TOOLTIP.DOWNLOADCSV.LABEL.TEXT' |  translate}}" matTooltipClass="tooltip-class"
        matTooltipHideDelay="100" matTooltipPosition="below" (click)="downloadAsCSV('Performance')"
        *ngIf="allAvgsPerformanceData.length > 0">download
      </mat-icon>
    </div>
    <div [style.height.px]="graphHeight" [style.display]="isAvgPerformanceChartDisplayed()"
      *ngIf="allAvgsPerformanceData?.length > 0">
      <ngx-charts-bar-horizontal-2d [view]="" [scheme]="colorScheme" [results]="allAvgsPerformanceData"
        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel" [barPadding]="barPadding" [noBarWhenZero]="true" [showGridLines]="false"
        [showDataLabel]="showDataLabel" [animations]="chartAnimation" (select)="onSelect($event)" style="fill: #F3F3F3;"
        [yAxisTickFormatting]="yAxisTickFormatting">
        <ng-template #tooltipTemplate let-model="model">
          <div class="tooltip" [innerHTML]="getToolTipForAvgsChart(model) | safeHtml">
          </div>
        </ng-template>
        <ng-template #yAxisTickTemplate let-label="label">
          <span [class]="getWorkerNameClass(label)">{{ label }}</span>
        </ng-template>
      </ngx-charts-bar-horizontal-2d>
    </div>
    <div *ngIf="sessionsData?.length === 0">
      {{'DASHBOARD.TABEL.SELECTEDDATE.NODATA.T_CONTENT.TEXT' | translate}}
    </div>
    <div [style.height.px]="graphHeightWorkerSpecific" [style.display]="isWorkerSpecificPerfChartDisplayed()"
      *ngIf="workerSpecificPerformanceData?.length > 0 && !allValuesZero">
      <ngx-charts-bar-horizontal-2d [view]="" [scheme]="colorScheme" [results]="workerSpecificPerformanceData"
        [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabelWorkerSpecific" [barPadding]="barPaddingWorkerSpecific" [noBarWhenZero]="true"
        [showGridLines]="false" [showDataLabel]="showDataLabel" [animations]="chartAnimation"
        (select)="onSelect($event)" style="fill: #F3F3F3;">
        <ng-template #tooltipTemplate let-model="model">
          <div class="tooltip" style='text-align: left;'
            [innerHTML]="getToolTipForWorkerSpecificChart(model) | safeHtml">
          </div>
        </ng-template>

        <ng-template #yAxisTickTemplate let-label="label">
          <span [class]="getWorkerNameClass(label)">{{ label }}</span>
        </ng-template>
      </ngx-charts-bar-horizontal-2d>
    </div>

    <div *ngIf="(isWorkerSpecificPerfChartDisplayed() === 'none') && this.performanceSortOption !== '-1'
  && (workerSpecificPerformanceData?.length === 0) && (isPerformanceTableDisplayed() === 'none')">
      {{'DASHBOARD.TABEL.SELECTEDDATE.NODATA.T_CONTENT.TEXT' | translate}}
    </div>

    <div
      *ngIf="(isWorkerSpecificPerfChartDisplayed() === 'block') && (isPerformanceTableDisplayed() === 'none') && allValuesZero">
      {{'DASHBOARD.TABEL.SELECTEDDATE.NODATA.T_CONTENT.TEXT' | translate}}
    </div>

    <section class="listing-container-laborPerf table-styled table-mobile-res table-to-card-mb" style="clear: both;"
      [style.display]="isPerformanceTableDisplayed()">

      <app-common-table [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay"
        [gridList]="tableData" [tableName]="'performanceTable'" [selectedClientDocData]="selectedClientDocData"
        [perms]="perms" [entityName]="'performance'">

      </app-common-table>
    </section>
  </div>
</ng-container>
