import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'opus-datepicker',
  templateUrl: './opus-datepicker.component.html',
  styleUrl: './opus-datepicker.component.scss'
})
export class OpusDatepickerComponent {
  @Input() label!: string;             // Label for the field (e.g., 'End date')
  @Input() control: FormControl;           // Parent form group
  @Output() selectedDateChange = new EventEmitter<any>();

  @Input() selectedValue:any

  clearDate() {
    this.control.setValue(null);
  }
  onDateSelection(event: MatDatepickerInputEvent<Date>){
    //console.log(event)
    this.selectedDateChange.emit(event)
  }
}
