<div class="modal-width">
  <h2 mat-dialog-title>{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.EDITTASK.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="editRegnForm">
    <div class="row">
      <div class="col-lg-6 mb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'DASHBOARD.REGISTRATIONS.TASK.MODAL.STARTTIME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput type="time" formControlName="startTime">
        </mat-form-field>
      </div>
      <div class="col-lg-6 mb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.ENDTIME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput type="time" formControlName="endTime">
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.WORKER.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="workerId">
            <mat-option [value]="null">{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.SELECTWORKER.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let worker of allWorkersList" [value]="worker.id">
              {{ worker.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.TASK.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="taskId">
            <mat-option [value]="null">{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.SELECTTASK.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let task of allTasksList" [value]="task.id">
              {{ task.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.ROW.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="rowId">
            <mat-option [value]="null">{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.SELECTROW.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let row of allRowsList" [value]="row.id">
              {{ row.rowNumber }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.VARIETY.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="varietyId">
            <mat-option [value]="null">{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.SELECTVARIETY.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let variety of allVarietiesList" [value]="variety.id">
              {{ variety.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'DASHBOARD.REGISTRATIONS.TASK.MODAL.COUNT.LABEL.TEXT' | translate}}</mat-label>
          <input matInput type="text" formControlName="count">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.LOCATION.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="locationId">
            <mat-option [value]="null">{{'DASHBOARD.REGISTRATIONS.TASK.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-option>
            <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
              {{ location.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <div style="text-align: center;margin-bottom: 0.4rem;">
    <mat-dialog-actions>
      <button
        mat-stroked-button
        color="accent"
        (click)="revertChanges()"
        class="listing-table-tools tdhover"
        style="margin-left: 2px;"
        matTooltip="Revert to original"
        matTooltipClass="tooltip-class"
        matTooltipHideDelay="100"
        matTooltipPosition="below"
        [disabled]="beingSaved || regnRecordBeingEdited.isOriginal"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
      <button mat-stroked-button (click)="close()"
              matTooltip="Close without saving"
              matTooltipClass="tooltip-class"
              matTooltipHideDelay="100"
              matTooltipPosition="below"
      >{{'DASHBOARD.REGISTRATIONS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateRegistration()" [disabled]="beingSaved" style="margin-left: 20px;"
              matTooltip="Save edited changes"
              matTooltipClass="tooltip-class"
              matTooltipHideDelay="100"
              matTooltipPosition="below"
      >{{'DASHBOARD.REGISTRATIONS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
