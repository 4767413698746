<div class="listing-container" *ngIf="perms?.canRead">
  <div class="controls-container">
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
            *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create Beacon" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'SETTINGS.BEACON.CREATEBEACON.BUTTON.TEXT' | translate}}</button>
      <span class="f-grow"></span>
      <button mat-stroked-button color="primary" (click)="uploadBeacons()" class="setting-add-button mr-3" *ngIf="perms?.canCreate">Upload Beacons</button>
    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [(ngModel)]="unArchivedVsArchived" (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon
            matTooltip="Show Beacons"
            matTooltipClass="tooltip-class"
            matTooltipHideDelay="100"
            matTooltipPosition="below"
          >list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon
            matTooltip="Show archived beacons"
            matTooltipClass="tooltip-class"
            matTooltipHideDelay="100"
            matTooltipPosition="below"
          >archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="button-grid d-flex pb-3">

    <button mat-flat-button color="primary" style="height: 2rem;font-size: 0.95rem;margin-left: 2rem;"
            [disabled]="!isArchiveMultipleEnabled() || beingDeleted || beingArchived || beingResetAssigned || !perms?.canUpdate" (click)="archiveMultipleBeacons()"
            *ngIf="areUnArchivedBeaconsShown()">{{'SETTINGS.BEACONS.ARCHIVE.BUTTON.TEXT' | translate}} {{getSelectedBeaconsCount()}}</button>

    <button mat-flat-button color="primary" style="height: 2rem;font-size: 0.95rem;margin-left: 2rem;"
            [disabled]="!isResetAssignedMultipleEnabled() || beingDeleted || beingArchived || beingResetAssigned || !perms?.canUpdate" (click)="resetAssignmentMultipleBeacons()"
            *ngIf="areUnArchivedBeaconsShown()">{{'SETTINGS.BEACONS.RESETASSGNMNT.BUTTON.TEXT' | translate}} {{selectedRessetableBeaconsCount ? '(' + selectedRessetableBeaconsCount + ')' : ''}}</button>

    <button mat-flat-button color="primary" style="height: 2rem;font-size: 0.95rem;margin-left: 2rem;"
            [disabled]="!areMultipleBeaconsSelected() || beingDeleted || beingArchived || beingResetAssigned || !perms?.canDelete" (click)="deleteMultipleBeacons()"
            *ngIf="areArchivedBeaconsShown()">{{'SETTINGS.BEACONS.DELETE.BUTTON.TEXT' | translate}} {{getSelectedBeaconsCount()}}</button>
  </div>
  <div class="table-card">
    <div *ngIf="areArchivedBeaconsShown()">Archived Beacons</div>
    <div class="mat-elevation-z8" style="clear:both;">
      <app-common-table [isRowExpandable]="false" [isRowDeleteable]="true" [isRowSelectable]="true" [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay" [gridList]="beaconsList" [tableName]="'beaconTable'" (editDialogOpened)="openEditDialog($event)" (onDeleteRow)="deleteBeacon($event)" (onArchiveRow)="archiveBeacon($event)" (onUnArchiveRow)="unarchiveBeacon($event)" [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'beacon'">

      </app-common-table>
    </div>
  </div>

</div>
