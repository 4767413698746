import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {getPermissions} from '../../../common/utils/permission-utils';
import {roleStrength} from '../../../common/utils/permission-utils';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  description: string;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  languagesList: any[];
  allWorkersList: any[];
  workerListSubscription: Subscription;
  roles: string[] = ['regular', 'admin'];

  roleValueChangesObserved = false;

  roleDisplayedValueMap = new Map();

  rolePermissionMapFromDB: any = {};

  standardRolesSubscription: Subscription;

  creatingUser = false;
  perms: any;
  loggedInUserSelfRoleObject: any;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreateUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.roleDisplayedValueMap.set('regular', 'Regular');
    this.roleDisplayedValueMap.set('admin', 'Admin');
    this.roleDisplayedValueMap.set('owner', 'Owner');
    this.roleDisplayedValueMap.set('developer', 'Developer');
    //this.roleDisplayedValueMap.set('custom', 'Custom');

    this.languagesList = data.languagesList;

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
        this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
          if (!selectedClientDocData) {
            return;
          }

          this.workerListSubscription = this.firestoreService.getUnArchivedWorkersForClientId(selectedClientDocData.id).subscribe((workersList) => {
            this.allWorkersList = workersList.sort((worker1, worker2) => {
              return worker1.name.toLowerCase() > worker2.name.toLowerCase() ? 1 : worker1.name.toLowerCase() < worker2.name.toLowerCase() ? -1 : 0;
            });
          });
          this.selectedClientDocData = selectedClientDocData;
          this.loggedInUserSelfRoleObject = userDocData.clients.filter(cl => cl.clientId === this.selectedClientDocData.id)[0];

          this.standardRolesSubscription = this.firestoreService.getAllStandardRoles().subscribe((rolesList: any) => {
            for (const role of rolesList) {
              this.rolePermissionMapFromDB[role.name] = role;
              if (this.loggedInUserDocData.isSuperUser) {
                if (!this.roles.includes(role.id)) {
                  this.roles.push(role.id);
                }
              } else if (roleStrength.get(role.id) <=
                roleStrength.get(this.loggedInUserSelfRoleObject.roleId ? this.loggedInUserSelfRoleObject.roleId : this.loggedInUserSelfRoleObject.role)) {
                if (!this.roles.includes(role.id)) {
                  this.roles.push(role.id);
                }
              }
            }
            this.setPermissionsFromRole('regular');
          });

          if (this.selectedClientDocData.rolePermissionsMap) {
            /* if (this.selectedClientDocData.rolePermissionsMap.name === 'developer' || this.selectedClientDocData.rolePermissionsMap.isSuperUser) {
               this.roles.push('developer');
             }*/
            this.perms = getPermissions('settings', 'users', selectedClientDocData.rolePermissionsMap);
          }
        });
      });
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      notes: ['', []],
      role: ['regular', []],
      languageCode: ['', []],
      associatedWorkerId: ['', []],
      permissions: this.fb.group({
        presences: this.fb.group({
          today: [[], []],
          presences: [[], []],
        }),
        labor: this.fb.group({
          overview: [[], []],
          performance: [[], []],
          productivity: [[], []],
          taskRegns: [[], []],
          sessions: [[], []],
          tasksOverview: [[], []]
        }),
        productivity: this.fb.group({
          rowMap: [[], []],
          trolley: [[], []],
          rowPrdctvty: [[], []],
          varietyPrdctvty: [[], []],
        }),
        training: [[], []],
        settings: this.fb.group({
          general: [[], []],
          userProfile: [[], []],
          locations: [[], []],
          users: [[], []],
          workers: [[], []],
          sessions: [[], []],
          presences: [[], []],
          positions: [[], []],
          assets: [[], []],
          beacons: [[], []],
          varieties: [[], []],
          tasks: [[], []],
          devices: [[], []],
          apis: [[], []]
        }),
        language: [[], []],
        clients: [[], []],
      })
    });
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.workerListSubscription?.unsubscribe();
    this.standardRolesSubscription?.unsubscribe();
  }

  ngOnInit() {

    this.form.controls.role?.valueChanges.subscribe((value) => {
      this.handleRoleChange(value);
    });

  }

  handleRoleChange(value: string) { // Function to handle both initial setup and custom role
    this.roles = ['regular', 'admin', 'owner']; // Reset to default roles
    if (value === 'regular') {
      this.roleValueChangesObserved = true;
      this.setPermissionsFromRole('regular');
    } else if (value === 'admin') {
      this.roleValueChangesObserved = true;
      this.setPermissionsFromRole('admin');
    } else if (value === 'owner') {
      this.roleValueChangesObserved = true;
      this.setPermissionsFromRole('owner');
    } else if (value === 'developer') {
      this.roleValueChangesObserved = true;
      this.setPermissionsFromRole('developer');
    } else { // 'custom' case
      if (!this.roles.includes('custom')) {  // Check before pushing again to prevent duplicate custom role
        this.roles.push('custom');
      }
      //this.form.controls.role.setValue('custom', { emitEvent: false }); // Set form value to custom (important for correct display)
      if (!this.roleValueChangesObserved) {
        this.form.controls.role.setValue('custom', {emitEvent: false});
      }
    }
  }

  setPermissionsFromRole(role: string) {
    const permissions = this.rolePermissionMapFromDB[role];
    this.setFormValuesRecursively(this.form.get('permissions'), permissions);
    this.disablePermissionFields(this.form);
    /* console.log(`role found:` + this.determineRoleFromPermissions(this.form.value.permissions));*/
  }

  private setFormValuesRecursively(formGroup: AbstractControl, values: any) {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const control = formGroup.get(key);
        if (control instanceof FormGroup) {
          this.setFormValuesRecursively(control, values[key]);
        } else if (Array.isArray(values[key])) {
          // Special handling for arrays using patchValue
          control?.patchValue(values[key]);  // <-- Use patchValue for arrays
        } else {
          control?.setValue(values[key]); // Use setValue for other types
        }
      }
    }
  }

  async createUser() {
    if (!this.form.valid) {
      this.openSnackBar('Name & email are mandatory', 'error');
      return;
    }

    if (this.form?.value?.name && (this.form?.value?.name?.trim() === '')) {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    if (this.form?.value?.email && (this.form?.value?.email?.trim() === '')) {
      this.openSnackBar('Invalid value entered for Email', 'error');
      return;
    }

    this.creatingUser = true;

    const userToCreate = this.form.value;
    userToCreate.clientId = this.selectedClientDocData?.id;
    userToCreate.name = userToCreate.name?.trim();
    userToCreate.email = userToCreate.email?.trim();

    if (userToCreate.associatedWorkerId) {
      userToCreate.associatedWorkerName = this.allWorkersList.filter(worker => worker.id === userToCreate.associatedWorkerId)[0]?.name;
      userToCreate.associatedWorkerClientId = this.selectedClientDocData.id;
    }

    userToCreate.roleName = userToCreate.role;
    userToCreate.roleId = userToCreate.role;
    userToCreate.roleType = 'standard';

    this.firestoreService.createUserForClientId(userToCreate, this.selectedClientDocData).subscribe({
      next: async (apiResponse) => {
        this.creatingUser = false;
        if (apiResponse.success) {
          this.dialogRef.close(this.form.value);
          if (apiResponse.wasExistingUser) {
            if (apiResponse.existingUserName === userToCreate.name) {
              this.openSnackBar(`User with email:'${userToCreate.email}' has been provided with required access.`,
                'success');
            } else {
              this.openSnackBar(`User with email:'${userToCreate.email}' already exists with name '${apiResponse.existingUserName}', and has been provided with required access.`,
                'success');
            }
          } else {
            await this.authService.resetPassword(userToCreate.email);
            this.openSnackBar(`User with email:'${userToCreate.email}' created successfully. Reset password email sent to user.`, 'success');
          }
          this.form.reset();
        } else if (apiResponse.success === false) {
          this.openSnackBar('Error in user creation:' + apiResponse.error, 'error');
        }
      },
      error: (error) => {
        this.creatingUser = false;
        this.openSnackBar('Error in user creation:' + error.message, 'error');
        console.log(error.message);
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  disablePermissionFields(form: UntypedFormGroup) {
    const permissionsGroup = form.get('permissions') as UntypedFormGroup;
    this.disableFormControlsRecursive(permissionsGroup);
  }

  private disableFormControlsRecursive(formGroup: AbstractControl) {
    if (formGroup instanceof FormGroup) {
      // tslint:disable-next-line:forin
      for (const controlName in formGroup.controls) {
        this.disableFormControlsRecursive(formGroup.get(controlName));
      }
    } else {
      (formGroup as UntypedFormControl).disable();
    }
  }

}

