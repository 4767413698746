<div class="modal-width">
  <h2 mat-dialog-title> {{'SETTINGS.TASK.EDITTASK.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="editTaskForm">
    <div *ngIf="taskRecordBeingEdited.isPauseTask" class="pb-3">
      {{'SETTINGS.TASK.PAUSETASK.T_CONTENT.TEXT' | translate}}
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'SETTINGS.MODAL.TASKGROUPS.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="taskGroupIds" multiple>
            <mat-option *ngFor="let taskGroup of allTaskGroupsList" [value]="taskGroup.id">
              {{taskGroup.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <small class="clr-primary worker-group" (click)="openAddTaaskGroup()">
          {{'SETTINGS.MODAL.ADDTASKGROUP.BUTTON.TEXT' | translate}}</small>
      </div>
      <div class="col-lg-6 pb-4">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.LABELS.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="labelIds" multiple>
            <mat-option *ngFor="let label of labelsList" [value]="label.id">{{label.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <small class="clr-primary worker-group" (click)="openAddLabel()">{{'SETTINGS.MODAL.ADDLABEL.BUTTON.TEXT' |
          translate}}</small>

      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'SETTINGS.MODAL.TARGETLOCATIONS.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="locationIds" multiple>
            <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
              {{location.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <app-beacon-selection [beaconIds]="editTaskForm.get('beaconIds')"
          [allBeaconsList]="allBeaconsList"></app-beacon-selection>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'SETTINGS.MODAL.TARGETDEVICE.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="deviceTarget" required multiple>
            <mat-option *ngFor="let targetDevice of allTargetDevicesList" [value]="targetDevice.id">
              {{targetDevice.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 pb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{'SETTINGS.MODAL.FUNCTION.LABEL.TEXT' | translate}}</mat-label>
          <mat-select formControlName="func" required>
            <mat-option *ngFor="let funcObj of allFunctionsList" [value]="funcObj.id">
              {{funcObj.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row field-with-hint mb-1" *ngIf="showAssetDetectionFields">
      <div class="col-lg-6 pb-4">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.PRESENCES.INTERVAL.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required formControlName="assetDetectionInterval" type="number" placeholder="0.00" step="0.1"
            class="rssi" max="600" min="0">
          <div matSuffix class="mat-suffix">seconds</div>
          <mat-error class="error-hint" *ngIf="!editTaskForm.controls['assetDetectionInterval'].valid && editTaskForm.controls['assetDetectionInterval'].touched">
            Interval should be between 0 and 600
          </mat-error>

        </mat-form-field>
      </div>
      <div class="col-lg-6 pb-4">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.RSSITHRSHLD.LABEL.TEXT' | translate }}</mat-label>
          <input matInput required formControlName="assetDetectionRssiThreshold" type="number" placeholder="0.00"
            step="0.1" class="rssi" max="0" min="-127">
          <div matSuffix class="mat-suffix">dBm</div>
          <mat-error class="error-hint" *ngIf="!editTaskForm.controls['assetDetectionRssiThreshold'].valid && editTaskForm.controls['assetDetectionRssiThreshold'].touched">
            RSSI threshold should be between -127 and 0
          </mat-error>

        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4" *ngIf="showVarietySelectionField">
      <div class="col-lg-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label> {{ 'SETTINGS.MODAL.VARIETIES.LABEL.TEXT' | translate }}</mat-label>
          <mat-select formControlName="varietyIds" multiple>
            <mat-option *ngFor="let variety of allVarietiesList" [value]="variety.id">
              {{ variety.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.EXTERNALID.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="externalId">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <div class="pb-2 footer-with-Id">
    <div class="col-lg-12 d-flex copyEntityId">
      <input matInput [(ngModel)]="taskId" readonly #copyTarget >
      <button mat-icon-button appCopySuccess [iconTarget]="icon" color="primary" class="copy-inside-input"
        [ngxClipboard]="copyTarget">
        <mat-icon class="material-icons-outlined m-0" #icon>content_copy</mat-icon>
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' |
        translate}}</button>
      <button mat-raised-button color="primary" (click)="updateTask()"
        style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
