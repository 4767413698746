<ng-container *ngIf="perms?.canRead">
<div *ngIf="rowsToDisplay?.length > 0" class="mb-2 mt-3">
  <mat-form-field style="width: 12rem;" [matTooltip]="getInputBoxToolTip()" appearance="outline">
    <mat-label>{{'DASHBOARD.PRODUCTIVITY.ROWMAP.MINIPICK.LABEL.TEXT' |  translate}} <span style="color: darkred;" *ngIf="doneCountField.dirty">*</span></mat-label>
    <input matInput id="doneCount" type="text" [(ngModel)]="doneCount" name="doneCount" #doneCountField="ngModel" [disabled]="!perms?.canUpdate"
      (change)="doneCountChanged()" />
    <button matSuffix mat-icon-button aria-label="Clear" (click)="saveMinPickingsForDone()"
      [disabled]="!doneCountField.dirty">
      <mat-icon>save</mat-icon>
    </button>
  </mat-form-field>
</div>
<div class="table-card">
  <div *ngIf="rowsToDisplay?.length > 0">
    <h4>{{'DASHBOARD.PRODUCTIVITY.ROWMAP.ACTUAL.TITLE.TEXT' |  translate}} </h4>
    <div class="d-flex gap-6 flex-wrap">
      <span><span class="cstm-color">{{'DASHBOARD.PRODUCTIVITY.STATUS.FININSHED.LABEL.TEXT' |  translate}} </span> {{countData.finished.count}} {{'DASHBOARD.PRODUCTIVITY.STATUS.PICKED.LABEL.TEXT' |  translate}},
        {{countData.finished.rows}} {{'DASHBOARD.PRODUCTIVITY.STATUS.ROWS.LABEL.TEXT' |  translate}}</span> <br />
      <span class=""><span class="cstm-color">{{'DASHBOARD.PRODUCTIVITY.STATUS.ACTUAL.LABEL.TEXT' |  translate}}</span> {{countData.totalAll.count}} {{'DASHBOARD.PRODUCTIVITY.STATUS.PICKED.LABEL.TEXT' |  translate}},
        {{countData.totalAll.rows}} {{'DASHBOARD.PRODUCTIVITY.STATUS.ROWS.LABEL.TEXT' |  translate}}</span> <br />
    </div>
    <div class="bar-chart-cards my-3 py-2">
      <div (click)="filterData(item)" class="cart-card-items" [ngClass]="item.classes" *ngFor="let item of barChart; let idx = index">
        <div style="margin-bottom: 4px;">
          <span>{{getBarChartToolTip(item) | translate}}</span>
          <span>{{item.numberOfRows}}</span>
        </div>
      </div>
    </div>
    <div class="bar mt-4 pt-2">
      <div appearance="outlined" *ngFor="let item of barChart; let idx = index" class="bar-card"
        [ngClass]="item.classes" [style.flexGrow]="item.percentage" [matTooltip]="getBarChartToolTip(item) | translate">
        <span class="bar-card-content"> {{item.percentage}}%</span>
      </div>
    </div>

    <div class="data-cards">
      <ng-container *ngFor="let row of rowsToDisplay">
        <ng-template #tooltipTpl>
          <div *ngIf="row?.tooltip"
            style="background: #5b5f66; color: mintcream;padding:5px;border-radius:3px; text-align: center;font-size: 0.95rem;">
            <div style="margin-bottom: 4px;">{{row.tooltip.topLine}}</div>
            <div>({{row.tooltip.bottomLine}})</div>
          </div>
        </ng-template>
        <div appearance="outlined" [ngClass]="getRowClass(row)" class="card data-card-items" mtxTooltipPosition="below"
          [mtxTooltip]="tooltipTpl" (click)="rowClick(row.rowNumber)" *ngIf="!filteredOn || (filteredOn === row.state)">
          <mat-card-content class="gap-6 grid-area-sm">
            <div class="row-content row-number">{{row.rowNumber}}</div>
            <div class="row-content row-varierty">
              <span>{{row.varietyName}}</span>
              <span class="row-state-info">
                <span *ngIf="row.state === 'done' || row.state === 'done-below'">{{row.workerCountStrForCompletedRow ??
                  ''}}</span>
                  <span *ngIf="row.inProgress">{{row.rowLatestWorkerName ?? ''}}</span>
              </span>
            </div>
            <div class="row-content row-state-icon">

              <mat-icon *ngIf="row.state === 'done' || row.state === 'done-below'"
                class="material-symbols-outlined row-state-icon" fontIcon="done" matTooltip="Row done"></mat-icon>
              <mat-icon *ngIf="row.state === 'pending'" class="material-symbols-outlined row-state-icon"
                fontIcon="pending" matTooltip="Pending"></mat-icon>
              <mat-icon *ngIf="row.state === 'add'" class="material-symbols-outlined row-state-icon"
                fontIcon="add_circle" matTooltip="Row added to today"></mat-icon>
              <mat-icon *ngIf="row.state === 'skip'" class="material-symbols-outlined row-state-icon" fontIcon="add"
                matTooltip="Add row to today"></mat-icon>
              <mat-icon *ngIf="row.inProgress" class="material-symbols-outlined row-state-icon"
                fontIcon="directions_walk" matTooltip="In progress"></mat-icon>
            </div>
          </mat-card-content>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="rowsToDisplay?.length === 0">
    There is no data available for today
  </div>
</div>
</ng-container>
