<div style="min-width: 600px;">
    <!-- <h2 mat-dialog-title style="text-align: center; text-decoration: underline; font-size: 1.2rem; margin-bottom: 1rem;">Edit Layout</h2> -->
    <h2 mat-dialog-title> {{'SETTINGS.LOCATIONS.MODAL.EDITLAYOUT.TITLE.TEXT' | translate}}</h2>
    <mat-dialog-content [formGroup]="editLayoutForm" style="text-align: center;">
      <div class="layout-modal-wrapper">
        <div class="row mb-3">
            <div class="col-lg-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'SETTINGS.LOCATIONS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label><input matInput required formControlName="name">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label> {{'SETTINGS.LOCATIONS.MODAL.ROWWIDTH.LABEL.TEXT' | translate}} </mat-label><input matInput type="number" formControlName="rowWidth">
                    <div matSuffix class="mat-suffix">{{'SETTINGS.LOCATIONS.MODAL.METER.LABEL.TEXT' | translate}}</div>
                </mat-form-field>
            </div>
        </div>
        <h2 class="mt-3">{{'SETTINGS.LOCATIONS.MODAL.ROWREFERENCE.TITLE.TEXT' | translate}}</h2>
        <h3 class="mt-1"> {{'SETTINGS.LOCATIONS.MODAL.FIRSTROW.TITLE.TEXT' | translate}}</h3>
        <div class="row grid-row">
            <div class="col-md-4 ">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'SETTINGS.LOCATIONS.MODAL.ROWREFERENCE.TITLE.TEXT' | translate}}</mat-label> <input matInput formControlName="firstRowNumber">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'SETTINGS.LOCATIONS.MODAL.LATITUDE.LABEL.TEXT' | translate}}</mat-label><input type="number" matInput #latitude1 formControlName="firstRowLatitude">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'SETTINGS.LOCATIONS.MODAL.LONGITUDE.LABEL.TEXT' | translate}}</mat-label><input type="number" matInput #longitude1 formControlName="firstRowLongitude">
                </mat-form-field>
            </div>
        </div>
        <a class="hint" (click)="openInGoogleMap(latitude1.value, longitude1.value)">{{'SETTINGS.LOCATIONS.MODAL.GOOGLEMAP.BUTTON.TEXT' | translate}}</a>

        <h3 class="mt-1"> {{'SETTINGS.LOCATIONS.MODAL.LASTROW.TITLE.TEXT' | translate}}</h3>
        <div class="row grid-row">
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'SETTINGS.LOCATIONS.MODAL.ROWREFERENCE.TITLE.TEXT' | translate}}</mat-label> <input matInput formControlName="secondRowNumber">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'SETTINGS.LOCATIONS.MODAL.LATITUDE.LABEL.TEXT' | translate}}</mat-label><input type="number" #latitude2 matInput  formControlName="secondRowLatitude">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>{{'SETTINGS.LOCATIONS.MODAL.LONGITUDE.LABEL.TEXT' | translate}}</mat-label><input type="number" #longitude2 matInput  formControlName="secondRowLongitude">
                </mat-form-field>
            </div>
        </div>
        <a class="hint" (click)="openInGoogleMap(latitude2.value, longitude2.value)">{{'SETTINGS.LOCATIONS.MODAL.GOOGLEMAP.BUTTON.TEXT' | translate}}</a>
        <div class="row mt-3">
          <div class="col-lg-12">
              <mat-form-field class="w-100" appearance="outline">
                  <mat-label>{{'SETTINGS.MODAL.EXTERNALID.LABEL.TEXT' | translate}}</mat-label>
                  <input matInput formControlName="externalId"/>
              </mat-form-field>
          </div>
      </div>
    </div>
    </mat-dialog-content>
    <br><br>
    <div style="text-align: right;">
        <mat-dialog-actions>
            <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CANCEL.BUTTON.TEXT' | translate}}</button>
            <button mat-raised-button color="primary" (click)="updateLayout()" style="margin-left: 20px;">{{'SETTINGS.LOCATION.MODAL.UPDATELAYOUT.BUTTON.TEXT' | translate}}</button>
        </mat-dialog-actions>
    </div>
</div>
