<div style="min-width: 400px;">
  <h2 mat-dialog-title>{{'SETTINGS.LABELS.EDITLABEL.TITLE.TEXT' | translate}}</h2>
  <mat-dialog-content [formGroup]="editLabelForm" style="text-align: center;">
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required
                 formControlName="name">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <br/>
  <div >
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateLabel()" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
