<ng-container *ngIf="perms?.canRead">
<mat-icon [matTooltip]="getTooltipForStreamingButton()" [inline]="true" (click)="toggleStreaming()"
  class="floating-play-btn" *ngIf="tableData?.length > 0">
  {{displayedIcon}}
</mat-icon>
<div class="inner-page-action d-flex">
  <app-date-selection></app-date-selection>
  <div class="client-name-blur f-grow text-right">
    <mat-button-toggle-group name="fontStyle"  aria-label="Font Style" multiple hideMultipleSelectionIndicator="true">
      <mat-button-toggle [checked]="workerNamesBlurred" [value]="workerNamesBlurred" (change)="updateBlurredSettingForUser()">
        <mat-icon class="material-symbols-outlined" aria-hidden="false">person_off</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="listing-container table-card">
  <div *ngIf="tableData?.length === 0">
    {{'DASHBOARD.TABEL.SELECTEDDATE.NODATA.T_CONTENT.TEXT' | translate}}
  </div>
  <div *ngIf="tableData?.length > 0" class="mat-elevation-z8" style="clear: both;"
    [style.display]="isLaborOverviewTableDisplayed()">
    <div class="table-body overflow-auto table-mobile-res table-to-card-mb" [ngClass]="{'worker-name-blurred':workerNamesBlurred}">

      <app-common-table [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay" [gridList]="tableData" [tableName]="'overviewTable'"  [selectedClientDocData]="selectedClientDocData" (expandedModal)="openDialog($event)" [perms]="perms" [entityName]="'overview'">

      </app-common-table>
    </div>


  </div>
</div>
</ng-container>
