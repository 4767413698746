<div style="min-width: 400px;">
  <h2 mat-dialog-title> {{'SETTINGS.BEACON.CREATEBEACON.TITLE.TEXT' | translate}}</h2>

  <mat-dialog-content [formGroup]="form" style="text-align: center;">
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.MACADDRESS.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required uppercaseHex mask="AA:AA:AA:AA:AA:AA" formControlName="mac">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.TYPE.LABEL.TEXT' | translate }}</mat-label>
          <mat-select formControlName="type" required>
            <mat-option value="ASSET">Asset</mat-option>
            <mat-option value="LOCATION">Location</mat-option>
            <mat-option value="POSITION">Position</mat-option>
            <mat-option value="ROW">Row</mat-option>
            <mat-option value="TASK">Task</mat-option>
            <mat-option value="WORKER">Worker</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{ 'SETTINGS.MODAL.BEACONTYPE.LABEL.TEXT' | translate }}</mat-label>
          <mat-select formControlName="beaconType" required>
            <mat-option value="OT2">OT2</mat-option>
            <mat-option value="OT4P">OT4P</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.BATTERYLEVEL.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="batteryLevel">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.ASSIGNEDTYPE.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="assignedType">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.ASSIGNEDID.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="assignedId">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.ASSIGNEDNAME.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="assignedName">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.STICKDURATION.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="stickDuration">
        </mat-form-field>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>{{'SETTINGS.MODAL.RSSITHRESHOLD.LABEL.TEXT' | translate}}</mat-label>
          <input matInput formControlName="rssiThreshold">
        </mat-form-field>
      </div>
    </div>-->
  </mat-dialog-content>
  <div>
    <mat-dialog-actions class="w-100">
      <!--<button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.ARCHIVE.BUTTON.TEXT' | translate}}</button>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.RESETASSIGNING.BUTTON.TEXT' | translate}}</button>-->
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="createBeacon()" style="margin-left: 20px;">{{'SETTINGS.BEACON.CREATEBEACON.TITLE.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
