<div class="modal-width">
  <mat-dialog-content [formGroup]="editDeviceForm" style="text-align: center;max-height: 100%;">
    <mat-form-field  class="pb-3 w-100">
      <mat-label>{{'SETTINGS.MODAL.NAME.LABEL.TEXT' | translate}}</mat-label>
      <input matInput formControlName="name" maxlength="8">
    </mat-form-field>
    <br/>
    <mat-form-field   class="pb-3 w-100">
      <mat-label>{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="locationId">
        <mat-option [value]="null">{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-option>
        <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <mat-form-field  class="pb-3 w-100">
      <mat-label>{{'SETTINGS.MODAL.APPMODE.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="assetTrackerAppMode">
        <mat-option value="MAT">MAT</mat-option>
        <mat-option value="TROLLEY">TROLLEY</mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <ng-container *ngIf="showAssetDetectionFields">
      <mat-form-field  class="pb-4 mb-1 w-100 field-with-hint">
        <mat-label>{{ 'SETTINGS.MODAL.RSSITHRSHLD.LABEL.TEXT' | translate }}</mat-label>
        <input matInput required formControlName="assetDetectionRssiThreshold" type="number" placeholder="0.00"
          step="0.1" class="rssi" max="0" min="-127">
        <div matSuffix class="mat-suffix">dBm</div>
        <mat-error [ngClass]="{'error-hint': !editDeviceForm.controls['assetDetectionRssiThreshold'].valid && editDeviceForm.controls['assetDetectionRssiThreshold'].touched}">
          RSSI threshold should be between -127 and 0
        </mat-error>

      </mat-form-field>
      <br/>
      <mat-form-field  class="pb-4 mb-1 w-100 field-with-hint">
        <mat-label>Expiration</mat-label>
        <input matInput required formControlName="assetRequestedExpirationMinutes" type="number" placeholder="0.00" step="0.1"
          class="rssi" max="300" min="0">
        <div matSuffix class="mat-suffix">minutes</div>
        <mat-error [ngClass]="{'error-hint': !editDeviceForm.controls['assetRequestedExpirationMinutes'].valid && editDeviceForm.controls['assetRequestedExpirationMinutes'].touched}">
         Expiration should be between 0 and 300
        </mat-error>

      </mat-form-field>
      <br/>
    </ng-container>

    <mat-form-field  class="pb-3 w-100">
      <mat-label>{{'SETTINGS.MODAL.SERVICEURL.LABEL.TEXT' | translate}}</mat-label>
      <input matInput formControlName="serviceUrl" maxlength="8">
    </mat-form-field>
    <br/>
    <mat-form-field  class="pb-3 w-100">
      <mat-label>{{'SETTINGS.TABLEHEAD.NOTES.TITLE.TEXT' | translate}}</mat-label>
      <textarea cdkTextareaAutosize
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="2" matInput placeholder="Notes" formControlName="notes" style="overflow: hidden;"
      ></textarea>
    </mat-form-field>
    <br/>
  </mat-dialog-content>
  <div class="footer-with-Id" style="text-align: right;margin-bottom: 0.4rem;">
    <div class="col-lg-12 d-flex copyEntityId">
      <input matInput [(ngModel)]="devicesId" readonly #copyTarget >
      <button mat-icon-button appCopySuccess [iconTarget]="icon" color="primary" class="copy-inside-input"
              [ngxClipboard]="copyTarget">
        <mat-icon class="material-icons-outlined m-0" #icon>content_copy</mat-icon>
      </button>
    </div>
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateDevice()" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
