<div class="container-f" *ngIf="perms?.canRead">
  <div class="listing-container page-top-space">
    <mat-card class="mat-mdc-card mdc-card page-main-card">
      <div class="controls-container">

        <div >
          <button *ngIf="trainingsList?.length > 0" mat-icon-button aria-label=""  matTooltipClass="tooltip-class"
            matTooltipHideDelay="100"
            matTooltipPosition="below" matTooltip="{{'DASHBOARD.TOOLTIP.DOWNLOADCSV.LABEL.TEXT' |  translate}}" (click)="downloadAsCSV('TrainingList')">
            <mat-icon>
              download
            </mat-icon>
          </button>

        </div>
        <div>
          <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [(ngModel)]="unArchivedVsArchived" (change)="toggleArchived()">
            <mat-button-toggle value="unarchived" aria-label="Text align left">
              <mat-icon
                matTooltip="Show trainings"
                matTooltipClass="tooltip-class"
                matTooltipHideDelay="100"
                matTooltipPosition="below"
              >list
              </mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="archived" aria-label="Text align center">
              <mat-icon
                matTooltip="Show archived trainings"
                matTooltipClass="tooltip-class"
                matTooltipHideDelay="100"
                matTooltipPosition="below"
              >archive
              </mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>

      </div>

    <div class="table-card">
      <div *ngIf="areArchivedTrainingsShown()">Archived Trainings</div>
      <div class="mat-elevation-z8 expandable-table" style="clear: both;">
        <ngx-simplebar>
          <table
            mat-table
            [dataSource]="dataSource"
            multiTemplateDataRows
            matSort
            class="listing-table"
          >
            <ng-container
            [matColumnDef]="column.name"
              *ngFor="let column of columnsToDisplay"
            >
            <th mat-header-cell *matHeaderCellDef >
              <div class="table-header-container">
                <div class="table-header-content-text">{{column.displayName | translate}}</div>
                <div class="table-header-content-menu">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                    class="menu-button" *ngIf="column.showHeaderFilter" [class]="column.filtered ? 'filtered' : ''">
                    <mat-icon>filter_list</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                    <app-table-filter [column]="column" [columns]="columnsToDisplay" [displayedTopFilters]="displayedTopFilters" [dataSource]="dataSource"></app-table-filter>
                  </mat-menu>
                </div>
              </div>
            </th>
              <td mat-cell *matCellDef="let element">{{element[column.name]}}</td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
              <td
                mat-cell
                *matCellDef="let element"
                [attr.colspan]="columnsToDisplay.length + 1"
              >
                <div
                  class="row-detail"
                  [@detailExpand]="
                    element.trainingId == expandedElement?.trainingId ? 'expanded' : 'collapsed'
                  "
                >

                <div class="row-detail-description">
                  <div class="row-detail-item" *ngFor="let column of element.remainingAttributesList">
                    <strong>{{ column[0] }}:</strong>
                    <span>
                      <ng-container
                            *ngIf="
                              column[0] === 'YouTube';
                              then linkTemplate;
                              else textTemplate
                            "
                          ></ng-container>
                          <ng-template #linkTemplate>
                            <a
                              *ngIf="column[1]"
                              href="{{ column[1] }}"
                              target="_blank"
                            >click here to open</a
                            >
                          </ng-template>
                          <ng-template #textTemplate>
                            {{ column[1] }}
                          </ng-template>
                    </span>
                  </div>
                </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper">
                <div class="grid-control-column">
                  <a
                  mat-icon-button
                  color="accent"
                  [routerLink]="editAnnotationLink(element)"
                  role="link"
                  class="listing-table-tools"
                >
                  <mat-icon>movie</mat-icon>
                </a>
                <button
                  mat-icon-button
                  color="accent"
                  (click)="archiveTraining(element)"
                  class="listing-table-tools"
                  *ngIf="!element.isArchived">
                  <mat-icon matTooltip="Archive this training"
                            matTooltipClass="tooltip-class"
                            matTooltipHideDelay="100"
                            matTooltipPosition="below">archive
                  </mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="accent"
                  (click)="unarchiveTraining(element)"
                  class="listing-table-tools"
                  *ngIf="element.isArchived">
                  <mat-icon matTooltip="Unarchive this training"
                            matTooltipClass="tooltip-class"
                            matTooltipHideDelay="100"
                            matTooltipPosition="below">unarchive
                  </mat-icon>
                </button>
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="columnsHeadersToDisplay; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let element; columns: columnsHeadersToDisplay"
              [class.expanded-row]="applyExpandedClass(element)"
              (click)="expandRow(element)"
            ></tr>
            <tr
              mat-row style="height: 0;"
              *matRowDef="let row; columns: ['expandedDetail']"
              class="row-detail"
            ></tr>
          </table>
        </ngx-simplebar>

        <mat-paginator
          [pageSizeOptions]="[5, 10, 25, 100]"
          [pageSize]="100"
          [showFirstLastButtons]="true"
        ></mat-paginator>
      </div>
    </div>

    </mat-card>

  </div>
</div>
