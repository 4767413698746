<div style="min-width:10rem;">
  <h2 mat-dialog-title>{{title}}</h2>
  <mat-dialog-content>
    <mat-slide-toggle
      [(ngModel)]="isTitled"
      style="margin-bottom: 1.5rem;"
    >
      <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.REPORTS.SHOWTITLE.LABEL.TEXT' |  translate}}</mat-label>
    </mat-slide-toggle>
    <div class="export-options">
      <div class="export-items" (click)="downloadAs(downloadOption)" *ngFor="let downloadOption of downloadOptions">
        <span>
          <mat-icon class="material-symbols-outlined">
            {{downloadOption.icon}}
          </mat-icon>
        </span>
        <span>Export as {{downloadOption.name}}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="w-100 focused-removed">
    <button mat-stroked-button color="accent" (click)="close()">Close</button>
  </mat-dialog-actions>
</div>
