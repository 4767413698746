import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../../services/firestore.service';
import {ClientInContextService} from '../../../../services/client-in-context.service';
import {AuthService} from '../../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import {roundTimeValue, TIME_FORMAT, TIME_ZONE} from '../../../../common/utils/time-utils';
import {SNACKBAR_CLASSES} from '../../../../common/utils/utils';
import {v4 as uuidv4} from 'uuid';
import {NgForm} from '@angular/forms';
import {DocumentReference} from '@angular/fire/compat/firestore';
import momentDurationFormatSetup from 'moment-duration-format';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

momentDurationFormatSetup(moment);

@Component({
  selector: 'app-create-presence-dialog',
  templateUrl: './create-presence-dialog.component.html',
  styleUrls: ['./create-presence-dialog.component.scss']
})
export class CreatePresenceDialogComponent implements OnInit, OnDestroy {
  presenceLocationId: string;
  presenceLocationName: string;
  presenceWorkerId: string;
  presenceWorkerName: string;
  presenceWorkerGroupId: string;
  presenceWorkerGroupName: string;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  breakNLeaveRegnsDisplayed: any[] = [];
  allWorkersList: any[];
  workersListSubscription: Subscription;
  allLocationsList: any[];
  locationListSubscription: Subscription;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  regnsSubscription: Subscription;
  beingSaved = false;
  startDayTaskRegn: any;
  endDayTaskRegn: any;
  hhMM24HoursPattern = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
  selectedDate = new Date();
  presenceExistsForSelectedDate = false;
  archivedPresenceExistsForSelectedDate = false;
  startTimeRoundedHint: string;
  endTimeRoundedHint: string;

  @ViewChild('createForm') public createForm: NgForm;
  hoursWorked: string;
  hoursBreaksPaid: string;
  hoursBreaksUnpaid: string;
  hoursLeavesPaid: string;
  hoursLeavesUnpaid: string;
  allLeaveTypesList: any[];
  leaveTypesSubscription: Subscription;


  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreatePresenceDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      this.startDayTaskRegn = {
        id: null,
        locatonId: null,
        startTime: '07:00',
      };
      this.endDayTaskRegn = {
        id: null,
        locatonId: null,
        endTime: '16:00',
      };
    });

    this.calculateDurations();

    this.leaveTypesSubscription =
      this.firestoreService.getAllUnarchivedLeaveTypesForClientId(this.selectedClientDocData?.id).subscribe((leaveTypesList) => {
        this.allLeaveTypesList = [...leaveTypesList].filter(lt => lt.isEnabled === true).sort((ltA: any, ltB: any) => {
          return ltA.name?.toLowerCase() < ltB.name?.toLowerCase() ? -1 : ltA.name?.toLowerCase() > ltB.name?.toLowerCase() ? 1 : 0;
        });
      });

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
        this.workersListSubscription = this.firestoreService
          .getUnArchivedWorkersForClientId(this.selectedClientDocData?.id)
          .subscribe((workersList) => {
            this.allWorkersList = workersList.sort((workerA: any, workerB: any) => {
              return workerA.name?.toLowerCase() < workerB.name?.toLowerCase() ? -1 : workerA.name?.toLowerCase() > workerB.name?.toLowerCase() ? 1 : 0;
            });
            if (this.loggedInUserDocData?.associatedWorkerId) {
              this.allWorkersList = this.allWorkersList.filter(worker => worker.id !== this.loggedInUserDocData.associatedWorkerId)
            }
          });
      }
    );
    this.locationListSubscription = this.firestoreService
      .getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id)
      .subscribe((locationsList) => (this.allLocationsList = locationsList.sort((workerA: any, workerB: any) => {
        return workerA.name?.toLowerCase() < workerB.name?.toLowerCase() ? -1 : workerA.name?.toLowerCase() > workerB.name?.toLowerCase() ? 1 : 0;
      })));
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.workersListSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.regnsSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.leaveTypesSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  getBreakOrLeaveRegnsToDisplay() {
    return this.breakNLeaveRegnsDisplayed.filter(regn => !regn.isDeleted);
  }

  deleteBreakOrLeaveRegn(regn, idx) {
    this.breakNLeaveRegnsDisplayed.splice(idx, 1);
    this.calculateDurations();
  }

  addNewBreakRegn(regn, idx) {
    const index = this.breakNLeaveRegnsDisplayed.findIndex(br => br.uuid === regn.uuid);
    const newBreakRegn = {
      uuid: uuidv4(),
      taskName: this.selectedClientDocData.defaultBreak?.name ?? '',
      breakTime: null,
      endTime: null,
      isPaid: this.selectedClientDocData.defaultBreak?.breakTaskFunction === 'BREAK_PAID',
      type: 'BREAK'
    };
    this.breakNLeaveRegnsDisplayed =
      [...this.breakNLeaveRegnsDisplayed.slice(0, index + 1), newBreakRegn, ...this.breakNLeaveRegnsDisplayed.slice(index + 1, this.breakNLeaveRegnsDisplayed.length)];
  }

  addNewLeaveRegn(regn, idx) {
    const index = this.breakNLeaveRegnsDisplayed.findIndex(br => br.uuid === regn.uuid);
    const newLeaveRegn = {
      uuid: uuidv4(),
      leaveTypeId: null,
      startTime: null,
      endTime: null,
      isPaid: null,
      type: 'LEAVE'
    };
    this.breakNLeaveRegnsDisplayed =
       [...this.breakNLeaveRegnsDisplayed.slice(0, index + 1), newLeaveRegn, ...this.breakNLeaveRegnsDisplayed.slice(index + 1, this.breakNLeaveRegnsDisplayed.length)];
  }

  addBreakRegnOnTop() {
    const newBreakRegn = {
      taskName: this.selectedClientDocData.defaultBreak?.name ?? '',
      breakTime: null,
      endTime: null,
      isPaid: this.selectedClientDocData.defaultBreak?.breakTaskFunction === 'BREAK_PAID',
      uuid: uuidv4(),
      type: 'BREAK'
    };
    this.breakNLeaveRegnsDisplayed.unshift(newBreakRegn);
  }

  addLeaveRegnOnTop() {
    const newLeaveRegn = {
      leaveTypeId: null,
      startTime: null,
      endTime: null,
      isPaid: null,
      uuid: uuidv4(),
      type: 'LEAVE'
    };
    this.breakNLeaveRegnsDisplayed.unshift(newLeaveRegn);
  }

  cancelPresenceSave() {
    this.dialogRef.close();
  }

  async createPresenceNRegistrations() {
    this.regnsSubscription?.unsubscribe();

    if (!this.presenceWorkerId || !this.presenceLocationId) {
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    for (const leaveOrBreak of this.breakNLeaveRegnsDisplayed) {
      if (leaveOrBreak.type === 'BREAK') {
        if (!leaveOrBreak.taskName && !leaveOrBreak.isDeleted) {
          this.openSnackBar('Please fill all mandatory fields', 'error');
          return;
        }
        if (!leaveOrBreak.breakTime.match(this.hhMM24HoursPattern) && !leaveOrBreak.isDeleted) {
          this.openSnackBar('Please fill all mandatory fields', 'error');
          return;
        }
      }
      if (leaveOrBreak.type === 'LEAVE') {
        if (!leaveOrBreak.startTime.match(this.hhMM24HoursPattern) && !leaveOrBreak.isDeleted) {
          this.openSnackBar('Please fill all mandatory fields', 'error');
          return;
        }
        if (!leaveOrBreak.leaveTypeId && !leaveOrBreak.isDeleted) {
          this.openSnackBar('Please fill all mandatory fields', 'error');
          return;
        }
      }
      if (!leaveOrBreak.endTime.match(this.hhMM24HoursPattern) && !leaveOrBreak.isDeleted) {
        this.openSnackBar('Please fill all mandatory fields', 'error');
        return;
      }
    }

    if (!this.startDayTaskRegn || !this.startDayTaskRegn.startTime.match(this.hhMM24HoursPattern)) {
      this.openSnackBar('Start time is mandatory', 'error');
      return;
    }

    if (!this.endDayTaskRegn || !this.endDayTaskRegn.endTime.match(this.hhMM24HoursPattern)) {
      this.openSnackBar('End time is mandatory', 'error');
      return;
    }
    this.beingSaved = true;

    const rawDate = moment(this.selectedDate).format('YYYY-MM-DD') + ' 12:00';

    const presenceStartTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
      hour: +this.startDayTaskRegn.startTime.split(':')[0] ?? 0,
      minute: +this.startDayTaskRegn.startTime.split(':')[1] ?? 0,
    });

    const presenceEndTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
      hour: +this.endDayTaskRegn.endTime.split(':')[0] ?? 0,
      minute: +this.endDayTaskRegn.endTime.split(':')[1] ?? 0,
    });

    const selectedWorker = this.allWorkersList.filter(wrkr => wrkr.id === this.presenceWorkerId)[0];
    const selectedLocation = this.allLocationsList.filter(wrkr => wrkr.id === this.presenceLocationId)[0];
    const createdPresenceRef: DocumentReference = await this.firestoreService.createPresenceDoc(this.selectedDate, this.loggedInUserDocData,
      selectedWorker, presenceStartTimestampMoment.toDate(), presenceEndTimestampMoment.toDate(), selectedLocation, this.selectedClientDocData);

    const startDayTaskRegnPITObj: any = {
      clientId: this.selectedClientDocData.id,
      clientName: this.selectedClientDocData.name ?? null,
      deviceId: null,
      deviceNumber: null,
      deviceType: 'browser-dashboard',
      isArchived: false,
      locationId: this.presenceLocationId,
      locationName: selectedLocation.name ?? null,
      taskFunction: 'START_DAY',
      createdFromDashboard: true,
      taskName: 'Start Day',
      taskType: 'PIT',
      timestamp: presenceStartTimestampMoment.toDate(),
      workerId: this.presenceWorkerId,
      workerName: selectedWorker.name ?? null,
      workerGroupId: selectedWorker.workerGroupId ?? null,
      workerGroupName: selectedWorker.workerGroupName ?? null
    }

    const startDayTaskRegnRef = await this.firestoreService.createRegn(startDayTaskRegnPITObj, createdPresenceRef.id, this.selectedClientDocData.id);

    const endDayTaskRegnPITObj: any = {
      clientId: this.selectedClientDocData.id,
      clientName: this.selectedClientDocData.name ?? null,
      deviceId: null,
      deviceNumber: null,
      deviceType: 'browser-dashboard',
      isArchived: false,
      locationId: this.presenceLocationId,
      locationName: selectedLocation.name ?? null,
      taskFunction: 'END_DAY',
      createdFromDashboard: true,
      taskName: 'End Day',
      taskType: 'PIT',
      timestamp: presenceEndTimestampMoment.toDate(),
      presenceEndTsCreatedFromDashboardAt: new Date(),
      workerId: this.presenceWorkerId,
      workerName: selectedWorker.name ?? null,
      workerGroupId: selectedWorker.workerGroupId ?? null,
      workerGroupName: selectedWorker.workerGroupName ?? null
    }
    const endDayTaskRegnRef = await this.firestoreService.createRegn(endDayTaskRegnPITObj, createdPresenceRef.id, this.selectedClientDocData.id);
    await this.firestoreService.updatePresenceByIdForClientId({
      startTsPitId: startDayTaskRegnRef.id,
      endTsPitId: endDayTaskRegnRef.id,
    }, this.selectedClientDocData.id, createdPresenceRef.id);

    for (const breakOrLeaveRegn of this.breakNLeaveRegnsDisplayed) {
      if (breakOrLeaveRegn.type === 'BREAK') {
        //create new break
        const breakStartTimeMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +breakOrLeaveRegn.breakTime.split(':')[0] ?? 0,
          minute: +breakOrLeaveRegn.breakTime.split(':')[1] ?? 0,
        });
        const breakEndTimeMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +breakOrLeaveRegn.endTime.split(':')[0] ?? 0,
          minute: +breakOrLeaveRegn.endTime.split(':')[1] ?? 0,
        });
        const breakRegnObj: any = {};
        breakRegnObj.uuid = breakOrLeaveRegn.uuid;
        breakRegnObj.createdFromDashboard = true; //backend will ignore creation of breakOrLeaveRegn & only update durations if this flag is set
        breakRegnObj.startTimestamp = breakStartTimeMoment.toDate();
        breakRegnObj.endTimestamp = breakEndTimeMoment.toDate();
        breakRegnObj.durationTotal = moment(breakRegnObj.endTimestamp).diff(breakRegnObj.startTimestamp, 'seconds');
        breakRegnObj.creationTimestamp = new Date();
        breakRegnObj.createdByUserId = this.loggedInUserDocData.id ?? null;
        breakRegnObj.createdByUserName = this.loggedInUserDocData.name ?? null;
        breakRegnObj.updatedTimestamp = new Date();
        breakRegnObj.workerId = this.presenceWorkerId;
        breakRegnObj.workerName = selectedWorker.name ?? null;
        breakRegnObj.workerGroupId = selectedWorker.workerGroupId ?? null;
        breakRegnObj.workerGroupName = selectedWorker.workerGroupName ?? null;
        breakRegnObj.isArchived = false;
        breakRegnObj.clientId = this.selectedClientDocData.id;
        breakRegnObj.clientName = this.selectedClientDocData.name ?? null;
        breakRegnObj.locationId = this.presenceLocationId;
        breakRegnObj.locationName = selectedLocation.name ?? null;
        breakRegnObj.deviceType = 'browser-dashboard';
        breakRegnObj.taskFunction = breakOrLeaveRegn.isPaid ? 'BREAK_PAID' : 'BREAK_UNPAID';
        breakRegnObj.taskType = 'BREAK';
        breakRegnObj.taskName = breakOrLeaveRegn.taskName ?? null;
        breakRegnObj.breakSchemeUuid = breakOrLeaveRegn.breakSchemeUuid ?? null;
        await this.firestoreService.createRegn(breakRegnObj, createdPresenceRef.id, this.selectedClientDocData.id);
      }

      if (breakOrLeaveRegn.type === 'LEAVE') {
        //create new break
        const leaveStartTimeMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +breakOrLeaveRegn.startTime.split(':')[0] ?? 0,
          minute: +breakOrLeaveRegn.startTime.split(':')[1] ?? 0,
        });
        const leaveEndTimeMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +breakOrLeaveRegn.endTime.split(':')[0] ?? 0,
          minute: +breakOrLeaveRegn.endTime.split(':')[1] ?? 0,
        });
        const leaveRegnObj: any = {};
        leaveRegnObj.uuid = breakOrLeaveRegn.uuid;
        leaveRegnObj.leaveTypeId = breakOrLeaveRegn.leaveTypeId;
        leaveRegnObj.leaveTypeName = this.allLeaveTypesList.filter(lt => lt.id === breakOrLeaveRegn.leaveTypeId)[0].name ?? null;
        leaveRegnObj.createdFromDashboard = true; //backend will ignore creation of breakOrLeaveRegn & only update durations if this flag is set
        leaveRegnObj.startTimestamp = leaveStartTimeMoment.toDate();
        leaveRegnObj.endTimestamp = leaveEndTimeMoment.toDate();
        leaveRegnObj.durationTotal = moment(leaveRegnObj.endTimestamp).diff(leaveRegnObj.startTimestamp, 'seconds');
        leaveRegnObj.creationTimestamp = new Date();
        leaveRegnObj.createdByUserId = this.loggedInUserDocData.id ?? null;
        leaveRegnObj.createdByUserName = this.loggedInUserDocData.name ?? null;
        leaveRegnObj.updatedTimestamp = new Date();
        leaveRegnObj.workerId = this.presenceWorkerId;
        leaveRegnObj.workerName = selectedWorker.name ?? null;
        leaveRegnObj.workerGroupId = selectedWorker.workerGroupId ?? null;
        leaveRegnObj.workerGroupName = selectedWorker.workerGroupName ?? null;
        leaveRegnObj.isArchived = false;
        leaveRegnObj.clientId = this.selectedClientDocData.id;
        leaveRegnObj.clientName = this.selectedClientDocData.name ?? null;
        leaveRegnObj.locationId = this.presenceLocationId;
        leaveRegnObj.locationName = selectedLocation.name ?? null;
        leaveRegnObj.deviceType = 'browser-dashboard';
        leaveRegnObj.taskFunction = breakOrLeaveRegn.isPaid ? 'LEAVE_PAID' : 'LEAVE_UNPAID';
        leaveRegnObj.taskType = 'LEAVE';
        leaveRegnObj.taskName = breakOrLeaveRegn.taskName ?? null;
        leaveRegnObj.breakSchemeUuid = breakOrLeaveRegn.breakSchemeUuid ?? null;
        await this.firestoreService.createRegn(leaveRegnObj, createdPresenceRef.id, this.selectedClientDocData.id);
      }

    }
    this.beingSaved = false;
    this.dialogRef.close();
  }

  async handleWorkerChange() {
    this.presenceExistsForSelectedDate = false;
    this.archivedPresenceExistsForSelectedDate = false;
    await this.checkNHandlePresenceExists(true);
  }

  async onLocationChange() {
    this.presenceExistsForSelectedDate = false;
    this.archivedPresenceExistsForSelectedDate = false;
    await this.checkNHandlePresenceExists(true);
  }

  async generateBreaks(workerDD: any) {
    this.breakNLeaveRegnsDisplayed = [];
    const rawDate = moment(this.selectedDate).format('YYYY-MM-DD') + ' 12:00';
    const presenceStartTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
      hour: +this.startDayTaskRegn.startTime.split(':')[0] ?? 0,
      minute: +this.startDayTaskRegn.startTime.split(':')[1] ?? 0,
    });

    const presenceEndTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
      hour: +this.endDayTaskRegn.endTime.split(':')[0] ?? 0,
      minute: +this.endDayTaskRegn.endTime.split(':')[1] ?? 0,
    });
    let aboList = this.selectedClientDocData.autoBreakOptions?.filter(abo => !abo.isArchived);
    aboList.filter(abo => abo.hasOwnProperty('breakTime') && abo.breakTime);

    // @ts-ignore
    aboList = aboList.sort((aboA, aboB) => moment.tz(aboA.breakTime, 'HH:mm', TIME_ZONE) - moment.tz(aboB.breakTime, 'HH:mm', TIME_ZONE));

    for (const autoBreak of aboList) {

      if (autoBreak.workerGroupIds && Array.isArray(autoBreak.workerGroupIds) && (autoBreak.workerGroupIds.length > 0)) {
        if (!autoBreak.workerGroupIds.includes(workerDD.workerGroupId)) {
          //None of autoBreak's workerGroupIds are set for worker. Continuing to next autoBreak.
          continue;
        }
      }

      const aboStartTimeMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
        hour: +autoBreak.startTime.split(':')[0] ?? 0,
        minute: +autoBreak.startTime.split(':')[1] ?? 0,
      });
      const aboEndTimeMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
        hour: +autoBreak.endTime.split(':')[0] ?? 0,
        minute: +autoBreak.endTime.split(':')[1] ?? 0,
      });

      if (presenceStartTimestampMoment.isAfter(aboStartTimeMoment)) {
        //This autoBreak has startTime set with value before presence startTimestamp. Skipping autoBreak.
        continue;
      }

      if (presenceEndTimestampMoment.isBefore(aboEndTimeMoment)) {
        //This autoBreak has endTime set with value after presence endTimestamp. Skipping autoBreak.
        continue;
      }

      const breakRegnObj: any = {};
      breakRegnObj.uuid = uuidv4();
      const breakTimeMoment = moment.tz(autoBreak.breakTime, 'HH:mm', TIME_ZONE);
      breakRegnObj.breakTime = autoBreak.breakTime;
      const endTimeMoment = breakTimeMoment.add(+autoBreak.duration, 'minutes');
      breakRegnObj.endTime = endTimeMoment.format('HH:mm'); //TODO -- check if TZ handling is needed
      breakRegnObj.taskName = autoBreak.name ?? null;
      breakRegnObj.isPaid = autoBreak.breakTaskFunction === 'BREAK_PAID' ?? false;
      breakRegnObj.breakSchemeUuid = autoBreak.uuid;
      breakRegnObj.type = 'BREAK';
      this.breakNLeaveRegnsDisplayed.push(breakRegnObj);
    }
  }

  async onDateChange(event: any) {
    this.selectedDate = event.value
    if (!this.presenceWorkerId) {
      this.presenceExistsForSelectedDate = false;
      this.archivedPresenceExistsForSelectedDate = false;
      return;
    }
    await this.checkNHandlePresenceExists(true);
  }

  async checkNHandlePresenceExists(generateTasks = false) {
    if (this.selectedClientDocData.isPresenceLocation && !this.presenceLocationId) {
      return;
    }

    if (!this.presenceWorkerId) {
      return;
    }

    this.presenceExistsForSelectedDate = false;
    this.archivedPresenceExistsForSelectedDate = false;
    const presenceCheckSubscription = this.firestoreService.getPresenceDocForDate(this.selectedDate, this.presenceWorkerId,
      this.selectedClientDocData.id, this.selectedClientDocData.isPresenceLocation ? this.presenceLocationId : null)
      .subscribe(async (presenceDocs) => {
        presenceCheckSubscription?.unsubscribe();
        if (presenceDocs.length > 0 && presenceDocs[0].isArchived) {
          this.archivedPresenceExistsForSelectedDate = true;
        } else if (presenceDocs.length > 0) {
          this.presenceExistsForSelectedDate = true;
        }

        if (generateTasks && !this.presenceExistsForSelectedDate && !this.archivedPresenceExistsForSelectedDate) {
          const selectedWorker = this.allWorkersList.filter(wrkr => wrkr.id === this.presenceWorkerId)[0];
          this.presenceWorkerGroupId = selectedWorker.workerGroupId ?? null;
          this.presenceWorkerGroupName = selectedWorker.workerGroupName ?? null;
          await this.generateBreaks(selectedWorker);
          this.calculateDurations();
        } else if (generateTasks && this.presenceExistsForSelectedDate) {
          this.breakNLeaveRegnsDisplayed = [];
          this.calculateDurations();
        }
      });
  }

  calculateDurations() {
    if (this.selectedDate && this.startDayTaskRegn?.startTime?.match(this.hhMM24HoursPattern)) {
      const startTimestampMoment = moment(this.selectedDate).tz(TIME_ZONE).set({
        hour: +this.startDayTaskRegn.startTime.split(':')[0] ?? 0,
        minute: +this.startDayTaskRegn.startTime.split(':')[1] ?? 0,
      });
      this.startTimeRoundedHint = roundTimeValue(startTimestampMoment, this.selectedClientDocData);
    } else {
      this.startTimeRoundedHint = null;
    }

    if (this.selectedDate && this.endDayTaskRegn?.endTime?.match(this.hhMM24HoursPattern)) {
      const endTimestampMoment = moment(this.selectedDate).tz(TIME_ZONE).set({
        hour: +this.endDayTaskRegn.endTime.split(':')[0] ?? 0,
        minute: +this.endDayTaskRegn.endTime.split(':')[1] ?? 0,
      });
      this.endTimeRoundedHint = roundTimeValue(endTimestampMoment, this.selectedClientDocData);
    } else {
      this.endTimeRoundedHint = null;
    }

    if (!this.startDayTaskRegn?.startTime?.match(this.hhMM24HoursPattern) || !this.endDayTaskRegn.endTime?.match(this.hhMM24HoursPattern)) {
      return;
    }

    if (this.startTimeRoundedHint && this.endTimeRoundedHint) {
      this.hoursWorked = moment.duration(moment(this.endTimeRoundedHint, 'HH:mm')
        .diff(moment(this.startTimeRoundedHint, 'HH:mm'), 'seconds'), 'seconds').format(TIME_FORMAT);
    } else {
      this.hoursWorked = moment.duration(moment(this.endDayTaskRegn.endTime, 'HH:mm')
        .diff(moment(this.startDayTaskRegn.startTime, 'HH:mm'), 'seconds'), 'seconds').format(TIME_FORMAT);
    }

    let totalBreaksPaidSeconds = 0;
    let totalBreaksUnpaidSeconds = 0;
    let totalLeavesPaidSeconds = 0;
    let totalLeavesUnpaidSeconds = 0;
    if (this.breakNLeaveRegnsDisplayed?.length > 0) {
      for (const leaveOrBreakRegn of this.breakNLeaveRegnsDisplayed) {
        if (leaveOrBreakRegn.type === 'BREAK') {
          if (!leaveOrBreakRegn.breakTime.match(this.hhMM24HoursPattern) || !leaveOrBreakRegn.endTime.match(this.hhMM24HoursPattern)) {
            continue;
          }
          const breakDurationSeconds = moment(leaveOrBreakRegn.endTime, 'HH:mm').diff(moment(leaveOrBreakRegn.breakTime, 'HH:mm'), 'seconds');
          if (leaveOrBreakRegn.isPaid) {
            totalBreaksPaidSeconds += breakDurationSeconds;
          } else {
            totalBreaksUnpaidSeconds += breakDurationSeconds;
          }
          this.hoursBreaksPaid = moment.duration(totalBreaksPaidSeconds, 'seconds').format(TIME_FORMAT);
          this.hoursBreaksUnpaid = moment.duration(totalBreaksUnpaidSeconds, 'seconds').format(TIME_FORMAT);
        }
        if (leaveOrBreakRegn.type === 'LEAVE') {
          if (!leaveOrBreakRegn.startTime?.match(this.hhMM24HoursPattern) || !leaveOrBreakRegn.endTime?.match(this.hhMM24HoursPattern)) {
            continue;
          }
          const leaveDurationSeconds = moment(leaveOrBreakRegn.endTime, 'HH:mm').diff(moment(leaveOrBreakRegn.startTime, 'HH:mm'), 'seconds');
          if (leaveOrBreakRegn.isPaid) {
            totalLeavesPaidSeconds += leaveDurationSeconds;
          } else {
            totalLeavesUnpaidSeconds += leaveDurationSeconds;
          }
          this.hoursLeavesPaid = moment.duration(totalLeavesPaidSeconds, 'seconds').format(TIME_FORMAT);
          this.hoursLeavesUnpaid = moment.duration(totalLeavesUnpaidSeconds, 'seconds').format(TIME_FORMAT);
        }
      }
    } else {
      this.hoursBreaksPaid = moment.duration(totalBreaksPaidSeconds, 'seconds').format(TIME_FORMAT);
      this.hoursBreaksUnpaid = moment.duration(totalBreaksUnpaidSeconds, 'seconds').format(TIME_FORMAT);
      this.hoursLeavesPaid = moment.duration(totalLeavesPaidSeconds, 'seconds').format(TIME_FORMAT);
      this.hoursLeavesUnpaid = moment.duration(totalLeavesUnpaidSeconds, 'seconds').format(TIME_FORMAT);
    }
  }

  leaveSelectionChanged(regn, idx) {
    if (regn.leaveTypeId) {
      regn.isPaid = this.allLeaveTypesList.filter(lt => lt.id === regn.leaveTypeId)[0].isPaid;
    }
    this.calculateDurations();
  }
}
