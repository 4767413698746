import { Component, DestroyRef, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SNACKBAR_CLASSES } from 'src/app/common/utils/utils';
import { AuthService } from 'src/app/services/auth.service';
import { ClientInContextService } from 'src/app/services/client-in-context.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {getPermissions} from '../../common/utils/permission-utils';

@Component({
  selector: 'app-client-sessions',
  templateUrl: './client-sessions.component.html',
  styleUrls: ['./client-sessions.component.scss']
})
export class ClientSessionsComponent implements OnDestroy {
  selectedClientDocData: any;
  autoArchiveShortSessions: boolean;
  private clientInContextServiceSubscription: Subscription;
  correctionFactor: number;
  assetExpiration = 30;
  perms: any;

  constructor(private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;
        if (this.selectedClientDocData.rolePermissionsMap) {
          this.perms = getPermissions('settings', 'sessions', selectedClientDocData.rolePermissionsMap);
        }

        this.autoArchiveShortSessions = selectedClientDocData.autoArchiveShortSessions ?? false;//set to false for backward compatibility
        this.correctionFactor = selectedClientDocData.correctionFactor;
        this.assetExpiration = selectedClientDocData.assetSetExpirationMinutes?selectedClientDocData.assetSetExpirationMinutes: 30;

      });
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  async saveAutoArchiveSessionFlag() {
    if (this.selectedClientDocData.hasOwnProperty('autoArchiveShortSessions')) {//for backward compatibility
      if (this.autoArchiveShortSessions === this.selectedClientDocData.autoArchiveShortSessions) {
        this.openSnackBar('No changes to save!', 'error');
        return;
      }
    }

    try {
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, {
        autoArchiveShortSessions: this.autoArchiveShortSessions,
      });
      this.reloadClientInContext();
      this.openSnackBar('Setting for auto archiving sessions changed successfully!', 'success');
    } catch (error) {
      this.openSnackBar('Error in saving setting for auto archiving sessions:' + error.message, 'error');
    }
  }

  reloadClientInContext() {
    const clientSubscription = this.firestoreService.getClientById(this.selectedClientDocData.id).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((clientFromDB) => {
        const loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(
            (userDocData) => {
              if (userDocData) {
                const clientElementInUserDoc = userDocData?.clients.filter(client => client.clientId === clientFromDB.id);
                if (clientElementInUserDoc && clientElementInUserDoc.length > 0) {
                  clientFromDB.role = clientElementInUserDoc[0]?.role;
                }
                this.clientInContextService.clientInContextSubject.next(clientFromDB);
                loggedInUserFromAuthServiceSubscription?.unsubscribe();
                clientSubscription?.unsubscribe();
              }
            });
      });
  }

  cancelAssetExpirataion() {
    this.assetExpiration = this.selectedClientDocData.assetSetExpirationMinutes;
  }

  async saveAssetExpiration() {
    if (!this.assetExpiration) {
      this.openSnackBar('Asset expiration is a mandatory field!', 'error');
      return;
    }

    if (this.assetExpiration === this.selectedClientDocData.assetSetExpirationMinutes) {
      this.openSnackBar('No changes to save!', 'error');
      return;
    }
    try {
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, {
        assetSetExpirationMinutes: +this.assetExpiration,
      });
      this.reloadClientInContext();
      this.openSnackBar('Asset expiration changed successfully!', 'success');
    } catch (error) {
      this.openSnackBar('Error in saving asset expiration:' + error.message, 'error');
    }
  }

  async saveCorrectionFactor() {
    if (!this.correctionFactor) {
      this.openSnackBar('Correction factor is a mandatory field!', 'error');
      return;
    }

    if (this.correctionFactor === this.selectedClientDocData.correctionFactor) {
      this.openSnackBar('No changes to save!', 'error');
      return;
    }

    if (!/^-?\d+(\.\d{1,2})?$/.test(this.correctionFactor + '')) {
      this.openSnackBar('Please enter a decimal number with 0/1/2 decimal place digits for correction factor!', 'error');
      return;
    }

    try {
      await this.firestoreService.updateClientById(this.selectedClientDocData.id, {
        correctionFactor: +this.correctionFactor,
      });
      this.reloadClientInContext();
      this.openSnackBar('Correction factor changed successfully!', 'success');
    } catch (error) {
      this.openSnackBar('Error in saving correction factor:' + error.message, 'error');
    }
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
  }
}
