<ng-container *ngIf="perms?.canRead">
<div class="inner-page-action">
  <app-date-selection></app-date-selection>
</div>
<div class="listing-container table-card table-mobile-res table-to-card-mb">
  <div class="mat-elevation-z8 table-responsive " style="clear: both;">
    <app-common-table [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay" [gridList]="sessionsData" [tableName]="'trolleyTable'"  [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'trolley'">

    </app-common-table>
  </div>
</div>
</ng-container>
