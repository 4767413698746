import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../../../services/firestore.service';
import { ClientInContextService } from '../../../services/client-in-context.service';
import { AuthService } from '../../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SNACKBAR_CLASSES } from '../../../common/utils/utils';

@Component({
  selector: 'app-create-asset-dialog',
  templateUrl: './create-asset-dialog.component.html',
  styleUrl: './create-asset-dialog.component.scss'
})
export class CreateAssetDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  description: string;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  allLocationsList: any[];
  allBeaconsList: any[];
  locationListSubscription: Subscription;
  beaconListSubscription: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreateAssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.description = data.description;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => {
        this.allLocationsList = locationsList?.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        });
        this.form.patchValue({ locationIds: locationsList.map(loc => loc.id) });
      });

      this.beaconListSubscription = this.firestoreService.getAllUnarchivedNAssgndBeaconsForClientId(this.selectedClientDocData?.id, 'ASSET').subscribe((beaconsList) => {
        this.allBeaconsList = beaconsList?.sort((beaconA: any, beaconB: any) => {
          return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
        });

      });

    });
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.form = this.fb.group({
      type: ['', [Validators.required]],
      name: ['', []],
      number: ['', []],
      locationIds: ['', []],
      rssiThreshold: [-46, [Validators.required]],
      beaconIds: ['', []],
      externalId: ['', []],
      filterForm: ['', []]
    });
  }

  async createAsset() {
    if (!this.form.valid) {
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    const assetToCreate = this.form.value;
    assetToCreate.name = assetToCreate.name?.trim();

    let locationNames = null;
    if (assetToCreate.locationIds && Array.isArray(assetToCreate.locationIds) && (assetToCreate.locationIds.length > 0)) {
      locationNames = this.allLocationsList.filter(loc => assetToCreate.locationIds.includes(loc.id)).map(loc => loc.name);
    }
    assetToCreate.locationNames = locationNames;

    let beaconNames = null;
    if (assetToCreate.beaconIds && Array.isArray(assetToCreate.beaconIds) && (assetToCreate.beaconIds.length > 0)) {
      beaconNames = this.allBeaconsList.filter(beacon => assetToCreate.beaconIds.includes(beacon.id)).map(beacon => beacon.name);
    }
    assetToCreate.beaconNames = beaconNames;

    try {
      await this.firestoreService.createAssetForClientId(assetToCreate, this.selectedClientDocData.id);
      this.dialogRef.close(this.form.value);
      this.openSnackBar(`Asset '${assetToCreate.name}' created successfully.`, 'success');
      this.form.reset();
    } catch (error) {
      this.openSnackBar('Error in asset creation:' + error.message, 'error');
      console.log(error.message);
    }


  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
