<div class="transparent-effect-card" appearance="outlined">
  <div style="display: flex; flex-direction: column;">
    <mat-card-title style="font-size: 1.2rem;margin-bottom: 0.4rem;font-weight: 500;">
      {{'SETTINGS.SESSIONS.HEADING.TITLE.TEXT' | translate}}
    </mat-card-title>
    <mat-card-subtitle style="font-weight: 200;">
      {{'SETTINGS.SESSIONS.DESCRIPTION.T_CONTENT.TEXT' | translate}}
    </mat-card-subtitle>
  </div>
  <br />
  <div>
    <div style="margin-left: 16px; display:flex; flex-direction: column;">
      <mat-slide-toggle [(ngModel)]="autoArchiveShortSessions" style="margin-top: 0.6rem;">
        <mat-label style="font-size: 1rem;font-weight: 300;"> {{'SETTINGS.SESSIONS.TOGGLE.LABEL.TEXT' |
          translate}}</mat-label>
      </mat-slide-toggle>
    </div>
  </div>
  <div align="end" style="margin-right: 1rem;">
    <button mat-flat-button (click)="saveAutoArchiveSessionFlag()" color="primary">{{'SETTINGS.SAVE.BUTTON.TEXT' |
      translate}}</button>
  </div>
</div>
