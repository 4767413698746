<div class="side-nav-outer" [ngClass]="{'menu-collapsed-o':!isMenuOpened}">
  <button class="menu-humBurger" mat-icon-button (click)="toggleMenu()">
    <mat-icon class="material-symbols-outlined">
      menu
    </mat-icon>
  </button>
  <div class="menu-sm-overlay" (click)="toggleMenu()"></div>
  <div class="d-flex flex-column h-100 side-nav-wrapper" [ngClass]="{'menu-collapsed':!isMenuOpened}"
    *ngIf="loggedInUserDocData && loggedInUserDocData.email !== 'info@jacoudijk.nl'">

    <h1 class="logo-side">
      <ng-container *ngIf="!isMenuOpened">
        <img width="135" height="50" src="assets/Opus Watch_Logo_RGB_Icon.png">
      </ng-container>
      <ng-container *ngIf="isMenuOpened">
        <img width="146" src="assets/Opus Watch_Logo_RGB_L_N_W.png">
      </ng-container>

    </h1>
    <div class="mobileNav-loc_cl-selector">

      <mat-form-field appearance="outline"
        *ngIf="loggedInUserDocData && clientInContextDocData && (clientLocationsList?.length > 0) && locationDropDownIsVisible()">
        <mat-select panelClass="nav-bar-select" [ngModel]="selectedClientLocationId" placeholder="Select Location"
          style="margin-top: 0.2rem; height:1.2rem;color: white!important;" panelClass="mat-select-panel-client-list"
          (ngModelChange)="onClientLocationChange($event)">
          <mat-option value="-1" *ngIf="!locationDropDownIsDisabled()">{{'DASHBOARD.NAV.MENU.ALLLOCATIONS.LABEL.TEXT' |
            translate}}</mat-option>
          <mat-option *ngFor="let location of clientLocationsList" [value]="location.id" style="color: white;">
            {{location.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline"
        *ngIf="loggedInUserDocData && clientInContextDocData && (loggedInUserDocData?.clients?.length !== 1) && locationDropDownIsVisible() && !onlyLocationDropdown()">
        <mat-select panelClass="nav-bar-select" [ngModel]="selectedClientId" placeholder="Select Client"
          style="margin-top: 0.2rem; height:1.2rem;color: white!important;" panelClass="mat-select-panel-client-list"
          (ngModelChange)="onClientChange($event)">
          <mat-slide-toggle class="blur-clientName-toggle" [(ngModel)]="clientNamesBlurred" (change)="updateBlurredSettingForUser()">
            Blur client names
          </mat-slide-toggle>
          <mat-option *ngFor="let client of loggedInUserDocData.clients" [value]="client.clientId"
            style="color: white;">
            <span [ngClass]="{'blurredName': clientNamesBlurred}">{{client.clientName}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="nav-items flex-column">
      @if(isMenuOpened){
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
          <mat-expansion-panel-header [matMenuTriggerFor]="presencesMenu">
            <mat-panel-title>
              <mat-icon class="material-symbols-outlined">
                group
              </mat-icon>
              {{'DASHBOARD.NAV.MENU.PRESENCE.TITLE.TEXT' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template [ngTemplateOutlet]="presncesMenuItem"></ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="material-symbols-outlined">
                patient_list
              </mat-icon>
              {{'DASHBOARD.NAV.MENU.LABOR.TITLE.TEXT' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template [ngTemplateOutlet]="laborMenuItem"></ng-template>

        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="material-symbols-outlined">
                monitoring
              </mat-icon>
              {{'DASHBOARD.NAV.MENU.PRODUCTIVITY.TITLE.TEXT' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template [ngTemplateOutlet]="productivityMenuItem"></ng-template>

        </mat-expansion-panel>
      </mat-accordion>
      }
      @else{
      <a class="action" [matMenuTriggerFor]="presencesMenu"
        [matTooltip]="'DASHBOARD.NAV.MENU.PRESENCE.TOOLTIP.TEXT' | translate" [matTooltipPosition]="'right'"
        matTooltipClass="nav-tooltip">
        <mat-icon class="material-symbols-outlined">
          group
        </mat-icon>
      </a>
      <mat-menu #presencesMenu="matMenu" class="sub-menu-list">
        <ng-template [ngTemplateOutlet]="presncesMenuItem"></ng-template>
      </mat-menu>
      <a class="action" [matMenuTriggerFor]="laborMenu" [matTooltip]="'DASHBOARD.NAV.MENU.LABOR.TOOLTIP.TEXT' | translate"
        [matTooltipPosition]="'right'" matTooltipClass="nav-tooltip">
        <mat-icon class="material-symbols-outlined">
          patient_list
        </mat-icon>
      </a>
      <mat-menu #laborMenu="matMenu" class="sub-menu-list">
        <ng-template [ngTemplateOutlet]="laborMenuItem"></ng-template>
      </mat-menu>
      <a class="action" [matMenuTriggerFor]="prodMenu"
        [matTooltip]="'DASHBOARD.NAV.MENU.PRODUCTIVITY.TOOLTIP.TEXT' | translate" [matTooltipPosition]="'right'"
        matTooltipClass="nav-tooltip">
        <mat-icon class="material-symbols-outlined">
          monitoring
        </mat-icon>
      </a>
      <mat-menu #prodMenu="matMenu" class="sub-menu-list">
        <ng-template [ngTemplateOutlet]="productivityMenuItem"></ng-template>
      </mat-menu>
      }
      <!-- <a class="action" routerLink="/trainings" routerLinkActive="active-link"
         *ngIf="this.loggedInUserDocData?.type === 'developer'" [matTooltipDisabled]="isMenuOpened" [matTooltip]="'DASHBOARD.NAV.MENU.TRAINING.TITLE.TEXT' | translate" [matTooltipPosition]="'right'" matTooltipClass="nav-tooltip">
        <mat-icon class="material-symbols-outlined">
          tv_signin
        </mat-icon>
        {{'DASHBOARD.NAV.MENU.TRAINING.TITLE.TEXT' |
        translate}}</a> -->
      <a class="action" routerLink="/userprofile" routerLinkActive="active-link"
          [matTooltipDisabled]="isMenuOpened"
        [matTooltip]="'DASHBOARD.NAV.MENU.USERPROFILE.TOOLTIP.TEXT' | translate" [matTooltipPosition]="'right'"
         matTooltipClass="nav-tooltip" *ngIf="hasUserProfileAccess">
        <mat-icon class="material-symbols-outlined">
          manage_accounts
        </mat-icon>
        @if(isMenuOpened){
        {{'DASHBOARD.SETTINGS.TABS.USERPROFILE.LABEL.TEXT' |
        translate}}
        }
      </a>
      <a class="action" routerLink="/settings" routerLinkActive="active-link"
        *ngIf="this.selectedClientDocData?.hasAccessToSettings" [matTooltipDisabled]="isMenuOpened"
         [matTooltip]="'DASHBOARD.NAV.MENU.SETTINGS.TOOLTIP.TEXT' | translate" [matTooltipPosition]="'right'"
        matTooltipClass="nav-tooltip">
        <mat-icon class="material-symbols-outlined">
          settings
        </mat-icon>
        @if(isMenuOpened){
        {{'DASHBOARD.NAV.MENU.SETTINGS.BUTTON.TEXT' | translate}}
        }
      </a>
    </div>

    <span class="central-stretchable-space flex-grow"></span>
    <div class="custom-hr"></div>
    <button class="user_info" routerLinkActive="active-link" aria-label="Example with a menu"
      routerLink="/settings/userprofile">
      <mat-icon class="material-symbols-outlined setting-man">
        manage_accounts
      </mat-icon>
      <div class="ml-1">
        <div class="name">
          {{loggedInUserDocData?.name}}
        </div>
        <div class="email">
          {{loggedInUserDocData?.email}}
        </div>
      </div>
      <mat-icon class="material-symbols-outlined">
        chevron_right
      </mat-icon>
    </button>
    <button class="logout_btn" (click)="this.authService.signOut()" [matTooltipDisabled]="isMenuOpened"
      [matTooltip]="'DASHBOARD.NAV.MENU.LOGOUT.TOOLTIP.TEXT' | translate" [matTooltipPosition]="'right'"
      matTooltipClass="nav-tooltip">
      <mat-icon class="material-symbols-outlined">
        logout
      </mat-icon>
      <div class="flex-grow ml-2 text-nowrap">{{'DASHBOARD.NAV.MENU.LOGOUT.BUTTON.TEXT' | translate}}</div>
      <mat-icon class="material-symbols-outlined">
        chevron_right
      </mat-icon>
    </button>
    <div class="toggle-action d-flex align-items-center justify-content-end">
      <button mat-icon-button class="toggle_menu" (click)="toggleMenu()">
        <mat-icon class="material-symbols-outlined" *ngIf="isMenuOpened">
          chevron_left
        </mat-icon>
        <mat-icon class="material-symbols-outlined" *ngIf="!isMenuOpened">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="d-flex flex-column h-100 side-nav-wrapper" [ngClass]="{'menu-collapsed':!isMenuOpened}"
    *ngIf="loggedInUserDocData && loggedInUserDocData.email === 'info@jacoudijk.nl'">

    <h1 class="logo-side">
      <ng-container *ngIf="!isMenuOpened">
        <img width="135" height="50" src="assets/Opus Watch_Logo_RGB_Icon.png">
      </ng-container>
      <ng-container *ngIf="isMenuOpened">
        <img width="146" src="assets/Opus Watch_Logo_RGB_L_N_W.png">
      </ng-container>

    </h1>
    <span class="central-stretchable-space flex-grow"></span>
    <div class="custom-hr"></div>
    <button class="user_info" routerLinkActive="active-link" aria-label="Example with a menu"
      routerLink="/settings/userprofile">
      <mat-icon class="material-symbols-outlined setting-man">
        manage_accounts
      </mat-icon>
      <div class="ml-1">
        <div class="name">
          {{loggedInUserDocData?.name}}
        </div>
        <div class="email">
          {{loggedInUserDocData?.email}}
        </div>
      </div>
      <mat-icon class="material-symbols-outlined">
        chevron_right
      </mat-icon>
    </button>
    <button class="logout_btn" (click)="this.authService.signOut()">
      <mat-icon class="material-symbols-outlined">
        logout
      </mat-icon>
      <div class="flex-grow ml-1 text-nowrap">{{'DASHBOARD.NAV.MENU.LOGOUT.BUTTON.TEXT' | translate}}</div>
      <mat-icon class="material-symbols-outlined">
        chevron_right
      </mat-icon>
    </button>
    <div class="toggle-action d-flex align-items-center justify-content-end">
      <button mat-icon-button class="toggle_menu" (click)="toggleMenu()">
        <mat-icon class="material-symbols-outlined" *ngIf="isMenuOpened">
          chevron_left
        </mat-icon>
        <mat-icon class="material-symbols-outlined" *ngIf="!isMenuOpened">
          chevron_right
        </mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" class="mat-menu-sidePanel" xPosition="before">
  <!-- <div class="user_info">
    <span>{{loggedInUserDocData?.name}} <span>{{loggedInUserDocData?.type === 'developer'? 'DEV': ''}}</span></span>
    <span>{{loggedInUserDocData?.email}}</span>
  </div> -->
  <button mat-menu-item [routerLink]="['/settings/users']" style="margin-top: -0.3rem; margin-bottom: -0.3rem;"
    *ngIf="selectedClientDocData?.hasManagerOrAdminRole">{{'DASHBOARD.NAV.MENU.NEWACCOUNT.BUTTON.TEXT' |
    translate}}</button>

  <button mat-menu-item [routerLink]="['/settings/userprofile']" style="margin-top: -0.3rem; margin-bottom: -0.3rem;"
    *ngIf="selectedClientDocData?.hasManagerOrAdminRole">{{'DASHBOARD.NAV.MENU.PROFILE.BUTTON.TEXT' | translate}}
  </button>
  <button mat-menu-item [routerLink]="['/settings']" style="margin-top: -0.3rem; margin-bottom: -0.3rem;"
    *ngIf="selectedClientDocData?.hasManagerOrAdminRole">{{'DASHBOARD.NAV.MENU.SETTINGS.BUTTON.TEXT' | translate}}
  </button>

  <button mat-menu-item [routerLink]="['/help']" style="margin-bottom: -0.3rem;">{{'DASHBOARD.NAV.MENU.HELP.BUTTON.TEXT'
    | translate}}</button>

  <button mat-menu-item (click)="this.authService.signOut()"
    style="margin-top: -0.3rem; margin-bottom: -0.3rem;">{{'DASHBOARD.NAV.MENU.LOGOUT.BUTTON.TEXT' |
    translate}}</button>
</mat-menu>

<ng-template #customTooltip>
  <div class="custom-tooltip-content">
    This is a custom tooltip with custom styles.
  </div>
</ng-template>

<ng-template #presncesMenuItem>
  <div class="inner-item-list">
    <a class="action" routerLink="/dashboard/labor/presences" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.TODAY.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/registrations/presences" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.PRESENCEREGISTRATION.TITLE.TEXT' | translate}}
    </a>
  </div>
</ng-template>
<ng-template #laborMenuItem>
  <div class="inner-item-list">
    <a class="action" routerLink="/dashboard/registrations/overview" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.TASKSOVERVIEW.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/registrations/tasks" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.TASKREGISTRATIONS.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/labor/overview" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.SESSIONSOVERVIEW.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/labor/performance" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.PERFORMANCE.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/labor/productivity" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.PRODUCTIVITY.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/sessions" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.SESSIONS.TITLE.TEXT' | translate}}
    </a>
  </div>
</ng-template>
<ng-template #productivityMenuItem>
  <div class="inner-item-list">
    <a class="action" routerLink="/dashboard/productivity/rowmap" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.ROWMAP.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/labor/trolley" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.TROLLEY.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/productivity/row" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.ROWPRODUCTIVITY.TITLE.TEXT' | translate}}
    </a>
    <a class="action" routerLink="/dashboard/productivity/variety" routerLinkActive="active-link">
      {{'DASHBOARD.NAV.MENU.VARIETYPRODUCTIVITY.TITLE.TEXT' | translate}}
    </a>
  </div>
</ng-template>
