<div class="large-modal">
  <h2 mat-dialog-title>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.SUMMARY.TITLE.TEXT' |  translate}}</h2>
  <mat-dialog-content class="text-white">
    <form #editForm="ngForm">
      <div id='Summary'>
        <div class="row pb-4">
          <div class="col-lg-3">
            <div>
              <div>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.WORKER.LABEL.TEXT' |  translate}}</div>
                <div>{{presenceDoc?.workerName ?? ''}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div>
              <div>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.WORKERGROUP.LABEL.TEXT' |  translate}}</div>
              <div>{{ presenceDoc?.workerGroupName ?? '' }}</div>
            </div>
          </div>
          <div class="col-lg-6">
            <mat-form-field class="w-100">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.LOCATION.LABEL.TEXT' |  translate}}</mat-label>
              <mat-select [(ngModel)]="presenceLocationId" name="locationId" [disabled]="true" autofocus required>
                <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
                  {{location.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row pb-4 pt-2">
          <div class="col-lg-3">
            <div class="">
              <div>Date {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.DATE.LABEL.TEXT' |  translate}}</div>
              <div>{{presenceListingRecord?.date ?? ''}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div>
              <div>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.HOURSWORKED.LABEL.TEXT' |  translate}}</div>
              <div>{{getHoursWorked()}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div>
              <div>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.BREAKPAID.LABEL.TEXT' |  translate}}</div>
              <div>{{getBreaksPaid()}}</div>
            </div>
          </div>
          <div class="col-lg-3">
            <div>
              <div>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.BREAKUNPAID.LABEL.TEXT' |  translate}}</div>
              <div>{{getBreaksUnpaid()}}</div>
            </div>
          </div>
        </div>
      </div>
      <div id='Details' class="mt-3">
        <h5 class="mb-4"> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.DETAILS.TITLE.TEXT' |  translate}}</h5>
        <div *ngIf="startDayTaskRegn" class="gap-12 startTime-detail pb-4 d-flex align-items-center">

          <mat-icon>play_circle</mat-icon>
          <div class="f-grow gap-12 align-items-center">
            <mat-form-field *ngIf="startDayTaskRegn.isShown">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.STARTTIME.LABEL.TEXT' |  translate}}</mat-label>
              <input matInput type="time" required name="dayStartTime"
                   [(ngModel)]="startDayTaskRegn.startTime"
                     (ngModelChange)="calculatePresenceDurations('startTime')"
              />
              <mat-hint>{{startTimeRoundedHint ?? ''}}</mat-hint>
            </mat-form-field>
            <button mat-raised-button color="accent" *ngIf="!startDayTaskRegn.isShown" (click)="showStartDayRegn()"> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.CREATESTARTTIME.BUTTON.TEXT' |  translate}}</button>
          </div>

          <button mat-icon-button (click)="addBreakRegnOnTop()">
            <mat-icon matTooltip="Add break">add</mat-icon>
          </button>
        </div>
        <div *ngFor="let regn of getBreakRegnsToDisplay();let idx = index" class="pb-4">
          <mat-card class="d-flex flex-row align-items-center transparent-effect-card my-2">
            <mat-icon class="material-symbols-outlined">free_breakfast_outline</mat-icon>
            <mat-form-field style="margin-left: 1rem;">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.NAME.LABEL.TEXT' |  translate}}</mat-label>
              <input required matInput type="text" [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="regn.taskName">
            </mat-form-field>
            <mat-form-field  style="margin-left: 1rem;">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.STARTTIME.LABEL.TEXT' |  translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="regn.startTime"
                     (ngModelChange)="calculatePresenceDurations('breakStartTime')"
              >
              <!--<mat-hint>{{regn.startTimeRounded ?? ''}}</mat-hint>-->
            </mat-form-field>
            <mat-form-field style="margin-left: 1rem;">
              <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ENDTIME.LABEL.TEXT' |  translate}}</mat-label>
              <input matInput type="time" required [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="regn.endTime"
                     (ngModelChange)="calculatePresenceDurations('breakEndTime')"
              >
              <!--<mat-hint>{{regn.endTimeRounded ?? ''}}</mat-hint>-->
            </mat-form-field>
            <mat-button-toggle-group [hideSingleSelectionIndicator]="true" [(ngModel)]="regn.isPaid" class="custom-toggle-btn"
            (ngModelChange)="calculatePresenceDurations('breakIsPaid')"
            name="isPaid" [ngModelOptions]="{standalone: true}" required aria-label="Font Style">
            <mat-button-toggle [value]="false" >{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEUNPAID.BUTTON.TEXT' |  translate}}</mat-button-toggle>
              <mat-button-toggle [value]="true" >{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.TOGGLEPAID.BUTTON.TEXT' |  translate}}</mat-button-toggle>

            </mat-button-toggle-group>
            <div class="d-flex align-items-center">
              <button mat-icon-button matTooltip="Remove break" (click)="deleteBreakRegn(regn, idx)">
                <mat-icon>remove</mat-icon>
              </button>
              <button class="ml-1" mat-icon-button matTooltip="Add break" (click)="addNewBreakRegn(regn, idx)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div>
           </div>
          </mat-card>
        </div>
        <div *ngIf="endDayTaskRegn" class="gap-12 startTime-detail pb-4 d-flex align-items-center">
          <mat-icon>stop_circle</mat-icon>

          <mat-form-field *ngIf="endDayTaskRegn.isShown">
            <mat-label>{{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.ENDTIME.LABEL.TEXT' |  translate}}</mat-label>
            <input matInput type="time" required #dayEndTime="ngModel"
                 [(ngModel)]="endDayTaskRegn.endTime"
                   (ngModelChange)="calculatePresenceDurations('endTime')"
                   name="endDayEndTime">
            <mat-hint>{{endTimeRoundedHint ?? ''}}</mat-hint>
          </mat-form-field>
          <button mat-raised-button color="accent" *ngIf="!endDayTaskRegn.isShown" (click)="showEndDayRegn()"> {{'DASHBOARD.REGISTRATIONS.PRESENCES.CREATEMODAL.CREATEENDTIME.LABEL.TEXT' |  translate}}</button>
        </div>
      </div>
    </form>
  </mat-dialog-content>


  <div id='ButtonPanel'>
    <mat-dialog-actions class="w-100">
      <div class="f-grow">
        <button
          mat-icon-button
          color="accent"
          [disableRipple]="true"
          (click)="archivePresenceNRegns()"
          matTooltip="Archive presence & registrations"
          matTooltipClass="tooltip-class"
          matTooltipHideDelay="100"
          matTooltipPosition="below"
          [disabled]="presenceDoc?.isArchived">
          <mat-icon>archive</mat-icon>
        </button>
        <span *ngIf="presenceDoc.updatedByUserId" style="color: lightgray;">&nbsp;&nbsp;Updated: {{getFormattedUpdatedTimestamp()}}({{this.presenceDoc.updatedByUserName}})</span>
      </div>
      <div>
        <button mat-stroked-button color="primary" (click)="cancelPresenceSave()" style="margin-right: 2rem;">{{'DASHBOARD.MODAL.CANCEL.BUTTON.TEXT' | translate}}</button>
        <button mat-raised-button [disabled]="beingSaved" color="primary" style="height: 1.92rem;" (click)="savePresenceNRegistrations()">{{'DASHBOARD.MODAL.SAVE.BUTTON.TEXT' | translate}}</button>
      </div>
    </mat-dialog-actions>

  </div>
</div>
