import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTab, MatTabBody, MatTabGroup, MatTabHeader, MatTabsModule} from '@angular/material/tabs';
import {ClientInContextService} from "../../services/client-in-context.service";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss',
    '../../common/styles/listing.scss']
})
export class ClientSettingsComponent implements OnInit, OnDestroy {

  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;

  rootUrl = '/settings';

  navigation = [
    {
      name: 'DASHBOARD.SETTINGS.TABS.GENERAL.LABEL.TEXT',
      link: '/general',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.USERPROFILE.LABEL.TEXT',
      link: '/userprofile',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.LOCATION.LABEL.TEXT',
      link: '/locations',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.USERS.LABEL.TEXT',
      link: '/users',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.WORKERS.LABEL.TEXT',
      link: '/workers',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.SESSIONS.LABEL.TEXT',
      link: '/sessions',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.PRESENCES.LABEL.TEXT',
      link: '/presences',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.TASKS.LABEL.TEXT',
      link: '/tasks',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.POSTIONS.LABEL.TEXT',
      link: '/positions',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.ASSETS.LABEL.TEXT',
      link: '/assets',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.BEACONS.LABEL.TEXT',
      link: '/beacons',
    },
    {
      name: 'DASHBOARD.SETTINGS.TABS.DEVICES.LABEL.TEXT',
      link: '/devices',
    }
  ];

  authUserSubscription: Subscription;

  constructor(private clientInContextService: ClientInContextService, private route: ActivatedRoute, public router: Router, private authService: AuthService) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      if (this.selectedClientDocData.hasOwnProperty('hasAdminRole')) {
        if (this.selectedClientDocData?.hasAdminRole) {
          this.navigation.push({
            name: 'Integrations',
            link: '/integrations',
          })
        }
        if (this.selectedClientDocData?.isDeveloper) {
          this.navigation.push({
            name: 'Languages',
            link: '/languages',
          });
        }
      } else {
        this.authUserSubscription = this.authService.loggedInUserFromAuthService$.subscribe((userDocData: any) => {
          if (this.navigation.filter(element => element.name === 'Languages').length > 0) {
            return;
          }
          if (this.navigation.filter(element => element.name === 'Integrations').length > 0) {
            return;
          }
          const clientSpecificRole = userDocData.clients.filter(client => client?.clientId === selectedClientDocData?.id)[0]?.role;
          const userHasAdminRole = ['admin'].includes(clientSpecificRole);
          const isDeveloper = userDocData.type === 'developer';
          if (userHasAdminRole) {
            this.navigation.push({
              name: 'Integrations',
              link: '/integrations',
            })
          }
          if (isDeveloper) {
            this.navigation.push({
              name: 'Languages',
              link: '/languages',
            })
          }
          this.authUserSubscription?.unsubscribe();
        });
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.authUserSubscription?.unsubscribe();
  }

}
