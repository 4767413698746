<section class="new-table-design-container mat-elevation-z8" tabindex="0" style="margin-top:4px;" *ngIf="perms?.canRead">
  <div class="controls-container filter-container sm-grid-areas">
    <button mat-flat-button color="primary" class="add-area" (click)="openCreateRegnDialog()"
      *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create new task" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'DASHBOARD.REGISTRATIONS.TASKS.CREATE.BUTTON.TEXT' | translate}}</button>
    <div class="date-container justify-content-center date-area">
      <app-date-selection></app-date-selection>
    </div>

    <button mat-icon-button (click)="downloadAsCSV('Registrations')" class="download-area hide-sm" aria-label=""
      [matTooltip]="'DASHBOARD.REGISTRATIONS.TASKS.DOWNLOAD.TOOLTIP.TEXT' | translate" *ngIf="perms?.canDownload">
      <mat-icon>download</mat-icon>

    </button>
    <mat-button-toggle-group class="archive-toggle toggle-area" [hideSingleSelectionIndicator]="true" [(ngModel)]="selectedView" #group="matButtonToggleGroup">
      <mat-button-toggle value="listView" (click)="toggleArchived(false);" checked matTooltip="All items">
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="archiveView" (click)="toggleArchived(true);" matTooltip="Archived items">
        <mat-icon>archive</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

  </div>
  <div class="filter-string" *ngIf="filterString">{{filterString}}</div>
</section>

<div class="table-card table-mobile-res table-to-card-mb new-table-design-container mat-elevation-z8 expandable-table">

  <app-common-table [isRowExpandable]="true" [columnsHeadersToDisplay]="getDisplayedCols()" [columnsToDisplay]="columns" [gridList]="tableData" [tableName]="'taskRegistrationTable'" (editDialogOpened)="openEditDialog($event)" (onArchiveRow)="archiveRegistration($event)" (onUnArchiveRow)="unarchiveRegistration($event)" [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'task registration'">

  </app-common-table>
</div>
