@if (bindGridList) {
<div class="table-container">
  <!-- Table with horizontal scroll -->
  <div class="table-scroll-container">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="listing-table">
      @if(isRowSelectable){
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="hide-sm">
          <mat-checkbox (click)="toggleMultiSelect()" [(ngModel)]="selectAll"
            [indeterminate]="determineIndeterminate()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools hide-sm">
          <mat-checkbox [(ngModel)]="element.isSelected" (click)="$event.stopPropagation()"></mat-checkbox>
        </td>
      </ng-container>
      }
      @if(tableName==='deviceTable'){

      <ng-container matColumnDef="deviceIcon">
        <th mat-header-cell *matHeaderCellDef>

        </th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools right-corner">
          <span class="material-symbols-outlined">{{element.deviceIcon}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="lockIcon">
        <th mat-header-cell *matHeaderCellDef>

        </th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools right-corner right-corner-2">
          <span *ngIf="element.isLocked" class="material-symbols-outlined">lock</span>
          <span *ngIf="!element.isLocked">&nbsp;</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="issue">
        <th mat-header-cell *matHeaderCellDef> {{'SETTINGS.TABLEHEAD.ISSUE.TITLE.TEXT' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools"
          attr.dataName="{{'SETTINGS.TABLEHEAD.ISSUE.TITLE.TEXT' | translate}}">
          <mat-checkbox [disabled]="!perms?.canUpdate" [(ngModel)]="element.hasIssue" (click)="$event.stopPropagation()"
            (change)="saveIssue($event, element)">
          </mat-checkbox>
        </td>
      </ng-container>
      }
      @if(tableName=== 'overviewTable'){
      <ng-container matColumnDef="red-dot">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools right-corner">
          <mat-icon *ngIf="element.avgWorkerPerfRelToGroupPast > 0" class="material-symbols-outlined success">
            arrow_upward
          </mat-icon>
          <mat-icon *ngIf="element.avgWorkerPerfRelToGroupPast < 0" class="material-symbols-outlined failure">
            arrow_downward
          </mat-icon>
          <img [mtxTooltip]="redDotTooltip" ngSrc="assets/lightgray.ico" height="18" width="18" style="margin-top: 4px;"
            *ngIf="(element.isAnyNestedRowRed)">
          <ng-template #redDotTooltip>
            <div
              style="background: #5b5f66; color: mintcream;padding:4px;border-radius:5px; text-align: center;font-size: 0.92rem;width:12rem !important;">
              <div style="margin-bottom: 4px;">Check if the session is correct</div>
              <span style="">Gross Performance is {{element.grossPerformance}}</span> <br />
              <span style="">Net Performance is {{element.netPerformance}}</span> <br />
            </div>
          </ng-template>
        </td>
      </ng-container>
      }
      @if(tableName === 'workerTable'){
      <ng-container matColumnDef="isLeftHanded">
        <th mat-header-cell *matHeaderCellDef>{{'SETTINGS.TABLEHEAD.LEFTHANDED.TITLE.TEXT' | translate}}</th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper right-corner">
          <mat-icon class="material-symbols-outlined" *ngIf="element.isLeftHanded">
            check
          </mat-icon>
        </td>
      </ng-container>
      }
      @if(tableName === 'taskRegistrationTable'){
      <ng-container matColumnDef="checkColumn">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools static-td right-corner bg-transparent-sm">
          <div class="duration-error-icon">
            <mat-icon style="color: #d81159" class="material-symbols-outlined"
              matTooltip="Duration does not seem correct" *ngIf="element.isTooLong" matTooltipClass="tooltip-class"
              matTooltipHideDelay="100" matTooltipPosition="below">error</mat-icon>
          </div>
        </td>
      </ng-container>
      }
      @if(tableName === 'sessionTable'){
      <ng-container matColumnDef='manual_indicator'>
        <th mat-header-cell class="table-header" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="static-td right-corner bg-transparent-sm">
          <mat-icon matTooltip="{{'DASHBOARD.SESSION.TABLE.TOOLTIP.MANUALLYCREATED.LABEL.TEXT' |  translate}}"
            matTooltipHideDelay="100" matTooltipPosition="below" *ngIf="element.isManual">front_hand</mat-icon>
        </td>
      </ng-container>
      }

      @if(showActionColumn){
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper left-corner bottom-full-w">
          <div [ngClass]="{'d-flex':tableName === 'sessionTable'}">
            <div class="d-flex grid-control-column">
              @if(isRowEditable){
              <button mat-icon-button color="accent" (click)="openEditDialog($event, element)"
                class="listing-table-tools" matTooltip="Edit {{entityName}}" matTooltipClass="tooltip-class"
                matTooltipHideDelay="100" matTooltipPosition="below" [disabled]="!perms?.canUpdate">
                <mat-icon>edit</mat-icon>
              </button>
              }
              @if(isArchivedAble){
              <button mat-icon-button color="accent" (click)="archiveRow($event, element)" class="listing-table-tools"
                matTooltip="Archive this {{entityName}}" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                matTooltipPosition="below" [disabled]="!perms?.canDelete"
                *ngIf="!element.isArchived && perms?.canDelete">
                <mat-icon>archive</mat-icon>
              </button>
              <button mat-icon-button color="accent" (click)="unarchiveRow($event,element)" class="listing-table-tools"
                matTooltip="Unarchive this {{entityName}}" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                matTooltipPosition="below" [disabled]="!perms?.canDelete"
                *ngIf="element.isArchived && perms?.canDelete">
                <mat-icon>unarchive</mat-icon>
              </button>
              }
              @if(isRowDeleteable){
              <button mat-icon-button color="accent" [disabled]="!perms?.canDelete" (click)="deleteRow($event, element)"
                class="listing-table-tools" *ngIf="element.isArchived && perms?.canDelete">
                <mat-icon matTooltip="Delete this {{entityName}}" matTooltipClass="tooltip-class"
                  matTooltipHideDelay="100" matTooltipPosition="below" style="width: 44px; height: 24px">delete
                </mat-icon>
              </button>
              }
              @if(tableName==='sessionTable'){
              <button mat-icon-button color="accent" (click)="copyAsTraining($event, element)"
                class="listing-table-tools tdhover" *ngIf="selectedClientDocData.isDeveloper">
                <mat-icon matTooltip="Copy as Training" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                  matTooltipPosition="below" *ngIf="perms?.canConvert">movie_edit
                </mat-icon>
              </button>
              }

            </div>
            @if(tableName === 'sessionTable'){
            <button mat-icon-button color="accent" (click)="fixSession($event, element)"
              [ngClass]="element.fixButtonClass" matTooltip="Initiate fix" matTooltipClass="tooltip-class"
              matTooltipHideDelay="500" matTooltipPosition="below" [disabled]="!element.fixAllowed"
              *ngIf="perms.canFix && (!element.fixFailed)">
              <mat-icon>restart_alt</mat-icon>
            </button>
            <button mat-icon-button color="accent" (click)="fixSession($event, element)"
              [ngClass]="element.fixButtonClass" [mtxTooltip]="tooltipFix" mtxTooltipPosition="below" [disabled]=""
              *ngIf="selectedClientDocData.isDeveloper && element.fixFailed">
              <mat-icon>sync_problem</mat-icon>
            </button>
            <ng-template #tooltipFix>
              <div
                style="background: #5b5f66; color: mintcream;padding:5px;border-radius:3px; text-align: center;font-size: 0.95rem;width:10rem;">
                <div style="margin-bottom: 4px;">{{getFailedToolTip(element).topline}}</div>
                <span style="text-decoration: underline;">Prev. failure reason</span> <br />
                <div style="font-size: 0.75rem;font-style: italic">{{getFailedToolTip(element).bottomLine}}</div>
              </div>
            </ng-template>
            }
          </div>

        </td>
      </ng-container>
      }

      <ng-container *ngIf="isRowExpandable">
        <ng-container matColumnDef="expandedDetail">
          @if(tableName === 'deviceTable'){
          <td mat-cell *matCellDef="let element" colspan="12">
            <mat-icon (click)="expandedElement = null"
              class="material-symbols-outlined close-expandable d-block d-md-none">
              close
            </mat-icon>
            <ng-template [ngTemplateOutlet]="DeviceExpandable" [ngTemplateOutletContext]="{ element: element }">
            </ng-template>
          </td>

          }
          @if(tableName === 'taskRegistrationTable'){
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsHeadersToDisplay.length">
            <mat-icon (click)="expandedElement = null"
              class="material-symbols-outlined close-expandable d-block d-md-none">
              close
            </mat-icon>
            <ng-template [ngTemplateOutlet]="taskRegistrationExpandable"
              [ngTemplateOutletContext]="{ element: element }">
            </ng-template>
          </td>
          }
          @if(tableName==='sessionTable'){
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsHeadersToDisplay.length">
            <mat-icon (click)="expandedElement = null"
              class="material-symbols-outlined close-expandable d-block d-md-none">
              close
            </mat-icon>
            <ng-template [ngTemplateOutlet]="sessionExpandable" [ngTemplateOutletContext]="{ element: element }">
            </ng-template>
            }

        </ng-container>
      </ng-container>

      @for ( column of columnsToDisplay; track $index) {
      <ng-container [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef>
          <div class="table-header-container">

            <div class="table-header-content-text">
              {{column.displayName | translate}}
            </div>
            <div class="table-header-content-menu">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                class="menu-button" [ngClass]="{'filtered':column.filterValue!==''}">
                <mat-icon>filter_list</mat-icon>
              </button>
              <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                <button mat-menu-item (click)="sortList(column.name,'asc')">
                  <mat-icon></mat-icon>
                  <span innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span>
                </button>
                <button mat-menu-item (click)="sortList(column.name,'desc')">
                  <mat-icon></mat-icon>
                  <span innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span>
                </button>


                <form class="example-form px-3" (click)="$event.stopPropagation()">
                  <mat-form-field class="filter" appearance="outline">
                    <mat-label>{{'DASHBOARD.TABLE.FILTERVALULE.LABEL.TEXT' | translate}}</mat-label>
                    <input type="text" placeholder="" aria-label="Filter Value" matInput
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="column.filterValue" [matAutocomplete]="auto"
                      (keyup)="headerFilter($event, column.name)" (focus)="headerFilter($event, column.name)" />
                    <button mat-icon-button matSuffix (click)="removeFilters()">
                      <mat-icon>close</mat-icon>
                    </button>

                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of column.filteredOptions" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
                <button mat-menu-item (click)="removeFilters($event, column)">
                  <mat-icon>filter_list_off</mat-icon>
                  <span>Clear Filters</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="{{isMobileCard?'common-flex':''}}"
          [attr.dataName]="column.displayName | translate">
          <div class="d-flex align-items-center mobile-allign-end">
            @if(column?.isDeviceLastSeen && entityName==='device'){
            <span class="material-symbols-outlined mr-1" *ngIf="element.lastSeenIcon === 'green'"
              (click)="visibilityClicked($event, element)"
              style="cursor: pointer;color: #00e9a7; font-size: 16px;">visibility</span>
            <span class="material-symbols-outlined mr-1" *ngIf="element.lastSeenIcon === 'red'"
              (click)="visibilityClicked($event, element)"
              style="cursor: pointer;color: #f44336;font-size: 16px">visibility_off</span>
            <div style="display: flex; align-items: end;" *ngIf="element.isBatteryStatus">
              <span class="material-symbols-outlined" style="margin-top: 7%;color: #00ffa7;transform: scale(1.2);"
                *ngIf="element.isCharging">battery_charging_60</span>
            </div>
            }
            <div>{{element[column.name]}}</div>
          </div>

        </td>
      </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay; sticky: true"></tr>

      @if(tableName === 'overviewTable'){
      <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"
        [class.expanded-row]="applyExpandedClass(element)" (click)="openExpandedModal(element)">
      </tr>
      }
      @else if(isRowExpandable){
      <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"
        [class.expanded-row]="applyExpandedClass(element)" (click)="expandRow(element)"></tr>
      <tr style="height: 0;" mat-row *matRowDef="let row; columns: ['expandedDetail']"
        class="row-detail expandable-row">
      </tr>
      }
      @else{
      <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"></tr>
      }

    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="100" [showFirstLastButtons]="true"></mat-paginator>
</div>
}

<ng-template #DeviceExpandable let-element="element">

  <div class="row-detail-description device-row-description">
    <div class="row-detail expanded-row-detail" [@detailExpand]="
    element.deviceId == expandedElement?.deviceId ? 'expanded' : 'collapsed'
  ">

      <table>
        <tr *ngFor="let column of element.remainingAttributesList" class="expandable-inner-row">
          <td class="details key expandable-inner-col">
            {{ column[0] | translate }}
          </td>
          <td class="details value expandable-inner-col"
            *ngIf="column[0] !== 'SETTINGS.TABLEHEAD.LASTGEOLOCATION.TITLE.TEXT' | translate">
            {{ column[1] }}
          </td>
          <td class="details value expandable-inner-col"
            *ngIf="(column[0] === 'SETTINGS.TABLEHEAD.LASTGEOLOCATION.TITLE.TEXT' | translate) && column[1]">
            <a href="https://maps.google.com/maps?z=2&t=m&q=loc:{{column[1].latitude}}+{{column[1].longitude}}"
              style="color: lightgray;" target="_blank">
              {{'SETTINGS.TABLE.OPENGOOGLEMAP.TITLE.TEXT' | translate}}
            </a>
          </td>
          <td class="details value expandable-inner-col" *ngIf="(column[0] === 'Last Geolocation') && !column[1]">
            &nbsp;
          </td>
        </tr>
      </table>

    </div>
  </div>
</ng-template>

<ng-template #taskRegistrationExpandable let-element="element">


  <div class="row-detail expanded-row-detail"
    [@detailExpand]=" element.id == expandedElement?.id ? 'expanded' : 'collapsed'">
    <div class="row-detail-description">
      <div class="row-detail-item">
        <strong> {{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.LOCATION.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['locationName']}}</span>
      </div>
      <div class="row-detail-item">
        <strong> {{'DASHBOARD.TABLEHEAD.COUNT.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['count']}}</span>
      </div>
      <div class="row-detail-item">
        <strong> {{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.ASSETS.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['assetNumbers']}}</span>
      </div>
      <div class="row-detail-item">
        <strong> {{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.STEPCOUNTER.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['stepCounter']}}</span>
      </div>
      <div class="row-detail-item">
        <strong>{{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.DEVICE.TITLE.TEXT' | translate}}:</strong>
        <span>{{element.deviceNumber}} ({{element['deviceId']}})</span>
      </div>
      <div class="row-detail-item">
        <strong> {{'DASHBOARD.REGISTRATION.TASK.NESTEDTABLE.REGISTRATION.TITLE.TEXT' | translate}}: </strong>
        <span>{{element['id']}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sessionExpandable let-element="element">
  <div class="row-detail" [@detailExpand]=" element.sessionId == expandedElement?.sessionId ? 'expanded' : 'collapsed'">
    <div class="row-detail-description">
      <div class="row-detail-item">
        <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.LOCATION.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['locationName']}}</span>
      </div>
      <div class="row-detail-item">
        <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.LAYOUT.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['layoutName']}}</span>
      </div>
      <div class="row-detail-item">
        <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.NET.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['nettPerformance']}}</span>
      </div>
      <div class="row-detail-item">
        <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.GROSS.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['grossPerformance']}}</span>
      </div>
      <div class="row-detail-item">
        <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.DEVICE.TITLE.TEXT' | translate}}:</strong>
        <span>{{element.deviceNumber}} ({{element['deviceId']}})</span>
      </div>
      <div class="row-detail-item">
        <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.SESSION.TITLE.TEXT' | translate}}:</strong>
        <span>{{element['sessionId']}}</span>
      </div>
    </div>
  </div>
</ng-template>
