 <div class="page-action listing-container-roles ">
  <mat-card class="transparent-effect-card mb-5">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="material-symbols-outlined mr-2">
          person
      </mat-icon>{{'SETTINGS.USERPROFILE.UPDATENAME.TITLE.TEXT' | translate}}
    </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="mt-3">
        <mat-form-field appearance="outline" class="w-100">
          <input matInput required
                 placeholder="Name"
                 [(ngModel)]="name"
          >
      </mat-form-field>
      </div>
      <div class="mt-3 d-flex justify-content-end">

        <div style="margin-right: 16px; display:flex; flex-direction: column;">
          <mat-slide-toggle  [(ngModel)]="clientNamesBlurred" (change)="updateBlurredSettingForUser()">
            <mat-label style="font-size: 1rem;font-weight: 300;"> Client names blurred</mat-label>
          </mat-slide-toggle>
        </div>

        <button mat-raised-button color="primary" class="ml-3" (click)="updateUser()">{{'SETTINGS.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
      </div>
    </mat-card-content>


  </mat-card>

  <mat-card class="listing-container-roles transparent-effect-card">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="material-symbols-outlined mr-2">
          person
      </mat-icon>{{'SETTINGS.USERPROFILE.CLIENTROLE.TITLE.TEXT' | translate}}
    </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="user-list mt-3">
        <div class="user-items transparent-effect-card" *ngFor="let item of userList">
          <mat-icon class="material-symbols-outlined">
            person
          </mat-icon>
          <div class="d-flex flex-column">
            <span class="user-name" [ngClass]="{'blurredName': clientNamesBlurred}">{{item.clientName}}</span>
            <span class="user-role">{{item.role}}</span>
          </div>
        </div>
      </div>
    </mat-card-content>


</mat-card>
</div>

