import {Component, DestroyRef, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {FirestoreService} from '../../../services/firestore.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {LegendPosition} from '@swimlane/ngx-charts';
import * as moment from 'moment/moment';
import momentDurationFormatSetup from 'moment-duration-format';
import {TIME_FORMAT} from '../../../common/utils/time-utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {getPermissions} from '../../../common/utils/permission-utils';

momentDurationFormatSetup(moment);

@Component({
  selector: 'app-registrations-overview-section',
  templateUrl: './registrations-overview-section.component.html',
  styleUrl: './registrations-overview-section.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RegistrationsOverviewSectionComponent implements OnInit, OnDestroy {
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  pieChartDataToDisplay: any[] = [];
  pieChartData: any[];
  view: any[] = [700, 400];

  // options
  gradient = false;
  showLegend = false;
  showLabels = true;
  isDoughnut = false;
  legendPosition = LegendPosition.Right;

  colorScheme = {
    domain: ['#00CCB3', '#D81159', '#E45932', '#EFA00B', '#00AEBF', '#00E9A7', '#13A384', '#265C60']
  }

  unarchivedRegnsSubscription: Subscription;
  regnsRawData: any[];
  pieChartMap = new Map();
  clientLocInContextServiceSubscription: Subscription;
  selectedLocationId: string;
  pieChartTotalTime: number;
  perms: any;

  constructor(private authService: AuthService,
              private firestoreService: FirestoreService,
              private router: Router,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private clientInContextService: ClientInContextService,
              private translateService: TranslateService,
              private destroyRef: DestroyRef) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      if (this.selectedClientDocData.rolePermissionsMap) {
        this.perms = getPermissions('labor', 'tasksOverview', selectedClientDocData.rolePermissionsMap);
      }
      this.clientLocInContextServiceSubscription = this.clientInContextService.clientLocSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedLocation => {
        this.selectedLocationId = !selectedLocation || (selectedLocation?.id === '-1') ? null : selectedLocation?.id;
        this.fetchUnarchivedRegns();
      });
    });
  }

  fetchUnarchivedRegns() {
    this.unarchivedRegnsSubscription =
      this.firestoreService.getUnarchivedRegnsForClientId(this.selectedClientDocData.id, new Date(), this.selectedLocationId).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(regns => {
        //console.log(`Overview - Loading regns data - length:${regns.length}`)
        regns = regns.map(regn => {
          return {
            ...regn
          }
        });
        this.regnsRawData = regns;
        this.pieChartMap = new Map();
        this.pieChartDataToDisplay = [];
        for (const regn of regns) {
          let taskDuration = 0;
          if (regn.taskName) {
            if (regn.startTimestamp && regn.endTimestamp) {
              taskDuration = moment(regn.endTimestamp.toDate()).diff(moment(regn.startTimestamp.toDate()), 'seconds');
            } else if (regn.startTimestamp && !regn.endTimestamp) {
              taskDuration = moment().diff(moment(regn.startTimestamp.toDate()), 'seconds');
            }
            if (this.pieChartMap.get(regn.taskName)) {
              this.pieChartMap.set(regn.taskName, this.pieChartMap.get(regn.taskName) + taskDuration);
            } else {
              this.pieChartMap.set(regn.taskName, taskDuration);
            }
          }
        }

        for (const key of this.pieChartMap.keys()) {
          this.pieChartDataToDisplay.push({
            name: key,
            value: this.pieChartMap.get(key)
          });
        }
        Object.assign(this, {pieChartData: this.pieChartDataToDisplay});
      });
  }


  labelFormatting(name) {
    const self: any = this; // this 'this' will refer to the chart component (pun intented :))

    const pieSpecificData = self.series.filter(x => x.name === name); // chartData will be present in
    // series along with the label
    let pieChartTotalTime = 0;
    for (const seriesEntry of self.series) {
      pieChartTotalTime += seriesEntry.value;
    }
    if (pieSpecificData.length > 0) {
      return `${pieSpecificData[0].name}: ${((pieSpecificData[0].value / pieChartTotalTime) * 100).toFixed(0)}% (${moment.duration(pieSpecificData[0].value, 'seconds').format(TIME_FORMAT)})`;
    } else {
      return name;
    }
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.clientLocInContextServiceSubscription?.unsubscribe();
    this.unarchivedRegnsSubscription?.unsubscribe();
  }

}
