import {Component} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import {UrlNavigationInterceptorService} from "./services/url-navigation-interceptor.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'opus-dashboard';
  public loggedInUserFromAuthServiceSubscription: Subscription;
  public loggedInUserDocData: any;

  constructor(public authService: AuthService,
              private urlNavigationService: UrlNavigationInterceptorService) {
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => this.loggedInUserDocData = userDocData)
  }
}
