import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {roleStrength} from '../../../common/utils/permission-utils';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit, OnDestroy {
  editUserForm: UntypedFormGroup;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  userId: string;
  userRecordBeingEdited: any;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  languagesList: any[];
  allWorkersList: any[];
  workerListSubscription: Subscription;
  userPermissions: any = {};
  userRole: any;
  standardRolesSubscription: Subscription;
  rolePermissionMapFromDB: any = {};
  updatingUser = false;
  roles: string[] = [];
  roleDisplayedValueMap = new Map();
  loggedInUserSelfRoleObject: any;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.roleDisplayedValueMap.set('regular', 'Regular');
    this.roleDisplayedValueMap.set('admin', 'Admin');
    this.roleDisplayedValueMap.set('owner', 'Owner');
    this.roleDisplayedValueMap.set('developer', 'Developer');
    this.userRecordBeingEdited = data.userRecord;
    this.userId = this.userRecordBeingEdited.id
    this.languagesList = data.languagesList;

    this.editUserForm = this.fb.group({
      name: [this.userRecordBeingEdited.name, [Validators.required]],
      notes: [this.userRecordBeingEdited.notes, []],
      languageCode: [this.userRecordBeingEdited.languageCode, []],
      associatedWorkerId: [this.userRecordBeingEdited.associatedWorkerId, []],
      role: [this.userRole, []],
      permissions: this.fb.group({
        presences: this.fb.group({
          today: [[], []],
          presences: [[], []],
        }),
        labor: this.fb.group({
          overview: [[], []],
          performance: [[], []],
          productivity: [[], []],
          taskRegns: [[], []],
          sessions: [[], []],
          tasksOverview: [[], []]
        }),
        productivity: this.fb.group({
          rowMap: [[], []],
          trolley: [[], []],
          rowPrdctvty: [[], []],
          varietyPrdctvty: [[], []],
        }),
        training: [[], []],
        settings: this.fb.group({
          general: [[], []],
          userProfile: [[], []],
          locations: [[], []],
          users: [[], []],
          workers: [[], []],
          sessions: [[], []],
          presences: [[], []],
          positions: [[], []],
          assets: [[], []],
          beacons: [[], []],
          varieties: [[], []],
          tasks: [[], []],
          devices: [[], []],
          apis: [[], []]
        }),
        language: [[], []],
        clients: [[], []],
      })
    });

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;

        this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
          if (!selectedClientDocData) {
            return;
          }
          this.selectedClientDocData = selectedClientDocData;
          this.loggedInUserSelfRoleObject = userDocData.clients.filter(cl => cl.clientId === this.selectedClientDocData.id)[0];
          /*if (this.selectedClientDocData.rolePermissionsMap.name === 'developer' || this.selectedClientDocData.rolePermissionsMap.isSuperUser) {
            this.roles.push('developer');
          }*/
          this.userPermissions = this.userRecordBeingEdited.clients[this.selectedClientDocData.id];
          const clientSpecificUserRoleObject = this.userRecordBeingEdited.clients.filter(cl => cl.clientId === this.selectedClientDocData.id)[0];
          this.userRole = clientSpecificUserRoleObject.roleId ?
            clientSpecificUserRoleObject.roleId : clientSpecificUserRoleObject.role;
          this.standardRolesSubscription = this.firestoreService.getAllStandardRoles().subscribe((rolesList: any) => {
            for (const role of rolesList) {
              this.rolePermissionMapFromDB[role.name] = role;
              if (this.loggedInUserDocData.isSuperUser) {
                this.roles.push(role.id);
              } else if (roleStrength.get(role.id) <=
                roleStrength.get(this.loggedInUserSelfRoleObject.roleId ? this.loggedInUserSelfRoleObject.roleId : this.loggedInUserSelfRoleObject.role)) {
                if (!this.roles.includes(role.id)) {
                  this.roles.push(role.id);
                }
              }
            }
            this.roles.sort();
            this.editUserForm.patchValue({
              role: this.userRole
            });
            if (!this.roles.includes(this.userRole)) {
              this.roles.push(this.userRole);
              this.editUserForm.controls.role.disable();
            }
            this.setPermissionsFromRole(this.userRole);
          });
          this.workerListSubscription = this.firestoreService.getUnArchivedWorkersForClientId(selectedClientDocData.id).subscribe((workersList) => {
            this.allWorkersList = workersList.sort((worker1, worker2) => {
              return worker1.name.toLowerCase() > worker2.name.toLowerCase() ? 1 : worker1.name.toLowerCase() < worker2.name.toLowerCase() ? -1 : 0;
            });
          });
        });
      });
  }

  ngOnInit() {
    this.editUserForm.controls.role?.valueChanges.subscribe((value) => {
      this.handleRoleChange(value);
    });
  }

  handleRoleChange(value: string) { // Function to handle both initial setup and custom role
    if (value === 'regular') {
      this.setPermissionsFromRole('regular');
    } else if (value === 'admin') {
      this.setPermissionsFromRole('admin');
    } else if (value === 'owner') {
      this.setPermissionsFromRole('owner');
    } else if (value === 'developer') {
      this.setPermissionsFromRole('developer');
    }/*else { // 'custom' case
      if (!this.roles.includes('custom')) {  // Check before pushing again to prevent duplicate custom role
        this.roles.push('custom');
      }
      //this.form.controls.role.setValue('custom', { emitEvent: false }); // Set form value to custom (important for correct display)
        this.form.controls.role.setValue('custom', {emitEvent: false});
    }*/
  }

  async updateUser() {
    if (!this.editUserForm.valid) {
      this.openSnackBar('Name is mandatory', 'error');
      return;
    }

    if (this.editUserForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    if (this.editUserForm?.value?.name && this.editUserForm?.value?.name?.trim() === '') {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    this.updatingUser = true;

    const userDataToUpdate = this.editUserForm.value;
    userDataToUpdate.name = userDataToUpdate.name?.trim();

    if (userDataToUpdate.associatedWorkerId) {
      userDataToUpdate.associatedWorkerName = this.allWorkersList.filter(worker => worker.id === userDataToUpdate.associatedWorkerId)[0]?.name;
      userDataToUpdate.associatedWorkerClientId = this.selectedClientDocData.id;
    }

    userDataToUpdate.roleName = userDataToUpdate.role;
    userDataToUpdate.roleId = userDataToUpdate.role;
    userDataToUpdate.roleType = 'standard';

    this.firestoreService
      .updateUserByIdForClientId(this.userRecordBeingEdited.id, userDataToUpdate, this.selectedClientDocData.id)
      .subscribe({
        next: async (apiResponse) => {
          this.updatingUser = false;
          if (apiResponse.success) {
            this.openSnackBar('Changes have been saved', 'success');
            this.editUserForm.markAsPristine();
            this.dialogRef.close();
          } else if (apiResponse.success === false) {
            this.openSnackBar('Error in user creation:' + apiResponse.error, 'error');
          }
        },
        error: (error) => {
          this.updatingUser = false;
          this.openSnackBar('Error in saving changes:' + error.message, 'error');
          console.log(error.message);
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.workerListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  setPermissionsFromRole(role: string) {
    const permissions = this.rolePermissionMapFromDB[role];
    this.setFormValuesRecursively(this.editUserForm.get('permissions'), permissions);
    this.disablePermissionFields(this.editUserForm);
    /* console.log(`role found:` + this.determineRoleFromPermissions(this.form.value.permissions));*/
  }

  private setFormValuesRecursively(formGroup: AbstractControl, values: any) {
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const control = formGroup.get(key);
        if (control instanceof FormGroup) {
          this.setFormValuesRecursively(control, values[key]);
        } else if (Array.isArray(values[key])) {
          // Special handling for arrays using patchValue
          control?.patchValue(values[key]);  // <-- Use patchValue for arrays
        } else {
          control?.setValue(values[key]); // Use setValue for other types
        }
      }
    }
  }

  disablePermissionFields(form: UntypedFormGroup) {
    const permissionsGroup = form.get('permissions') as UntypedFormGroup;
    this.disableFormControlsRecursive(permissionsGroup);
  }

  private disableFormControlsRecursive(formGroup: AbstractControl) {
    if (formGroup instanceof FormGroup) {
      // tslint:disable-next-line:forin
      for (const controlName in formGroup.controls) {
        this.disableFormControlsRecursive(formGroup.get(controlName));
      }
    } else {
      (formGroup as UntypedFormControl).disable();
    }
  }
}
