<div class="large-modal">
  <h2 mat-dialog-title> {{'SETTINGS.VARIETIES.HEADING.TITLE.TEXT' | translate}}</h2>
  <div class="controls-container px-4">
    <div class="date-container">
      <mat-form-field appearance="outline" class="listing-filter-with-archival">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label style="color: gray"> {{'SETTINGS.VARIETIES.FILTER.LABEL.TEXT' | translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="filterValue" placeholder="name" #input />
      </mat-form-field>
    </div>
    <button mat-stroked-button color="primary" class="setting-add-button mr-3" (click)="openLabels()">
      <mat-icon class="material-symbols-outlined" matTooltip="Labels" matTooltipClass="tooltip-class"
        matTooltipHideDelay="100000">
        {{'SETTINGS.LABELS.BUTTON.TEXT' | translate}}
      </mat-icon> Labels</button>
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
      *ngIf="this.perms?.canCreate">
      <mat-icon matTooltip="Create Variety" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon>{{'SETTINGS.VARIETIES.CREATE.BUTTON.TEXT' | translate}}</button>
    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [(ngModel)]="unArchivedVsArchived"
        (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon matTooltip="Show users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon matTooltip="Show archived users" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
            matTooltipPosition="below">archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <mat-dialog-content class="text-white">
    <div class="listing-container">

      <div class="table-card">
        <div *ngIf="areArchivedVarietiesShown()"> {{'SETTINGS.VARIETIES.ARCHIVED.TITLE.TEXT' | translate}}
        </div>
        <div class="mat-elevation-z8" style="clear:both;">
          <table class="listing-table" mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
            <ng-container matColumnDef="{{ column }}" *ngFor="let column of columnsToDisplay">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ varietyAttributesReadableMap.get(column) | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
            </ng-container>

            <ng-container matColumnDef="Edit">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="listing-table-tools">
                <button mat-icon-button color="accent" (click)="openEditDialog(element)" class="listing-table-tools"
                  [disabled]="!this.perms?.canUpdate">
                  <mat-icon>edit</mat-icon>
                </button>

                <button mat-icon-button color="accent" (click)="archiveVariety(element)" class="listing-table-tools"
                  *ngIf="!element.isArchived">
                  <mat-icon matTooltip="Archive this variety" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                    matTooltipPosition="below">archive
                  </mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="unarchiveVariety(element)" class="listing-table-tools"
                  *ngIf="element.isArchived">
                  <mat-icon matTooltip="Unarchive this variety" matTooltipClass="tooltip-class"
                    matTooltipHideDelay="100" matTooltipPosition="below">unarchive
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsHeadersToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsHeadersToDisplay"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="100"
            [showFirstLastButtons]="true"></mat-paginator>
        </div>
      </div>

    </div>
  </mat-dialog-content>
  <div id='ButtonPanel'>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="primary" (click)="closeModal()" style="margin-right: 20px;">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>

    </mat-dialog-actions>
  </div>
</div>
