import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import {ConfirmationDialogComponent} from "../../utility/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: 'app-edit-beacon-dialog',
  templateUrl: './edit-beacon-dialog.component.html',
  styleUrl: './edit-beacon-dialog.component.scss'
})
export class EditBeaconDialogComponent implements OnInit, OnDestroy {
  editBeaconForm: UntypedFormGroup;

  beaconRecordBeingEdited: any;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  allLocationsList: any[];
  locationListSubscription: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditBeaconDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    this.beaconRecordBeingEdited = data.beaconRecord;

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => {
        this.allLocationsList = locationsList?.sort((locA: any, locB: any) => {
          return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
        });
      });
    });
  }

  ngOnInit() {
    this.editBeaconForm = this.fb.group({
      name: [this.beaconRecordBeingEdited.name, [Validators.required]],
      type: [{
        value: this.beaconRecordBeingEdited.type,
        disabled: this.beaconRecordBeingEdited.assignedId != null
      }, [Validators.required]],
      mac: [{value: this.beaconRecordBeingEdited.mac, disabled: true}, [Validators.required]],
      batteryLevel: [{value: this.getBatteryLevelPercentage(this.beaconRecordBeingEdited.lastBatteryPercentage), disabled: true}, []],
      assetType: [{value: this.beaconRecordBeingEdited.assetType ?? null, disabled: true}, []],
      assignedId: [{value: this.beaconRecordBeingEdited.assignedId, disabled: true}, []],
      assignedName: [{value: this.beaconRecordBeingEdited.assignedName, disabled: true}, []],
      stickDuration: [{value: this.beaconRecordBeingEdited.stickDuration, disabled: true}, []],
      rssiThreshold: [this.beaconRecordBeingEdited.rssiThreshold, [Validators.required]],
    });
  }

  getBatteryLevelPercentage(lastBatteryPercentage) {
    return lastBatteryPercentage ? (lastBatteryPercentage * 100).toFixed(0) + '%' : null;
  }

  async updateBeacon() {
    if (!this.editBeaconForm.valid) {
      this.openSnackBar('Please fill all mandatory fields', 'error');
      return;
    }

    if (this.editBeaconForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    const beaconDataToUpdate = this.editBeaconForm.value;
    beaconDataToUpdate.name = beaconDataToUpdate.name?.trim();

    if (beaconDataToUpdate.locationId) {
      beaconDataToUpdate.locationName = this.allLocationsList
      .filter((location) => location.id === beaconDataToUpdate.locationId).map((location) => (location.name ? location.name : null))[0];
    } else {
      beaconDataToUpdate.locationName = null;
    }

    try {
      await this.firestoreService.updateBeaconByIdForClientId(this.beaconRecordBeingEdited.id, this.selectedClientDocData.id, beaconDataToUpdate);
      this.openSnackBar('Changes have been saved', 'success');
      this.editBeaconForm.markAsPristine();
      this.dialogRef.close();
    } catch (error) {
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  async resetAssignment() {
    await this.firestoreService.updateAssignedEntityNBeaconWithBeaconChanges(this.beaconRecordBeingEdited,this.selectedClientDocData.id);
    this.openSnackBar(`Beacon '${this.beaconRecordBeingEdited?.name}' unassigned successfully`, 'success');
    this.editBeaconForm.patchValue({
      assignedId: null,
      assignedName: null,
      assignedType: null,
      stickDuration: null,
      rssiThreshold: -46
    });
    this.editBeaconForm.controls.type.enable();
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.locationListSubscription.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}

