<mat-toolbar *ngIf="loggedInUserDocData && loggedInUserDocData.email !== 'info@jacoudijk.nl'">

  <h1 class="pr-2"></h1>
  <div class="date_and_time"><span class="date">{{ currentDate | date: 'MMMM d, y' }}</span><span class="week-number">{{weekNumber}}</span><span class="time">{{
      currentDate | date: 'HH:mm:ss' }}</span></div>
  <span class="central-stretchable-space flex-grow"></span>
  <mat-form-field class="hide-sm" appearance="outline" style="height: 3rem"
    *ngIf="loggedInUserDocData && clientInContextDocData && (clientLocationsList?.length > 0) && locationDropDownIsVisible()">
    <mat-select panelClass="nav-bar-select" [ngModel]="selectedClientLocationId" placeholder="Select Location"
      style="margin-top: 0.2rem; height:1.2rem;color: white!important;" panelClass="mat-select-panel-client-list"
      (ngModelChange)="onClientLocationChange($event)">
      <mat-option value="-1" *ngIf="!locationDropDownIsDisabled()">{{'DASHBOARD.NAV.MENU.ALLLOCATIONS.LABEL.TEXT' |
        translate}}</mat-option>
      <mat-option *ngFor="let location of clientLocationsList" [value]="location.id" style="color: white;">
        {{location.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="hide-sm" appearance="outline" style="height: 3rem"
    *ngIf="loggedInUserDocData && clientInContextDocData && (loggedInUserDocData?.clients?.length !== 1)">
    <mat-select panelClass="nav-bar-select" [ngModel]="selectedClientId" placeholder="Select Client"
      style="margin-top: 0.2rem; height:1.2rem;color: white!important;" panelClass="mat-select-panel-client-list"
      (ngModelChange)="onClientChange($event)">
      <mat-slide-toggle class="blur-clientName-toggle" [(ngModel)]="clientNamesBlurred" (change)="updateBlurredSettingForUser()">
        Blur client names
      </mat-slide-toggle>
      <mat-select-trigger>
        {{selectedClientDocData?.name?.slice(0,2)}}<span [ngClass]="{'blurredName': clientNamesBlurred}">{{selectedClientDocData?.name?.slice(2)}}</span>
      </mat-select-trigger>
      <mat-option *ngFor="let client of loggedInUserDocData.clients" [value]="client.clientId" style="color: white;">
        {{client.clientName?.slice(0,2)}}<span [ngClass]="{'blurredName': clientNamesBlurred}">{{client.clientName?.slice(2)}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field appearance="outline" *ngIf="!loggedInUserDocData">
    <mat-select [(ngModel)]="selectedClientId">
      <mat-label> {{'DASHBOARD.NAV.MENU.SELECTCLIENTS.LABEL.TEXT' | translate}}</mat-label>
      <mat-option *ngFor="let client of loggedInUserDocData.clients" [value]="client.clientId">
        {{client.clientName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="mx-3 mx-xl-4" *ngIf="loggedInUserDocData">
    <mat-icon matTooltip="Open Clock App" class="material-icons-outlined" (click)="openClockWeb()"
      style="cursor:pointer;transform: scale(1)">timer</mat-icon>
  </div>
</mat-toolbar>
