import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {AuthService} from '../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ClientInContextService} from "../../../services/client-in-context.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SNACKBAR_CLASSES} from "../../../common/utils/utils";
import {
  CreateWorkerGroupDialogComponent
} from '../../client-worker-groups/create-worker-group-dialog/create-worker-group-dialog.component';
import {CreateLabelDialogComponent} from '../../client-labels/create-label-dialog/create-label-dialog.component';
import * as moment from 'moment-timezone';
import {TIME_ZONE} from '../../../common/utils/time-utils';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { EUROPEAN_DATE_FORMATS } from 'src/app/common/utils/date-utils';

@Component({
  selector: 'app-create-worker-dialog',
  templateUrl: './create-worker-dialog.component.html',
  styleUrls: ['./create-worker-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: EUROPEAN_DATE_FORMATS}
  ]
})
export class CreateWorkerDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  description: string;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  allLocationsList: any[];
  locationListSubscription: Subscription;
  allwgGroupsList: any[];
  wgListSubscription: Subscription;
  labelsList: any[];
  unarchivedLabelsSubscription: Subscription;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<CreateWorkerDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data) {

    this.description = data.description;

    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(userDocData => {
      this.loggedInUserDocData = userDocData;
    });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.locationListSubscription = this.firestoreService.getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).subscribe((locationsList) => (this.allLocationsList = locationsList.sort((locA: any, locB: any) => {
        return locA.name?.toLowerCase() < locB.name?.toLowerCase() ? -1 : locA.name?.toLowerCase() > locB.name?.toLowerCase() ? 1 : 0;
      })));

      this.wgListSubscription = this.firestoreService.getAllUnarchivedWorkerGroupsForClientId(this.selectedClientDocData?.id).subscribe((wgList) => (this.allwgGroupsList = wgList.sort((wgA: any, wgB: any) => {
        return wgA.name?.toLowerCase() < wgB.name?.toLowerCase() ? -1 : wgA.name?.toLowerCase() > wgB.name?.toLowerCase() ? 1 : 0;
      })));
    });
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.wgListSubscription?.unsubscribe();
    this.unarchivedLabelsSubscription?.unsubscribe();
  }

  openAddWorker() {
    this.dialog.open(CreateWorkerGroupDialogComponent, {});
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      workerCode: [Math.floor(Math.random() * 90 + 10) + '', []],
      labelIds: ['', []],
      workerGroupId: ['', []],
      isLeftHanded: [false, []],
      locationIds: ['', []],
      hourlyRate: [0.00, []],
      notes: ['', []],
      externalId: ['', []],
      startTimestamp: [null, []],
      endTimestamp: [null, []]
    });

    this.form.controls.hourlyRate.valueChanges.subscribe(val => {
      if (!val) {
        return;
      }
      const valString = '' + val;
      if (valString?.indexOf('.') === -1) {
        return;
      }

      if (valString?.indexOf('.') !== -1) {
        if (valString?.endsWith('.')) {
          return;
        }
      }
      const valNumber = +valString;
      let decimalCount = 0;
      if ((valNumber % 1) !== 0) {
        decimalCount = valNumber.toString().split(".")[1].length;
      }

      const newVal = +valNumber.toFixed(decimalCount <= 2 ? decimalCount : 2);
      if (newVal !== +this.form.controls.hourlyRate.value) {
        this.form.patchValue({hourlyRate: newVal});
      }
    });
    this.fetchAllLabels()
  }

  get startTimestampSelected(): Date | undefined {
    return this.form?.get('startTimestamp')?.value;
  }

  set startTimestampSelected(value: Date | undefined) {
    this.form?.get('startTimestamp')?.setValue(value);
  }

  get endTimestampSelected(): Date | undefined {
    return this.form?.get('endTimestamp')?.value;
  }

  set endTimestampSelected(value: Date | undefined) {
    this.form?.get('endTimestamp')?.setValue(value);
  }

  async createWorker() {

    if ((this.form?.value?.workerCode !== '') && (!/^([0-9]{2})$/.test(this.form?.value?.workerCode))) {
      this.openSnackBar('Please enter exactly 2 digits for worker code!', 'error');
      return;
    }

    if (!this.form.valid) {
      this.openSnackBar('Name is mandatory', 'error');
      return;
    }

    if (this.form?.value?.name && (this.form?.value?.name?.trim() === '')) {
      this.openSnackBar('Invalid value entered for Name', 'error');
      return;
    }

    const workerToCreate = this.form.value;
    workerToCreate.name = workerToCreate.name?.trim();
    workerToCreate.externalId = workerToCreate.externalId?.trim() ?? null;
    workerToCreate.isArchived = false;

    if (workerToCreate.startTimestamp) {
      const rawStartTimestamp = moment(workerToCreate.startTimestamp).format('YYYY-MM-DD') + ' 12:00';
      const startTimestampMoment = moment.tz(rawStartTimestamp, 'YYYY-MM-DD HH:mm', TIME_ZONE).startOf('day');
      workerToCreate.startTimestamp = startTimestampMoment.toDate();
    }

    if (workerToCreate.endTimestamp) {
      const rawEndTimestamp = moment(workerToCreate.endTimestamp).format('YYYY-MM-DD') + ' 12:00';
      const endTimestampMoment = moment.tz(rawEndTimestamp, 'YYYY-MM-DD HH:mm', TIME_ZONE).endOf('day');
      workerToCreate.endTimestamp = endTimestampMoment.toDate();
    }

    if (workerToCreate.labelIds && (workerToCreate.labelIds.length > 0)) {
      workerToCreate.labels = this.labelsList.filter(label => workerToCreate.labelIds.includes(label.id)).map(label => label.name);
    } else {
      workerToCreate.labelIds = null;
      workerToCreate.labels = null;
    }

    if (workerToCreate.workerGroupId) {
      workerToCreate.workerGroupName = this.allwgGroupsList.filter(wg => wg.id === workerToCreate.workerGroupId)[0]?.name;
    } else {
      workerToCreate.workerGroupId = null;
      workerToCreate.workerGroupName = null;
    }

    try {
      await this.firestoreService.createWorkerForClientId(workerToCreate, this.selectedClientDocData.id);
      this.openSnackBar('Worker created successfully', 'success');
      this.form.reset({
        workerCode: Math.floor(Math.random() * 90 + 10) + ''
      });
    } catch (error) {
      this.openSnackBar('Error in worker creation:' + error.message, 'error');
      console.log(error.message);
    }
  }

  openAddLabel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(CreateLabelDialogComponent, dialogConfig);
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

  fetchAllLabels() {
    this.labelsList = [];
    this.unarchivedLabelsSubscription = this.firestoreService.getAllUnarchivedLabelsForClientId(this.selectedClientDocData.id).subscribe((labelsList) => {
      this.labelsList = labelsList.sort((labelA: any, labelB: any) => {
        return labelA.name < labelB.name ? -1 : labelA.name > labelB.name ? 1 : 0;
      });
    });
  }
}
