<div class="listing-container" *ngIf="perms?.canRead">
  <div class="controls-container">
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
    *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create Position" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'SETTINGS.POSITION.CREATEPOSITION.BUTTON.TEXT' | translate}}</button>
      <span class="f-grow"></span>
      <div>
        <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [(ngModel)]="unArchivedVsArchived" (change)="toggleArchived()">
          <mat-button-toggle value="unarchived" aria-label="Text align left">
            <mat-icon
              matTooltip="Show Positions"
              matTooltipClass="tooltip-class"
              matTooltipHideDelay="100"
              matTooltipPosition="below"
            >list
            </mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="archived" aria-label="Text align center">
            <mat-icon
              matTooltip="Show archived positions"
              matTooltipClass="tooltip-class"
              matTooltipHideDelay="100"
              matTooltipPosition="below"
            >archive
            </mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
  </div>

  <div class="table-card">
    <div *ngIf="areArchivedPositionsShown()">Archived Positions</div>
    <div class="mat-elevation-z8" style="clear:both;">

      <app-common-table  [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay" [gridList]="positionsList" [tableName]="'positionTable'" (editDialogOpened)="openEditDialog($event)" (onArchiveRow)="archivePosition($event)" (onUnArchiveRow)="unarchivePosition($event)" [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'position'">

      </app-common-table>
    </div>
  </div>

</div>
