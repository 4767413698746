<div class="listing-container" *ngIf="perms?.canRead">
  <div class="controls-container">
    <button mat-flat-button color="primary" class="setting-add-button mr-3" (click)="openDialog()"
            *ngIf="perms?.canCreate">
      <mat-icon matTooltip="Create Asset" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'SETTINGS.ASSETS.CREATEASSET.BUTTON.TEXT' | translate}}</button>
      <span class="f-grow"></span>
    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [(ngModel)]="unArchivedVsArchived" (change)="toggleArchived()">
        <mat-button-toggle value="unarchived" aria-label="Text align left">
          <mat-icon
            matTooltip="Show assets"
            matTooltipClass="tooltip-class"
            matTooltipHideDelay="100"
            matTooltipPosition="below"
          >list
          </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="archived" aria-label="Text align center">
          <mat-icon
            matTooltip="Show archived assets"
            matTooltipClass="tooltip-class"
            matTooltipHideDelay="100"
            matTooltipPosition="below"
          >archive
          </mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="table-card">
    <div *ngIf="areArchivedAssetsShown()">Archived Assets</div>
    <div class="mat-elevation-z8" style="clear:both;">
      <app-common-table [isToggleBtn]="isArchivedToggleChange" [columnsHeadersToDisplay]="columnsHeadersToDisplay" [columnsToDisplay]="columnsToDisplay" [gridList]="assetsList" [tableName]="'deviceTable'" (editDialogOpened)="openEditDialog($event)" (onArchiveRow)="archiveAsset($event)" (onUnArchiveRow)="unarchiveAsset($event)" [selectedClientDocData]="selectedClientDocData" [perms]="perms" [entityName]="'asset'">

      </app-common-table>
    </div>
  </div>

</div>
