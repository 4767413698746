import {Component, DestroyRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FirestoreService} from '../../../../services/firestore.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Subscription} from 'rxjs';
import {RowMainAttributes} from "../../../../common/interfaces/device-interfaces";
import {AuthService} from "../../../../services/auth.service";
import {ClientInContextService} from "../../../../services/client-in-context.service";
import {SNACKBAR_CLASSES} from "../../../../common/utils/utils";
import * as moment from 'moment-timezone';
import {TranslateService} from "@ngx-translate/core";
import {FormControl} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-client-loc-lyt-rows',
  templateUrl: './client-loc-lyt-rows.component.html',
  styleUrls: ['./client-loc-lyt-rows.component.scss']
})
export class ClientLocLytRowsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() locationId: string;
  @Input() clientId: string;
  @Input() layoutId: string;
  @Input() rowWidth: any;
  @Input() triggerReload: any;
  public rowList: RowMainAttributes[];

  rowAttributesSchema: any = {
    rowReference: 'number',
    rowNumber: 'text',
    rowLength: 'number',
    activityId: 'text',
    varietyId: 'text',
    labelIds: 'text'
  }

  rowAttributesReadableMap: Map<string, string> = new Map([
    ['rowReference', 'SETTINGS.TABLEHEAD.REFERENCE.TITLE.TEXT'],
    ['rowNumber', 'SETTINGS.TABLEHEAD.ROWNUMBER.TITLE.TEXT'],
    ['rowLength', 'SETTINGS.TABLEHEAD.ROWLENGTH.TITLE.TEXT'],
    ['floorArea', 'SETTINGS.TABLEHEAD.FLOORAREA.TITLE.TEXT'],
    ['activityId', 'SETTINGS.TABLEHEAD.ACTIVITY.TITLE.TEXT'],
    ['varietyId', 'SETTINGS.TABLEHEAD.VARIETY.TITLE.TEXT'],
    ['beaconIds', 'SETTINGS.TABLEHEAD.BEACONS.TITLE.TEXT'],
    ['labelIds', 'SETTINGS.TABLEHEAD.LABELS.TITLE.TEXT']
  ]);

  columnsToDisplay: string[] = ['rowReference', 'rowNumber', 'rowLength', 'activityId', 'varietyId', 'beaconIds', 'labelIds'];
  columnsHeadersToDisplay: string[] = ['rowReference', 'rowNumber', 'rowLength', 'floorArea', 'activityId', 'varietyId', 'beaconIds', 'labelIds', 'edit'];
  allActivitiesList: any[];
  allVarietiesList: any[];
  allLabelsList: any[];

  dataSource: MatTableDataSource<RowMainAttributes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  activityListSubscription: Subscription;
  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;
  hasAdminRole: boolean;
  private allRowsSubscription: Subscription;
  private rowIndexesBeingSaved: number[] = [];
  varietyListSubscription: Subscription;
  labelListSubscription: Subscription;
  rowLengthBulkUpdateInProgress = false;
  restartInProgress = false;
  beaconListSubscription: Subscription;
  allBeaconsList: any[];
  allNonAssignedBeaconsList: any[];
  filterValue = '';

  constructor(private firestoreService: FirestoreService,
              private authService: AuthService,
              private clientInContextService: ClientInContextService,
              private snackBar: MatSnackBar,
              private translateService: TranslateService,
              private destroyRef: DestroyRef) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      this.beaconListSubscription = this.firestoreService.getAllUnarchivedBeaconsByTypeForClientId(this.selectedClientDocData?.id, 'ROW')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((beaconsList) => {
        this.allBeaconsList = beaconsList;
        if (this.rowList?.length > 0) {//Speeding up first load
          this.loadAllRows();
        }
      });
    });
  }

  ngOnInit(): void {
    this.activityListSubscription = this.firestoreService.getAllActivities().pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((activityList) => (this.allActivitiesList = activityList));
    this.varietyListSubscription = this.firestoreService.getAllUnarchivedVarietiesForClientId(this.selectedClientDocData.id)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((varietyList) =>
      (this.allVarietiesList = varietyList.sort((varietyA: any, varietyB: any) => {
        return varietyA.name?.toLowerCase() < varietyB.name?.toLowerCase() ? -1 :
          varietyA.name?.toLowerCase() > varietyB.name?.toLowerCase() ? 1 : 0;
      })));
    this.labelListSubscription =
      this.firestoreService.getAllUnarchivedLabelsForClientId(this.selectedClientDocData.id).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((labelsList) => (this.allLabelsList = labelsList));
    this.loadAllRows();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.triggerReload.currentValue) {
      //console.log(`Trigger reload`)
      this.loadAllRows();
    }
  }


  async archiveCurrentRow(row, index) {
    if (row.id) {
      await this.firestoreService.archiveRowForLytLocClientId(this.layoutId, this.locationId, this.clientId, row.id);
      this.loadAllRows();
    } else {
      this.rowList.splice(index, 1);
      this.dataSource = new MatTableDataSource(this.rowList);
    }
  }

  async restartCurrentRow(row, index) {
    this.restartInProgress = true;
    if (row.id) {
      try {
        await this.firestoreService.restartRowForLytLocClientId(this.layoutId, this.locationId, this.clientId, row.id, row);
        this.restartInProgress = false;
        this.openSnackBar(this.translateService.instant('SETTINGS.LOCATIONS.ROW.RESTARTSUCCESS.SNACKBAR.TEXT'), 'success');
      } catch (error) {
        this.restartInProgress = false;
        this.openSnackBar(`${this.translateService.instant('SETTINGS.LOCATIONS.ROW.RESTARTFAILURE.SNACKBAR.TEXT')}:${error.message}`, 'error');
      }
      this.loadAllRows();
    }
  }

  async saveRow(row) {
    await this.setRowFieldsToNumber(row);
    await this.handleActivityNVarietySelection(row);
    if (!row.id) {
      await this.firestoreService.createRowForLytLocClientId(this.layoutId, this.locationId, this.clientId, row);
      this.loadAllRows();
    } else {
      await this.firestoreService.updateRowForLytLocClientId(this.layoutId, this.locationId, this.clientId, row);
    }
  }

  async saveRowOnFocusOut(row, index) {
    await this.setRowFieldsToNumber(row);
    await this.handleActivityNVarietySelection(row);
    await this.handleBeaconSelection(row);
    if (!row.id) {
      if (!this.rowIndexesBeingSaved.includes(index)) {
        this.rowIndexesBeingSaved.push(index);
        if (row.rowReference || row.rowNumber || row.rowLength || row.varietyId || row.activityId) { //atleast 1 field should be filled for row to be created
          await this.firestoreService.createRowForLytLocClientId(this.layoutId, this.locationId, this.clientId, row);
          this.rowIndexesBeingSaved = this.rowIndexesBeingSaved.filter(indexEntry => indexEntry !== index);
          this.loadAllRows();
        }
      }
    } else {
      await this.firestoreService.updateRowForLytLocClientId(this.layoutId, this.locationId, this.clientId, row);
      this.loadAllRows();
    }
  }

  async copyLengthToAllRows(row, index) {
    const rowLength = row.rowLength && !isNaN(row.rowLength) ? +row.rowLength : null;
    if (rowLength || (rowLength === 0)) {
      this.rowLengthBulkUpdateInProgress = true;
      try {
        await this.firestoreService.updateRowLengthForAllRows(this.layoutId, this.locationId, this.clientId, this.rowList.map(rw => rw.id), rowLength);
      } catch (error) {
        this.rowLengthBulkUpdateInProgress = false;
      }
      this.loadAllRows(true);
    }
  }

  async setRowFieldsToNumber(row) {
    row.rowReference = row.rowReference && !isNaN(row.rowReference) ? +row.rowReference : null;
    row.rowLength = row.rowLength && !isNaN(row.rowLength) ? +row.rowLength : null;
  }

  async handleActivityNVarietySelection(row) {
    if (row.activityId && (row.activityId !== '-1')) {
      row.activityName = this.allActivitiesList.filter(activity => activity.id === row.activityId)[0]?.name;
    } else {
      row.activityId = null;
      row.activityName = null;
    }
    if (row.locationId && (row.locationId !== '-1')) {
      const location = await this.firestoreService.getLocationByIdForClientId(this.locationId, this.clientId);
      row.locationName = location.data().name;
    } else {
      row.locationId = null;
      row.locationName = null;
    }
    if (row.layoutId && (row.layoutId !== '-1')) {
      const layout = await this.firestoreService.getLayoutByIdForLocIdClientId(this.layoutId, this.locationId, this.clientId);
      row.layoutName = layout.data().name;
    } else {
      row.layoutId = null;
      row.layoutName = null;
    }
    if (row.varietyId && (row.varietyId !== '-1')) {
      row.varietyName = this.allVarietiesList.filter(variety => variety.id === row.varietyId)[0]?.name;
    } else {
      row.varietyId = null;
      row.varietyName = null;
    }
    if (row.labelIds && (row.labelIds.length > 0)) {
      row.labels = this.allLabelsList.filter(label => row.labelIds.includes(label.id)).map(label => label.name);
    } else {
      row.labelIds = null;
      row.labels = null;
    }

  }

  async handleBeaconSelection(row) {
    this.allNonAssignedBeaconsList = this.allNonAssignedBeaconsList.filter(beacon => !row.beaconIds?.includes(beacon.id));
    //If assigned beacon removal from other rows is too slow via beacon list listener, then here we will need to remove assigned beacon for all rows' rowBeaconsList
    let beaconNames = [];
    if (row.beaconIds && Array.isArray(row.beaconIds) && (row.beaconIds.length > 0)) {
      beaconNames = this.allBeaconsList.filter(beacon => row.beaconIds.includes(beacon.id)).map(beacon => beacon.name);
    }
    row.beaconNames = beaconNames;

  }

  addNewEmptyRow() {
    const newRow: RowMainAttributes = {
      id: '',
      rowReference: null,
      rowNumber: null,
      rowLength: null,
      activityId: null,
      varietyId: null,
      labelIds: []
    };
    //this.rowList.push(newRow);
    this.dataSource.data.push(newRow);
    this.dataSource.data = this.dataSource.data;
    this.dataSource.paginator.lastPage();
  }

  loadAllRows(resetBulkUpdateFlag = false) {
    this.allRowsSubscription = this.firestoreService.getAllRowsForLytLocClientId(this.layoutId, this.locationId, this.clientId)
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((rowListQS) => {
      let allAssignedBeaconIds = [];
      this.allNonAssignedBeaconsList = this.allBeaconsList.filter(beacon => !beacon.hasOwnProperty('assignedId') || !beacon.assignedId);
      this.rowList = rowListQS?.docs?.map((rowSnapshot) => {
        const row = rowSnapshot.data();
        if (row.beaconIds && Array.isArray(row.beaconIds)) {
          allAssignedBeaconIds = allAssignedBeaconIds.concat(row.beaconIds);
        }
      });

      this.rowList = rowListQS?.docs?.map((rowSnapshot) => {
        const row = rowSnapshot.data();

        const momentCreationTimestamp = row.creationTimestamp ? moment(row.creationTimestamp.toDate()) : null;
        row.isCreatedToday = false;
        if (momentCreationTimestamp) {
          if (momentCreationTimestamp.isAfter(moment().startOf('day'))) {
            row.isCreatedToday = true;
          }
        }
        row.id = rowSnapshot.id;

        const beaconsAssignedToThisRow = this.allBeaconsList.filter(beacon => beacon.assignedId === row.id);
        const listsCombined = beaconsAssignedToThisRow.concat(this.allNonAssignedBeaconsList);
        row.rowBeaconsList = listsCombined?.sort((beaconA: any, beaconB: any) => {
          return beaconA.name?.toLowerCase() < beaconB.name?.toLowerCase() ? -1 : beaconA.name?.toLowerCase() > beaconB.name?.toLowerCase() ? 1 : 0;
        });
        row.inputType = 'label';

        return {
          ...row
        };

      });
      this.rowList = this.rowList.sort((x, y) => (x.rowReference as any) - (y.rowReference as any));
      this.dataSource = new MatTableDataSource(this.rowList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.sortData = this.sortData();
      if (resetBulkUpdateFlag) {
        this.rowLengthBulkUpdateInProgress = false;
      }
      this.allRowsSubscription.unsubscribe();
    });
  }

  getFirstSelectedBeaconName(element: any): string {
    if (element.beaconIds?.length && element.rowBeaconsList.length > 0) {
      const firstBeaconId = element.beaconIds[0];
      const beacon = element.rowBeaconsList.find((b: any) => b.id === firstBeaconId);
      return beacon ? beacon.name : '';
    }
    return '';
  }

  ngOnDestroy(): void {
    this.activityListSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.allRowsSubscription?.unsubscribe();
    this.varietyListSubscription?.unsubscribe();
    this.labelListSubscription?.unsubscribe();
    this.beaconListSubscription?.unsubscribe();
  }

  sortData() {
    return (items: any[], sort: MatSort): any[] => {
      if (!sort.active || sort.direction === '') {
        return items;
      }
      return items.sort((a: any, b: any) => {
        let comparatorResult = 0;
        switch (sort.active) {
          case 'varietyId':
            comparatorResult = this.compare(a.varietyName, b.varietyName, sort.direction === 'asc');
            break;
          case 'activityid':
            comparatorResult = this.compare(a.activityName, b.activityName, sort.direction === 'asc');
            break;
          default:
            comparatorResult = this.compare(a[sort.active], b[sort.active], sort.direction === 'asc');
            break;
        }
        return comparatorResult;
      });
    };
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openSnackBar(message: string, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

}
