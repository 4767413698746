import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {

  constructor(private translateService: TranslateService){
    super()
  }
  itemsPerPageLabel = this.translateService.instant('TABLES.PAGINATION.ITEMSPERPAGE.LABEL.TEXT');
  nextPageLabel     =  this.translateService.instant('TABLES.PAGINATION.NEXT.LABEL.TEXT');
  previousPageLabel = this.translateService.instant('TABLES.PAGINATION.PREVIOUS.LABEL.TEXT');
  firstPageLabel    = this.translateService.instant('TABLES.PAGINATION.FIRSTPAGE.LABEL.TEXT');
  lastPageLabel     = this.translateService.instant('TABLES.PAGINATION.LASTPAGE.LABEL.TEXT');

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 of ${this.translateService.instant('TABLES.PAGINATION.OFLENGTH.LABEL.TEXT')} ${length}`;
    }
    const totalPages = Math.ceil(length / pageSize);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? 
      Math.min(startIndex + pageSize, length) : 
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.translateService.instant('TABLES.PAGINATION.OFLENGTH.LABEL.TEXT')} ${length}`;
  }
}
