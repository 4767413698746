<div class="listing-container" *ngIf="locationList">
  <div class="d-flex mb-3">
    <button mat-flat-button color="primary" (click)="openDialog()" *ngIf="selectedClientDocData?.hasAdminRole">
      <mat-icon matTooltip="Create new Location" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
        add
      </mat-icon> {{'SETTINGS.LOCATION.CREATELOCATION.BUTTON.TEXT' | translate}}</button>
    <span class="f-grow"></span>
    <button mat-stroked-button color="primary" class="mr-3" (click)="openArchivedRows()">
      <mat-icon class="material-symbols-outlined" matTooltip="Worker Groups" matTooltipClass="tooltip-class"
                matTooltipHideDelay="100000">
        archive
      </mat-icon> {{'SETTINGS.ARCHIVED.ROWS.MODAL.HEADING.TITLE.TEXT' | translate}}</button>
    <button mat-stroked-button color="primary" class="mr-3" (click)="openVariety()">
      <mat-icon class="material-symbols-outlined" matTooltip="Worker Groups" matTooltipClass="tooltip-class"
        matTooltipHideDelay="100000">
        stacks
      </mat-icon> {{'SETTINGS.VARITIES.BUTTON.TEXT' | translate}}</button>

    <button mat-stroked-button color="primary" class="" (click)="openLabels()">
      <mat-icon class="material-symbols-outlined" matTooltip="Labels" matTooltipClass="tooltip-class"
        matTooltipHideDelay="100000">
        label
      </mat-icon> {{'SETTINGS.LABELS.BUTTON.TEXT' | translate}}</button>
  </div>
  <div class="location-period-wrapper transparent-effect-card">
    <mat-tab-group animationDuration="0ms">
      <mat-tab *ngFor="let location of locationList; let counter = index">
        <ng-template mat-tab-label>
          <div class="layout-vetical-tabs d-flex align-items-center">
            <span class="material-symbols-outlined tab_icon material-icons mr-3">
              map
            </span>

            <span class="tab_item_title" (focusout)="saveNewLocationName($event, location)"
              (click)="preventPropagation($event)" contenteditable ngDefaultControl
              [disabled]="!selectedClientDocData?.hasAdminRole" [(ngModel)]="locationList[counter].name"></span>
          </div>
        </ng-template>

        <div class="layout-wrapper">
          <div class="d-flex justify-content-end time-registration__btn-group pb-2">
            <button mat-stroked-button matTooltip='Edit Location "{{location.name}}"'
              (click)="openEditLocationDialog($event, location)" *ngIf="selectedClientDocData?.hasAdminRole">
              <mat-icon>edit</mat-icon>{{'SETTINGS.LOCATION.EDITLOCATION.BUTTON.TEXT' | translate}}
            </button>
          </div>

          <div class="d-flex justify-content-between my-2">
            <label class="d-block mb-3 font-xs-18 title">{{'SETTINGS.LOCATION.LAYOUTFOR.LABEL.TEXT' | translate}} {{location.name}} </label>
            <button matTooltip='Create New Layout for {{location.name}}'
              (click)="openCreateLayoutDialog($event, location)" mat-stroked-button>
              <mat-icon class="info-icon">playlist_add</mat-icon> {{'SETTINGS.LOCATION.NEWLAYOUT.BUTTON.TEXT' | translate}}</button>
          </div>

          <div class="time-registration__card mb-4">
            <div class="time-off-day-section max-w-600">
              <app-client-location-layout [locationId]="location.id" [locationName]="location.name"
                [clientId]="selectedClientDocData.id" [triggerReload]="triggerReload"></app-client-location-layout>
            </div>
          </div>


        </div>

      </mat-tab>

    </mat-tab-group>
  </div>


  <mat-accordion class="d-none">
    <mat-expansion-panel [expanded]="counter === 0" *ngFor="let location of locationList; let counter = index">
      <mat-expansion-panel-header>
        <mat-panel-title style="max-width: 10rem;">
          <span style="font-size: 1.1rem;margin-top: 0.5rem;" (focusout)="saveNewLocationName($event, location)"
            (click)="preventPropagation($event)" contenteditable ngDefaultControl
            [disabled]="!selectedClientDocData?.hasAdminRole" [(ngModel)]="locationList[counter].name"></span>
        </mat-panel-title>
        <mat-panel-description style="display: flex; justify-content: right; margin-right: 1rem;">
          <button mat-icon-button color="accent" matTooltip='Edit Location "{{location.name}}"'
            (click)="openEditLocationDialog($event, location)" style="margin-left: 20px;"
            *ngIf="selectedClientDocData?.hasAdminRole">
            <mat-icon>edit</mat-icon>
          </button>
          <!-- <button mat-raised-button color="primary"
                  matTooltip='Edit Location "{{location.name}}"'
                  (click)="openEditLocationDialog($event, location)"
                  style="margin-left: 20px;"
          *ngIf="selectedClientDocData?.hasAdminRole">Edit</button> -->
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-client-location-layout [locationId]="location.id" [locationName]="location.name"
        [clientId]="selectedClientDocData.id"></app-client-location-layout>
      <div style="text-align: center;margin-top:0.5rem;">
        <button mat-icon-button color="accent" matTooltip='Create New Layout for {{location.name}}'
          (click)="openCreateLayoutDialog($event, location)" style="margin-left: 20px;"
          *ngIf="selectedClientDocData?.hasAdminRole">
          <mat-icon>playlist_add</mat-icon>
        </button>

      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
