import {
  AfterViewInit,
  Component, DestroyRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateWorkerDialogComponent } from './create-worker-dialog/create-worker-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { WorkerMainAttributes } from '../../common/interfaces/worker-interfaces';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FirestoreService } from '../../services/firestore.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { EditWorkerDialogComponent } from './edit-worker-dialog/edit-worker-dialog.component';
import { DatePipe } from '@angular/common';
import firebase from 'firebase/compat/app';
import { ClientInContextService } from "../../services/client-in-context.service";
import { ConfirmationDialogComponent } from "../utility/confirmation-dialog/confirmation-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Timestamp } from 'firebase/firestore';
import { ClientWorkerGroupsComponent } from '../client-worker-groups/client-worker-groups.component';
import { ClientLabelsComponent } from '../client-labels/client-labels.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-client-worker',
  templateUrl: './client-worker.component.html',
  styleUrls: [
    './client-worker.component.scss',
    '../../common/styles/listing.scss',
  ],
})
export class ClientWorkerComponent implements AfterViewInit, OnDestroy {
  unArchivedVsArchived = 'unarchived';
  workerAttributesReadableMap: Map<string, string> = new Map([
    ['id', 'ID'],
    ['workerCode', 'SETTINGS.TABLEHEAD.CODE.TITLE.TEXT'],
    ['name', 'SETTINGS.TABLEHEAD.NAME.TITLE.TEXT'],
    ['notes', 'SETTINGS.TABLEHEAD.NOTES.TITLE.TEXT'],
    ['locationNames', 'SETTINGS.TABLEHEAD.LOCATIONS.TITLE.TEXT'],
    ['labelsShow', 'SETTINGS.TABLEHEAD.LABELS.TITLE.TEXT'],
    ['workerGroupName', 'SETTINGS.TABLEHEAD.WORKERGROUP.TITLE.TEXT'],
    ['hourlyRate', 'SETTINGS.TABLEHEAD.HOURLYRATE.TITLE.TEXT'],
    ['creationTimestamp', 'SETTINGS.TABLEHEAD.CREATEDAT.TITLE.TEXT'],
  ]);

  columnsToDisplay: string[] = ['id', 'workerCode', 'name', 'notes', 'locationNames', 'labelsShow', 'workerGroupName', 'hourlyRate', 'creationTimestamp'];
  columnsHeadersToDisplay: string[] = [
    'name',
    'workerCode',
    'isLeftHanded',
    'notes',
    'locationNames',
    'labelsShow',
    'workerGroupName',
    'hourlyRate',
    'creationTimestamp',
    'Edit',
  ];
  dataSource: MatTableDataSource<WorkerMainAttributes>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public workerList: [];
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  selectedClientDocData: any;
  dateColumns: string[] = ['creationTimestamp'];
  datePipe = new DatePipe('en-US');
  clientInContextServiceSubscription: Subscription;
  unArchivedWorkersSubscription: Subscription;
  archivedWorkersSubscription: Subscription;
  locationListSubscription: Subscription;
  allLocationsList: any[];
  filterValue: string;
  labelsList: any[];
  unarchivedLabelsSubscription: Subscription;

  constructor(
    public firestoreService: FirestoreService,
    public authService: AuthService,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    private clientInContextService: ClientInContextService,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef) {
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
          this.loggedInUserDocData = userDocData;
      });

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedClientDocData => {
        if (!selectedClientDocData) {
          return;
        }
        this.selectedClientDocData = selectedClientDocData;

        this.locationListSubscription = this.firestoreService
          .getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id).pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((locationsList) => (this.allLocationsList = locationsList));
        this.fetchAllLabels()
        this.fetchUnarchivedWorkers();
      });
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    this.dialog.open(CreateWorkerDialogComponent, dialogConfig);
  }

  openEditDialog(record) {
    const dialogConfig = new MatDialogConfig();

    //dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: record.id,
      workerCode: record.workerCode,
      hourlyRate: record.hourlyRate ?? null,
      name: record.name,
      locationIds: record.locationIds,
      labelIds: record.labelIds,
      notes: record.notes,
      isLeftHanded: record.isLeftHanded,
      workerGroupId: record.workerGroupId,
      externalId: record.externalId,
      startTimestamp: record.startTimestamp ?? null,
      endTimestamp: record.endTimestamp ?? null
    };

    this.dialog.open(EditWorkerDialogComponent, dialogConfig);
  }

  openWorkerGroup() {
    this.dialog.open(ClientWorkerGroupsComponent, {});
  }

  openLabels() {
    this.dialog.open(ClientLabelsComponent, {});
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.unArchivedWorkersSubscription?.unsubscribe();
    this.archivedWorkersSubscription?.unsubscribe();
    this.locationListSubscription?.unsubscribe();
    this.unarchivedLabelsSubscription?.unsubscribe();
  }

  archiveWorker(worker) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: `Are you sure want to archive worker '${worker?.name ?? ''}' ?`,
        buttonText: {
          ok: 'Archive',
          cancel: 'Cancel'
        },
        worker
      }
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (value.decision) {
        try {
          await this.firestoreService.archiveWorkerByIdForClientId(value?.worker?.id, this.selectedClientDocData.id);
          this.snackBar.open(`Worker '${value?.worker?.name}' archived successfully`, '', {
            duration: 5000,
            panelClass: ['snackbar-success'],
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        } catch (error) {
          this.snackBar.open(`Error in archiving worker '${value?.worker?.name}'.\nPlease try again and/or contact support if problem persists`, '', {
            panelClass: ['snackbar-error'],
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
        }
      }
    });
  }

  async unarchiveWorker(worker) {
    try {
      await this.firestoreService.unArchiveWorkerByIdForClientId(worker?.id, this.selectedClientDocData.id);
      this.snackBar.open(`Worker '${worker?.name}' unarchived successfully`, '', {
        duration: 5000,
        panelClass: ['snackbar-success'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    } catch (error) {
      this.snackBar.open(`Error in unarchiving worker '${worker?.name}'.\nPlease try again and/or contact support if problem persists`, '', {
        panelClass: ['snackbar-error'],
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      });
    }
  }

  toggleArchived() {
    if (this.areUnArchivedWorkersShown()) {
      this.fetchUnarchivedWorkers();
    } else {
      this.fetchArchivedWorkers();
    }
  }

  fetchUnarchivedWorkers() {
    this.archivedWorkersSubscription?.unsubscribe();
    this.unArchivedWorkersSubscription = this.firestoreService
      .getUnArchivedWorkersForClientId(this.selectedClientDocData.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((workerList) => {
        this.workerList = workerList.map((worker) => {
          if (worker.hourlyRate) {
            worker.hourlyRate = (+worker.hourlyRate).toFixed(2);
          }
          for (const [key, value] of Object.entries(worker)) {
            if (this.dateColumns.includes(key)) {
              const timeValue = value as Timestamp;
              worker[key] = this.datePipe.transform(
                timeValue.toMillis(),
                'yyyy-MM-dd HH:mm'
              );
            }
          }
          if (worker.labelIds && worker.labelIds.length > 0) {
            worker.labelsShow = this.labelsList.filter(label => worker.labelIds.includes(label.id)).map(label => label.name).join(', ');
          } else {
            worker.labelsShow = '';
          }

          let locationNames = '';
          if (worker.locationIds && Array.isArray(worker.locationIds) && (worker.locationIds.length > 0)) {
            locationNames = this.allLocationsList.filter(loc => worker.locationIds.includes(loc.id))
              .map(loc => loc.name).sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : a?.toLowerCase() > b?.toLowerCase() ? 1 : 0).join(', ');
          }
          worker.locationNames = locationNames;
          return {
            ...worker,
          };
        });

        this.workerList.sort((workerA: any, workerB: any) => {
          return workerA.name < workerB.name ? -1 : workerA.name > workerB.name ? 1 : 0;
        });
        this.dataSource = new MatTableDataSource(this.workerList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.initiateFiltering();
      });
  }

  fetchArchivedWorkers() {
    this.unArchivedWorkersSubscription?.unsubscribe();
    this.archivedWorkersSubscription = this.firestoreService
      .getArchivedWorkersForClientId(this.selectedClientDocData.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((workerList) => {
        this.workerList = workerList.map((worker) => {
          if (worker.hourlyRate) {
            worker.hourlyRate = (+worker.hourlyRate).toFixed(2);
          }
          for (const [key, value] of Object.entries(worker)) {
            if (this.dateColumns.includes(key)) {
              const timeValue = value as Timestamp;
              worker[key] = this.datePipe.transform(
                timeValue.toMillis(),
                'yyyy-MM-dd HH:mm'
              );
            }
          }
          return {
            ...worker,
          };
        });

        this.workerList.sort((workerA: any, workerB: any) => {
          return workerA.name < workerB.name ? -1 : workerA.name > workerB.name ? 1 : 0;
        });

        this.dataSource = new MatTableDataSource(this.workerList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.initiateFiltering();
      });
  }

  initiateFiltering() {
    if (this.filterValue) {
      this.dataSource.filter = this.filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  areArchivedWorkersShown() {
    return (this.unArchivedVsArchived === 'archived');
  }

  areUnArchivedWorkersShown() {
    return (this.unArchivedVsArchived === 'unarchived');
  }

  fetchAllLabels() {
    this.labelsList = [];
    this.unarchivedLabelsSubscription = this.firestoreService
      .getAllUnarchivedLabelsForClientId(this.selectedClientDocData.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((labelsList) => {
        this.labelsList = labelsList.sort((labelA: any, labelB: any) => {
          return labelA.name < labelB.name ? -1 : labelA.name > labelB.name ? 1 : 0;
        });
      });
  }
}
