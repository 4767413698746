import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FirestoreService} from '../../../services/firestore.service';
import {ClientInContextService} from '../../../services/client-in-context.service';
import {AuthService} from '../../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SNACKBAR_CLASSES} from '../../../common/utils/utils';
import * as moment from 'moment-timezone';
import {TIME_FORMAT, TIME_ZONE} from "../../../common/utils/time-utils";

@Component({
  selector: 'app-edit-registration-dialog',
  templateUrl: './edit-registration-dialog.component.html',
  styleUrls: ['./edit-registration-dialog.component.scss']
})
export class EditRegistrationDialogComponent implements OnInit, OnDestroy {
  editRegnForm: UntypedFormGroup;
  loggedInUserFromAuthServiceSubscription: Subscription;
  loggedInUserDocData: any;
  regnRecordBeingEdited: any;
  allWorkersList: any[];
  workersListSubscription: Subscription;
  allTasksList: any[];
  allLocationsList: any[];
  locationListSubscription: Subscription;
  selectedClientDocData: any;
  clientInContextServiceSubscription: Subscription;
  taskListSubscription: Subscription;
  beingSaved = false;
  varietyListSubscription: Subscription;
  rowListSubscription: Subscription;
  allRowsList: any[];
  allVarietiesList: any[];

  private originalRegnDocument: any;
  private selectedDateInParent: string;

  constructor(
    private firestoreService: FirestoreService,
    private clientInContextService: ClientInContextService,
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditRegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private ref: ChangeDetectorRef
  ) {
    this.selectedDateInParent = data.selectedDateInParent;
    this.originalRegnDocument = data.regnRecord.originalRegnDocument;
    this.regnRecordBeingEdited = data.regnRecord;
    //console.log(moment(this.regnRecordBeingEdited.startTs.toDate()).tz())
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$.subscribe(
      (userDocData) => {
        this.loggedInUserDocData = userDocData;
      }
    );

    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;

      this.taskListSubscription = this.firestoreService
        .getAllUnarchivedTasksForClientId(this.selectedClientDocData?.id)
        .subscribe((tasksList) => (this.allTasksList = tasksList));
    });

    this.workersListSubscription = this.firestoreService
      .getUnArchivedWorkersForClientId(this.selectedClientDocData?.id)
      .subscribe((workerList) => {
        this.allWorkersList = workerList;
      });

    this.rowListSubscription = this.firestoreService.getAllRowsForClientId(this.selectedClientDocData.id)
    .subscribe(rows => {
      this.allRowsList = rows;
    });

    this.varietyListSubscription = this.firestoreService.getAllUnarchivedVarietiesForClientId(this.selectedClientDocData.id)
    .subscribe((varietyList) =>
      (this.allVarietiesList = varietyList.sort((varietyA: any, varietyB: any) => {
        return varietyA.name?.toLowerCase() < varietyB.name?.toLowerCase() ? -1 :
          varietyA.name?.toLowerCase() > varietyB.name?.toLowerCase() ? 1 : 0;
      })));
    this.locationListSubscription = this.firestoreService
      .getAllUnarchivedLocationsForClientId(this.selectedClientDocData?.id)
      .subscribe((locationsList) => (this.allLocationsList = locationsList));
  }

  ngOnInit() {
    let startTime = null;
    let endTime = null;
    if (this.regnRecordBeingEdited.startTimestamp) {
      startTime = moment(this.regnRecordBeingEdited.startTimestamp, 'HH:mm').tz(TIME_ZONE).format('HH:mm');
    }
    if (this.regnRecordBeingEdited.endTimestamp) {
      endTime = moment(this.regnRecordBeingEdited.endTimestamp, 'HH:mm').tz(TIME_ZONE).format('HH:mm');
    }

    this.editRegnForm = this.fb.group({
      workerId: [{value: this.regnRecordBeingEdited.workerId, disabled: false}, [Validators.required]],
      locationId: [{value: this.regnRecordBeingEdited.locationId, disabled: false}, []],
      startTime: [{value: startTime, disabled: false}, [Validators.required]],
      endTime: [{value: endTime, disabled: false}, [Validators.required]],
      taskId: [{value: this.regnRecordBeingEdited.taskId, disabled: false}, [Validators.required]],
      rowId: [{value: this.regnRecordBeingEdited.rowId ?? null, disabled: false}, []],
      varietyId: [{value: this.regnRecordBeingEdited.varietyId ?? null, disabled: false}, []],
      count: [{value: this.regnRecordBeingEdited.count ?? null, disabled: false}, []]
    });
  }

  async updateRegistration() {
    if (this.editRegnForm.pristine) {
      this.openSnackBar('No changes detected!', 'error');
      return;
    }

    const isOriginal = this.regnRecordBeingEdited.hasOwnProperty('isOriginal') ? this.regnRecordBeingEdited.isOriginal : true;

    this.beingSaved = true;

    const regnDataToUpdate = this.editRegnForm.value;
    //this.dialogRef.close(this.form.value);

    try {

      if (isOriginal) {
        if (this.regnRecordBeingEdited.grandParentCollection === 'presences') {
          regnDataToUpdate.backupDocId = await this.firestoreService.createOriginalVersionCopyOfRegnBelowPresence(this.regnRecordBeingEdited.id, this.selectedClientDocData.id,
            this.regnRecordBeingEdited.parentPresenceId, this.originalRegnDocument);
        } else {
          regnDataToUpdate.backupDocId = await this.firestoreService.createOriginalVersionCopyOfRegn(this.regnRecordBeingEdited.id,
            this.selectedClientDocData.id, this.originalRegnDocument);
        }
        regnDataToUpdate.isOriginal = false;
      }

      const rawDate = moment(this.selectedDateInParent).format('YYYY-MM-DD') + ' 12:00';
      let regnStartTimestampMoment = null;
      let regnEndTimestampMoment = null;
      if (regnDataToUpdate.startTime) {
        regnStartTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +regnDataToUpdate.startTime.split(':')[0] ?? 0,
          minute: +regnDataToUpdate.startTime.split(':')[1] ?? 0,
        });
        regnDataToUpdate.startTimestamp = regnStartTimestampMoment.toDate()
      }

      if (regnDataToUpdate.endTime) {
        regnEndTimestampMoment = moment.tz(rawDate, 'YYYY-MM-DD HH:mm', TIME_ZONE).set({
          hour: +regnDataToUpdate.endTime.split(':')[0] ?? 0,
          minute: +regnDataToUpdate.endTime.split(':')[1] ?? 0,
        });
        regnDataToUpdate.endTimestamp = regnEndTimestampMoment.toDate()
      }

      if (regnStartTimestampMoment && regnEndTimestampMoment) {
        if (regnStartTimestampMoment.isSameOrAfter(regnEndTimestampMoment)) {
          this.openSnackBar('End timestamp should be after start timestamp!', 'error');
          this.beingSaved = false;
          return;
        }
      }
      regnDataToUpdate.id = this.regnRecordBeingEdited.id;

      if (regnDataToUpdate.workerId) {
        if (regnDataToUpdate.workerId !== this.regnRecordBeingEdited.workerId) {
          regnDataToUpdate.workerName = this.allWorkersList.filter((worker) => worker.id === regnDataToUpdate.workerId)
            .map((worker) => (worker.name ? worker.name : null))[0];
        }
      } else {
        regnDataToUpdate.workerName = null;
      }


      if (regnDataToUpdate.taskId) {
        if (regnDataToUpdate.taskId !== this.regnRecordBeingEdited.taskId) {
          regnDataToUpdate.taskName = this.allTasksList.filter((task) => task.id === regnDataToUpdate.taskId)
          .map((task) => (task.name ? task.name : null))[0];
        }
      } else {
        regnDataToUpdate.taskName = null;
      }

      if (regnDataToUpdate.locationId) {
        if (regnDataToUpdate.locationId !== this.regnRecordBeingEdited.locationId) {
          regnDataToUpdate.locationName = this.allLocationsList.filter((location) => location.id === regnDataToUpdate.locationId)
            .map((location) => (location.name ? location.name : null))[0];
        }
      } else {
        regnDataToUpdate.locationName = null;
      }

      if (regnDataToUpdate.rowId) {
        regnDataToUpdate.rowNumber = this.allRowsList.filter((row) => row.id === regnDataToUpdate.rowId)
        .map((row) => (row.rowNumber ? row.rowNumber : null))[0];
      } else {
        regnDataToUpdate.rowNumber = null;
      }

      if (regnDataToUpdate.varietyId) {
        regnDataToUpdate.varietyName = this.allVarietiesList.filter((variety) => variety.id === regnDataToUpdate.varietyId)
        .map((variety) => (variety.name ? variety.name : null))[0];
      } else {
        regnDataToUpdate.varietyName = null;
      }

      if (!regnDataToUpdate.count) {
        regnDataToUpdate.count = null;
      }

      regnDataToUpdate.updatedByUserId = this.loggedInUserDocData.id ?? null;
      regnDataToUpdate.updatedByUserName = this.loggedInUserDocData.name ?? null;

      delete regnDataToUpdate.startTime;
      delete regnDataToUpdate.endTime;

      if (this.regnRecordBeingEdited.grandParentCollection === 'presences') {
        await this.firestoreService.updateRegnBelowPresenceForClientId(regnDataToUpdate, this.selectedClientDocData.id, this.regnRecordBeingEdited.parentPresenceId);
      } else {
        await this.firestoreService.updateRegnForClientId(regnDataToUpdate, this.selectedClientDocData.id);
      }
      this.openSnackBar('Changes have been saved', 'success');
      this.editRegnForm.markAsPristine();
      this.dialogRef.close();
      this.beingSaved = false;
    } catch (error) {
      this.beingSaved = false;
      this.openSnackBar('Error in saving changes:' + error.message, 'error');
      console.log(error.message);
    }
  }

  close() {
    this.dialogRef.close();
  }

  async revertChanges() {
    if (this.regnRecordBeingEdited.hasOwnProperty('isOriginal') && this.regnRecordBeingEdited.isOriginal) {
      return;
    }
    if (this.regnRecordBeingEdited.grandParentCollection === 'presences') {
      await this.firestoreService.restoreRegnFromVersionBackupBelowPresence(this.regnRecordBeingEdited.id,
        this.selectedClientDocData.id, this.regnRecordBeingEdited.parentPresenceId, this.originalRegnDocument.backupDocId, this.loggedInUserDocData);
    } else {
      await this.firestoreService.restoreRegnFromVersionBackup(this.regnRecordBeingEdited.id,
        this.selectedClientDocData.id, this.originalRegnDocument.backupDocId, this.loggedInUserDocData);
    }
    this.openSnackBar('Session has been reverted to original values', 'success');
    this.dialogRef.close();
  }


  ngOnDestroy(): void {
    this.workersListSubscription?.unsubscribe();
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
    this.clientInContextServiceSubscription?.unsubscribe();
    this.taskListSubscription?.unsubscribe();
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }

}

