import {Component, inject, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SNACKBAR_CLASSES} from '../../utils/utils';

@Component({
  selector: 'app-video-help-modal',
  templateUrl: './video-help-modal.component.html',
  styleUrl: './video-help-modal.component.scss'
})
export class VideoHelpModalComponent implements OnDestroy {
  videoURL = ''
  private snackBar = inject(MatSnackBar);
  private attemptListener: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<VideoHelpModalComponent>, private clipboard: Clipboard) {
    this.videoURL = this.data.videoId
  }

  copyVideoLink() {
    const pending = this.clipboard.beginCopy(
      'https://www.youtube.com/watch?v=' + this.videoURL
    );
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        this.attemptListener = setTimeout(attempt);
      } else {
        this.openSnackBar('Youtube video link copied', 'success');
        pending.destroy();
      }
    };
    attempt();
  }


  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    clearTimeout(this.attemptListener);
  }

  openSnackBar(message, type) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
