import {AfterViewInit, Component, DestroyRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {AuthService} from "../../services/auth.service";
import {SNACKBAR_CLASSES} from "../../common/utils/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FirestoreService} from "../../services/firestore.service";
import {MatButtonModule} from '@angular/material/button';
import {MatTableDataSource} from "@angular/material/table";
import {ClientMainAttributes} from "../../common/interfaces/client-interfaces";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-client-user-profile',
  templateUrl: './client-user-profile.component.html',
  styleUrls: ['./client-user-profile.component.scss',
    '../../common/styles/listing.scss']
})
export class ClientUserProfileComponent implements OnInit, OnDestroy {
  userList: any[];
  private loggedInUserFromAuthServiceSubscription: Subscription;
  private loggedInUserDocData: any;
  name: string;
  @ViewChild(MatSort) sort: MatSort;
  clientNamesBlurred = false

  constructor(private authService: AuthService,
              private snackBar: MatSnackBar,
              private firestoreService: FirestoreService,
              private destroyRef: DestroyRef) {
    this.loggedInUserFromAuthServiceSubscription = this.authService.loggedInUserFromAuthService$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userDocData) => {
          this.loggedInUserDocData = userDocData;
          if (this.loggedInUserDocData.hasOwnProperty('clientNamesBlurred')) {
            this.clientNamesBlurred = this.loggedInUserDocData.clientNamesBlurred;
          }
          this.name = userDocData.name;
          this.userList = this.loggedInUserDocData.clients
      });
  }

  async updateUser() {
    if (!this.name || !this.name?.trim()) {
      this.openSnackBar('Name is a mandatory field!', 'error');
      return;
    }

    if (this.name.trim() === this.loggedInUserDocData.name) {
      this.openSnackBar('No changes to save!', 'error');
      return;
    }

    try {
      await this.firestoreService.updateUserById(this.loggedInUserDocData.id, {
        name: this.name.trim()
      });
      this.openSnackBar('Name is changed successfully!', 'success');
    } catch (error) {
      this.openSnackBar('Error in saving user name:' + error.message, 'error');
    }
  }

  async updateBlurredSettingForUser() {
    try {
      await this.firestoreService.updateUserById(this.loggedInUserDocData.id, {
        clientNamesBlurred: this.clientNamesBlurred
      });
    } catch (error) {
      console.log(`Error in saving blurring setting change:` + error.message)
    }
  }

  openSnackBar(message, type, duration?) {
    this.snackBar.open(message, '', {
      panelClass: SNACKBAR_CLASSES[type],
      duration: duration ? duration : 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.loggedInUserFromAuthServiceSubscription?.unsubscribe();
  }

}
