<div class="listing-container">
  <mat-form-field appearance="outline" class="listing-filter">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label style="color: gray">Type Filter String Here...</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="client" #input />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      matSort
      class="listing-table"
    >
      <ng-container
        matColumnDef="{{ column }}"
        *ngFor="let column of columnsToDisplay"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ activitiesAttributesReadableMap.get(column) }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="row-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="row-detail-description">
              <table>
                <tr *ngFor="let column of element.remainingAttributesList">
                  <td class="details key">
                    {{ column[0] }}
                  </td>
                  <td class="details value">
                    {{ column[1] }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="row-detail"
      ></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 25, 100]"
      [pageSize]="100"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</div>
