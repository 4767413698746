import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export class CommonDataSource<T> extends DataSource<T> {
  private filterChange = new BehaviorSubject<{ [key: string]: string }>({});
  private sortChange = new BehaviorSubject<Sort>({ active: '', direction: '' });
  private dataSubject = new BehaviorSubject<T[]>([]);

  constructor(
    private paginator: MatPaginator,
    private sort: MatSort
  ) {
    super();
  }

  set data(data: T[]) {
     const currentData = this.dataSubject.value;
    // Only update if the data has changed
    if (JSON.stringify(currentData) !== JSON.stringify(data)) {
      this.dataSubject.next(data);
    }
  }

  updateData(data: T[]) {
    this.dataSubject.next(data);

  }

  getData(): T[] {
    return this.dataSubject.value;
  }

  connect(): Observable<T[]> {
    const displayDataChanges = [
      this.dataSubject,
      this.filterChange,
      this.sortChange,
      this.paginator.page
    ];

    return merge(...displayDataChanges).pipe(
      debounceTime(100),
      map(() => {
        let data = this.dataSubject.value.slice();
        // Apply filters for each column
        const filters = this.filterChange.value;
        Object.keys(filters).forEach(column => {
          const filterValue = filters[column].toLowerCase();
          if (filterValue) {
            data = data.filter(item => {
              const fieldValue = item[column]?.toString().toLowerCase();
              return fieldValue?.includes(filterValue);
            });
          }
        });

        // Sort data
        const sort = this.sortChange.value;
        if (sort.active && sort.direction) {
          data = this.sortData(data, sort.active, sort.direction);
        }

        // Paginate data
        // Update paginator length
      this.paginator.length = data.length;
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        return data.splice(startIndex, this.paginator.pageSize);
      })
    );
  }

  disconnect() {}

  sortData(data: T[], active: string, direction: string): T[] {
    return data.sort((a, b) => {
      const valueA = a[active];
      const valueB = b[active];

      if (valueA == null || valueB == null) {
        return 0; // Handle null or undefined values
      }

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      if (valueA < valueB) {
        return direction === 'asc' ? -1 : 1;
      } else if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  setSort(active: string, direction: any) {
    this.sortChange.next({ active, direction });
  }



  setFilter(column: string, filterValue: string) {

    const filters = this.filterChange.value;
    filters[column] = filterValue;
    this.filterChange.next(filters);
  }

  clearFilters() {
    this.filterChange.next({});
  }
}
