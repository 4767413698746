<div style="min-width: 400px;">
  <mat-dialog-content [formGroup]="enrollmentSettingsForm" style="text-align: center;">
    <mat-form-field class="mb-2" style="width: 18rem;">
      <mat-label>{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="locationId">
        <mat-option [value]="null">{{'SETTINGS.MODAL.SELECTLOCATION.LABEL.TEXT' | translate}}</mat-option>
        <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <mat-form-field class="mb-2" style="width: 18rem;margin-top: 0.4rem;">
      <mat-label> {{'SETTINGS.MODAL.APPMODES.LABEL.TEXT' | translate}}</mat-label>
      <mat-select formControlName="appModes" multiple>
        <mat-option *ngFor="let appMode of allAppModesList" [value]="appMode.id">
          {{appMode.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <mat-form-field class="mb-2" style="width: 18rem;margin-top: 0.4rem;">
      <mat-label> {{'SETTINGS.MODAL.NEXTDEVICENUMBER.LABEL.TEXT' | translate}}</mat-label>
      <input matInput formControlName="nextDeviceNumber"/>
    </mat-form-field>
    <br/>
    <span style="color: #EC4444; font-size: 0.7rem;font-style: italic;"
          *ngIf="enrollmentSettingsForm.controls['nextDeviceNumber'].invalid">
    {{'SETTINGS.MODAL.DEVICENUMBERERROR.LABEL.TEXT' | translate}}
  </span>
    <span style="color: #EC4444; font-size: 0.7rem;font-style: italic;"
          *ngIf="enrollmentSettingsForm.controls['nextDeviceNumber'].valid">
    &nbsp;
  </span>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      style="margin-top: 0.4rem !important;"
      formControlName="enableAutoTouchLock"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLELOCK.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableGPS"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEGPS.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableOutOfRange"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLERANGE.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableTrainingMode"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEMODE.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableVoiceAssistance"
    >
      <mat-label>{{'SETTINGS.TABLEHEAD.ENABLEVOICE.TITLE.TEXT' | translate}}</mat-label>
    </mat-slide-toggle>
    <br/>
  </mat-dialog-content>
  <div style="text-align: right;margin-bottom: 0.4rem;">
    <mat-dialog-actions>
      <button  mat-stroked-button color="accent" (click)="close()" [disabled]="beingSaved">{{'SETTINGS.MODAL.CLOSE.BUTTON.TEXT' | translate}}</button>
      <button mat-raised-button color="primary" (click)="updateEnrollmentSettings()"
              [disabled]="beingSaved || enrollmentSettingsForm?.invalid" style="margin-left: 20px;">{{'SETTINGS.MODAL.SAVECHANGES.BUTTON.TEXT' | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
