<div class="page-top-space">
  <mat-card class="mat-mdc-card mdc-card page-main-card">
    <mat-icon [matTooltip]="getTooltipForStreamingButton()" class="video-streaming" [inline]="true"
      (click)="toggleStreaming()" *ngIf="tableData?.length > 0">
      {{displayedIcon}}
    </mat-icon>
    <section class="new-table-design-container mat-elevation-z8 " tabindex="0">
      <div class="controls-container ">
        <div class="filter-container w-100 sm-grid-areas">
          <button mat-flat-button color="primary" class="add-area" (click)="openCreateSessionDialog()"
            *ngIf="selectedClientDocData?.hasAdminRole">
            <mat-icon matTooltip="Create Session" matTooltipClass="tooltip-class" matTooltipHideDelay="100000">
              add
            </mat-icon>{{'DASHBOARD.SESSIONS.CREATE.BUTTON.TEXT' | translate}} </button>
          <div class="date-container justify-content-center date-area">
            <app-date-selection></app-date-selection>
          </div>

          <button mat-icon-button class="download-area hide-sm" aria-label="" (click)="downloadAsCSV('Sessions')" matTooltip="Download data">
            <mat-icon>download</mat-icon>
          </button>
          <button mat-icon-button class="filter-area hide-sm" [matMenuTriggerFor]="filterMenu" aria-label="Example icon-button with a menu">
            <mat-icon>filter_alt</mat-icon>
          </button>

          <mat-menu #filterMenu="matMenu" class="customize">
            <form class="example-form" (click)="$event.stopPropagation()" *ngFor="let filter of displayedTopFilters">
              <mat-form-field class="filter px-3" appearance="outline">
                <mat-label>{{filter.displayName}}</mat-label>
                <input type="text" placeholder="" aria-label="Number" matInput [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="filter.filterValue" [matAutocomplete]="auto" (keyup)="headerFilter($event, filter.name)"
                  (focus)="headerFilter($event, filter.name)" />
                <mat-icon matSuffix>search</mat-icon>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filter.filterOptions" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
            <button mat-menu-item (click)="removeFilters()">
              <mat-icon>filter_list_off</mat-icon>
              <span>{{'DASHBOARD.COMMON.REMOVEALLFILTER.BUTTON.TEXT' | translate}}</span>
            </button>
          </mat-menu>
          <mat-button-toggle-group [hideSingleSelectionIndicator]="true" class="archive-toggle toggle-area" #group="matButtonToggleGroup">
            <mat-button-toggle value="listView" (click)="toggleArchived(false)" checked matTooltip="All items">
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="archiveView" (click)="toggleArchived(true);" matTooltip="Archived items">
              <mat-icon>archive</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="filter-string" *ngIf="filterString">{{filterString}}</div>
    </section>

    <div class="table-card table-mobile-res">
      <section class="new-table-design-container expandable-table mat-elevation-z8 table-to-card-mb" tabindex="0">
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z8">
          <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
            <th mat-header-cell class="table-header" *matHeaderCellDef>
              <div class="table-header-container">
                <div class="table-header-content-text">{{column.displayName | translate}}</div>
                <!--<div class="table-header-content-spacer"></div>-->
                <div class="table-header-content-menu">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                    class="menu-button" *ngIf="column.showHeaderFilter" [class]="column.filtered ? 'filtered' : ''">
                    <mat-icon>filter_list</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                    <button mat-menu-item (click)="sortList(column.name,'asc')">
                      <mat-icon></mat-icon>
                      <span innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span>
                    </button>
                    <button mat-menu-item (click)="sortList(column.name,'desc')">
                      <mat-icon></mat-icon>
                      <span innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span>
                    </button>
                    <form class="example-form px-3" (click)="$event.stopPropagation()">
                      <mat-form-field class="filter" appearance="outline">
                        <mat-label>{{'DASHBOARD.TABLE.FILTERVALULE.LABEL.TEXT' | translate}}</mat-label>
                        <input type="text" placeholder="" aria-label="Filter Value" matInput
                          [ngModelOptions]="{standalone: true}" [(ngModel)]="column.filterValue"
                          [matAutocomplete]="auto" (keyup)="headerFilter($event, column.name)"
                          (focus)="headerFilter($event, column.name)" />
                          <button mat-icon-button matSuffix (click)="removeFilters()">
                            <mat-icon>close</mat-icon>
                          </button>
                        
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let option of column.filterOptions" [value]="option">
                            {{option}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </form>
                    <button mat-menu-item (click)="removeFilters()">
                      <mat-icon>filter_list_off</mat-icon>
                      <span> {{'DASHBOARD.TABLE.REMOVEALLFILTERS.LABEL.TEXT' | translate}}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </th>
            <td [attr.dataName]="column.displayName | translate" mat-cell *matCellDef="let element"><span>{{element[column.name]}}</span></td>
            <td mat-footer-cell *matFooterCellDef> {{column.footerValue}} </td>
          </ng-container>

          <ng-container matColumnDef='edit'>
            <th mat-header-cell class="table-header" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="listing-table-tools-wrapper bottom-full-w left-corner static-td">
              <div class="d-flex row-reverse align-items-center">
                <div class="align-items-center grid-control-column">
                  <button mat-icon-button color="accent" [ngClass]="element.editButtonClass"
                    (click)="editSession($event, element)">
                    <mat-icon matTooltip="Edit this session" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below">edit
                    </mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="copyAsTraining($event, element)"
                    class="listing-table-tools tdhover" *ngIf="selectedClientDocData.isDeveloper">

                    <mat-icon matTooltip="Copy as Training" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                      matTooltipPosition="below">movie_edit
                    </mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="archiveSession($event, element)"
                    class="listing-table-tools tdhover" matTooltip="Archive session" matTooltipClass="tooltip-class"
                    matTooltipHideDelay="100" matTooltipPosition="below" *ngIf="!element.isArchived">
                    <mat-icon>archive</mat-icon>
                  </button>
                  <button mat-icon-button color="accent" (click)="unarchiveSession($event, element)"
                    matTooltip="Unarchive session" matTooltipClass="tooltip-class" matTooltipHideDelay="100"
                    matTooltipPosition="below" class="listing-table-tools tdhover" *ngIf="element.isArchived">
                    <mat-icon>unarchive</mat-icon>
                  </button>

                </div>
                <button mat-icon-button color="accent" (click)="fixSession($event, element)"
                  [ngClass]="element.fixButtonClass" matTooltip="Initiate fix" matTooltipClass="tooltip-class"
                  matTooltipHideDelay="500" matTooltipPosition="below" [disabled]="!element.fixAllowed"
                  *ngIf="selectedClientDocData.isDeveloper && (!element.fixFailed)">
                  <mat-icon>restart_alt</mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="fixSession($event, element)"
                  [ngClass]="element.fixButtonClass" [mtxTooltip]="tooltipFix" mtxTooltipPosition="below" [disabled]=""
                  *ngIf="selectedClientDocData.isDeveloper && element.fixFailed">
                  <mat-icon>sync_problem</mat-icon>
                </button>
              </div>
              <ng-template #tooltipFix>
                <div
                  style="background: #5b5f66; color: mintcream;padding:5px;border-radius:3px; text-align: center;font-size: 0.95rem;width:10rem;">
                  <div style="margin-bottom: 4px;">{{getFailedToolTip(element).topline}}</div>
                  <span style="text-decoration: underline;">Prev. failure reason</span> <br />
                  <div style="font-size: 0.75rem;font-style: italic">{{getFailedToolTip(element).bottomLine}}</div>
                </div>
              </ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef='manual_indicator'>
            <th mat-header-cell class="table-header" *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="static-td right-corner bg-transparent-sm">
              <mat-icon matTooltip="{{'DASHBOARD.SESSION.TABLE.TOOLTIP.MANUALLYCREATED.LABEL.TEXT' |  translate}}"
                matTooltipHideDelay="100" matTooltipPosition="below" *ngIf="element.isManual">front_hand</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef='startTimestamp'>
            <th mat-header-cell class="table-header hide-sm" *matHeaderCellDef>{{'DASHBOARD.TABLEHEAD.STARTTIMESTAMP.TITLE.TEXT' | translate}}</th>
            <td mat-cell *matCellDef="let element" [attr.dataName]="'DASHBOARD.TABLEHEAD.STARTTIMESTAMP.TITLE.TEXT' | translate">
             <span>{{element['startTimestamp']}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef='endTimestamp'>
            <th mat-header-cell class="table-header hide-sm" *matHeaderCellDef>{{'DASHBOARD.TABLEHEAD.ENDTIMESTAMP.TITLE.TEXT' | translate}}</th>
            <td mat-cell *matCellDef="let element" [attr.dataName]="'DASHBOARD.TABLEHEAD.ENDTIMESTAMP.TITLE.TEXT' | translate">
             <span>{{element['endTimestamp']}}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef='count'>
            <th mat-header-cell class="table-header hide-sm" *matHeaderCellDef>{{'DASHBOARD.TABLEHEAD.COUNT.TITLE.TEXT' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="" [attr.dataName]="'DASHBOARD.TABLEHEAD.COUNT.TITLE.TEXT' | translate">
             <span>{{element['count']}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="getDisplayedCols().length">
              <mat-icon (click)="expandedElement = null" class="material-symbols-outlined close-expandable d-block d-md-none">
                close 
              </mat-icon>
              <div class="row-detail"
                [@detailExpand]=" element.sessionId == expandedElement?.sessionId ? 'expanded' : 'collapsed'">
                <div class="row-detail-description">
                  <div class="row-detail-item">
                    <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.LOCATION.TITLE.TEXT' | translate}}:</strong>
                    <span>{{element['locationName']}}</span>
                  </div>
                  <div class="row-detail-item">
                    <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.LAYOUT.TITLE.TEXT' | translate}}:</strong>
                    <span>{{element['layoutName']}}</span>
                  </div>
                  <div class="row-detail-item">
                    <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.NET.TITLE.TEXT' | translate}}:</strong>
                    <span>{{element['nettPerformance']}}</span>
                  </div>
                  <div class="row-detail-item">
                    <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.GROSS.TITLE.TEXT' | translate}}:</strong>
                    <span>{{element['grossPerformance']}}</span>
                  </div>
                  <div class="row-detail-item">
                    <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.DEVICE.TITLE.TEXT' | translate}}:</strong>
                    <span>{{element.deviceNumber}} ({{element['deviceId']}})</span>
                  </div>
                  <div class="row-detail-item">
                    <strong>{{'DASHBOARD.SESSION.NESTEDTABLE.SESSION.TITLE.TEXT' | translate}}:</strong>
                    <span>{{element['sessionId']}}</span>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="getDisplayedCols(); sticky: true"></tr>
          <tr mat-row [class.hidden]="toggleTableChart !== 'table'"
            *matRowDef="let element; columns: getDisplayedCols();" [class.expanded-row]="applyExpandedClass(element)"
            (click)="expandRow(element)"></tr>

          <tr mat-row style="height: 0;" *matRowDef="let row; columns: ['expandedDetail']"
            class="row-detail expandable-row">

          </tr>
        </table>
      </section>
      <section class="new-table-design-container mat-elevation-z8" tabindex="0">
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons aria-label="Select page">
        </mat-paginator>
      </section>
    </div>
  </mat-card>



</div>
