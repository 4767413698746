<div class="d-flex client-general-wrapper">
  <mat-card  class="transparent-effect-card">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="material-symbols-outlined mr-2">
        security
      </mat-icon>{{'DASHBOARD.SETTINGS.GENERAL.SECURITY.TITLE.TEXT' | translate}} 
    </mat-card-title>
      <mat-card-subtitle class="mt-2">{{'DASHBOARD.SETTINGS.GENERAL.SECURITY.SUBTITLE.TEXT' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="d-flex flex-column">
        <mat-form-field appearance="outline">
          <mat-label> {{'DASHBOARD.SETTINGS.GENERAL.SECURITY.CODE.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required placeholder="0000"
                 [(ngModel)]="deviceSecurityCode"
                 maxlength="4"
                 minlength="4"/>
        </mat-form-field>

        <mat-slide-toggle [(ngModel)]="workerCodeWatchEnabled" class="my-3">
          <mat-label> {{'DASHBOARD.SETTINGS.GENERAL.SECURITY.WATCHUSER.LABEL.TEXT' | translate}}</mat-label>
        </mat-slide-toggle>
        <mat-slide-toggle [(ngModel)]="workerCodeClockEnabled">
          <mat-label >{{'DASHBOARD.SETTINGS.GENERAL.SECURITY.CLOCKUSER.LABEL.TEXT' | translate}}</mat-label>
        </mat-slide-toggle>
      </div>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-flat-button (click)="saveDeviceSecurityCode()" color="primary">
        {{'DASHBOARD.SETTINGS.GENERAL.SECURITY.SAVE.BUTTON.TEXT' | translate}}</button>
    </mat-card-actions>
  </mat-card>

  <mat-card class="transparent-effect-card">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center">
        <mat-icon class="material-symbols-outlined mr-2">
          checklist
      </mat-icon>{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.TITLE.TEXT' | translate}} 
    </mat-card-title>
      <mat-card-subtitle class="mt-2">{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.SUBTITLE.TEXT' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.FACTOR.LABEL.TEXT' | translate}}</mat-label>
          <input matInput required placeholder="00.00"
                 [(ngModel)]="correctionFactor"
                 />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions align="end" style="margin-right: 1rem;">
      <button mat-flat-button (click)="saveCorrectionFactor()" color="primary">{{'DASHBOARD.SETTINGS.GENERAL.CORRECTION.SAVE.BUTTON.TEXT' | translate}} </button>
    </mat-card-actions>
  </mat-card>

</div>
