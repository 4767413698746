<div style="min-width: 400px;">
  <h2 mat-dialog-title>Create Task Group</h2>

  <mat-dialog-content [formGroup]="form" style="text-align: center;">
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="w-100">
          <mat-label>Name</mat-label>
          <input matInput required
                 formControlName="name">
        </mat-form-field> 
      </div>
    </div>
  </mat-dialog-content> 
  <div>
    <mat-dialog-actions class="w-100">
      <button mat-stroked-button color="accent" (click)="close()">Close</button>
      <button mat-raised-button color="primary" (click)="createTaskGroup()" style="margin-left: 20px;">Create Task Group</button>
    </mat-dialog-actions>
  </div>
</div>
