import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTab, MatTabBody, MatTabGroup, MatTabHeader, MatTabsModule} from '@angular/material/tabs';
import {ClientInContextService} from "../../services/client-in-context.service";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from '../../services/auth.service';
import {getPermissions} from '../../common/utils/permission-utils';

@Component({
  selector: 'app-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss',
    '../../common/styles/listing.scss']
})
export class ClientSettingsComponent implements OnInit, OnDestroy {

  clientInContextServiceSubscription: Subscription;
  selectedClientDocData: any;

  rootUrl = '/settings';

  navigation = [];

  authUserSubscription: Subscription;

  constructor(private clientInContextService: ClientInContextService, private route: ActivatedRoute, public router: Router, private authService: AuthService) {
    this.clientInContextServiceSubscription = this.clientInContextService.clientInContextSubject.subscribe(selectedClientDocData => {
      if (!selectedClientDocData) {
        return;
      }
      this.selectedClientDocData = selectedClientDocData;
      if (this.getPermissionFlag('settings', 'general').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.GENERAL.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.GENERAL.LABEL.TEXT',
            link: '/general',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'locations').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.LOCATION.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.LOCATION.LABEL.TEXT',
            link: '/locations',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'users').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.USERS.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.USERS.LABEL.TEXT',
            link: '/users',
            hideMobile:false
          });
        }
      }
      if (this.getPermissionFlag('settings', 'workers').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.WORKERS.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.WORKERS.LABEL.TEXT',
            link: '/workers',
            hideMobile:false
          });
        }
      }
      if (this.getPermissionFlag('settings', 'sessions').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.SESSIONS.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.SESSIONS.LABEL.TEXT',
            link: '/sessions',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'presences').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.PRESENCES.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.PRESENCES.LABEL.TEXT',
            link: '/presences',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'tasks').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.TASKS.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.TASKS.LABEL.TEXT',
            link: '/tasks',
            hideMobile:false
          });
        }
      }
      if (this.getPermissionFlag('settings', 'positions').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.POSTIONS.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.POSTIONS.LABEL.TEXT',
            link: '/positions',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'assets').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.ASSETS.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.ASSETS.LABEL.TEXT',
            link: '/assets',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'beacons').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.BEACONS.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.BEACONS.LABEL.TEXT',
            link: '/beacons',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'devices').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.DEVICES.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.DEVICES.LABEL.TEXT',
            link: '/devices',
          });
        }
      }
      if (this.getPermissionFlag('settings', 'devices').canRead) {
        if (this.navigation.filter(element => element.name === 'DASHBOARD.SETTINGS.TABS.DEVICES.LABEL.TEXT').length === 0) {
          this.navigation.push({
            name: 'DASHBOARD.SETTINGS.TABS.DEVICES.LABEL.TEXT',
            link: '/devices',
          });
        }
      }
      if (this.getPermissionFlag('language', null).canRead) {
        if (this.navigation.filter(element => element.name === 'Languages').length === 0) {
          this.navigation.push({
            name: 'Languages',
            link: '/languages',
            hideMobile:true
          });
        }
      }
      if (this.getPermissionFlag('settings', 'apis').canRead) {
        if (this.navigation.filter(element => element.name === 'Integrations').length === 0) {
          this.navigation.push({
            name: 'Integrations',
            link: '/integrations',
            hideMobile:true
          });
        }
      }
    });
  }

  getPermissionFlag(level1: string, level2: string) {
    if (this.selectedClientDocData.rolePermissionsMap) {
      return getPermissions(level1, level2, this.selectedClientDocData.rolePermissionsMap);
    } else {
      return {};
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clientInContextServiceSubscription?.unsubscribe();
    this.authUserSubscription?.unsubscribe();
  }

}
