<div style="min-width: 400px;">
  <mat-dialog-content [formGroup]="multipleDeviceEditForm" style="text-align: center;">
    <mat-form-field class="mb-2"  style="width: 18rem;">
      <mat-label>Select Location</mat-label>
      <mat-select formControlName="locationId">
        <mat-option [value]="null">Select Location</mat-option>
        <mat-option *ngFor="let location of allLocationsList" [value]="location.id">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <mat-form-field class="mb-2" style="width: 18rem;margin-top: 0.4rem;">
      <mat-label>App Modes</mat-label>
      <mat-select formControlName="appModes" multiple>
        <mat-option *ngFor="let appMode of allAppModesList" [value]="appMode.id">
          {{appMode.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableAutoTouchLock"
    >
      <mat-label>Enable auto touch lock</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableGPS"
    >
      <mat-label>Enable GPS</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableOutOfRange"
    >
      <mat-label>Enable out of range</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableTrainingMode"
    >
      <mat-label>Enable training mode</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="enableVoiceAssistance"
    >
      <mat-label>Enable voice assistance</mat-label>
    </mat-slide-toggle>
    <br/>
    <mat-slide-toggle
      class="edit_device_toggles"
      formControlName="isLocked"
    >
      <mat-label>Lock device</mat-label>
    </mat-slide-toggle>
    <br/>
  </mat-dialog-content>
  <div style="text-align: right;margin-bottom: 0.4rem;">
    <mat-dialog-actions>
      <button mat-stroked-button color="accent" (click)="close()" [disabled]="beingSaved">Close</button>
      <button mat-raised-button color="primary" (click)="updateDevices()" [disabled]="beingSaved" style="margin-left: 20px;">Save Changes</button>
    </mat-dialog-actions>
  </div>
</div>
