<div>
  <section class="new-table-design-container mat-elevation-z8" tabindex="0" style="margin-top:4px;">
    <div class="controls-container justify-content-center filter-container">
      <div class="date-container justify-content-center flex-unset">
        <app-date-selection></app-date-selection>
      </div>
    </div>
  </section>

  <div class="table-card">
    <section class="new-table-design-container table-body mat-elevation-z8 expandable-table" tabindex="0">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8">
        <ng-container [matColumnDef]="column.name" *ngFor="let column of columns">
          <th mat-header-cell class="table-header" *matHeaderCellDef>
            <div class="table-header-container">
              <div class="table-header-content-text">{{ column.displayName | translate }}</div>
              <!--<div class="table-header-content-spacer"></div>-->
              <div class="table-header-content-menu">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        class="menu-button" *ngIf="column.showHeaderFilter" [class]="column.filtered ? 'filtered' : ''">
                  <mat-icon>filter_list</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="menu customize" xPosition="before">
                  <button mat-menu-item (click)="sortList(column.name,'asc')">
                    <mat-icon></mat-icon>
                    <span innerHTML="{{'DASHBOARD.PAGESORT.ATOZ.LABEL.TEXT' |  translate}}"></span>
                  </button>
                  <button mat-menu-item (click)="sortList(column.name,'desc')">
                    <mat-icon></mat-icon>
                    <span innerHTML="{{'DASHBOARD.PAGESORT.ZTOA.LABEL.TEXT' |  translate}}"></span>
                  </button>
                  <form class="example-form" (click)="$event.stopPropagation()">
                    <mat-form-field class="filter px-3" appearance="outline">
                      <mat-label>{{ 'DASHBOARD.TABLE.FILTERVALULE.LABEL.TEXT' | translate }}</mat-label>
                      <input type="text" placeholder="" aria-label="Filter Value" matInput
                             [ngModelOptions]="{standalone: true}" [(ngModel)]="column.filterValue"
                             [matAutocomplete]="auto"
                             (keyup)="headerFilter($event, column.name)" (focus)="headerFilter($event, column.name)"/>
                      <mat-icon matSuffix>search</mat-icon>
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of column.filterOptions" [value]="option">
                          {{ option }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </form>
                  <button mat-menu-item (click)="removeFilters()">
                    <mat-icon>filter_list_off</mat-icon>
                    <span> {{ 'DASHBOARD.TABLE.REMOVEALLFILTERS.LABEL.TEXT' | translate }}</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">{{ element[column.name] }}</td>
          <td mat-footer-cell *matFooterCellDef> {{ column.footerValue }}</td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="getDisplayedCols().length">

            <div class="row-detail" [@detailExpand]=" element.requestId == expandedElement?.requestId ? 'expanded' : 'collapsed'">
              <div class="row-detail-description">
                <div class="row-detail-item">
                  <strong> {{ 'SETTINGS.APILOGS.NESTEDTABLE.REQID.TITLE.TEXT' | translate }}: </strong>
                  <span>{{ element['requestId'] }}</span>
                </div>
                <div class="row-detail-item">
                  <strong>{{ 'SETTINGS.APILOGS.NESTEDTABLE.SRCIP.TITLE.TEXT' | translate }}: </strong>
                  <span>{{ element['sourceIP'] }}</span>
                </div>
                <div class="row-detail-item">
                  <strong> {{ 'SETTINGS.APILOGS.NESTEDTABLE.URL.TITLE.TEXT' | translate }}: </strong>
                  <span>{{ element['url'] }}</span>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedCols(); sticky: true"></tr>
        <tr mat-row [class.hidden]="toggleTableChart !== 'table'" *matRowDef="let element; columns: getDisplayedCols();"
            [class.expanded-row]="applyExpandedClass(element)" (click)="expandRow(element)"></tr>
        <tr style="height: 0;" mat-row *matRowDef="let element; columns: ['expandedDetail']"
            class="row-detail expandable-row"></tr>
      </table>
    </section>
    <section class="new-table-design-container mat-elevation-z8" tabindex="0">
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons
                     aria-label="Select page">
      </mat-paginator>
    </section>
  </div>
</div>
